/*
    ======== Table of Sections
    --------------------------
    ## half backgrounds
    --------------------------
    ## images
    --------------------------
    ## play btn
    --------------------------
    ## deviders
    --------------------------
    ## title line
    --------------------------
    ##  tabs
    --------------------------
    ##  table
    --------------------------
    ##  dropzone
    --------------------------
    ##  made by
    --------------------------
    ##   toolbar
    --------------------------
*/
/*--------------------------------------------------------------
## half backgrounds
--------------------------------------------------------------*/
.half_light {
    @include before {
        bottom: 0;
        background: $color_light;
        height: 50%;
        width: 100%;
        z-index: -1;
    }
}

.has_dark {
    @include before {
        height: 40%;
        bottom: 0;
        width: 100%;
        background: $color_dark;
        z-index: -1;
    }
}
/*--------------------------------------------------------------
## images
--------------------------------------------------------------*/
.img_top {
    border-radius: $border_radius;
    margin-bottom: 30px;
    box-shadow: $shadow_2;
    position: relative;
}
/*--------------------------------------------------------------
## play btn
--------------------------------------------------------------*/
.play_btn {
    @include absolute {
        display: flex;
        align-items: center;
        justify-content: center;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
        height: 4rem;
        width: 4rem;
        border-radius: 50%;
    }
}
/*--------------------------------------------------------------
## deviders
--------------------------------------------------------------*/
.devider {
    position: absolute;
    background-image: url(../images/others/devider.svg);
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 20px;
    bottom: -2px;
    left: 0;
    right: 0;
}
.devider_grey {
    position: absolute;
    background-image: url(../images/others/devider_grey.svg);
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 20px;
    bottom: -2px;
    left: 0;
    right: 0;
}
.devider_white {
    position: absolute;
    background-image: url(../images/others/devider.svg);
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 20px;
    bottom: -2px;
    left: 0;
    right: 0;
}
.devider_dark_top {
    background: url(../images/others/devider_light.svg);
    background-repeat: no-repeat;
    width: 100%;
    height: 6rem;
    background-position: top;
}
.devider_light_top {
    background: url(../images/others/devider_light.svg);
    background-repeat: no-repeat;
    width: 100%;
    height: 6rem;
    background-position: top;
}
.divider_light_bottom {
    background: url(../images/others/devider_light.svg);
    background-repeat: no-repeat;
    width: 100%;
    height: 6rem;
    background-position: bottom;
}
/*--------------------------------------------------------------
## title line
--------------------------------------------------------------*/
.title_line {
    position: relative;
    display: inline;
    z-index: 0;
    &::before {
        content: "";
        background-repeat: no-repeat;
        height: 20px;
        width: 100%;
        position: absolute;
        bottom: -13px;
        top: 33px;
        border-radius: 10px;
        right: -2px;
        background: $color_brand_light;
        background-size: contain;
        left: auto;
        z-index: -1;
    }
}
/*--------------------------------------------------------------
##  tabs
--------------------------------------------------------------*/
.nav-tabs {
    margin-bottom: 30px;
    border-bottom: none;
}
.nav-link {
    color: $color-dark;
    &:hover {
        border: none;
    }
}
.nav-link.active {
    border: none;
    border-bottom: solid 3px $color_brand;
    border-radius: $border_radius $border_radius 0 0;
    background: transparent;
    color: $color_brand;
}
/*--------------------------------------------------------------
##  table
--------------------------------------------------------------*/
.table-flush tbody td:first-child,
.table-flush tbody th:first-child,
.table-flush tfoot td:first-child,
.table-flush thead th:first-child {
    padding-left: 0;
}

.table-flush tbody td:last-child,
.table-flush tbody th:last-child,
.table-flush tfoot td:last-child,
.table-flush thead th:last-child {
    padding-right: 0;
}
.table td,
.table th {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-top: 1px solid #e5ebf1;
}
.table-white {
    background-color: white;
    padding: 0 30px 30px;
    border-radius: $border_radius;
}

.table th {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    font-weight: 400;
}

.table thead th {
    border-bottom: 0;
    text-transform: uppercase;
    font-size: 0.875rem;
    color: #8d9aae;
}

.table tfoot td {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.table-clickable [data-href]:hover {
    cursor: pointer;
}

.table-clickable [data-href]:hover .table-clickable-hover {
    color: #5546e8;
}

@media (max-width: 767.98px) {
    .table-features.table-features-alt td:nth-child(2),
    .table-features.table-features-alt th:nth-child(2) {
        display: none;
    }

    .table-features:not(.table-features-alt) td:nth-child(3),
    .table-features:not(.table-features-alt) th:nth-child(3) {
        display: none;
    }
}

/*--------------------------------------------------------------
##  dropzone
--------------------------------------------------------------*/
.dropzone {
    padding: 5rem 1rem;
    background-color: #fff;
    border: 2px dashed #c6d3e6;
    border-radius: 0.375rem;
    text-align: center;
    color: #869ab8;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-box-ordinal-group: 0;
    order: -1;
    cursor: pointer;
    z-index: 999;
    margin-bottom: 20px;
    margin-top: 2px;
    p {
        color: #526692;
    }
    &:hover {
        background-color: rgba(241, 242, 248, 0.562);

        p {
            color: $color_dark;
        }
    }
}
/*--------------------------------------------------------------
##  made by
--------------------------------------------------------------*/
.made_by {
    position: fixed !important;
    display: inline-block !important;
    visibility: visible !important;
    z-index: 2147483647 !important;
    top: auto !important;
    right: 12px !important;
    bottom: 12px !important;
    left: auto !important;
    color: #aaadb0 !important;
    background-color: #fff !important;
    border-radius: 3px !important;
    padding: 6px 8px 6px 6px !important;
    font-size: 12px !important;
    opacity: 1 !important;
    line-height: 14px !important;
    text-decoration: none !important;
    transform: none !important;
    margin: 0 !important;
    width: auto !important;
    height: auto !important;
    overflow: visible !important;
    white-space: nowrap;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}
/*--------------------------------------------------------------
##   domain form
--------------------------------------------------------------*/

.domain_form {
    background: $color_white;
    box-shadow: $shadow_3;
    display: flex;
    align-items: center;
    padding: 4px;
    border-radius: 5px;
    max-width: 50rem;
    border: solid 1px transparent;
    width: 100%;

    transition: all 0.2s ease-in-out;
    .btn_search {
        display: flex;
        align-items: center;
        justify-content: center;
        span {
            display: block;
        }
        i {
            font-size: 25px;
            line-height: 100%;
            margin-top: -4px;
        }
    }
    @include tablet_vert_screen {
        max-width: auto !important;
    }
    &:hover,
    &:focus {
        border: solid 1px $color_brand;
    }

    input {
        height: 30px;
        border: none;
        box-shadow: none;
        &:hover,
        &:focus {
            border: none;
            box-shadow: none;
        }
    }
    .btn {
        padding: 18px;
        width: 30%;
    }
}
.table_sh {
    box-shadow: $shadow_3;
    padding: 30px;
    border-radius: 10px;
    span {
        color: #6f7583;
    }
}
