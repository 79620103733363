/*--------------------------------------------------------------
## popups styles
--------------------------------------------------------------*/
#popup_form1 {
    .cover_sign {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }
    .popup_title {
        @include fluid-type($font_size_h4_mobile, $font_size_h3);
        margin-bottom: 30px;
    }
    .popup_description {
        margin-bottom: 30px;
    }
    .popup_wrap {
    }
    .modal-body {
        padding: $spacer_20;
    }
}
#popup_form2 {
    ion-icon {
        color: white;
    }
    .modal-body {
        background: $color_brand;
        padding: $spacer_40;
        .popup_title {
            @include fluid-type($font_size_h4_mobile, $font_size_h3);
            margin-bottom: 30px;
            color: white;
            text-align: center;
        }
        .popup_description {
            margin-bottom: 30px;
            color: white;
            text-align: center;
        }
    }
}
#popup_form3 {
    .modal-dialog {
        bottom: -76% !important;
        @include tablet_vert_screen {
            bottom: 0 !important;
        }
    }
    .close {
        position: absolute;
        top: 15px;
        right: 15px;
        background: rgba(255, 255, 255, 0.74);
        z-index: 6;
        &:hover {
            ion-icon {
                color: $color_dark;
            }
        }
    }
    .popup_wrap {
        padding: 30px;
        @include before {
            background: url(../assets/images/others/top_decoration.png);
            top: -16px;
            left: -16px;
            height: 100%;
            width: 100%;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
    .popup_title {
        @include fluid-type($font_size_h4_mobile, $font_size_h3);
        margin-bottom: 30px;
    }
}
#popup_notification1 {
    .popup_wrap {
        padding: $spacer_40;
    }
    .popup_title {
        @include fluid-type($font_size_h4_mobile, $font_size_h3);
        margin-bottom: 30px;
        text-align: center;
    }
    .popup_description {
        text-align: center;
    }
    img {
        width: 14rem;
        margin-left: auto;
        margin-right: auto;
    }
}
#popup_notification2 {
    .modal-dialog {
        bottom: -20% !important;
        @include tablet_vert_screen {
            bottom: 0 !important;
        }
    }
    .popup_wrap {
        padding: $spacer_60 $spacer_40;
    }
    .close {
        position: absolute;
        top: 15px;
        right: 15px;
        background: rgba(255, 255, 255, 0.74);
    }
    .top_img {
        overflow: hidden;
        width: 100%;
        img {
            height: 15rem;
            object-fit: cover;
            width: 100%;
        }
    }
    .modal-body {
        padding: 0;
        border: none;
    }
    .popup_title {
        @include fluid-type($font_size_h4_mobile, $font_size_h3);
        margin-bottom: 30px;
        text-align: center;
    }
    .popup_description {
        text-align: center;
        margin-bottom: 30px;
    }
    .popup_wrap {
        @include before {
            background: url(../assets/images/others/circle_decoration.png);
            top: -46%;
            left: 1%;
            height: 100%;
            width: 100%;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
}
#popup_notification3 {
    .modal-dialog {
        bottom: -20% !important;
        @include tablet_vert_screen {
            bottom: 0 !important;
        }
    }
    .popup_wrap {
        padding: $spacer_60 $spacer_40;
    }
    .close {
        position: absolute;
        top: 15px;
        right: 15px;
        background: rgb(255, 255, 255);
    }
    .modal-body {
        padding: 0;
        border: none;
        background: $color_brand;
    }
    .popup_title {
        @include fluid-type($font_size_h4_mobile, $font_size_h4);
        margin-bottom: 30px;
        color: white;
    }
    .popup_description {
        margin-bottom: 30px;
        color: white;
    }
    .popup_wrap {
        overflow: hidden;
        @include before {
            background: url(../assets/images/others/circle_decoration.png);
            top: -46%;
            left: 1%;
            height: 100%;
            width: 100%;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
    .top_img {
        overflow: hidden;
        width: 100%;
        img {
            height: 15rem;
            object-fit: cover;
            width: 100%;
        }
    }
}
#popup_cookies1 {
    .modal-dialog {
        bottom: -20% !important;
        @include tablet_vert_screen {
            bottom: 0 !important;
        }
    }
    .modal-content {
        min-width: 21rem;
    }

    .popup_wrap {
        padding: $spacer_30;
    }
    .close {
        position: absolute;
        top: 15px;
        right: 15px;
        background: rgb(255, 255, 255);
        z-index: 5;
    }
    .modal-body {
        padding: 0;
        border: none;
        background: white;
    }
    .top_img {
        max-width: 5rem;
        margin-bottom: 20px;
    }
    .popup_title {
        @include fluid-type($font_size_h4_mobile, $font_size_h4);
        margin-bottom: 20px;
    }
    .popup_description {
        margin-bottom: 20px;
    }
    .popup_wrap {
        overflow: hidden;
        @include before {
            background: url(../assets/images/others/circle_decoration.png);
            top: -46%;
            left: 1%;
            height: 100%;
            width: 100%;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
}
#popup_cookies2 {
    .modal-dialog {
        bottom: -20% !important;
        @include tablet_vert_screen {
            bottom: 0 !important;
        }
    }
    .popup_wrap {
        padding: $spacer_30;
    }
    .close {
        position: absolute;
        top: 15px;
        right: 15px;
        background: rgb(255, 255, 255);
        z-index: 9;
        &:hover {
            ion-icon {
                color: $color_dark;
            }
        }
    }
    .modal-body {
        padding: 0;
        border: none;
        background: white;
    }
    .top_img {
        max-width: 5rem;
        margin-right: 30px;
    }
    .popup_title {
        @include fluid-type($font_size_h4_mobile, $font_size_h4);
    }
    .popup_description {
        max-width: 30rem;
    }
    .popup_wrap {
        overflow: hidden;
        @include before {
            background: url(../assets/images/others/circle_decoration.png);
            top: -46%;
            left: 1%;
            height: 100%;
            width: 100%;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
}
#popup_cookies3 {
    .popup_wrap {
        padding: $spacer_30;
        background: $color_brand;
        border: solid 10px rgba(255, 255, 255, 0.212);
    }
    .modal-body {
        padding: 0;
        border: none;
        background: white;
    }
    .top_img {
        max-width: 5rem;
        margin-right: 30px;
    }
    .popup_title {
        @include fluid-type($font_size_h4_mobile, $font_size_h4);
        color: white;
    }
    .popup_description {
        max-width: 30rem;
        color: white;
    }

    .popup_wrap {
        overflow: hidden;
        @include before {
            background: url(../assets/images/others/circle_decoration.png);
            top: -46%;
            right: 1%;
            height: 100%;
            width: 100%;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
}
