// === Template colors

$color_brand: #be346a;
$color_brand_second: #683769;
$color_brand_light: #faf4f6;
$color_grad: linear-gradient(45deg, #4c3d69 0%, #c73e67 100%);
$color_white: #fff;
$color_light: #f3f8ff;
$color_neutral: #858c9b;
$color_dark: #10154e;
$color_dark_lighten: #2e3158;

$color_error: #de4b53;
$color_success: #3dcabd;

$color_orange: #ff784b;
$color_text: #232226;

// === end

$maxMobile: "(max-width:767px)";
$maxSmall: "(max-width:991px)";
$minSmall: "(min-width:768px)";
$minMedium: "(min-width:992px)";
$minLarge: "(min-width:1200px)";

/*-----------------------------
  Colors
-----------------------------*/
$orange-red-color: #fd6b3b;
$yollow-color: #ffc329;
$aquamarine-color: #17e79b;
$orange-color: #f28e1c;
$red-color: #f93542;
$gray-color: #6c7a87;
$light-color: #9da6af;
$bg-color: #f7f8fa;
$border-color: #edeef1;
$beiget-color: #ffe7aa;
$green-color: #19a389;
$lightgreen-color: #7bd88a;
$sea-color: #4d81f7;
$gold-color: #ffce53;
$purple-color: #6f5fa6;
$green2-color: #31d1ab;
$black-color: #000;
$blue2-color: #3875f7;
$orange2-color: #fd7028;
// color Currency
$sky-color: #6bb0f8;
$currency-p-color: #aa9bb3; //pargraph
$magenta-color: #fd4ffe;
$currency-d-color: #29013e;
