.case_study {
    .case_box {
        .img {
            border-radius: 5px;
            position: relative;
            z-index: 0;
            transition: 0.4s $transition_function;
        }
        .box_wrap {
            border-radius: 5px;
            padding: 10px 20px;
            box-shadow: $shadow_3;
            margin: -20px 20px 0 20px;
            position: relative;
            background: $color-white;
            z-index: 2;
            transition: 0.3s $transition_function;
        }
        .box_title {
            transition: 0.6s $transition_function;
        }
        .tags {
            transition: 0.6s $transition_function;
            opacity: 1;
        }
        &:hover {
            .tags {
                transform: translateY(20px);
                opacity: 0;
            }
            .box_title {
                transform: translateY(-10px);
            }
            .box_wrap {
                transform: translateY(-40px);
            }
            .img {
                filter: opacity(0.5);
            }
        }
    }
}
