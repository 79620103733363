/*-----------------------------
  Colors
-----------------------------*/
/*-----------------------------
  media query
-----------------------------*/
/*-----------------------------
    mixin prefix
  -----------------------------*/
/*-----------------------------
    mixin animation
  -----------------------------*/
body {
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-font-smoothing: antialiased !important;
  -moz-font-smoothing: antialiased !important;
  -o-font-smoothing: antialiased !important;
  -ms-font-smoothing: antialiased !important;
  font-smoothing: antialiased !important;
  font-family: "SoraStd", sans-serif;
  font-size: 16px;
  color: #10154e;
  -webkit-transition: 0.3s ease-in-out !important;
  -moz-transition: 0.3s ease-in-out !important;
  -o-transition: 0.3s ease-in-out !important;
  -ms-transition: 0.3s ease-in-out !important;
  transition: 0.3s ease-in-out !important;
}

body.modal-open {
  padding-right: 0 !important;
  overflow: auto;
}

#wrapper,
#content {
  width: 100%;
  height: 100%;
}

/*-----------------------------
  button, input , a , btn
-----------------------------*/
button,
input {
  -webkit-outline: 0 !important;
  -moz-outline: 0 !important;
  -o-outline: 0 !important;
  -ms-outline: 0 !important;
  outline: 0 !important;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.btn,
.btn.focus,
.btn:active,
.btn.active,
.btn:focus {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  -o-box-shadow: none !important;
  -ms-box-shadow: none !important;
  box-shadow: none !important;
  border: 0;
}

img {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -o-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/*-----------------------------
  Selection
-----------------------------*/
::selection {
  background-color: #be346a;
  color: #fff;
}

.title_sections {
  margin-bottom: 6.25rem;
}

.title_sections .before_title {
  margin-bottom: 1.25rem;
}

.title_sections .before_title span {
  color: #10154e;
  font-size: 1rem;
  font-weight: 500;
}

.title_sections .before_title span:last-child {
  color: #be346a;
}

.title_sections h2 {
  font-size: 1.875rem;
  margin-bottom: 1.25rem;
  font-weight: 600;
  color: #10154e;
}

.title_sections p {
  color: #6c7a87;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 1.25rem;
}

@media (max-width: 991px) {
  .title_sections {
    margin-bottom: 2.5rem;
  }
}

.title_sections_inner {
  margin-bottom: 3.125rem;
}

.title_sections_inner .before_title {
  margin-bottom: 1.25rem;
}

.title_sections_inner .before_title span {
  color: #10154e;
  font-size: 1rem;
  font-weight: 500;
}

.title_sections_inner .before_title span:last-child {
  color: #be346a;
}

.title_sections_inner h2 {
  font-size: 1.875rem;
  margin-bottom: 1.25rem;
  font-weight: 600;
  color: #10154e;
}

.title_sections_inner p {
  color: #6c7a87;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 1.25rem;
}

.title_sections_inner .btn {
  margin-top: 1.25rem;
}

@media (max-width: 991px) {
  .title_sections_inner {
    margin-bottom: 2.5rem;
  }
}

/*-----------------------------
  loading_overlay
-----------------------------*/
.loaded_page #particles-js {
  z-index: 12;
}

.loaded_page .loading_overlay {
  opacity: 0;
  pointer-events: none;
  -webkit-transition: opacity 0.6s !important;
  -moz-transition: opacity 0.6s !important;
  -o-transition: opacity 0.6s !important;
  -ms-transition: opacity 0.6s !important;
  transition: opacity 0.6s !important;
}

.loading_overlay {
  background-color: #f3f8ff;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading_overlay .loader_logo {
  position: relative;
}

.loading_overlay .loader_logo .logo {
  -webkit-animation: text-focus-in 1.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both !important;
  -moz-animation: text-focus-in 1.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both !important;
  -o-animation: text-focus-in 1.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both !important;
  -ms-animation: text-focus-in 1.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both !important;
  animation: text-focus-in 1.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both !important;
}

.black_left {
  -webkit-box-shadow: inset 60px 0px 0px 0px #000 !important;
  -moz-box-shadow: inset 60px 0px 0px 0px #000 !important;
  -o-box-shadow: inset 60px 0px 0px 0px #000 !important;
  -ms-box-shadow: inset 60px 0px 0px 0px #000 !important;
  box-shadow: inset 60px 0px 0px 0px #000 !important;
}

@media (max-width: 767px) {
  .black_left {
    -webkit-box-shadow: inset 0px 0px 0px 0px #000 !important;
    -moz-box-shadow: inset 0px 0px 0px 0px #000 !important;
    -o-box-shadow: inset 0px 0px 0px 0px #000 !important;
    -ms-box-shadow: inset 0px 0px 0px 0px #000 !important;
    box-shadow: inset 0px 0px 0px 0px #000 !important;
  }
}

.shadow-none {
  -webkit-box-shadow: nnoe !important;
  -moz-box-shadow: nnoe !important;
  -o-box-shadow: nnoe !important;
  -ms-box-shadow: nnoe !important;
  box-shadow: nnoe !important;
}

.filter-blur {
  -webkit-background: rgba(255, 255, 255, 0.2) !important;
  -moz-background: rgba(255, 255, 255, 0.2) !important;
  -o-background: rgba(255, 255, 255, 0.2) !important;
  -ms-background: rgba(255, 255, 255, 0.2) !important;
  background: rgba(255, 255, 255, 0.2) !important;
  -webkit-backdrop-filter: saturate(1) blur(20px) !important;
  -moz-backdrop-filter: saturate(1) blur(20px) !important;
  -o-backdrop-filter: saturate(1) blur(20px) !important;
  -ms-backdrop-filter: saturate(1) blur(20px) !important;
  backdrop-filter: saturate(1) blur(20px) !important;
}

.fixed-header .when-scroll .btn {
  color: #10154e !important;
}

/*=========================================
    - backgrounds    
=========================================*/
.bg_brand {
  background: #be346a !important;
}

.bg_brand_light {
  background: #faf4f6 !important;
}

.bg_dark {
  background: #10154e !important;
}

.bg_white {
  background: #fff !important;
}

.bg_grad {
  background: linear-gradient(45deg, #4c3d69 0%, #c73e67 100%) !important;
}

.bg_light {
  background: #f3f8ff !important;
}

.bg_error {
  background: #de4b53 !important;
}

.bg_success {
  background: #3dcabd !important;
}

.bg-blue {
  background-color: #be346a !important;
}

.bg-gold {
  background-color: #ffce53 !important;
}

.bg-green {
  background-color: #19a389 !important;
}

.bg-aquamarine {
  background-color: #17e79b !important;
}

.bg-orange {
  background-color: #f28e1c !important;
}

.bg-red {
  background-color: #f93542 !important;
}

.bg-yollow {
  background-color: #ffc329 !important;
}

.bg-gray {
  background-color: #6c7a87 !important;
}

.bg-light {
  background-color: #9da6af !important;
}

.bg-beiget {
  background-color: #ffe7aa !important;
}

.bg-skuy {
  background-color: #6bb0f8 !important;
}

.bg-magenta {
  background-color: #fd4ffe !important;
}

.bg-green2 {
  background-color: #31d1ab !important;
}

.bg-currency-d {
  background-color: #29013e !important;
}

.bg-orange-red {
  background-color: #fd6b3b !important;
}

.bg-currency-p {
  background-color: #aa9bb3 !important;
}

.bg-sea {
  background-color: #4d81f7 !important;
}

.bg-purple {
  background-color: #6f5fa6 !important;
}

.bg-lightgreen {
  background-color: #7bd88a !important;
}

.bg-black {
  background-color: #000 !important;
}

.bg-snow {
  background-color: #f7f8fa !important;
}

.bg-blue2 {
  background-color: #3875f7 !important;
}

.bg-orange2 {
  background-color: #fd7028 !important;
}

.btn-block {
  width: 100%;
}

/*=========================================
    - colors    
=========================================*/
.color_brand {
  color: #be346a !important;
}

.color_second {
  color: #683769 !important;
}

.color_light {
  color: #faf4f6 !important;
}

.color_white {
  color: #fff !important;
}

.color_light {
  color: #f3f8ff !important;
}

.color_neutral {
  color: #858c9b !important;
}

.color_dark {
  color: #10154e !important;
}

.color_dark_lighten {
  color: #2e3158 !important;
}

.color_error {
  color: #de4b53 !important;
}

.color_success {
  color: #3dcabd !important;
}

/* =======================================================
  - - - - - - - - 1 / spacing
========================================================== */
.half_hero {
  margin-top: -200px !important;
}

.space_half_hero {
  margin-top: 100px !important;
}

.top-15 {
  top: 15px;
}

.height-100_reset {
  margin-top: -100px;
}

.height-100 {
  height: 100px;
}

.top_hero_reset {
  height: 20rem;
}

.top_hero {
  margin-top: -20rem;
}

/* =======================================================
  - - - - - - - - 2 / Padding classes
========================================================== */
.py-1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-2 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-3 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-4 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-5 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.py-6 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.py-7 {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important;
}

.py-8 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}

.py-9 {
  padding-top: 9rem !important;
  padding-bottom: 9rem !important;
}

.py-10 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-helf {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-20 {
  padding: 20px !important;
}

.p-30 {
  padding: 30px;
}

.p-40 {
  padding: 40px;
}

.p-50 {
  padding: 50px;
}

.p-60 {
  padding: 60px;
}

.p-70 {
  padding: 70px;
}

.p-80 {
  padding: 80px;
}

.p-90 {
  padding: 90px;
}

.p-100 {
  padding: 10rem;
}

/* ------------------------------------ padding top*/
.pt-helf {
  padding-top: 5px;
}

.pt-0 {
  padding-top: 0rem !important;
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-80 {
  padding-top: 80px !important;
}

.pt-90 {
  padding-top: 90px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-120 {
  padding-top: 120px;
}

/* ------------------------------------ padding bottom*/
.pb-helf {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-100 {
  padding-bottom: 10rem;
}

/* ------------------------------------ padding bottom*/
.pl-helf {
  padding-left: 0.5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-60 {
  padding-left: 60px;
}

.pl-70 {
  padding-left: 70px;
}

.pl-80 {
  padding-left: 80px;
}

.pl-90 {
  padding-left: 90px;
}

.pl-100 {
  padding-left: 10rem;
}

/* ------------------------------------ padding bottom*/
@media screen and (max-width: 576px) {
  .pr-sm-0 {
    padding-right: 0 !important;
  }
}

@media screen and (max-width: 576px) {
  .pl-sm-0 {
    padding-left: 0 !important;
  }
}

.pr-helf {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.pr-30 {
  padding-right: 30px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-60 {
  padding-right: 60px;
}

.pr-70 {
  padding-right: 70px;
}

.pr-80 {
  padding-right: 80px;
}

.pr-90 {
  padding-right: 90px;
}

.pr-100 {
  padding-right: 10rem;
}

/* =======================================================
  - - - - - - - - 2 / Margin classes
========================================================== */
@media screen and (max-width: 576px) {
  .mr-0-sm {
    margin-right: 0rem !important;
  }
}

.m-0 {
  margin: 0;
}

.m-helf {
  margin: 0.5px;
}

.m-10 {
  margin: 10px;
}

.m-20 {
  margin: 20px;
}

.m-30 {
  margin: 30px;
}

.m-40 {
  margin: 40px;
}

.m-50 {
  margin: 50px;
}

.m-60 {
  margin: 60px;
}

.m-70 {
  margin: 70px;
}

.m-80 {
  margin: 80px;
}

.m-90 {
  margin: 90px;
}

.m-100 {
  margin: 10rem;
}

/* ------------------------------------ margin top*/
@media screen and (max-width: 576px) {
  .mt-sm-30 {
    margin-top: 30px;
  }
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-30_reset {
  margin-bottom: -30px !important;
}

.mb-1_reset {
  margin-bottom: -1rem !important;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-helf {
  margin-top: 0.5px;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-100 {
  margin-top: 10rem;
}

/* ------------------------------------ margin bottom*/
.mb-helf {
  margin-bottom: 0.5px;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-1_reset {
  margin-bottom: -1rem;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-20_reset {
  margin-bottom: -20px;
}

.mb-30_reset {
  margin-bottom: -30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-100 {
  margin-bottom: 100px;
}

/* ------------------------------------ padding bottom*/
.ml-helf {
  margin-left: 0.5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-70 {
  margin-left: 70px;
}

.ml-80 {
  margin-left: 80px;
}

.ml-90 {
  margin-left: 90px;
}

.ml-100 {
  margin-left: 10px;
}

/* ------------------------------------ margin bottom*/
.mr-helf {
  margin-right: 0.5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-70 {
  margin-right: 70px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-90 {
  margin-right: 90px;
}

.mr-100 {
  margin-right: 100px;
}

.w-100 {
  width: 100%;
}

.w-33 {
  width: 33.33%;
}

/* =======================================================
  - - - - - - - - 3 / hidden classes
========================================================== */
@media screen and (max-width: 992px) {
  .lg-hidden {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .md-hidden {
    display: none;
  }
}

@media screen and (max-width: 576px) {
  .sm-hidden {
    display: none;
  }
}

/* =======================================================
  - - - - - - - - 5 /  flex desiplay
========================================================== */
.flex {
  display: flex;
  flex-wrap: wrap;
}

.flex.start {
  justify-content: flex-start;
}

.flex.center {
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .flex.md-center {
    justify-content: center;
  }
}

@media screen and (max-width: 576px) {
  .flex.sm-center {
    justify-content: center;
  }
}

.flex.vcenter {
  align-items: center;
}

.flex.end {
  justify-content: flex-end;
}

.flex.vend {
  align-items: flex-end;
}

.flex.between {
  justify-content: space-between;
}

.flex.is-column {
  flex-direction: column;
}

.white-link {
  color: #fff;
  transition: 0.3s;
}

.white-link:hover {
  opacity: 0.7;
  color: #fff;
  font-size: 15.5px;
}

/* =======================================================
  - - - - - - - - 6 /  border-radius
========================================================== */
.border-rad5 {
  border-radius: 5px;
}

.border-rad10 {
  border-radius: 10px;
}

.border-rad15 {
  border-radius: 15px;
}

.border-rad20 {
  border-radius: 20px;
}

.border-rad50 {
  border-radius: 50px;
}

/* =======================================================
  - - - - - - - - 7 /  box-Shadows
========================================================== */
.sh_1 {
  box-shadow: 52px 52px 74px 0 rgba(115, 131, 169, 0.08);
  border: solid 1px #edf8fa;
  border-radius: 15px;
}

.sh_2 {
  box-shadow: 0 40px 40px -20px rgba(23, 29, 48, 0.1);
  border: solid 1px #edf8fa;
  border-radius: 15px;
}

.sh_3 {
  box-shadow: 0 33px 88px 0 #1d0e6211;
  border: solid 1px #edf8fa;
  border-radius: 15px;
}

/* =======================================================
  - - - - - - - - 8 /  Animations
========================================================== */
@keyframes anim-up-down {
  from {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50% {
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px);
  }
  to {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

.anim-up-down {
  animation-name: anim-up-down;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes animbg {
  from {
    background-size: 130%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  50% {
    background-size: 120%;
    background-blend-mode: saturation;
    background-repeat: no-repeat;
  }
  to {
    background-size: 130%;
    background-repeat: no-repeat;
  }
}

@keyframes anim2 {
  from {
    height: 120%;
    width: 120%;
  }
  50% {
    height: 170%;
    width: 170%;
  }
  70% {
    height: 120%;
    width: 120%;
  }
  to {
    height: 130%;
    width: 130%;
  }
}

@keyframes anim1 {
  from {
    height: 180%;
    width: 180%;
  }
  50% {
    height: 140%;
    width: 140%;
  }
  70% {
    height: 150%;
    width: 150%;
  }
  to {
    height: 180%;
    width: 180%;
  }
}

/* =======================================================
  - - - - - - - - 9 /  Text floats
========================================================== */
.text-primary {
  color: #be346a !important;
}

.text-secondary {
  color: #683769 !important;
}

/* ----------------------------- Font Size */
.font-s-100 {
  font-size: 10px;
}

.font-s-20 {
  font-size: 20px;
}

.font-s-30 {
  font-size: 30px;
}

.font-s-40 {
  font-size: 40px;
}

.font-s-50 {
  font-size: 50px;
}

.font-s-60 {
  font-size: 60px;
}

.font-s-70 {
  font-size: 70px;
}

.font-s-80 {
  font-size: 80px;
}

.font-s-90 {
  font-size: 90px;
}

.font-s-100 {
  font-size: 100px;
}

/* ----------------------------- Font weight */
.font-w-400 {
  font-weight: 400 !important;
}

.font-w-500 {
  font-weight: 500 !important;
}

.font-w-600 {
  font-weight: 600 !important;
}

/* ----------------------------- Text floats */
.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-deco {
  text-decoration: underline;
}

/* =======================================================
  - - - - - - - - 10 /  OTHER
========================================================== */
.before-none::before {
  content: none !important;
}

/* ----------------------------- display none classes  */
@media screen and (max-width: 576px) {
  .sm-none {
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  .md-none {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .lg-none {
    display: none;
  }
}

@media screen and (max-width: 1400px) {
  .xl-none {
    display: none;
  }
}

/* ------------------------------------ display block classes  */
@media screen and (max-width: 576px) {
  .sm-block {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .md-block {
    display: block;
  }
}

@media screen and (max-width: 992px) {
  .lg-block {
    display: block;
  }
}

@media screen and (max-width: 1400px) {
  .xl-block {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .mb-sm-20 {
    margin-bottom: 20px;
  }
}

.top-border {
  border-top: solid 1px #f3f3f3;
}

.min-30 {
  margin-bottom: -30px;
}

.min-40 {
  margin-bottom: -40px;
}

.mt-section {
  margin-top: 70px;
}

@media screen and (max-width: 768px) {
  .mb-sm-30 {
    margin-bottom: 30px;
  }
}

.rounded {
  position: absolute;
  width: 100%;
  top: -11px;
}

@media screen and (max-width: 992px) {
  .rounded {
    top: -1px;
  }
}

.top-0 {
  top: 0 !important;
}

.mb-70 {
  margin-bottom: 70px;
}

.min-20 {
  margin-bottom: -20px;
}

.text-copyright {
  color: rgba(255, 255, 255, 0.548) !important;
}

.max-30 {
  max-width: 30rem;
}

.max-20 {
  max-width: 20rem;
}

.min-60 {
  margin-bottom: -60px;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.spacer_header {
  height: 100px;
}

.spacer_header_top {
  height: 100px;
}

.spacer_10 {
  height: 10px;
}

.spacer_bottom {
  padding-bottom: 90px;
}

.filter-blur {
  -webkit-background: rgba(255, 255, 255, 0.2) !important;
  -moz-background: rgba(255, 255, 255, 0.2) !important;
  -o-background: rgba(255, 255, 255, 0.2) !important;
  -ms-background: rgba(255, 255, 255, 0.2) !important;
  background: rgba(255, 255, 255, 0.2) !important;
  -webkit-backdrop-filter: saturate(1) blur(20px) !important;
  -moz-backdrop-filter: saturate(1) blur(20px) !important;
  -o-backdrop-filter: saturate(1) blur(20px) !important;
  -ms-backdrop-filter: saturate(1) blur(20px) !important;
  backdrop-filter: saturate(1) blur(20px) !important;
}

.h-fit-content {
  height: 100%;
}

.md\:flex {
  display: flex;
}

/*--------------------------------------------------------------
## Include Fonts
--------------------------------------------------------------*/
@font-face {
  font-family: "Sora-Bold";
  src: url("../fonts/Sora/static/Sora-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Sora";
  src: url("../fonts/Sora/static/Sora-Medium.ttf") format("truetype");
  font-weight: normal;
}

/*--------------------------------------------------------------
## Base
--------------------------------------------------------------*/
html {
  scroll-behavior: smooth;
}

body {
  font-family: "Sora", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 15px;
  letter-spacing: 0.3px;
  color: #10154e;
}

@media screen and (max-width: 768px) {
  body {
    font-size: 15.5px;
  }
}

body.is-dark {
  background: #2d3a58;
  color: #fff;
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1220px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    max-width: 800px !important;
  }
}

@media screen and (max-width: 1400px) {
  .container {
    max-width: 1200px;
  }
}

@media only screen and (min-width: 1510px) {
  .index_page .container {
    max-width: 1400px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: "Sora-Bold", -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

ul {
  margin: 0;
  padding: 0;
}

section:focus {
  outline: none !important;
}

a {
  transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  text-decoration: none !important;
}

a:hover {
  text-decoration: none;
}

li {
  list-style: none;
}

p {
  font-family: "Sora";
  color: #6f7583;
}

/*--------------------------------------------------------------
## swiper reset
--------------------------------------------------------------*/
.swiper-pagination-bullet {
  margin-top: 20px;
  width: 12px !important;
  height: 12px !important;
}

.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0px !important;
}

.swiper-pagination-bullet-active {
  background: #be346a !important;
}

/*--------------------------------------------------------------
## prismjs reset
--------------------------------------------------------------*/
.code-toolbar {
  position: relative;
}

.code-toolbar .toolbar {
  position: absolute;
  top: 20px;
  right: 20px;
}

.code-toolbar button {
  background: #be346a;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 5px;
  outline: none;
  border: none;
  box-shadow: none;
}

.sm_dropdown.show {
  height: 180px !important;
}

.single-drop.show {
  height: 300px !important;
}

.single-drop.show .drop_dark {
  display: block;
  padding: 20px 15px !important;
}

.single-drop.show .pl-10 {
  padding-left: 0 !important;
}

.heading {
  margin-bottom: 30px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  clear: both;
  font-family: "Sora-Bold", -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
  font-weight: bold;
  text-align: inherit;
  position: relative;
  color: #10154e;
  letter-spacing: -0.01em;
}

a {
  color: #be346a;
}

a:hover {
  color: #be346a;
}

h1 {
  font-size: calc(46px + 18 * ( (100vw - 576px) / 1024));
  line-height: 1;
  letter-spacing: -0.03em;
}

@media screen and (max-width: 576px) {
  h1 {
    font-size: 46px;
  }
}

@media screen and (min-width: 1600px) {
  h1 {
    font-size: 64px;
  }
}

@media screen and (max-width: 768px) {
  h1 {
    line-height: 1 !important;
  }
}

h2 {
  font-size: calc(40px + 14 * ( (100vw - 576px) / 1024));
  line-height: 1.15;
  letter-spacing: -0.02em;
}

@media screen and (max-width: 576px) {
  h2 {
    font-size: 40px;
  }
}

@media screen and (min-width: 1600px) {
  h2 {
    font-size: 54px;
  }
}

h3 {
  font-size: calc(36px + 4 * ( (100vw - 576px) / 1024));
  line-height: 1.1;
}

@media screen and (max-width: 576px) {
  h3 {
    font-size: 36px;
  }
}

@media screen and (min-width: 1600px) {
  h3 {
    font-size: 40px;
  }
}

h4 {
  font-size: calc(24px + 2 * ( (100vw - 576px) / 1024));
  line-height: 1.35;
}

@media screen and (max-width: 576px) {
  h4 {
    font-size: 24px;
  }
}

@media screen and (min-width: 1600px) {
  h4 {
    font-size: 26px;
  }
}

h5 {
  font-size: calc(19px + 1 * ( (100vw - 576px) / 1024));
  line-height: 1.5;
}

@media screen and (max-width: 576px) {
  h5 {
    font-size: 19px;
  }
}

@media screen and (min-width: 1600px) {
  h5 {
    font-size: 20px;
  }
}

h6 {
  font-size: 1.05em;
  line-height: 1.5;
  letter-spacing: 0;
}

/*--------------------------------------------------------------
## Typography
--------------------------------------------------------------*/
p {
  width: 100%;
  margin: 10px auto;
  display: block;
  line-height: 1.6;
}

/*--------------------------------------------------------------
## accordion styles
--------------------------------------------------------------*/
.accordion {
  background: transparent !important;
  overflow: inherit !important;
}

.accordion .card {
  border-radius: 8px;
  border: 0;
  margin-bottom: 1rem;
  border: solid 1px #f1f3f5;
}

.accordion .card .card-header {
  padding: 0.5rem;
  background-color: white;
  box-shadow: 0 33px 88px 0 #1d0e6211;
  border-radius: 8px;
  border-color: #edeef1;
  border-bottom: 0;
}

.accordion .card .card-header .btn-link {
  background: transparent;
  border-color: transparent !important;
  width: 100%;
  text-align: left;
}

.accordion .card .card-header .btn-link i {
  color: #10154e;
  vertical-align: middle;
  font-size: 22px;
  margin-right: 5px;
  margin-top: -2px;
}

.accordion .card .card-header .btn-link:hover {
  border-color: transparent !important;
}

.accordion .card .card-header .btn-link::before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z'/%3E%3C/svg%3E");
  float: right;
  margin-top: -2px;
  font-size: 20px;
  color: #858c9b;
  transition: 0.25 cubic-bezier(0.4, 0, 0.2, 1);
  height: 20px;
  width: 20px;
  -webkit-transition: 0.4s !important;
  -moz-transition: 0.4s !important;
  -o-transition: 0.4s !important;
  -ms-transition: 0.4s !important;
  transition: 0.4s !important;
}

.accordion .card .card-header.active .btn-link::before {
  -webkit-transform: rotate(180deg) !important;
  -moz-transform: rotate(180deg) !important;
  -o-transform: rotate(180deg) !important;
  -ms-transform: rotate(180deg) !important;
  transform: rotate(180deg) !important;
}

.accordion .card .card-header h3 .btn-link {
  color: #10154e;
  font-size: 15.5px;
  font-weight: 500;
  text-decoration: none;
}

.accordion .card .card-header h3 .btn-link:hover {
  text-decoration: none;
}

.accordion .card .card-body p {
  font-size: 14px !important;
  color: #858c9b !important;
  font-weight: 400 !important;
  margin: 0px;
}

/*--------------------------------------------------------------
## effects styles
--------------------------------------------------------------*/
/*-----------------------------
  ripple
-----------------------------*/
.ripple:active:before, .ripple:focus:before {
  background: #be346a;
  border-radius: 50px;
  content: "";
  display: block;
  height: 100%;
  opacity: 0.25;
  position: absolute;
  width: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  animation: ripple 1s cubic-bezier(0.29, -0.01, 0.17, 0.95);
}

/*-----------------------------
    ripple_circle
  -----------------------------*/
.ripple_circle:active:before, .ripple_circle:focus:before {
  background: #be346a;
  border-radius: 50%;
  content: "";
  display: block;
  height: 100%;
  opacity: 0.25;
  position: absolute;
  width: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  animation: rippleCircle 0.6s cubic-bezier(0.29, -0.01, 0.17, 0.95);
}

/*-----------------------------
    ripple_box
  -----------------------------*/
.ripple_box:active:before, .ripple_box:focus:before {
  background: #be346a;
  border-radius: 8px;
  content: "";
  display: block;
  height: 100%;
  opacity: 0.25;
  position: absolute;
  width: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  animation: ripple 0.7s cubic-bezier(0.29, -0.01, 0.17, 0.95);
}

/*-----------------------------
    scale
  -----------------------------*/
.scale {
  -webkit-transition: 0.3s !important;
  -moz-transition: 0.3s !important;
  -o-transition: 0.3s !important;
  -ms-transition: 0.3s !important;
  transition: 0.3s !important;
  transform: scale(1) translateZ(0);
}

.scale:active {
  transform: scale(0.95);
}

/*-----------------------------
    anime on hover (effect-letter)
  -----------------------------*/
.effect-letter {
  position: relative;
  overflow: hidden;
}

.effect-letter:not(.simple):before {
  content: "";
  position: absolute;
  background: var(--pb, var(--hover-back));
  top: 0;
  left: 0;
  right: 0;
  height: 200%;
  border-radius: var(--br, 40%);
  -webkit-transform: translateY(var(--y, 50%));
  transform: translateY(var(--y, 50%));
  -webkit-transition: border-radius 0.5s ease var(--br-d, 0.08s), -webkit-transform var(--d, 0.4s) ease-in var(--d-d, 0s);
  transition: border-radius 0.5s ease var(--br-d, 0.08s), -webkit-transform var(--d, 0.4s) ease-in var(--d-d, 0s);
  transition: transform var(--d, 0.4s) ease-in var(--d-d, 0s), border-radius 0.5s ease var(--br-d, 0.08s);
  transition: transform var(--d, 0.4s) ease-in var(--d-d, 0s), border-radius 0.5s ease var(--br-d, 0.08s), -webkit-transform var(--d, 0.4s) ease-in var(--d-d, 0s);
}

.effect-letter:not(.simple):after {
  content: "";
  position: absolute;
  background: var(--pb, var(--hover-back));
  top: 0;
  left: 0;
  right: 0;
  height: 200%;
  border-radius: var(--br, 40%);
  -webkit-transform: translateY(var(--y, 50%));
  transform: translateY(var(--y, 50%));
  -webkit-transition: border-radius 0.5s ease var(--br-d, 0.08s), -webkit-transform var(--d, 0.4s) ease-in var(--d-d, 0s);
  transition: border-radius 0.5s ease var(--br-d, 0.08s), -webkit-transform var(--d, 0.4s) ease-in var(--d-d, 0s);
  transition: transform var(--d, 0.4s) ease-in var(--d-d, 0s), border-radius 0.5s ease var(--br-d, 0.08s);
  transition: transform var(--d, 0.4s) ease-in var(--d-d, 0s), border-radius 0.5s ease var(--br-d, 0.08s), -webkit-transform var(--d, 0.4s) ease-in var(--d-d, 0s);
}

.effect-letter div {
  z-index: 1;
  position: relative;
  display: -webkit-box;
  display: flex;
}

.effect-letter div span {
  display: block;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: var(--name, none) 0.7s linear forwards 0.18s;
  animation: var(--name, none) 0.7s linear forwards 0.18s;
}

.effect-letter.in {
  --name: move;
}

.effect-letter.in:not(.out) {
  --c: var(--color-hover);
  --b: var(--background-hover);
}

.effect-letter.in:not(.out):before {
  --y: 0;
  --br: 5%;
}

.effect-letter.in:not(.out):after {
  --y: 0;
  --br: 5%;
  --br: 10%;
  --d-d: 0.02s;
}

.effect-letter.in.out {
  --name: move-out;
}

.effect-letter.in.out:before {
  --d-d: 0.06s;
}

/*-----------------------------
    Fliker for attention
  -----------------------------*/
.flicker {
  -webkit-animation: flicker-1 2s linear infinite both !important;
  -moz-animation: flicker-1 2s linear infinite both !important;
  -o-animation: flicker-1 2s linear infinite both !important;
  -ms-animation: flicker-1 2s linear infinite both !important;
  animation: flicker-1 2s linear infinite both !important;
}

/*-----------------------------
    floating
  -----------------------------*/
.floating {
  -webkit-animation: floating 1.5s infinite ease-in-out !important;
  -moz-animation: floating 1.5s infinite ease-in-out !important;
  -o-animation: floating 1.5s infinite ease-in-out !important;
  -ms-animation: floating 1.5s infinite ease-in-out !important;
  animation: floating 1.5s infinite ease-in-out !important;
}

.floating-2 {
  -webkit-animation: floating-2 2s infinite ease-in-out !important;
  -moz-animation: floating-2 2s infinite ease-in-out !important;
  -o-animation: floating-2 2s infinite ease-in-out !important;
  -ms-animation: floating-2 2s infinite ease-in-out !important;
  animation: floating-2 2s infinite ease-in-out !important;
}

.floating-3 {
  -webkit-animation: floating-3 2.5s infinite ease-in-out !important;
  -moz-animation: floating-3 2.5s infinite ease-in-out !important;
  -o-animation: floating-3 2.5s infinite ease-in-out !important;
  -ms-animation: floating-3 2.5s infinite ease-in-out !important;
  animation: floating-3 2.5s infinite ease-in-out !important;
}

.floating-4 {
  -webkit-animation: floating-4 3s infinite ease-in-out !important;
  -moz-animation: floating-4 3s infinite ease-in-out !important;
  -o-animation: floating-4 3s infinite ease-in-out !important;
  -ms-animation: floating-4 3s infinite ease-in-out !important;
  animation: floating-4 3s infinite ease-in-out !important;
}

.floating-simple {
  -webkit-animation: floating-simple 3.5s infinite ease-in-out !important;
  -moz-animation: floating-simple 3.5s infinite ease-in-out !important;
  -o-animation: floating-simple 3.5s infinite ease-in-out !important;
  -ms-animation: floating-simple 3.5s infinite ease-in-out !important;
  animation: floating-simple 3.5s infinite ease-in-out !important;
}

.floating-simple2 {
  -webkit-animation: floating-simple 2.5s infinite ease-in-out !important;
  -moz-animation: floating-simple 2.5s infinite ease-in-out !important;
  -o-animation: floating-simple 2.5s infinite ease-in-out !important;
  -ms-animation: floating-simple 2.5s infinite ease-in-out !important;
  animation: floating-simple 2.5s infinite ease-in-out !important;
}

/*-----------------------------
    KeyFrames
  -----------------------------*/
@keyframes move {
  30%,
  36% {
    -webkit-transform: translateY(calc(-6px * var(--move))) translateZ(0) rotate(calc(-13deg * var(--rotate) * var(--part)));
    transform: translateY(calc(-6px * var(--move))) translateZ(0) rotate(calc(-13deg * var(--rotate) * var(--part)));
  }
  50% {
    -webkit-transform: translateY(calc(3px * var(--move))) translateZ(0) rotate(calc(6deg * var(--rotate) * var(--part)));
    transform: translateY(calc(3px * var(--move))) translateZ(0) rotate(calc(6deg * var(--rotate) * var(--part)));
  }
  70% {
    -webkit-transform: translateY(calc(-2px * var(--move))) translateZ(0) rotate(calc(-3deg * var(--rotate) * var(--part)));
    transform: translateY(calc(-2px * var(--move))) translateZ(0) rotate(calc(-3deg * var(--rotate) * var(--part)));
  }
}

@-webkit-keyframes move-out {
  30%,
  36% {
    -webkit-transform: translateY(calc(6px * var(--move))) translateZ(0) rotate(calc(13deg * var(--rotate) * var(--part)));
    transform: translateY(calc(6px * var(--move))) translateZ(0) rotate(calc(13deg * var(--rotate) * var(--part)));
  }
  50% {
    -webkit-transform: translateY(calc(-3px * var(--move))) translateZ(0) rotate(calc(-6deg * var(--rotate) * var(--part)));
    transform: translateY(calc(-3px * var(--move))) translateZ(0) rotate(calc(-6deg * var(--rotate) * var(--part)));
  }
  70% {
    -webkit-transform: translateY(calc(2px * var(--move))) translateZ(0) rotate(calc(3deg * var(--rotate) * var(--part)));
    transform: translateY(calc(2px * var(--move))) translateZ(0) rotate(calc(3deg * var(--rotate) * var(--part)));
  }
}

@-webkit-keyframes ripple-map {
  to {
    transform: scale(5);
    opacity: 0;
  }
}

@-moz-keyframes ripple-map {
  to {
    transform: scale(5);
    opacity: 0;
  }
}

@keyframes ripple-map {
  to {
    transform: scale(5);
    opacity: 0;
  }
}

@-webkit-keyframes ripple {
  to {
    transform: scaleX(1.3) scaleY(1.6);
    opacity: 0;
  }
}

@-moz-keyframes ripple {
  to {
    transform: scaleX(1.3) scaleY(1.6);
    opacity: 0;
  }
}

@keyframes ripple {
  to {
    transform: scaleX(1.3) scaleY(1.6);
    opacity: 0;
  }
}

@-webkit-keyframes rippleCircle {
  to {
    transform: scale(4.5);
    opacity: 0;
  }
}

@-moz-keyframes rippleCircle {
  to {
    transform: scale(4.5);
    opacity: 0;
  }
}

@keyframes rippleCircle {
  to {
    transform: scale(4.5);
    opacity: 0;
  }
}

@-webkit-keyframes RippleMenu {
  to {
    transform: scale(10);
    opacity: 0;
  }
}

@-moz-keyframes RippleMenu {
  to {
    transform: scale(10);
    opacity: 0;
  }
}

@keyframes RippleMenu {
  to {
    transform: scale(10);
    opacity: 0;
  }
}

@-webkit-keyframes ripple_Circle {
  to {
    transform: scale(1.7);
    opacity: 0;
  }
}

@-moz-keyframes ripple_Circle {
  to {
    transform: scale(1.7);
    opacity: 0;
  }
}

@keyframes ripple_Circle {
  to {
    transform: scale(1.7);
    opacity: 0;
  }
}

/**
   * ----------------------------------------
   * animation flicker-1
   * ----------------------------------------
   */
@-webkit-keyframes flicker-1 {
  0%,
  100% {
    opacity: 1;
  }
  41.99% {
    opacity: 1;
  }
  42% {
    opacity: 0;
  }
  43% {
    opacity: 0;
  }
  43.01% {
    opacity: 1;
  }
  47.99% {
    opacity: 1;
  }
  48% {
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  49.01% {
    opacity: 1;
  }
}

@-moz-keyframes flicker-1 {
  0%,
  100% {
    opacity: 1;
  }
  41.99% {
    opacity: 1;
  }
  42% {
    opacity: 0;
  }
  43% {
    opacity: 0;
  }
  43.01% {
    opacity: 1;
  }
  47.99% {
    opacity: 1;
  }
  48% {
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  49.01% {
    opacity: 1;
  }
}

@keyframes flicker-1 {
  0%,
  100% {
    opacity: 1;
  }
  41.99% {
    opacity: 1;
  }
  42% {
    opacity: 0;
  }
  43% {
    opacity: 0;
  }
  43.01% {
    opacity: 1;
  }
  47.99% {
    opacity: 1;
  }
  48% {
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  49.01% {
    opacity: 1;
  }
}

/**
   * ----------------------------------------
   * animation heartbeat 
   * ----------------------------------------
   */
@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@-moz-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

/**
   * ----------------------------------------
   * animation wobble-hor-bottom
   * ----------------------------------------
   */
@-webkit-keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-10px) rotate(-2deg);
    transform: translateX(-10px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(5px) rotate(3deg);
    transform: translateX(5px) rotate(3deg);
  }
  45% {
    -webkit-transform: translateX(-5px) rotate(-2.6deg);
    transform: translateX(-5px) rotate(-2.6deg);
  }
  60% {
    -webkit-transform: translateX(2px) rotate(1.4deg);
    transform: translateX(2px) rotate(1.4deg);
  }
  75% {
    -webkit-transform: translateX(-3px) rotate(-0.5deg);
    transform: translateX(-3px) rotate(-0.5deg);
  }
}

@-moz-keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-10px) rotate(-2deg);
    transform: translateX(-10px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(5px) rotate(3deg);
    transform: translateX(5px) rotate(3deg);
  }
  45% {
    -webkit-transform: translateX(-5px) rotate(-2.6deg);
    transform: translateX(-5px) rotate(-2.6deg);
  }
  60% {
    -webkit-transform: translateX(2px) rotate(1.4deg);
    transform: translateX(2px) rotate(1.4deg);
  }
  75% {
    -webkit-transform: translateX(-3px) rotate(-0.5deg);
    transform: translateX(-3px) rotate(-0.5deg);
  }
}

@keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-10px) rotate(-2deg);
    transform: translateX(-10px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(5px) rotate(3deg);
    transform: translateX(5px) rotate(3deg);
  }
  45% {
    -webkit-transform: translateX(-5px) rotate(-2.6deg);
    transform: translateX(-5px) rotate(-2.6deg);
  }
  60% {
    -webkit-transform: translateX(2px) rotate(1.4deg);
    transform: translateX(2px) rotate(1.4deg);
  }
  75% {
    -webkit-transform: translateX(-3px) rotate(-0.5deg);
    transform: translateX(-3px) rotate(-0.5deg);
  }
}

/**
   * ----------------------------------------
   * animation slide-top
   * ----------------------------------------
   */
@-webkit-keyframes slide-top {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-moz-keyframes slide-top {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-top {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

/**
   * ----------------------------------------
   * animation slide-bottom
   * ----------------------------------------
   */
@-webkit-keyframes slide-bottom {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
    opacity: 0;
  }
}

@-moz-keyframes slide-bottom {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
    opacity: 0;
  }
}

@keyframes slide-bottom {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
    opacity: 0;
  }
}

/**
    * ----------------------------------------
    * floating
    * ----------------------------------------
    */
@-webkit-keyframes floating {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(20%);
  }
  100% {
    transform: translateY(0%);
  }
}

@-moz-keyframes floating {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(20%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes floating {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(20%);
  }
  100% {
    transform: translateY(0%);
  }
}

@-webkit-keyframes floating-2 {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(25%);
  }
  100% {
    transform: translateY(0%);
  }
}

@-moz-keyframes floating-2 {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(25%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes floating-2 {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(25%);
  }
  100% {
    transform: translateY(0%);
  }
}

@-webkit-keyframes floating-3 {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(30%);
  }
  100% {
    transform: translateY(0%);
  }
}

@-moz-keyframes floating-3 {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(30%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes floating-3 {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(30%);
  }
  100% {
    transform: translateY(0%);
  }
}

@-webkit-keyframes floating-4 {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(35%);
  }
  100% {
    transform: translateY(0%);
  }
}

@-moz-keyframes floating-4 {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(35%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes floating-4 {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(35%);
  }
  100% {
    transform: translateY(0%);
  }
}

@-webkit-keyframes floating-simple {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(3%);
  }
  100% {
    transform: translateY(0%);
  }
}

@-moz-keyframes floating-simple {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(3%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes floating-simple {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(3%);
  }
  100% {
    transform: translateY(0%);
  }
}

/**
   * ----------------------------------------
   * animation bounce-top
   * ----------------------------------------
   */
@-webkit-keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-35px);
    transform: translateY(-35px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  24% {
    opacity: 0.5;
  }
  40% {
    -webkit-transform: translateY(-24px);
    transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}

@-moz-keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-35px);
    transform: translateY(-35px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  24% {
    opacity: 0.5;
  }
  40% {
    -webkit-transform: translateY(-24px);
    transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}

@keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-35px);
    transform: translateY(-35px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  24% {
    opacity: 0.5;
  }
  40% {
    -webkit-transform: translateY(-24px);
    transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}

/**
   * ----------------------------------------
   * animation puff-in-center
   * ----------------------------------------
   */
@-webkit-keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

@-moz-keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

@keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

/**
   * ----------------------------------------
   * animation puff-out-center
   * ----------------------------------------
   */
@-webkit-keyframes puff-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
}

@-moz-keyframes puff-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
}

@keyframes puff-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
}

/**
   * ----------------------------------------
   * animation scale-in-center
   * ----------------------------------------
   */
@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@-moz-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

/**
   * ----------------------------------------
   * animation slide-in-right
   * ----------------------------------------
   */
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(200px);
    transform: translateX(200px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@-moz-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(200px);
    transform: translateX(200px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(200px);
    transform: translateX(200px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

/**
   * ----------------------------------------
   * animation slide-in-bottom
   * ----------------------------------------
   */
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-moz-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

/**
   * ----------------------------------------
   * animation blink-1
   * ----------------------------------------
   */
@-webkit-keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}

@-moz-keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}

@keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}

@-webkit-keyframes circleRipple {
  from {
    -webkit-transform: scale(0);
    opacity: 1;
  }
  to {
    -webkit-transform: scale(2);
    opacity: 0;
  }
}

@-moz-keyframes circleRipple {
  from {
    -webkit-transform: scale(0);
    opacity: 1;
  }
  to {
    -webkit-transform: scale(2);
    opacity: 0;
  }
}

@keyframes circleRipple {
  from {
    -webkit-transform: scale(0);
    opacity: 1;
  }
  to {
    -webkit-transform: scale(2);
    opacity: 0;
  }
}

/**
   * ----------------------------------------
   * animation puff-in-center
   * ----------------------------------------
   */
@-webkit-keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

@-moz-keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

@keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

@-webkit-keyframes pulseRipple {
  0% {
    -webkit-transform: scale(1.2);
    opacity: 0;
  }
  50% {
    -webkit-transform: scale(2);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2);
    opacity: 0;
  }
}

@-moz-keyframes pulseRipple {
  0% {
    -webkit-transform: scale(1.2);
    opacity: 0;
  }
  50% {
    -webkit-transform: scale(2);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2);
    opacity: 0;
  }
}

@keyframes pulseRipple {
  0% {
    -webkit-transform: scale(1.2);
    opacity: 0;
  }
  50% {
    -webkit-transform: scale(2);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2);
    opacity: 0;
  }
}

/**
   * ----------------------------------------
   * animation text-focus-in
   * ----------------------------------------
   */
@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

@-moz-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

/**
   * ----------------------------------------
   * animation tilt-up
   * ----------------------------------------
   */
@keyframes tilt-up {
  40%,
  60% {
    transform: perspective(500px) rotateX(8deg);
  }
}

@keyframes tilt-down {
  40%,
  60% {
    transform: perspective(500px) rotateX(-8deg);
  }
}

/**
   * ----------------------------------------
   * fade-up AOS
   * ----------------------------------------
   */
html:not(.no-js) [data-aos="fade-up"] {
  -webkit-transform: translate3d(0, 30px, 0);
  transform: translate3d(0, 30px, 0);
}

/**
   * ----------------------------------------
   * animation jello-horizontal
   * ----------------------------------------
   */
@-webkit-keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@-moz-keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

/**
   * ----------------------------------------
   * animation flip-horizontal-bottom
   * ----------------------------------------
   */
@-webkit-keyframes flip-horizontal-bottom {
  0% {
    -webkit-transform: rotateX(-180deg);
    transform: rotateX(-180deg);
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
  }
}

@-moz-keyframes flip-horizontal-bottom {
  0% {
    -webkit-transform: rotateX(-180deg);
    transform: rotateX(-180deg);
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
  }
}

@keyframes flip-horizontal-bottom {
  0% {
    -webkit-transform: rotateX(-180deg);
    transform: rotateX(-180deg);
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
  }
}

/**
   * ----------------------------------------
   * fadein
   * ----------------------------------------
   */
@-webkit-keyframes fadein {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-moz-keyframes fadein {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-in-bottom {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both !important;
  -moz-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both !important;
  -o-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both !important;
  -ms-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both !important;
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both !important;
}

/*--------------------------------------------------------------
## btn styles
--------------------------------------------------------------*/
input[type="submit"],
button,
.btn,
a.btn input.btn,
a.button,
span.button,
div.button {
  color: white;
  padding: 12px 18px;
  min-height: 20px;
  line-height: 20px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
  -moz-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
  -o-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
  font-weight: normal;
  text-decoration: none;
  vertical-align: middle;
  outline: none;
  display: inline-block;
  position: relative;
  text-align: center;
  border-radius: 5px;
  overflow: hidden;
  will-change: transform, box-shadow;
}

input[type="submit"]:not(.btn-outline):not(.btn-flat):not(.btn-link) .text,
button:not(.btn-outline):not(.btn-flat):not(.btn-link) .text,
.btn:not(.btn-outline):not(.btn-flat):not(.btn-link) .text,
a.btn input.btn:not(.btn-outline):not(.btn-flat):not(.btn-link) .text,
a.button:not(.btn-outline):not(.btn-flat):not(.btn-link) .text,
span.button:not(.btn-outline):not(.btn-flat):not(.btn-link) .text,
div.button:not(.btn-outline):not(.btn-flat):not(.btn-link) .text {
  color: #fff;
}

input[type="submit"] i,
button i,
.btn i,
a.btn input.btn i,
a.button i,
span.button i,
div.button i {
  display: inline-block;
  vertical-align: middle;
  margin-top: -16px;
  margin-right: 5px;
  font-size: 20px;
  position: relative;
  stroke: 2px;
  min-width: 20px;
  height: 20px;
}

input[type="submit"] i-left,
button i-left,
.btn i-left,
a.btn input.btn i-left,
a.button i-left,
span.button i-left,
div.button i-left {
  margin-right: 5px;
}

input[type="submit"] i-right,
button i-right,
.btn i-right,
a.btn input.btn i-right,
a.button i-right,
span.button i-right,
div.button i-right {
  margin-left: 5px;
}

input[type="submit"]:not(.text-hover):hover .icon.icon-right:before,
input[type="submit"]:not(.text-hover):hover .icon.icon-right svg,
button:not(.text-hover):hover .icon.icon-right:before,
button:not(.text-hover):hover .icon.icon-right svg,
.btn:not(.text-hover):hover .icon.icon-right:before,
.btn:not(.text-hover):hover .icon.icon-right svg,
a.btn input.btn:not(.text-hover):hover .icon.icon-right:before,
a.btn input.btn:not(.text-hover):hover .icon.icon-right svg,
a.button:not(.text-hover):hover .icon.icon-right:before,
a.button:not(.text-hover):hover .icon.icon-right svg,
span.button:not(.text-hover):hover .icon.icon-right:before,
span.button:not(.text-hover):hover .icon.icon-right svg,
div.button:not(.text-hover):hover .icon.icon-right:before,
div.button:not(.text-hover):hover .icon.icon-right svg {
  animation: hoverBtn ease-out 0.3s;
}

input[type="submit"]:not(.text-hover):hover .icon.icon-left:before,
input[type="submit"]:not(.text-hover):hover .icon.icon-left svg,
button:not(.text-hover):hover .icon.icon-left:before,
button:not(.text-hover):hover .icon.icon-left svg,
.btn:not(.text-hover):hover .icon.icon-left:before,
.btn:not(.text-hover):hover .icon.icon-left svg,
a.btn input.btn:not(.text-hover):hover .icon.icon-left:before,
a.btn input.btn:not(.text-hover):hover .icon.icon-left svg,
a.button:not(.text-hover):hover .icon.icon-left:before,
a.button:not(.text-hover):hover .icon.icon-left svg,
span.button:not(.text-hover):hover .icon.icon-left:before,
span.button:not(.text-hover):hover .icon.icon-left svg,
div.button:not(.text-hover):hover .icon.icon-left:before,
div.button:not(.text-hover):hover .icon.icon-left svg {
  animation: hoverBtn ease-out 0.3s reverse;
}

input[type="submit"]:not(.text-hover):hover .icon:before,
button:not(.text-hover):hover .icon:before,
.btn:not(.text-hover):hover .icon:before,
a.btn input.btn:not(.text-hover):hover .icon:before,
a.button:not(.text-hover):hover .icon:before,
span.button:not(.text-hover):hover .icon:before,
div.button:not(.text-hover):hover .icon:before {
  color: inherit;
}

@keyframes hoverBtn {
  0% {
    transform: translateX(0px);
  }
  51% {
    transform: translateX(20px);
    opacity: 1;
  }
  52% {
    opacity: 0;
    transform: translateX(-20px);
  }
  53% {
    opacity: 0;
  }
  54% {
    opacity: 1;
  }
  100% {
    transform: translateX(0px);
  }
}

input[type="submit"]:hover, input[type="submit"]:focus,
button:hover,
button:focus,
.btn:hover,
.btn:focus,
a.btn input.btn:hover,
a.btn input.btn:focus,
a.button:hover,
a.button:focus,
span.button:hover,
span.button:focus,
div.button:hover,
div.button:focus {
  color: #fff;
  background-color: #be346a;
  border-color: #be346a;
  opacity: 0.9;
  outline: none;
  box-shadow: inherit;
  opacity: 0.9;
}

input[type="submit"].text-hover,
button.text-hover,
.btn.text-hover,
a.btn input.btn.text-hover,
a.button.text-hover,
span.button.text-hover,
div.button.text-hover {
  overflow: hidden;
  position: relative;
}

input[type="submit"].text-hover .icon,
input[type="submit"].text-hover .text,
button.text-hover .icon,
button.text-hover .text,
.btn.text-hover .icon,
.btn.text-hover .text,
a.btn input.btn.text-hover .icon,
a.btn input.btn.text-hover .text,
a.button.text-hover .icon,
a.button.text-hover .text,
span.button.text-hover .icon,
span.button.text-hover .text,
div.button.text-hover .icon,
div.button.text-hover .text {
  -webkit-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
  -moz-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
  -o-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
}

input[type="submit"].text-hover .icon,
button.text-hover .icon,
.btn.text-hover .icon,
a.btn input.btn.text-hover .icon,
a.button.text-hover .icon,
span.button.text-hover .icon,
div.button.text-hover .icon {
  max-width: 100%;
  text-align: center;
  height: 26px;
  position: absolute;
  top: calc(50% - 10px);
  font-size: 20px;
  left: 50%;
  top: 50%;
  margin: 0;
  transform: translate(-50%, -50%);
}

input[type="submit"].text-hover .text,
button.text-hover .text,
.btn.text-hover .text,
a.btn input.btn.text-hover .text,
a.button.text-hover .text,
span.button.text-hover .text,
div.button.text-hover .text {
  display: inline-block;
  transform: translateY(-120%);
  opacity: 0;
}

input[type="submit"].text-hover:hover .icon,
button.text-hover:hover .icon,
.btn.text-hover:hover .icon,
a.btn input.btn.text-hover:hover .icon,
a.button.text-hover:hover .icon,
span.button.text-hover:hover .icon,
div.button.text-hover:hover .icon {
  transform: translate(-50%, 120%);
  opacity: 0;
}

input[type="submit"].text-hover:hover .text,
button.text-hover:hover .text,
.btn.text-hover:hover .text,
a.btn input.btn.text-hover:hover .text,
a.button.text-hover:hover .text,
span.button.text-hover:hover .text,
div.button.text-hover:hover .text {
  transform: none;
  opacity: 1;
}

p a.btn:hover {
  text-decoration: none;
}

button[disabled].btn-white:hover,
input[type="submit"][disabled].btn-white:hover {
  background-color: #fff;
  color: #10154e;
}

button[disabled].btn-primary:hover,
input[type="submit"][disabled].btn-primary:hover {
  background-color: #be346a;
  color: #fff;
}

/*--------------------------------------------------------------
### Buttons: outline
--------------------------------------------------------------*/
.btn-outline,
input[type="submit"].btn-outline,
a.btn-outline {
  background-color: transparent;
  color: currentColor;
}

.btn-outline:after, .btn-outline:before,
input[type="submit"].btn-outline:after,
input[type="submit"].btn-outline:before,
a.btn-outline:after,
a.btn-outline:before {
  display: none;
}

.btn-outline:hover, .btn-outline:focus,
input[type="submit"].btn-outline:hover,
input[type="submit"].btn-outline:focus,
a.btn-outline:hover,
a.btn-outline:focus {
  background-color: #be346a;
  color: #fff;
}

.btn-outline.btn-white,
input[type="submit"].btn-outline.btn-white,
a.btn-outline.btn-white {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
}

.btn-outline.btn-white:hover,
input[type="submit"].btn-outline.btn-white:hover,
a.btn-outline.btn-white:hover {
  background-color: #fff;
  color: #10154e;
}

.btn-outline.disabled:hover,
input[type="submit"].btn-outline.disabled:hover,
a.btn-outline.disabled:hover {
  background-color: transparent;
  color: #10154e;
  border-color: #10154e;
  cursor: default;
}

/*--------------------------------------------------------------
### Buttons: loading spinner
--------------------------------------------------------------*/
.btn-loading {
  box-shadow: none;
}

.btn-loading:before {
  content: "";
  position: relative;
  display: inline-block !important;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  bottom: auto;
  left: auto;
  opacity: 1;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top: 2px solid rgba(255, 255, 255, 0.7);
  border-right: 2px solid rgba(255, 255, 255, 0.7);
  background: transparent;
  animation: 0.8s rotate cubic-bezier(0.99, 0.71, 0.22, 0.68) infinite;
}

.btn-loading.btn-outline:before, .btn-loading.btn:before {
  margin-right: 8px;
}

.btn-loading:before {
  vertical-align: top;
  margin-top: 1px;
}

.btn-loading .ion-left {
  display: none;
}

.btn-loading:hover {
  transition: none;
}

.btn-loading:hover:before {
  background: transparent;
  width: 18px;
  transition: none;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0);
  }
}

/* =====================================================
- - - - - - - - - 1/ Button sizes
======================================================*/
.btn-xs {
  padding: 7px 0px;
  min-width: 100px;
  border-radius: 4px;
  font-size: 13px;
}

.btn-sm {
  padding: 8px 12px;
  min-width: 100px;
  border-radius: 6px;
  font-size: 15.5px;
}

.btn-lg {
  padding: 9px 20px;
  font-size: 18px;
  line-height: 1.7;
}

.btn-xl {
  padding: 11px 22px;
  font-size: 19px;
  line-height: 1.7;
}

/* ==================================================+===
- - - - - - - - - 2/ Button variations
=======================================================*/
.btn-primary {
  background: #be346a;
  color: #fff;
  border: none !important;
  transition: 0.4s ease-in-out;
}

.btn-primary.btn-sh {
  box-shadow: 0 14px 35px -11px #be346a;
}

.btn-primary.btn-outline {
  border: solid 2px #be346a;
  background: transparent !important;
  color: #be346a;
}

.btn-primary.btn-outline-bottom {
  border-bottom: solid 3px #be346a;
  background: transparent !important;
  color: #be346a !important;
}

.btn-primary.btn-hover-line:hover {
  background: transparent;
  border: solid 2px #be346a;
  color: #be346a;
}

.btn-primary:hover {
  outline: none;
  background: #b63266 !important;
  border: solid 2px #be346a;
}

.btn-secondary {
  background: #683769;
  color: #fff;
  transition: 0.4s ease-in-out;
}

.btn-secondary:hover {
  background: #472548 !important;
  color: #fff;
}

.btn-secondary.btn-sh {
  box-shadow: 0 14px 35px -11px #683769;
}

.btn-secondary.btn-outline {
  border: solid 2px #683769;
  background: transparent !important;
  color: #683769;
}

.btn-secondary.btn-outline-bottom {
  border-bottom: solid 3px #683769;
  background: transparent !important;
  color: #be346a !important;
}

.btn-secondary.btn-hover-line:hover {
  background: transparent;
  border: solid 2px #683769;
  color: #683769;
}

.btn-orange {
  background: #ffe4db;
  color: #ff784b;
  border: solid 2px #683769;
  transition: 0.4s ease-in-out;
  border: none;
}

.btn-white {
  background: white;
  border: solid 1px rgba(10, 33, 65, 0.05);
  color: #10154e;
  box-shadow: 0px 2px 4px #7e8eb11f !important;
}

.btn-white span::after {
  color: #10154e !important;
}

.btn-white.btn-outline {
  color: white;
  border: solid 2px white;
}

.btn-white:hover {
  opacity: 0.8;
  background: white;
  color: #be346a !important;
  box-shadow: 0 9px 6px rgba(0, 0, 0, 0.1), 0 -50px 0 rgba(0, 0, 0, 0) inset;
  border: solid 1px rgba(10, 33, 65, 0.05) !important;
}

.btn-dark {
  background: #10154e;
  color: #fff;
  border: solid 1px #10154e;
}

.btn-dark:hover {
  color: #faf4f6 !important;
  background: #10154e;
  border: solid 1px #10154e;
}

.btn-dark.btn-sh {
  box-shadow: 0 14px 35px -11px #10154e;
}

.btn-dark.btn-outline {
  border: solid 2px #10154e;
  background: transparent !important;
  color: #10154e;
}

.btn-dark.btn-outline:hover {
  color: #be346a !important;
}

.btn-dark.btn-outline-bottom {
  border-bottom: solid 3px #10154e;
  background: transparent !important;
  color: #10154e;
}

/* ==================================================+===
- - - - - - - - - 3 / outher button
=======================================================*/
button.close {
  height: 2rem;
  width: 2rem;
  border-radius: 50px;
  position: relative;
}

button.close::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 0px;
  height: 20px;
  width: 40px;
  background-size: contain;
  opacity: 0.5;
  background-repeat: no-repeat;
}

button.close:hover {
  color: white;
  outline: none;
  box-shadow: none;
  border: none;
}

/*=== Small Button ====
==================*/
.btn_sm_primary {
  padding: 0.7rem 1.55rem;
  font-size: 15px;
  text-align: center;
  -webkit-transition: 0.3s !important;
  -moz-transition: 0.3s !important;
  -o-transition: 0.3s !important;
  -ms-transition: 0.3s !important;
  transition: 0.3s !important;
}

.btn_sm_primary:hover {
  opacity: 0.9;
}

/*=== Medium Button ====
==================*/
.btn_md_primary {
  padding: 0.85rem 1.8rem;
  font-size: 15px;
  text-align: center;
  -webkit-transition: 0.3s !important;
  -moz-transition: 0.3s !important;
  -o-transition: 0.3s !important;
  -ms-transition: 0.3s !important;
  transition: 0.3s !important;
}

.btn_md_primary:hover {
  opacity: 0.9;
}

/*=== Large Button ====
==================*/
.btn_lg_primary {
  padding: 0.9rem 2.3rem;
  font-size: 15px;
  text-align: center;
  -webkit-transition: 0.3s !important;
  -moz-transition: 0.3s !important;
  -o-transition: 0.3s !important;
  -ms-transition: 0.3s !important;
  transition: 0.3s !important;
}

.btn_lg_primary:hover {
  opacity: 0.9;
}

/*=== XLarge Button ====
==================*/
.btn_xl_primary {
  padding: 1.1rem 2.3rem;
  font-size: 15px;
  text-align: center;
  -webkit-transition: 0.3s !important;
  -moz-transition: 0.3s !important;
  -o-transition: 0.3s !important;
  -ms-transition: 0.3s !important;
  transition: 0.3s !important;
}

.btn_xl_primary:hover {
  opacity: 0.9;
}

/*-----------------------------
  btn-default
-----------------------------*/
.header-nav-center .btn-default {
  margin-right: 0.5rem;
}

/*-----------------------------
  btn video
-----------------------------*/
.btn-video {
  background-color: #be346a;
  color: #fff;
  padding: 0.9rem 1.55rem;
  text-align: center;
  position: relative;
}

.btn-video:before {
  background: #be346a;
  border-radius: 50px;
  content: "";
  display: block;
  height: 100%;
  opacity: 0.25;
  position: absolute;
  width: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  -webkit-animation: ripple 1.3s infinite cubic-bezier(0.29, -0.01, 0.17, 0.95) !important;
  -moz-animation: ripple 1.3s infinite cubic-bezier(0.29, -0.01, 0.17, 0.95) !important;
  -o-animation: ripple 1.3s infinite cubic-bezier(0.29, -0.01, 0.17, 0.95) !important;
  -ms-animation: ripple 1.3s infinite cubic-bezier(0.29, -0.01, 0.17, 0.95) !important;
  animation: ripple 1.3s infinite cubic-bezier(0.29, -0.01, 0.17, 0.95) !important;
}

.btn-video.ripple_box:before {
  border-radius: 8px;
}

.btn-video .tio {
  vertical-align: sub;
  margin-right: 0.2rem;
}

.btn-video:hover {
  color: #fff;
}

/*-----------------------------
  btn video Circle
-----------------------------*/
.btn-ripple_circle {
  position: relative;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-ripple_circle .icon {
  background-color: #be346a;
  color: #fff;
  width: 50px;
  height: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  position: absolute;
  border-radius: 50%;
}

.btn-ripple_circle .effect_ripp {
  border-radius: 50%;
  display: block;
  height: 100%;
  opacity: 0.25;
  position: absolute;
  width: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  background: #6c7a87;
  -webkit-animation: ripple_Circle 1.3s infinite cubic-bezier(0.29, -0.01, 0.17, 0.95) !important;
  -moz-animation: ripple_Circle 1.3s infinite cubic-bezier(0.29, -0.01, 0.17, 0.95) !important;
  -o-animation: ripple_Circle 1.3s infinite cubic-bezier(0.29, -0.01, 0.17, 0.95) !important;
  -ms-animation: ripple_Circle 1.3s infinite cubic-bezier(0.29, -0.01, 0.17, 0.95) !important;
  animation: ripple_Circle 1.3s infinite cubic-bezier(0.29, -0.01, 0.17, 0.95) !important;
}

.btn-ripple_circle .tio {
  vertical-align: middle;
  font-size: 24px;
  margin: 0;
}

.btn-ripple_circle:hover {
  color: #fff;
}

/*-----------------------------
  play_video
-----------------------------*/
.play_video {
  width: 50px;
  height: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  background-color: #10154e;
  padding: 0;
  -webkit-transition: 0.3s !important;
  -moz-transition: 0.3s !important;
  -o-transition: 0.3s !important;
  -ms-transition: 0.3s !important;
  transition: 0.3s !important;
}

.play_video::after {
  content: "";
  width: 70px;
  height: 70px;
  position: absolute;
  display: block;
  z-index: -1;
  background: rgba(16, 21, 78, 0.1);
  border-radius: 50%;
}

.play_video::before {
  content: "";
  position: absolute;
  border-radius: 50%;
  display: block;
  height: 100%;
  width: 100%;
  z-index: -1;
  background: rgba(16, 21, 78, 0.1);
  -webkit-animation: pulseRipple 2.5s ease-in-out infinite !important;
  -moz-animation: pulseRipple 2.5s ease-in-out infinite !important;
  -o-animation: pulseRipple 2.5s ease-in-out infinite !important;
  -ms-animation: pulseRipple 2.5s ease-in-out infinite !important;
  animation: pulseRipple 2.5s ease-in-out infinite !important;
}

.play_video:focus {
  background-color: #10154e;
}

.play_video .tio {
  font-size: 24px;
  color: #fff;
}

/*-----------------------------
  Smartphone app
-----------------------------*/
.app_smartphone .btn--app {
  display: inline-block;
  margin-right: 1.5rem;
}

.app_smartphone .btn--app .icon {
  border-radius: 20px;
  width: 50px;
  height: 50px;
  background-color: #17e79b;
  padding: 0.75rem;
  margin-right: 0.75rem;
}

.app_smartphone .btn--app .icon .tio {
  color: #fff;
  font-size: 25px;
  display: flex;
  text-align: center;
  justify-content: center;
}

.app_smartphone .btn--app .txt span {
  color: #9da6af;
  font-size: 12px;
  font-weight: 400;
}

.app_smartphone .btn--app .txt h4 {
  font-weight: 400;
  color: #fff;
  font-size: 18px;
  margin-top: 0.2rem;
  margin-bottom: 0;
}

/*-----------------------------
  Menu Mobile
-----------------------------*/
.menu {
  color: #10154e;
  width: 36px;
  height: 36px;
  padding: 0;
  margin: 0;
  outline: none;
  position: relative;
  border: none;
  background: none;
  cursor: pointer;
  -webkit-appearence: none;
  -webkit-tap-highlight-color: transparent;
}

.menu.ripplemenu:active:before {
  background: #be346a;
  border-radius: 50%;
  content: "";
  display: block;
  height: 100%;
  opacity: 0.25;
  position: absolute;
  width: 100%;
  z-index: -1;
  top: 0;
  left: -9px;
  animation: RippleMenu 0.3s cubic-bezier(0.29, -0.01, 0.17, 0.95);
}

.menu svg {
  width: 44px;
  height: 48px;
  top: -6px;
  left: -14px;
  stroke: #10154e;
  stroke-width: 0.2rem;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
  display: block;
  position: absolute;
}

@media (max-width: 991px) {
  .menu svg {
    left: 0;
  }
}

.menu svg path {
  transition: stroke-dasharray var(--duration, 0.85s) var(--easing, ease) var(--delay, 0s), stroke-dashoffset var(--duration, 0.85s) var(--easing, ease) var(--delay, 0s);
  stroke-dasharray: var(--array-1, 26px) var(--array-2, 100px);
  stroke-dashoffset: var(--offset, 126px);
  transform: translateZ(0);
}

.menu svg path:nth-child(2) {
  --duration: 0.7s;
  --easing: ease-in;
  --offset: 100px;
  --array-2: 74px;
}

.menu svg path:nth-child(3) {
  --offset: 133px;
  --array-2: 107px;
}

.menu.active svg path {
  --offset: 57px;
}

.menu.active svg path:nth-child(1), .menu.active svg path:nth-child(3) {
  --delay: 0.15s;
  --easing: cubic-bezier(0.2, 0.4, 0.2, 1.1);
}

.menu.active svg path:nth-child(2) {
  --duration: 0.4s;
  --offset: 2px;
  --array-1: 1px;
}

.menu.active svg path:nth-child(3) {
  --offset: 58px;
}

/*-----------------------------
  Button DropDown Download
-----------------------------*/
.drop_download {
  position: relative;
  color: #fff;
  font-size: 15px;
  padding: 0.6rem 1.75rem;
  border-radius: 8px;
  text-align: center;
  -webkit-transition: 0.3s !important;
  -moz-transition: 0.3s !important;
  -o-transition: 0.3s !important;
  -ms-transition: 0.3s !important;
  transition: 0.3s !important;
}

.drop_download .links-btn {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.drop_download .links-btn a {
  color: #aa9bb3;
  font-size: 14px;
  display: block;
  padding-top: 0.8rem;
  text-align: left;
  padding-left: 0.25rem;
  -webkit-transition: 0.3s !important;
  -moz-transition: 0.3s !important;
  -o-transition: 0.3s !important;
  -ms-transition: 0.3s !important;
  transition: 0.3s !important;
}

.drop_download .links-btn a:nth-child(1) {
  transform: translate(-12px);
}

.drop_download .links-btn a:nth-child(2) {
  transform: translate(-16px);
}

.drop_download .links-btn a:nth-child(3) {
  transform: translate(-22px);
}

.drop_download:hover {
  color: #fff;
}

.drop_download:hover:before {
  content: "";
  background-color: rgba(255, 255, 255, 0.1);
  height: 155px;
  width: 130px;
  position: absolute;
  border-radius: 8px;
  left: 0;
  top: 0;
}

.drop_download:hover .links-btn {
  opacity: 1;
  pointer-events: auto;
}

.drop_download:hover .links-btn a {
  transform: translate(0px);
}

.drop_download:hover .links-btn a:nth-child(1) {
  -webkit-transition: 0.2s !important;
  -moz-transition: 0.2s !important;
  -o-transition: 0.2s !important;
  -ms-transition: 0.2s !important;
  transition: 0.2s !important;
}

.drop_download:hover .links-btn a:nth-child(2) {
  -webkit-transition: 0.4s !important;
  -moz-transition: 0.4s !important;
  -o-transition: 0.4s !important;
  -ms-transition: 0.4s !important;
  transition: 0.4s !important;
}

.drop_download:hover .links-btn a:nth-child(3) {
  -webkit-transition: 0.5s !important;
  -moz-transition: 0.5s !important;
  -o-transition: 0.5s !important;
  -ms-transition: 0.5s !important;
  transition: 0.5s !important;
}

.drop_download:hover .links-btn a:hover {
  color: #fff;
}

/*-----------------------------
  Button Cryptocurrency (Dark
-----------------------------*/
.button--click .btn-token {
  border-radius: 8px;
  font-size: 15.5px;
  font-weight: 500;
  text-align: center;
  color: #fff;
  margin: 0 0.75rem;
  display: inline-flex;
  justify-content: center;
}

.button--click .btn-token.c-gradient {
  -webkit-background: linear-gradient(90deg, #6bb0f8 0%, #fd4ffe 100%) !important;
  -moz-background: linear-gradient(90deg, #6bb0f8 0%, #fd4ffe 100%) !important;
  -o-background: linear-gradient(90deg, #6bb0f8 0%, #fd4ffe 100%) !important;
  -ms-background: linear-gradient(90deg, #6bb0f8 0%, #fd4ffe 100%) !important;
  background: linear-gradient(90deg, #6bb0f8 0%, #fd4ffe 100%) !important;
}

.button--click .btn-token.defalult {
  border: 1px solid rgba(255, 255, 255, 0.4);
}

/*-----------------------------
  Button Read More
-----------------------------*/
.btn_readmore {
  color: #be346a;
  font-size: 15.5px;
  position: relative;
  padding-left: 0;
  -webkit-transition: 0.4s !important;
  -moz-transition: 0.4s !important;
  -o-transition: 0.4s !important;
  -ms-transition: 0.4s !important;
  transition: 0.4s !important;
}

.btn_readmore:before {
  content: "";
  position: absolute;
  left: -0.7rem;
  top: 0;
  width: 45%;
  height: 100%;
  background-color: rgba(190, 52, 106, 0.1);
  -webkit-transition: 0.4s cubic-bezier(0.4, 0.2, 0.01, 1.01) !important;
  -moz-transition: 0.4s cubic-bezier(0.4, 0.2, 0.01, 1.01) !important;
  -o-transition: 0.4s cubic-bezier(0.4, 0.2, 0.01, 1.01) !important;
  -ms-transition: 0.4s cubic-bezier(0.4, 0.2, 0.01, 1.01) !important;
  transition: 0.4s cubic-bezier(0.4, 0.2, 0.01, 1.01) !important;
}

.btn_readmore:hover {
  color: #be346a;
}

.btn_readmore:hover:before {
  width: 0;
}

/*-----------------------------
  Back to top with progress indicator
-----------------------------*/
.prgoress_indicator {
  position: fixed;
  right: 50px;
  bottom: 50px;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px rgba(190, 52, 106, 0.2);
  z-index: 10000;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  -webkit-transform: translateY(15px) !important;
  -moz-transform: translateY(15px) !important;
  -o-transform: translateY(15px) !important;
  -ms-transform: translateY(15px) !important;
  transform: translateY(15px) !important;
  -webkit-transition: all 200ms linear !important;
  -moz-transition: all 200ms linear !important;
  -o-transition: all 200ms linear !important;
  -ms-transition: all 200ms linear !important;
  transition: all 200ms linear !important;
}

.prgoress_indicator.active-progress {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0) !important;
  -moz-transform: translateY(0) !important;
  -o-transform: translateY(0) !important;
  -ms-transform: translateY(0) !important;
  transform: translateY(0) !important;
}

.prgoress_indicator::after {
  position: absolute;
  font-family: "The-Icon-of";
  content: "\e9a2";
  text-align: center;
  line-height: 46px;
  font-size: 18px;
  color: rgba(190, 52, 106, 0.2);
  left: 0;
  top: 0;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  z-index: 1;
  -webkit-transition: all 200ms linear !important;
  -moz-transition: all 200ms linear !important;
  -o-transition: all 200ms linear !important;
  -ms-transition: all 200ms linear !important;
  transition: all 200ms linear !important;
}

.prgoress_indicator::before {
  position: absolute;
  font-family: "The-Icon-of";
  content: "\e9a2";
  text-align: center;
  line-height: 46px;
  font-size: 18px;
  opacity: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  left: 0;
  top: 0;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  z-index: 2;
  -webkit-transition: all 200ms linear !important;
  -moz-transition: all 200ms linear !important;
  -o-transition: all 200ms linear !important;
  -ms-transition: all 200ms linear !important;
  transition: all 200ms linear !important;
}

.prgoress_indicator:hover::after {
  color: #be346a;
}

.prgoress_indicator:hover:before {
  opacity: 1;
}

.prgoress_indicator svg path {
  fill: none;
}

.prgoress_indicator svg.progress-circle path {
  stroke: #be346a;
  stroke-width: 4;
  box-sizing: border-box;
  -webkit-transition: all 200ms linear !important;
  -moz-transition: all 200ms linear !important;
  -o-transition: all 200ms linear !important;
  -ms-transition: all 200ms linear !important;
  transition: all 200ms linear !important;
}

/*-----------------------------
  btn__more
-----------------------------*/
.btn__more .tio {
  vertical-align: middle;
  -webkit-transition: 0.3s !important;
  -moz-transition: 0.3s !important;
  -o-transition: 0.3s !important;
  -ms-transition: 0.3s !important;
  transition: 0.3s !important;
}

.btn__more:hover .tio {
  color: #10154e;
  font-size: 18px;
  margin-left: 5px;
}

/*--------------------------------------------------------------
## breadcrumb styles
--------------------------------------------------------------*/
.breadcrumb {
  /*-----------------------------
       default
  -----------------------------*/
}

.breadcrumb.default {
  background-color: white;
  padding: 5px 20px;
  box-shadow: 0 2px 4px #7e8eb11f !important;
  margin-bottom: 0;
  border: 1px solid #eaeaee;
}

.breadcrumb.default .breadcrumb-item {
  color: #6b7280;
}

.breadcrumb.default .breadcrumb-item::before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z' fill='rgba(107,114,128,1)'/%3E%3C/svg%3E");
  vertical-align: middle;
  font-size: 15px;
  height: 20px;
  width: 20px;
  margin-top: 2px;
  margin-right: 0.5rem;
}

.breadcrumb.default .breadcrumb-item:first-of-type::before {
  display: none;
}

.breadcrumb.default .breadcrumb-item a {
  font-weight: normal !important;
  color: #6b7280;
}

.breadcrumb.default .breadcrumb-item.active a {
  color: #858c9b;
}

.breadcrumb {
  width: fit-content;
  background-color: transparent;
  border-radius: 0.25rem;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.breadcrumb a {
  color: #10154e;
}

.breadcrumb.has_style1 {
  border-radius: 5px;
  background: #10154e !important;
}

.breadcrumb.has_style1 a {
  color: rgba(255, 255, 255, 0.459);
}

.breadcrumb.has_style1 .active {
  color: white !important;
}

.breadcrumb.has_style1 .breadcrumb-item {
  position: relative;
}

.breadcrumb.has_style1 .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0;
  color: #6c757d;
  content: "";
  margin-right: 7px;
  left: 0;
  top: 0%;
  width: 9px;
  height: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
}

.breadcrumb.has_stylel2 {
  border-radius: 5px;
  background: transparent !important;
}

.breadcrumb.has_stylel2 a {
  color: rgba(255, 255, 255, 0.459);
}

.breadcrumb.has_stylel2 .active {
  color: white !important;
}

.breadcrumb.has_stylel2 .breadcrumb-item {
  position: relative;
}

.breadcrumb.has_stylel2 .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0;
  color: #6c757d;
  content: "";
  margin-right: 7px;
  left: 0;
  top: 0%;
  width: 9px;
  height: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
}

.breadcrumb.has_stylel2::before {
  content: none;
}

.breadcrumb .breadcrumb-item {
  position: relative;
}

.breadcrumb .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0;
  color: #6c757d;
  content: "";
  margin-right: 5px;
  left: 0;
  top: 0%;
  width: 12px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
}

.breadcrumb .breadcrumb-item:first-child::before {
  content: none;
}

/*--------------------------------------------------------------
## form styles
--------------------------------------------------------------*/
.select-holder {
  position: relative;
}

.select-holder:after {
  content: "\f3d0";
  font-family: "Ionicons";
  font-weight: 600;
  font-size: 13px;
  position: absolute;
  line-height: 1;
  top: 50%;
  transform: translateY(-50%);
  right: 18px;
  z-index: 10;
  -webkit-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
  -moz-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
  -o-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
}

select {
  cursor: pointer;
  display: inline-block;
  padding: 15px !important;
  color: #6c757d !important;
}

textarea {
  padding: 15px !important;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="number"],
select,
textarea {
  background: 0 0;
  color: #232226;
  background-color: white;
  border: 1px solid #e2e4e9;
  width: 100%;
  padding: 15px;
  line-height: 1.5;
  font-weight: 400;
  font-size: inherit;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  position: relative;
  -webkit-appearance: none;
  box-shadow: 0 7px 9px rgba(29, 14, 98, 0.05);
  -webkit-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
  -o-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
  outline: 0;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
input[type="number"]:focus,
select:focus,
textarea:focus {
  border: 1px solid rgba(190, 52, 106, 0.5);
  outline: 0;
  box-shadow: none;
}

input[type="text"].is-valid,
input[type="password"].is-valid,
input[type="email"].is-valid,
input[type="tel"].is-valid,
input[type="number"].is-valid,
select.is-valid,
textarea.is-valid {
  border-bottom: solid 3px #3dcabd !important;
  border: 1px solid #e2e4e9;
  padding-right: calc(1.5em + 0.75rem);
  background: none !important;
}

input[type="text"].is-valid:focus,
input[type="password"].is-valid:focus,
input[type="email"].is-valid:focus,
input[type="tel"].is-valid:focus,
input[type="number"].is-valid:focus,
select.is-valid:focus,
textarea.is-valid:focus {
  border-bottom: solid 3px #3dcabd !important;
  border: 1px solid #e2e4e9;
  outline: 0;
  box-shadow: none;
}

input[type="text"].is-invalid,
input[type="password"].is-invalid,
input[type="email"].is-invalid,
input[type="tel"].is-invalid,
input[type="number"].is-invalid,
select.is-invalid,
textarea.is-invalid {
  border-bottom: solid 3px #de4b53 !important;
  border: 1px solid #e2e4e9;
  padding-right: calc(1.5em + 0.75rem);
  background: none;
}

input[type="text"].is-invalid:focus,
input[type="password"].is-invalid:focus,
input[type="email"].is-invalid:focus,
input[type="tel"].is-invalid:focus,
input[type="number"].is-invalid:focus,
select.is-invalid:focus,
textarea.is-invalid:focus {
  border-bottom: solid 3px #de4b53 !important;
  border: 1px solid #e2e4e9;
  outline: 0;
  box-shadow: none;
}

.form-group {
  margin-bottom: 20px !important;
}

.input-group-text {
  padding: 0.375rem 20px !important;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #ffffff;
  border: 1px solid #e2e4e9;
  border-radius: 0.25rem 0 0 0.25rem;
  min-height: 64px;
}

.textarea {
  width: 100%;
  height: 150px;
  resize: vertical;
}

label {
  color: #77748f;
  margin-bottom: 5px;
}

/*--------------------------------------------------------------
### Radio Checkbox
--------------------------------------------------------------*/
.radio {
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  margin-right: 5px;
}

.radio + label {
  text-transform: none;
  font-weight: normal;
  color: inherit;
  vertical-align: middle;
}

.radio input {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  cursor: pointer;
}

.radio input:checked {
  cursor: default;
}

.radio input:checked + .input:after {
  background: #10154e;
}

.radio .input {
  width: 18px;
  height: 18px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.radio .input:after {
  content: "";
  background: #fff;
  width: 8px;
  height: 8px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  left: 5px;
  -webkit-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
  -moz-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
  -o-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
}

.radio:hover .input:after {
  background: #10154e;
}

.radio:hover input:checked + .input:after {
  background: #10154e;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: white;
  background-color: #be346a;
  box-shadow: 52px 52px 74px 0 rgba(115, 131, 169, 0.08);
  width: 20px;
  height: 20px;
}

.custom-control-label {
  margin-left: 5px;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none;
}

.custom-control-label::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -30px;
  display: block;
  box-shadow: 52px 52px 74px 0 rgba(115, 131, 169, 0.08) !important;
  outline: none;
  width: 20px;
  height: 20px;
  content: "";
  background: no-repeat 50%/50% 50%;
  outline: none !important;
}

.custom-control-label::before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -30px;
  display: block;
  box-shadow: 52px 52px 74px 0 rgba(115, 131, 169, 0.08) !important;
  outline: none;
  width: 20px;
  height: 20px;
  content: "";
  background-color: #fff;
  outline: none !important;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 2.75rem;
  pointer-events: all;
  border-radius: 24px;
  height: 22px;
  border: none;
  background-color: #f3f8ff;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: white;
  background-color: #5a52ff;
  box-shadow: 0 21px 51px rgba(29, 14, 98, 0.15);
  width: 2.75rem;
  height: 20px;
}

.custom-switch .custom-control-label::after {
  top: 1px;
  left: calc(-14px + 0px);
  width: 18px;
  height: 18px;
  background-color: #ffffff;
  box-shadow: 0 21px 23px -5px rgba(82, 48, 162, 0.12);
  border-radius: 1.5rem;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  left: calc(-47px + 0px);
  top: -9px;
}

.custom-switch .custom-control-label {
  margin-left: 0;
  padding-left: 19px;
}

.custom-select {
  height: 62px;
}

.input-group-prepend .btn {
  padding: 19px 18px;
}

.custom-select-xs {
  height: calc(0.5rem + 0.9375rem * 1.6 + 2px);
  padding: 0.25rem 1.875rem 0.25rem 0.625rem;
  line-height: 1.6;
  font-size: 0.9375rem;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: 0.35s ease-in-out;
}

.form_cc_four {
  background-color: #fff;
  border-radius: 8px;
  border: solid 1px #dfe4e7;
  padding: 2.5rem;
  -webkit-box-shadow: 0px 50px 70px -10px rgba(11, 34, 56, 0.05) !important;
  -moz-box-shadow: 0px 50px 70px -10px rgba(11, 34, 56, 0.05) !important;
  -o-box-shadow: 0px 50px 70px -10px rgba(11, 34, 56, 0.05) !important;
  -ms-box-shadow: 0px 50px 70px -10px rgba(11, 34, 56, 0.05) !important;
  box-shadow: 0px 50px 70px -10px rgba(11, 34, 56, 0.05) !important;
}

.form_cc_four.bg_dark label {
  margin-bottom: 5px;
  color: #fff;
}

.form_cc_four.bg_dark input,
.form_cc_four.bg_dark .form-control {
  background-color: rgba(255, 255, 255, 0.192) !important;
  border: none !important;
  color: #fff !important;
}

.form_cc_four.bg_dark input::placeholder,
.form_cc_four.bg_dark .form-control::placeholder {
  color: #908ea7 !important;
}

.form_cc_four.bg_dark input:hover,
.form_cc_four.bg_dark .form-control:hover {
  background-color: rgba(255, 255, 255, 0.075) !important;
}

@media (min-width: 992px) {
  .form_cc_four .item_upload {
    display: flex;
  }
}

.form_cc_four .item_upload span {
  font-size: 13px;
  color: #6c7a87;
}

@media (max-width: 767px) {
  .form_cc_four .item_upload span {
    display: block;
  }
}

.form_cc_four .upload__file {
  position: relative;
  overflow: hidden;
  margin-right: 15px;
  background: white;
  color: #10154e;
  border-radius: 8px;
  -webkit-transition: 0.3s !important;
  -moz-transition: 0.3s !important;
  -o-transition: 0.3s !important;
  -ms-transition: 0.3s !important;
  transition: 0.3s !important;
}

.form_cc_four .upload__file input[type="file"] {
  position: absolute;
  opacity: 0;
  top: 0;
  left: -160px;
  font-size: 40px;
  cursor: pointer;
}

.form_cc_four .upload__file .btn {
  border-radius: 8px;
  border: 1px dashed #dce0e3;
  color: #10154e;
}

.form_cc_four .upload__file .btn i {
  vertical-align: middle;
  color: #10154e;
  font-size: 18px;
  margin-right: 4px;
  top: 5px;
}

.form_cc_four .upload__file:hover {
  background-color: #f7f8fa;
}

.form_cc_four .upload__file:hover .btn {
  color: white;
}

.form_cc_four .upload__file:hover i {
  color: white;
}

/*--------------------------------------------------------------
## alert styles
--------------------------------------------------------------*/
.alert {
  border-radius: 8px;
  padding: 16px 20px;
  display: flex;
  align-items: center;
  border: 0;
  color: #10154e;
}

.alert button {
  border: none;
  box-shadow: none;
  outline: none;
}

.alert .alert-link {
  padding-left: 5px;
}

.alert > [class^="ri-"],
.alert > [class*=" ri-"] {
  margin-right: 10px;
  font-size: 20px;
}

.alert a {
  color: #10154e;
  font-weight: 500;
  -webkit-transition: 0.3s !important;
  -moz-transition: 0.3s !important;
  -o-transition: 0.3s !important;
  -ms-transition: 0.3s !important;
  transition: 0.3s !important;
}

.alert a:hover {
  color: #be346a;
}

.alert .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  top: auto;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  opacity: 1 !important;
  border-radius: 50%;
  background-color: #fff;
  padding: 0;
  -webkit-transition: 0.3s !important;
  -moz-transition: 0.3s !important;
  -o-transition: 0.3s !important;
  -ms-transition: 0.3s !important;
  transition: 0.3s !important;
  box-shadow: none !important;
  text-shadow: none !important;
}

.alert .close i {
  font-size: 18px;
  color: #10154e;
  margin-top: -1px;
  margin-left: 5px;
  position: relative;
}

.alert .close:hover {
  background-color: rgba(16, 21, 78, 0.05);
}

.alert.alert-warning {
  background-color: rgba(255, 195, 41, 0.15);
}

.alert.alert-primary {
  background-color: rgba(190, 52, 106, 0.15);
}

.alert.alert-primary [class^="ri-"],
.alert.alert-primary [class*=" ri-"] {
  color: #be346a;
}

.alert.alert-secondary {
  background-color: rgba(104, 55, 105, 0.15);
  color: #683769;
}

.alert.alert-success {
  background-color: rgba(49, 209, 171, 0.15);
  color: #31d1ab;
}

.alert.alert-danger {
  background-color: rgba(249, 53, 66, 0.15);
  color: #f93542;
}

.alert.alert-info {
  background-color: rgba(107, 176, 248, 0.15);
  color: #6bb0f8;
}

.alert.alert-light {
  background-color: rgba(255, 255, 255, 0.15);
  color: #fff;
}

.alert.alert-dark {
  background-color: rgba(16, 21, 78, 0.15);
  color: #10154e;
}

/*--------------------------------------------------------------
## pagination styles
--------------------------------------------------------------*/
.pagination.has_style1 .page-link {
  background: white;
}

.pagination.has_style1 .page-link:hover {
  color: #be346a;
}

.pagination.has_style1 .page-link.is-active {
  background: white;
  color: #10154e;
  box-shadow: 52px 52px 74px 0 rgba(115, 131, 169, 0.08);
  transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

.pagination.has_style1 .page-link.is-active:hover {
  background: white;
  color: #10154e;
}

.page-link {
  position: relative;
  display: block;
  margin-left: -1px;
  line-height: 100%;
  color: #10154e;
  background-color: #faf4f6;
  padding: 0.5rem 0.75rem;
  border-width: 1px;
  border: none;
  border-radius: 60px;
  margin-right: 20px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-link.is-active {
  background: #10154e;
  color: white;
  box-shadow: 52px 52px 74px 0 rgba(115, 131, 169, 0.08);
  transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

.page-link.is-active:hover {
  background: #10154e;
  color: white;
}

.page-link:hover {
  background: #683769;
  transform: scale(1.09);
  color: #fff;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-radius: 60px;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-radius: 60px;
}

/*--------------------------------------------------------------
## avatar styles
--------------------------------------------------------------*/
.avatar {
  width: 4.5rem;
  height: 4.5rem;
}

.avatar.has_style1 {
  box-shadow: 0 33px 88px 0 #1d0e6221;
  border-radius: 50%;
  border: 7px solid #fff;
}

.avatar.avatar-sm {
  width: 3rem;
  height: 3rem;
}

.avatar.avatar-md {
  width: 5rem;
  height: 5rem;
}

.avatar.avatar-lg {
  width: 6rem;
  height: 6rem;
}

.avatar-group {
  display: flex;
  margin-left: 25px;
}

.avatar-group .avatar-item {
  width: 3rem;
  height: 3rem;
  margin-left: -25px;
  border: solid 6px white;
}

/*--------------------------------------------------------------
## popups styles
--------------------------------------------------------------*/
#popup_form1 .cover_sign {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

#popup_form1 .popup_title {
  font-size: calc(24px + 16 * ( (100vw - 576px) / 1024));
  margin-bottom: 30px;
}

@media screen and (max-width: 576px) {
  #popup_form1 .popup_title {
    font-size: 24px;
  }
}

@media screen and (min-width: 1600px) {
  #popup_form1 .popup_title {
    font-size: 40px;
  }
}

#popup_form1 .popup_description {
  margin-bottom: 30px;
}

#popup_form1 .modal-body {
  padding: 20px;
}

#popup_form2 ion-icon {
  color: white;
}

#popup_form2 .modal-body {
  background: #be346a;
  padding: 40px;
}

#popup_form2 .modal-body .popup_title {
  font-size: calc(24px + 16 * ( (100vw - 576px) / 1024));
  margin-bottom: 30px;
  color: white;
  text-align: center;
}

@media screen and (max-width: 576px) {
  #popup_form2 .modal-body .popup_title {
    font-size: 24px;
  }
}

@media screen and (min-width: 1600px) {
  #popup_form2 .modal-body .popup_title {
    font-size: 40px;
  }
}

#popup_form2 .modal-body .popup_description {
  margin-bottom: 30px;
  color: white;
  text-align: center;
}

#popup_form3 .modal-dialog {
  bottom: -76% !important;
}

@media screen and (max-width: 991px) {
  #popup_form3 .modal-dialog {
    bottom: 0 !important;
  }
}

#popup_form3 .close {
  position: absolute;
  top: 15px;
  right: 15px;
  background: rgba(255, 255, 255, 0.74);
  z-index: 6;
}

#popup_form3 .close:hover ion-icon {
  color: #10154e;
}

#popup_form3 .popup_wrap {
  padding: 30px;
  position: relative;
}

#popup_form3 .popup_wrap::before {
  content: "";
  position: absolute;
  background: url(../assets/images/others/top_decoration.png);
  top: -16px;
  left: -16px;
  height: 100%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

#popup_form3 .popup_title {
  font-size: calc(24px + 16 * ( (100vw - 576px) / 1024));
  margin-bottom: 30px;
}

@media screen and (max-width: 576px) {
  #popup_form3 .popup_title {
    font-size: 24px;
  }
}

@media screen and (min-width: 1600px) {
  #popup_form3 .popup_title {
    font-size: 40px;
  }
}

#popup_notification1 .popup_wrap {
  padding: 40px;
}

#popup_notification1 .popup_title {
  font-size: calc(24px + 16 * ( (100vw - 576px) / 1024));
  margin-bottom: 30px;
  text-align: center;
}

@media screen and (max-width: 576px) {
  #popup_notification1 .popup_title {
    font-size: 24px;
  }
}

@media screen and (min-width: 1600px) {
  #popup_notification1 .popup_title {
    font-size: 40px;
  }
}

#popup_notification1 .popup_description {
  text-align: center;
}

#popup_notification1 img {
  width: 14rem;
  margin-left: auto;
  margin-right: auto;
}

#popup_notification2 .modal-dialog {
  bottom: -20% !important;
}

@media screen and (max-width: 991px) {
  #popup_notification2 .modal-dialog {
    bottom: 0 !important;
  }
}

#popup_notification2 .popup_wrap {
  padding: 60px 40px;
}

#popup_notification2 .close {
  position: absolute;
  top: 15px;
  right: 15px;
  background: rgba(255, 255, 255, 0.74);
}

#popup_notification2 .top_img {
  overflow: hidden;
  width: 100%;
}

#popup_notification2 .top_img img {
  height: 15rem;
  object-fit: cover;
  width: 100%;
}

#popup_notification2 .modal-body {
  padding: 0;
  border: none;
}

#popup_notification2 .popup_title {
  font-size: calc(24px + 16 * ( (100vw - 576px) / 1024));
  margin-bottom: 30px;
  text-align: center;
}

@media screen and (max-width: 576px) {
  #popup_notification2 .popup_title {
    font-size: 24px;
  }
}

@media screen and (min-width: 1600px) {
  #popup_notification2 .popup_title {
    font-size: 40px;
  }
}

#popup_notification2 .popup_description {
  text-align: center;
  margin-bottom: 30px;
}

#popup_notification2 .popup_wrap {
  position: relative;
}

#popup_notification2 .popup_wrap::before {
  content: "";
  position: absolute;
  background: url(../assets/images/others/circle_decoration.png);
  top: -46%;
  left: 1%;
  height: 100%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

#popup_notification3 .modal-dialog {
  bottom: -20% !important;
}

@media screen and (max-width: 991px) {
  #popup_notification3 .modal-dialog {
    bottom: 0 !important;
  }
}

#popup_notification3 .popup_wrap {
  padding: 60px 40px;
}

#popup_notification3 .close {
  position: absolute;
  top: 15px;
  right: 15px;
  background: white;
}

#popup_notification3 .modal-body {
  padding: 0;
  border: none;
  background: #be346a;
}

#popup_notification3 .popup_title {
  font-size: calc(24px + 2 * ( (100vw - 576px) / 1024));
  margin-bottom: 30px;
  color: white;
}

@media screen and (max-width: 576px) {
  #popup_notification3 .popup_title {
    font-size: 24px;
  }
}

@media screen and (min-width: 1600px) {
  #popup_notification3 .popup_title {
    font-size: 26px;
  }
}

#popup_notification3 .popup_description {
  margin-bottom: 30px;
  color: white;
}

#popup_notification3 .popup_wrap {
  overflow: hidden;
  position: relative;
}

#popup_notification3 .popup_wrap::before {
  content: "";
  position: absolute;
  background: url(../assets/images/others/circle_decoration.png);
  top: -46%;
  left: 1%;
  height: 100%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

#popup_notification3 .top_img {
  overflow: hidden;
  width: 100%;
}

#popup_notification3 .top_img img {
  height: 15rem;
  object-fit: cover;
  width: 100%;
}

#popup_cookies1 .modal-dialog {
  bottom: -20% !important;
}

@media screen and (max-width: 991px) {
  #popup_cookies1 .modal-dialog {
    bottom: 0 !important;
  }
}

#popup_cookies1 .modal-content {
  min-width: 21rem;
}

#popup_cookies1 .popup_wrap {
  padding: 30px;
}

#popup_cookies1 .close {
  position: absolute;
  top: 15px;
  right: 15px;
  background: white;
  z-index: 5;
}

#popup_cookies1 .modal-body {
  padding: 0;
  border: none;
  background: white;
}

#popup_cookies1 .top_img {
  max-width: 5rem;
  margin-bottom: 20px;
}

#popup_cookies1 .popup_title {
  font-size: calc(24px + 2 * ( (100vw - 576px) / 1024));
  margin-bottom: 20px;
}

@media screen and (max-width: 576px) {
  #popup_cookies1 .popup_title {
    font-size: 24px;
  }
}

@media screen and (min-width: 1600px) {
  #popup_cookies1 .popup_title {
    font-size: 26px;
  }
}

#popup_cookies1 .popup_description {
  margin-bottom: 20px;
}

#popup_cookies1 .popup_wrap {
  overflow: hidden;
  position: relative;
}

#popup_cookies1 .popup_wrap::before {
  content: "";
  position: absolute;
  background: url(../assets/images/others/circle_decoration.png);
  top: -46%;
  left: 1%;
  height: 100%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

#popup_cookies2 .modal-dialog {
  bottom: -20% !important;
}

@media screen and (max-width: 991px) {
  #popup_cookies2 .modal-dialog {
    bottom: 0 !important;
  }
}

#popup_cookies2 .popup_wrap {
  padding: 30px;
}

#popup_cookies2 .close {
  position: absolute;
  top: 15px;
  right: 15px;
  background: white;
  z-index: 9;
}

#popup_cookies2 .close:hover ion-icon {
  color: #10154e;
}

#popup_cookies2 .modal-body {
  padding: 0;
  border: none;
  background: white;
}

#popup_cookies2 .top_img {
  max-width: 5rem;
  margin-right: 30px;
}

#popup_cookies2 .popup_title {
  font-size: calc(24px + 2 * ( (100vw - 576px) / 1024));
}

@media screen and (max-width: 576px) {
  #popup_cookies2 .popup_title {
    font-size: 24px;
  }
}

@media screen and (min-width: 1600px) {
  #popup_cookies2 .popup_title {
    font-size: 26px;
  }
}

#popup_cookies2 .popup_description {
  max-width: 30rem;
}

#popup_cookies2 .popup_wrap {
  overflow: hidden;
  position: relative;
}

#popup_cookies2 .popup_wrap::before {
  content: "";
  position: absolute;
  background: url(../assets/images/others/circle_decoration.png);
  top: -46%;
  left: 1%;
  height: 100%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

#popup_cookies3 .popup_wrap {
  padding: 30px;
  background: #be346a;
  border: solid 10px rgba(255, 255, 255, 0.212);
}

#popup_cookies3 .modal-body {
  padding: 0;
  border: none;
  background: white;
}

#popup_cookies3 .top_img {
  max-width: 5rem;
  margin-right: 30px;
}

#popup_cookies3 .popup_title {
  font-size: calc(24px + 2 * ( (100vw - 576px) / 1024));
  color: white;
}

@media screen and (max-width: 576px) {
  #popup_cookies3 .popup_title {
    font-size: 24px;
  }
}

@media screen and (min-width: 1600px) {
  #popup_cookies3 .popup_title {
    font-size: 26px;
  }
}

#popup_cookies3 .popup_description {
  max-width: 30rem;
  color: white;
}

#popup_cookies3 .popup_wrap {
  overflow: hidden;
  position: relative;
}

#popup_cookies3 .popup_wrap::before {
  content: "";
  position: absolute;
  background: url(../assets/images/others/circle_decoration.png);
  top: -46%;
  right: 1%;
  height: 100%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

/*--------------------------------------------------------------
## box styles
--------------------------------------------------------------*/
.box {
  padding: 40px 30px;
  border-radius: 10px;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
  cursor: default;
}

.box.has-style1 {
  background: #f3f8ff;
  -webkit-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.box.has-style1 .box-icon {
  margin-bottom: 20px;
  text-align: center;
}

.box.has-style1 .box-icon img {
  max-height: 3rem;
}

.box.has-style1 .box-icon ion-icon {
  color: #be346a;
  font-size: 35px !important;
}

.box.has-style1 .box-icon svg {
  fill: #be346a;
}

.box.has-style1 .box-icon [class^="ri-"] {
  fill: #be346a !important;
  font-size: 2rem;
}

.box.has-style1 .box-title {
  margin-bottom: 14px;
  text-align: center;
}

.box.has-style1 .box-desc {
  text-align: center;
}

.box.has-style2 {
  background: #fff;
  box-shadow: 52px 52px 74px 0 rgba(115, 131, 169, 0.08);
  transition: 0.25s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.box.has-style2 .box-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  text-align: center;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background: #faf4f6 !important;
  z-index: 0;
}

.box.has-style2 .box-icon img {
  max-height: 3rem;
}

.box.has-style2 .box-icon ion-icon {
  color: #be346a;
  font-size: 35px !important;
}

.box.has-style2 .box-icon svg {
  fill: #be346a;
}

.box.has-style2 .box-icon [class^="ri-"] {
  fill: #be346a !important;
  font-size: 2rem;
}

.box.has-style2 .box-title {
  margin-bottom: 20px;
  text-align: center;
}

.box.has-style2 .box-desc {
  color: #232323;
  text-align: center;
  margin: 0;
}

.box.is-dark {
  background: #be346a;
  transition: 0.25s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box.is-dark .box-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  text-align: center;
}

.box.is-dark .box-icon img {
  max-height: 5rem;
}

.box.is-dark .box-icon ion-icon {
  color: #fff !important;
  font-size: 35px;
}

.box.is-dark .box-title {
  margin-bottom: 10px;
  text-align: left;
  color: white;
}

.box.is-dark .box-desc {
  color: white;
  text-align: left;
  margin: 0;
}

.box.has-left-icon {
  background: #fff;
  box-shadow: 52px 52px 74px 0 rgba(115, 131, 169, 0.08);
  transition: 0.25s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box.has-left-icon .box-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  text-align: center;
}

.box.has-left-icon .box-icon img {
  max-height: 5rem;
}

.box.has-left-icon .box-icon [class^="ri-"] {
  font-size: 35px;
  height: 40px;
  color: #be346a;
  width: 40px;
}

.box.has-left-icon .box-title {
  margin-bottom: 10px;
  text-align: left;
}

.box.has-left-icon .box-desc {
  text-align: left;
  margin: 0;
}

.card.style_1 .card-body {
  box-shadow: 52px 52px 74px 0 rgba(115, 131, 169, 0.08);
}

.box_numbers {
  padding: 10px;
  border-radius: 5px;
  max-width: 25rem;
  box-shadow: 52px 52px 74px 0 rgba(115, 131, 169, 0.08);
  overflow: hidden;
  position: relative;
}

.box_numbers::before {
  content: "";
  position: absolute;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  bottom: auto;
  top: 27px;
  left: 46%;
  background-color: transparent;
  background-image: url(../images/others/img_effect.png);
  background-size: contain;
}

@media screen and (max-width: 991px) {
  .box_numbers {
    margin-left: auto;
    margin-right: auto;
  }
}

.box_numbers_wrap {
  background: #be346a;
  padding: 30px;
}

.box_numbers .item {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.199);
}

.box_numbers .number {
  color: white;
}

.box_numbers .description {
  color: white;
}

.box_img {
  box-shadow: 0 33px 88px 0 #1d0e6211;
  border-radius: 10px;
  background: white;
  padding: 40px;
  transition: 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  margin-bottom: 30px;
}

.box_img .box-inner {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.box_img .img_wrap {
  width: 5rem;
  height: 5rem;
  background: #683769;
  border-radius: 50%;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box_img .img_wrap i {
  color: white;
  font-size: 2rem;
}

.box_img img {
  width: 70%;
  height: 10rem;
}

.box_img * {
  text-align: center;
}

/*
    ======== Table of Sections
    --------------------------
    ## half backgrounds
    --------------------------
    ## images
    --------------------------
    ## play btn
    --------------------------
    ## deviders
    --------------------------
    ## title line
    --------------------------
    ##  tabs
    --------------------------
    ##  table
    --------------------------
    ##  dropzone
    --------------------------
    ##  made by
    --------------------------
    ##   toolbar
    --------------------------
*/
/*--------------------------------------------------------------
## half backgrounds
--------------------------------------------------------------*/
.half_light {
  position: relative;
}

.half_light::before {
  content: "";
  position: absolute;
  bottom: 0;
  background: #f3f8ff;
  height: 50%;
  width: 100%;
  z-index: -1;
}

.has_dark {
  position: relative;
}

.has_dark::before {
  content: "";
  position: absolute;
  height: 40%;
  bottom: 0;
  width: 100%;
  background: #10154e;
  z-index: -1;
}

/*--------------------------------------------------------------
## images
--------------------------------------------------------------*/
.img_top {
  border-radius: 5px;
  margin-bottom: 30px;
  box-shadow: 0 40px 40px -20px rgba(23, 29, 48, 0.1);
  position: relative;
}

/*--------------------------------------------------------------
## play btn
--------------------------------------------------------------*/
.play_btn {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
}

/*--------------------------------------------------------------
## deviders
--------------------------------------------------------------*/
.devider {
  position: absolute;
  background-image: url(../images/others/devider.svg);
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  height: 20px;
  bottom: -2px;
  left: 0;
  right: 0;
}

.devider_grey {
  position: absolute;
  background-image: url(../images/others/devider_grey.svg);
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  height: 20px;
  bottom: -2px;
  left: 0;
  right: 0;
}

.devider_white {
  position: absolute;
  background-image: url(../images/others/devider.svg);
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  height: 20px;
  bottom: -2px;
  left: 0;
  right: 0;
}

.devider_dark_top {
  background: url(../images/others/devider_light.svg);
  background-repeat: no-repeat;
  width: 100%;
  height: 6rem;
  background-position: top;
}

.devider_light_top {
  background: url(../images/others/devider_light.svg);
  background-repeat: no-repeat;
  width: 100%;
  height: 6rem;
  background-position: top;
}

.divider_light_bottom {
  background: url(../images/others/devider_light.svg);
  background-repeat: no-repeat;
  width: 100%;
  height: 6rem;
  background-position: bottom;
}

/*--------------------------------------------------------------
## title line
--------------------------------------------------------------*/
.title_line {
  position: relative;
  display: inline;
  z-index: 0;
}

.title_line::before {
  content: "";
  background-repeat: no-repeat;
  height: 20px;
  width: 100%;
  position: absolute;
  bottom: -13px;
  top: 33px;
  border-radius: 10px;
  right: -2px;
  background: #faf4f6;
  background-size: contain;
  left: auto;
  z-index: -1;
}

/*--------------------------------------------------------------
##  tabs
--------------------------------------------------------------*/
.nav-tabs {
  margin-bottom: 30px;
  border-bottom: none;
}

.nav-link {
  color: #10154e;
}

.nav-link:hover {
  border: none;
}

.nav-link.active {
  border: none;
  border-bottom: solid 3px #be346a;
  border-radius: 5px 5px 0 0;
  background: transparent;
  color: #be346a;
}

/*--------------------------------------------------------------
##  table
--------------------------------------------------------------*/
.table-flush tbody td:first-child,
.table-flush tbody th:first-child,
.table-flush tfoot td:first-child,
.table-flush thead th:first-child {
  padding-left: 0;
}

.table-flush tbody td:last-child,
.table-flush tbody th:last-child,
.table-flush tfoot td:last-child,
.table-flush thead th:last-child {
  padding-right: 0;
}

.table td,
.table th {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-top: 1px solid #e5ebf1;
}

.table-white {
  background-color: white;
  padding: 0 30px 30px;
  border-radius: 5px;
}

.table th {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-weight: 400;
}

.table thead th {
  border-bottom: 0;
  text-transform: uppercase;
  font-size: 0.875rem;
  color: #8d9aae;
}

.table tfoot td {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.table-clickable [data-href]:hover {
  cursor: pointer;
}

.table-clickable [data-href]:hover .table-clickable-hover {
  color: #5546e8;
}

@media (max-width: 767.98px) {
  .table-features.table-features-alt td:nth-child(2),
  .table-features.table-features-alt th:nth-child(2) {
    display: none;
  }
  .table-features:not(.table-features-alt) td:nth-child(3),
  .table-features:not(.table-features-alt) th:nth-child(3) {
    display: none;
  }
}

/*--------------------------------------------------------------
##  dropzone
--------------------------------------------------------------*/
.dropzone {
  padding: 5rem 1rem;
  background-color: #fff;
  border: 2px dashed #c6d3e6;
  border-radius: 0.375rem;
  text-align: center;
  color: #869ab8;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-box-ordinal-group: 0;
  order: -1;
  cursor: pointer;
  z-index: 999;
  margin-bottom: 20px;
  margin-top: 2px;
}

.dropzone p {
  color: #526692;
}

.dropzone:hover {
  background-color: rgba(241, 242, 248, 0.562);
}

.dropzone:hover p {
  color: #10154e;
}

/*--------------------------------------------------------------
##  made by
--------------------------------------------------------------*/
.made_by {
  position: fixed !important;
  display: inline-block !important;
  visibility: visible !important;
  z-index: 2147483647 !important;
  top: auto !important;
  right: 12px !important;
  bottom: 12px !important;
  left: auto !important;
  color: #aaadb0 !important;
  background-color: #fff !important;
  border-radius: 3px !important;
  padding: 6px 8px 6px 6px !important;
  font-size: 12px !important;
  opacity: 1 !important;
  line-height: 14px !important;
  text-decoration: none !important;
  transform: none !important;
  margin: 0 !important;
  width: auto !important;
  height: auto !important;
  overflow: visible !important;
  white-space: nowrap;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

/*--------------------------------------------------------------
##   domain form
--------------------------------------------------------------*/
.domain_form {
  background: #fff;
  box-shadow: 0 33px 88px 0 #1d0e6211;
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 5px;
  max-width: 50rem;
  border: solid 1px transparent;
  width: 100%;
  transition: all 0.2s ease-in-out;
}

.domain_form .btn_search {
  display: flex;
  align-items: center;
  justify-content: center;
}

.domain_form .btn_search span {
  display: block;
}

.domain_form .btn_search i {
  font-size: 25px;
  line-height: 100%;
  margin-top: -4px;
}

@media screen and (max-width: 991px) {
  .domain_form {
    max-width: auto !important;
  }
}

.domain_form:hover, .domain_form:focus {
  border: solid 1px #be346a;
}

.domain_form input {
  height: 30px;
  border: none;
  box-shadow: none;
}

.domain_form input:hover, .domain_form input:focus {
  border: none;
  box-shadow: none;
}

.domain_form .btn {
  padding: 18px;
  width: 30%;
}

.table_sh {
  box-shadow: 0 33px 88px 0 #1d0e6211;
  padding: 30px;
  border-radius: 10px;
}

.table_sh span {
  color: #6f7583;
}

/*
    CSS Table of Sections
    --------------------------
    ** Style header Default
    --------------------------
    ** header-nav-center
    --------------------------
    ** header-nav-left
    --------------------------
    ** Fixed header
*/
/*-----------------------------
  Style header Default
-----------------------------*/
header {
  z-index: 9;
  position: absolute;
  top: 0;
  background: transparent !important;
  right: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid rgba(16, 21, 78, 0);
  -webkit-backdrop-filter: saturate(1) blur(20px) !important;
  -moz-backdrop-filter: saturate(1) blur(20px) !important;
  -o-backdrop-filter: saturate(1) blur(20px) !important;
  -ms-backdrop-filter: saturate(1) blur(20px) !important;
  backdrop-filter: saturate(1) blur(20px) !important;
  -webkit-transition: 0.3s !important;
  -moz-transition: 0.3s !important;
  -o-transition: 0.3s !important;
  -ms-transition: 0.3s !important;
  transition: 0.3s !important;
}

header.no_blur {
  -webkit-backdrop-filter: none !important;
  -moz-backdrop-filter: none !important;
  -o-backdrop-filter: none !important;
  -ms-backdrop-filter: none !important;
  backdrop-filter: none !important;
}

.fixed-header header {
  background: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: saturate(1) blur(20px) !important;
  -o-backdrop-filter: saturate(1) blur(20px) !important;
  -ms-backdrop-filter: saturate(1) blur(20px) !important;
  backdrop-filter: saturate(1) blur(20px) !important;
  border-bottom: 1px solid #edeef1;
}

@-moz-document url-prefix() {
  .fixed-header header {
    background: white;
    border-bottom: 0 !important;
    backdrop-filter: saturate(1) blur(0);
    box-shadow: 0px 10px 40px 0px rgba(11, 34, 56, 0.05);
  }
}

header .navbar {
  padding: 28px 0;
}

header .navbar .navbar-brand {
  font-weight: 600;
  font-size: 22px;
}

header .navbar .navbar-brand .logo {
  vertical-align: bottom;
  margin-right: 0.5rem;
  height: 34px;
}

@media screen and (max-width: 991px) {
  header .navbar .navbar-collapse {
    background-color: white;
    box-shadow: #11164e 0px 36px 66px -42px;
    border-radius: 5px;
    padding: 20px;
    margin-top: 10px;
    border: solid 1px #d5d8df;
  }
}

header .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
  font-size: 15.5px;
  font-weight: 400;
  text-transform: capitalize;
  margin-right: 1.25rem;
  background-color: transparent;
}

header .navbar .navbar-collapse .navbar-nav .dropdown.show .nav-link {
  opacity: 0.7;
}

header .navbar .navbar-collapse .nav_account .btn {
  font-size: 15px;
}

header .navbar .navbar-collapse .nav_account.btn_demo2 .btn_sm_primary {
  background-color: rgba(190, 52, 106, 0.1);
  color: #be346a;
}

header .navbar .navbar-collapse .nav_account.btn_demo3 .btn_sm_primary {
  border: 1px solid #edeef1;
}

@media screen and (max-width: 991px) {
  header {
    background: #fff;
  }
  header .navbar-toggler {
    background-color: transparent !important;
  }
  header .navbar-toggler:focus {
    border: 0;
    outline: 0;
    box-shadow: none;
  }
  header .navbar-brand {
    color: #10154e !important;
    font-size: 18px !important;
  }
  header .navbar-brand .logo {
    height: 34px;
  }
  header .navbar-collapse {
    margin-left: 0 !important;
  }
  header .navbar-collapse .navbar-nav {
    padding-bottom: 1rem;
  }
  header .navbar-collapse .navbar-nav .nav-item .nav-link {
    color: #10154e !important;
  }
  header .navbar-collapse .navbar-nav .nav-item .nav-link.active {
    color: #be346a !important;
  }
  header .navbar-collapse .nav_account {
    border-top: 1px solid #edeef1;
    padding: 1rem 0;
  }
  header .navbar-collapse .nav_account .btn-default {
    color: #10154e !important;
  }
  header .navbar-collapse .nav_account .btn-primary {
    background-color: #be346a !important;
    color: #fff !important;
  }
}

/*-----------------------------
    header-nav-center
  -----------------------------*/
.header-nav-center {
  width: 100%;
  left: 0;
  z-index: 12;
}

.header-nav-center .navbar-brand {
  color: #10154e;
}

.header-nav-center .navbar-collapse .nav-item .nav-link {
  color: #10154e;
  -webkit-transition: 0.4s !important;
  -moz-transition: 0.4s !important;
  -o-transition: 0.4s !important;
  -ms-transition: 0.4s !important;
  transition: 0.4s !important;
}

.header-nav-center .navbar-collapse .nav-item .nav-link:hover {
  opacity: 0.7;
}

.header-nav-center .navbar-collapse .nav-item .nav-link.active {
  color: #be346a;
  background-color: transparent;
}

.header-nav-center .navbar-collapse .nav-item.active .nav-link {
  color: #be346a;
  background-color: transparent;
}

/*-----------------------------
    header-nav-left
  -----------------------------*/
.header-nav-left .navbar-collapse {
  margin-left: 2rem;
}

.header-nav-left.light .nav-pills .nav-item .nav-link {
  color: #fff;
}

.header-nav-left.light .nav-pills .nav-item .nav-link.active {
  color: #ffe7aa;
}

/*-----------------------------
    Fixed header
  -----------------------------*/
.fixed-header header.nav-product .navbar-brand {
  color: #10154e !important;
}

.fixed-header header.nav-product .nav-pills .nav-item .nav-link {
  color: #10154e !important;
}

.fixed-header header.nav-product .nav-pills .nav-item .nav-link.active {
  color: #be346a !important;
}

.fixed-header header.nav-product .nav_account .btn-default {
  color: #10154e !important;
}

.fixed-header header.nav-product .nav_account .btn-primary {
  background-color: #be346a !important;
  color: #fff !important;
}

.fixed-header .gch_header_light .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
  color: #fff;
  border-radius: 0;
  line-height: 54px;
}

.fixed-header .gch_header_light .navbar .navbar-collapse .navbar-nav .nav-item .nav-link.active {
  -webkit-box-shadow: 0px 3px 0px 0px #be346a !important;
  -moz-box-shadow: 0px 3px 0px 0px #be346a !important;
  -o-box-shadow: 0px 3px 0px 0px #be346a !important;
  -ms-box-shadow: 0px 3px 0px 0px #be346a !important;
  box-shadow: 0px 3px 0px 0px #be346a !important;
}

@media screen and (max-width: 991px) {
  .fixed-header .gch_header_light .navbar .navbar-collapse .navbar-nav .nav-item .nav-link.active {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    -o-box-shadow: none !important;
    -ms-box-shadow: none !important;
    box-shadow: none !important;
  }
}

/*-----------------------------
    header-Crypto
  -----------------------------*/
.header-nav-left.crypto_1 .nav-pills .nav-item .nav-link.active {
  color: #fd6b3b !important;
}

.fixed-header .header-nav-left.crypto_1 .nav-pills .nav-item .nav-link.active {
  color: #fd6b3b !important;
}

.fixed-header .header-nav-left.crypto_1 .nav_account .btn-primary {
  background-color: #fd6b3b !important;
}

.header-nav-left.crypto_1 .nav_account .btn-primary {
  background-color: #fd6b3b !important;
}

/*-----------------------------
    header-Crypto Dark
  -----------------------------*/
.crypto_dark {
  background-color: transparent;
  position: absolute;
  -webkit-backdrop-filter: saturate(1) blur(0) !important;
  -moz-backdrop-filter: saturate(1) blur(0) !important;
  -o-backdrop-filter: saturate(1) blur(0) !important;
  -ms-backdrop-filter: saturate(1) blur(0) !important;
  backdrop-filter: saturate(1) blur(0) !important;
}

.crypto_dark .nav_account .btn_try {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .crypto_dark .nav_account .btn_try {
    background-color: rgba(16, 21, 78, 0.1) !important;
    color: #10154e !important;
  }
}

@media screen and (max-width: 768px) {
  .crypto_dark .nav_account .drop_download {
    display: none;
    background-color: rgba(16, 21, 78, 0.1);
    color: #10154e;
  }
}

@media screen and (max-width: 768px) {
  .crypto_dark .nav_account .btn-default {
    color: #10154e;
  }
}

@media screen and (max-width: 768px) {
  .crypto_dark {
    position: fixed;
    background: rgba(255, 255, 255, 0.8);
    -webkit-backdrop-filter: saturate(1) blur(20px) !important;
    -moz-backdrop-filter: saturate(1) blur(20px) !important;
    -o-backdrop-filter: saturate(1) blur(20px) !important;
    -ms-backdrop-filter: saturate(1) blur(20px) !important;
    backdrop-filter: saturate(1) blur(20px) !important;
  }
  @-moz-document url-prefix() {
    .crypto_dark {
      background: white;
      border-bottom: 0 !important;
      box-shadow: 0px 10px 40px 0px rgba(11, 34, 56, 0.05);
    }
  }
}

/*-----------------------------
    Fixed side menu
  -----------------------------*/
.fixed_side_menu {
  position: fixed !important;
  right: 1%;
  top: 35%;
  z-index: 3;
}

.fixed_side_menu .nav-item .nav-link {
  position: relative;
  color: #aa9bb3;
  font-size: 15.5px;
  direction: rtl;
  text-align: right;
  -webkit-transition: 0.3s !important;
  -moz-transition: 0.3s !important;
  -o-transition: 0.3s !important;
  -ms-transition: 0.3s !important;
  transition: 0.3s !important;
}

.fixed_side_menu .nav-item .nav-link::before {
  content: "";
  position: absolute;
  top: 1.1rem;
  right: -0.8rem;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0;
  -webkit-transition: 3s !important;
  -moz-transition: 3s !important;
  -o-transition: 3s !important;
  -ms-transition: 3s !important;
  transition: 3s !important;
}

.fixed_side_menu .nav-item .nav-link.active {
  background-color: transparent;
  color: #fff;
}

.fixed_side_menu .nav-item .nav-link.active:before {
  opacity: 1;
  -webkit-animation: bounce-top 0.9s both !important;
  -moz-animation: bounce-top 0.9s both !important;
  -o-animation: bounce-top 0.9s both !important;
  -ms-animation: bounce-top 0.9s both !important;
  animation: bounce-top 0.9s both !important;
}

/*-----------------------------
    header charity
  -----------------------------*/
.gch_header_light {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  -webkit-backdrop-filter: saturate(1) blur(0) !important;
  -moz-backdrop-filter: saturate(1) blur(0) !important;
  -o-backdrop-filter: saturate(1) blur(0) !important;
  -ms-backdrop-filter: saturate(1) blur(0) !important;
  backdrop-filter: saturate(1) blur(0) !important;
}

.gch_header_light .navbar-brand {
  color: #fff;
}

.gch_header_light .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
  color: #fff;
  border-radius: 0;
  line-height: 54px;
}

@media screen and (max-width: 768px) {
  .gch_header_light .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
    line-height: inherit;
  }
}

.gch_header_light .navbar .navbar-collapse .navbar-nav .nav-item .nav-link.active {
  -webkit-box-shadow: 0px 3px 0px 0px white !important;
  -moz-box-shadow: 0px 3px 0px 0px white !important;
  -o-box-shadow: 0px 3px 0px 0px white !important;
  -ms-box-shadow: 0px 3px 0px 0px white !important;
  box-shadow: 0px 3px 0px 0px white !important;
}

@media screen and (max-width: 768px) {
  .gch_header_light .navbar .navbar-collapse .navbar-nav .nav-item .nav-link.active {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    -o-box-shadow: none !important;
    -ms-box-shadow: none !important;
    box-shadow: none !important;
  }
}

/*-----------------------------
    head_host
  -----------------------------*/
.head_host {
  border-bottom: 0;
}

.head_host .navbar .navbar-brand {
  color: #fff;
}

.head_host .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
  color: #fff;
}

.head_host .navbar .navbar-collapse .navbar-nav .nav-item .nav-link.active {
  color: #be346a;
}

/*-----------------------------
    page interior - header
  -----------------------------*/
.demo_body_interior .header-nav-center {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
  top: 45px;
}

@media screen and (max-width: 768px) {
  .demo_body_interior .header-nav-center {
    top: 20px;
  }
}

.demo_body_interior.fixed-header .header-nav-center {
  top: 0;
}

/*-----------------------------
    header Charity - left
  -----------------------------*/
.header_ch_left .navbar-collapse {
  margin-left: 6.25rem;
}

.header_ch_left .navbar-collapse .nav-item .nav-link {
  color: #9da6af;
}

.header_ch_left .navbar-collapse .nav-item .nav-link:hover {
  color: #10154e;
}

.header_ch_left .navbar-collapse .nav-item .nav-link.active {
  color: #fd6b3b;
}

/*-----------------------------
    header__workspace
  -----------------------------*/
.header__workspace .navbar-collapse {
  margin-left: 0;
}

.header__workspace .btn_demo3 .btn {
  border: 1px solid rgba(253, 107, 59, 0.25) !important;
  color: #fd6b3b;
}

/*-----------------------------
    header_software
  -----------------------------*/
.header_software .navbar-collapse .nav-item .nav-link {
  color: #6c7a87;
}

.header_software .navbar-collapse .nav-item .nav-link:hover,
.header_software .navbar-collapse .nav-item .nav-link :focus {
  color: #31d1ab;
}

.header_software .navbar-collapse .nav-item .nav-link.active {
  color: #fff;
}

.header_software .btn_demo3 .btn {
  background-color: rgba(49, 209, 171, 0.1);
  color: #31d1ab;
  border: 0 !important;
}

.fixed-header .header_software .navbar-collapse .nav-item .nav-link.active {
  color: #31d1ab;
}

/*-----------------------------
    white_scroll_dark
  -----------------------------*/
.white-scroll-dark .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
  color: #fff;
}

.fixed-header .white-scroll-dark .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
  color: #10154e;
}

/*-----------------------------
    header-black 
  -----------------------------*/
.header-black .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
  color: #fff;
}

@media screen and (max-width: 991px) {
  .header-black .nav_account .filter-blur {
    background: #000 !important;
    color: #fff !important;
  }
}

.fixed-header .header-black {
  background: rgba(0, 0, 0, 0.6);
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

.fixed-header .header-black .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
  color: #fff;
}

.fixed-header .header-black .navbar .filter-blur {
  -webkit-backdrop-filter: none !important;
  -moz-backdrop-filter: none !important;
  -o-backdrop-filter: none !important;
  -ms-backdrop-filter: none !important;
  backdrop-filter: none !important;
  background: #fff !important;
  color: #000 !important;
}

@media screen and (max-width: 991px) {
  .fixed-header .header-black .nav_account .filter-blur {
    background: #000 !important;
    color: #fff !important;
  }
}

.fixed-header .nav_account .bg-white {
  background-color: #10154e !important;
  color: #fff !important;
}

/**
 * ----------------------------------------
 * Default dropdown
 * ----------------------------------------
 */
.dropdown .dropdown-toggle {
  text-transform: capitalize;
}

.dropdown .dropdown-toggle::after {
  display: none;
}

.dropdown .dropdown-toggle .icon_arrow {
  display: none;
}

@media screen and (max-width: 768px) {
  .dropdown .dropdown-toggle {
    position: relative;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    margin-left: -1.7rem !important;
    margin-right: -2rem !important;
    padding-left: 1.7rem !important;
    padding-right: 2rem !important;
    overflow: hidden;
  }
  .dropdown .dropdown-toggle .icon_arrow {
    display: block;
  }
  .dropdown .dropdown-toggle .icon_arrow .tio {
    position: absolute;
    right: auto;
    top: calc(50% - 9px);
    transform-origin: center;
    -webkit-transition: 0.4s !important;
    -moz-transition: 0.4s !important;
    -o-transition: 0.4s !important;
    -ms-transition: 0.4s !important;
    transition: 0.4s !important;
  }
}

@media screen and (max-width: 768px) {
  .dropdown .dropdown-toggle.active {
    background-color: #f7f8fa !important;
  }
}

.dropdown .dropdown-toggle.active .icon_arrow .tio {
  transform: rotate(90deg);
}

.dropdown .dropdown-menu {
  margin: 0;
}

@media screen and (max-width: 768px) {
  .dropdown .dropdown-menu {
    max-height: 300px;
    overflow: auto !important;
  }
}

.dropdown .dropdown-menu .dropdown-item {
  -webkit-transition: 0.4s !important;
  -moz-transition: 0.4s !important;
  -o-transition: 0.4s !important;
  -ms-transition: 0.4s !important;
  transition: 0.4s !important;
}

.dropdown .dropdown-menu .dropdown-item .icon_arrow .tio {
  position: absolute;
  right: 20px;
  top: calc(50% - 9px);
  transform-origin: center;
  -webkit-transition: 0.4s !important;
  -moz-transition: 0.4s !important;
  -o-transition: 0.4s !important;
  -ms-transition: 0.4s !important;
  transition: 0.4s !important;
}

.dropdown .dropdown-menu .dropdown-item:active, .dropdown .dropdown-menu .dropdown-item:focus, .dropdown .dropdown-menu .dropdown-item:hover {
  background-color: #f7f8fa;
  color: #be346a;
}

.dropdown .dropdown-menu .dropdown-item:active .icon_arrow .tio, .dropdown .dropdown-menu .dropdown-item:focus .icon_arrow .tio, .dropdown .dropdown-menu .dropdown-item:hover .icon_arrow .tio {
  transform: rotate(90deg);
}

.dropdown .dropdown-menu .dropdown-item .item_new {
  background-color: #31d1ab;
  color: #fff;
  font-size: 12px;
  padding: 0.1rem 0.5rem;
  border-radius: 4px;
  display: inline-block;
}

.dropdown .dropdown-menu.single-drop {
  border: 0;
  border-radius: 8px;
  -webkit-box-shadow: 0px 20px 60px 0px rgba(11, 34, 56, 0.1) !important;
  -moz-box-shadow: 0px 20px 60px 0px rgba(11, 34, 56, 0.1) !important;
  -o-box-shadow: 0px 20px 60px 0px rgba(11, 34, 56, 0.1) !important;
  -ms-box-shadow: 0px 20px 60px 0px rgba(11, 34, 56, 0.1) !important;
  box-shadow: 0px 20px 60px 0px rgba(11, 34, 56, 0.1) !important;
  min-width: 210px;
  padding-top: 15px;
  padding-bottom: 15px;
  transform: perspective(300px) rotateX(0);
  transform-origin: 50% -50px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s !important;
  -moz-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s !important;
  -o-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s !important;
  -ms-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s !important;
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s !important;
  display: inline-block;
  /**
          * ----------------------------------------
          * For Mobile
          * ----------------------------------------
          */
}

@media screen and (min-width: 769px) {
  .dropdown .dropdown-menu.single-drop {
    height: auto !important;
  }
}

@media screen and (max-width: 768px) {
  .dropdown .dropdown-menu.single-drop.sm_dropdown {
    top: 57px;
  }
}

@media screen and (max-width: 768px) {
  .dropdown .dropdown-menu.single-drop {
    box-shadow: none;
    display: block;
    transform: none !important;
    overflow: hidden;
    height: 0;
    width: 100%;
    padding: 0;
    -webkit-transition: height 0.4s cubic-bezier(0.35, 0.2, 0.2, 1) !important;
    -moz-transition: height 0.4s cubic-bezier(0.35, 0.2, 0.2, 1) !important;
    -o-transition: height 0.4s cubic-bezier(0.35, 0.2, 0.2, 1) !important;
    -ms-transition: height 0.4s cubic-bezier(0.35, 0.2, 0.2, 1) !important;
    transition: height 0.4s cubic-bezier(0.35, 0.2, 0.2, 1) !important;
    box-shadow: none !important;
  }
}

.dropdown .dropdown-menu .dropdown_menu_nav {
  padding: 0;
}

.dropdown .dropdown-menu .dropdown_menu_nav li {
  list-style: none;
}

.dropdown .dropdown-menu .dropdown_menu_nav li .dropdown-item {
  padding: 0.4rem 1.5rem;
  font-size: 15px;
  color: #10154e;
  text-transform: capitalize;
}

.dropdown .dropdown-menu .dropdown_menu_nav .item_colume .sub_title {
  display: block;
  color: rgba(255, 255, 255, 0.781);
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 0 1rem;
  margin-bottom: 0.938rem;
}

@media screen and (max-width: 768px) {
  .dropdown .dropdown-menu .dropdown_menu_nav .item_colume .sub_title {
    margin: 0.938rem 0;
  }
}

.dropdown .dropdown-menu .dropdown_menu_nav .item_colume .sub_nav_menu .nav_meun {
  padding: 0;
  margin: 0;
}

.dropdown .dropdown-menu .dropdown_menu_nav .item_colume .sub_nav_menu .nav_meun li {
  list-style: none;
  padding: 0;
}

.dropdown .dropdown-menu .dropdown_menu_nav .item_colume .sub_nav_menu .nav_meun li .dropdown-item {
  border-radius: 6px;
  padding: 0.4rem 1rem;
  color: #10154e;
  font-size: 15.5px;
  -webkkit-transition: 0.3s !important;
  -moz-transition: 0.3s !important;
  -o-transition: 0.3s !important;
  -ms-transition: 0.3s !important;
  transition: 0.3s !important;
}

.dropdown .dropdown-menu .dropdown_menu_nav .item_colume .sub_nav_menu .nav_meun li .dropdown-item:hover {
  background-color: #f7f8fa;
  opacity: 0.7;
}

.dropdown.show .dropdown-menu.single-drop {
  transform: perspective(0) rotateX(0);
  -webkit-transform: perspective(0) rotateX(0);
  transition-timing-function: ease-out;
  -webkit-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s !important;
  -moz-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s !important;
  -o-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s !important;
  -ms-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s !important;
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s !important;
  opacity: 1;
  visibility: visible;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .dropdown .active + .dropdown-menu {
    opacity: 1;
    visibility: visible;
  }
}

/**
   * ----------------------------------------
   * Dropdown Full Width
   * ----------------------------------------
   */
.dropdown_full .dropdown-menu .dropdown_menu_nav {
  display: flex;
  padding: 1.25rem 1.875rem;
}

@media screen and (max-width: 768px) {
  .dropdown_full .dropdown-menu .dropdown_menu_nav {
    display: block;
    padding: 0 0.3rem 0 0;
  }
}

.dropdown_full .dropdown-menu .dropdown_menu_nav .item_colume {
  width: calc(100% / 5);
}

@media screen and (max-width: 768px) {
  .dropdown_full .dropdown-menu .dropdown_menu_nav .item_colume {
    width: 100%;
  }
}

/**
   * ----------------------------------------
   * Sub Dropdown menu
   * ----------------------------------------
   */
.dropdown-submenu {
  position: relative;
  list-style: none;
}

.dropdown-submenu .dropdown-menu.sec-drop {
  padding-top: 15px;
  padding-bottom: 15px;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 33px 88px 0 #1d0e6211;
  border: solid 1px #9c97a8;
  min-width: 210px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s !important;
  -moz-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s !important;
  -o-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s !important;
  -ms-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s !important;
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s !important;
  display: inline-block;
  /**
          * ----------------------------------------
          * For Mobile
          * ----------------------------------------
          */
}

@media screen and (max-width: 768px) {
  .dropdown-submenu .dropdown-menu.sec-drop {
    box-shadow: none !important;
    display: block;
    transform: none !important;
    overflow: auto;
    width: 100%;
    visibility: visible;
    opacity: 1;
    -webkit-transition: height 0.4s cubic-bezier(0.35, 0.2, 0.2, 1) !important;
    -moz-transition: height 0.4s cubic-bezier(0.35, 0.2, 0.2, 1) !important;
    -o-transition: height 0.4s cubic-bezier(0.35, 0.2, 0.2, 1) !important;
    -ms-transition: height 0.4s cubic-bezier(0.35, 0.2, 0.2, 1) !important;
    transition: height 0.4s cubic-bezier(0.35, 0.2, 0.2, 1) !important;
  }
}

.dropdown-submenu.show .sec-drop {
  transform: perspective(0) rotateX(0);
  -webkit-transform: perspective(0) rotateX(0);
  transition-timing-function: ease-out;
  -webkit-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s !important;
  -moz-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s !important;
  -o-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s !important;
  -ms-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s !important;
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s !important;
  opacity: 1;
  visibility: visible;
}

.dropdown-submenu.show:active, .dropdown-submenu.show:focus, .dropdown-submenu.show:hover {
  background-color: #f7f8fa;
  color: #be346a;
}

@media screen and (max-width: 768px) {
  .dropdown-submenu.show:active, .dropdown-submenu.show:focus, .dropdown-submenu.show:hover {
    background-color: transparent !important;
  }
}

.dropdown-submenu.show:active .icon_arrow .tio, .dropdown-submenu.show:focus .icon_arrow .tio, .dropdown-submenu.show:hover .icon_arrow .tio {
  transform: rotate(90deg);
}

@media screen and (max-width: 768px) {
  .dropdown-submenu.show:active .icon_arrow .tio, .dropdown-submenu.show:focus .icon_arrow .tio, .dropdown-submenu.show:hover .icon_arrow .tio {
    transform: none !important;
  }
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
}

@media screen and (max-width: 768px) {
  .dropdown-submenu > .dropdown-menu {
    top: inherit;
    left: inherit;
    margin-top: inherit;
  }
}

@media screen and (max-width: 768px) {
  .dropdown-submenu .dropdown-toggle {
    font-size: 13px;
    text-transform: uppercase;
    color: #6c7a87;
  }
}

.dropdown-submenu .dropdown-toggle .icon_arrow {
  display: block;
}

@media screen and (max-width: 768px) {
  .dropdown-submenu .dropdown-toggle .icon_arrow {
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  .dropdown-submenu .active + .dropdown-menu {
    opacity: 1;
    visibility: visible;
  }
}

.single-drop {
  background: linear-gradient(104deg, #fff, white, #fff, rgba(255, 255, 255, 0.836)) !important;
}

@media screen and (max-width: 768px) {
  .single-drop .dropdown-submenu .dropdown-toggle:hover, .single-drop .dropdown-submenu .dropdown-toggle:focus, .single-drop .dropdown-submenu .dropdown-toggle:active {
    background-color: transparent !important;
  }
}

@media screen and (max-width: 768px) {
  .single-drop .dropdown-submenu .dropdown-toggle.active {
    background-color: transparent !important;
    color: #be346a;
  }
}

.coming {
  position: relative;
  position: relative;
}

.coming::before {
  content: "";
  position: absolute;
  content: " coming soon ";
  position: absolute;
  top: 6px;
  font-size: 10px;
  background-color: #683769;
  color: #fff;
  padding: 3px 10px;
  border-radius: 3px;
  right: 2rem;
}

.drop_dark .dropdown-item {
  color: white !important;
}

.drop_dark .dropdown-item:hover {
  background-color: #683769 !important;
  color: white !important;
  opacity: 1 !important;
}

.hero {
  position: relative;
  z-index: 1;
  background-repeat: no-repeat;
  /*====================================
    =========  in index
    =====================================*/
  /*====================================
    ========= has style1
    =====================================*/
  /*====================================
    ========= has style2
    =====================================*/
  /*====================================
    ========= has style3
    =====================================*/
  /*====================================
    ========= has style4
    =====================================*/
  /*====================================
    ========= has style5
    =====================================*/
  /*====================================
    ========= has style6
    =====================================*/
  /*====================================
    ========= is dark 
    =====================================*/
  /*====================================
    ========= has background
    =====================================*/
  /*====================================
    ========= has style deco
    =====================================*/
}

.hero .container,
.hero .row {
  height: 100%;
}

.hero .hero_title {
  text-transform: capitalize;
}

.hero .hero_wrap {
  padding: 7rem 0;
}

@media screen and (max-width: 768px) {
  .hero .hero_wrap {
    padding: 3rem 0 3rem;
  }
}

.hero.in_index {
  position: relative;
  position: relative;
  position: relative;
}

.hero.in_index::after {
  content: "";
  position: absolute;
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  top: 0;
  background: #10154e;
  z-index: -2;
  border-bottom-right-radius: 350px;
}

@media screen and (max-width: 991px) {
  .hero.in_index::after {
    border-bottom-right-radius: 50px;
  }
}

.hero.in_index::before {
  content: "";
  position: absolute;
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../img/hero.png);
  z-index: -1;
  background-position: center;
}

.hero.in_index .hero_wrap {
  padding: 12rem 0 12rem;
  height: 100vh;
  position: relative;
}

.hero.in_index .hero_wrap::before {
  content: "";
  position: absolute;
  bottom: -4rem;
  left: 50%;
  transform: translateX(-50%);
  background-image: url(../img/decorations/circles.svg);
  background-size: contain;
  height: 6rem;
  width: 14rem;
  background-repeat: no-repeat;
  z-index: 2;
}

@media screen and (max-width: 991px) {
  .hero.in_index .hero_wrap {
    padding: 10rem 0 0;
    height: auto;
  }
}

.hero.in_index .hero_title {
  font-size: calc(46px + 18 * ( (100vw - 576px) / 1024));
  line-height: 1.1;
  margin-bottom: 10px;
  max-width: 60rem;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 576px) {
  .hero.in_index .hero_title {
    font-size: 46px;
  }
}

@media screen and (min-width: 1600px) {
  .hero.in_index .hero_title {
    font-size: 64px;
  }
}

.hero.in_index .img_right {
  height: 100%;
  width: auto;
}

@media screen and (max-width: 991px) {
  .hero.in_index .img_right {
    display: none;
  }
}

.hero.in_index .hero_text {
  margin-bottom: 0;
  margin: 0;
  margin-bottom: 20px;
  font-size: 17px;
}

.hero.in_index .list_index {
  padding-bottom: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  margin-top: 10px;
  font-size: 20px;
  text-align: center;
}

.hero.in_index .advantages {
  background: url(../images/others/banner.webp);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: 0 0;
  overflow: visible;
}

.hero.has_style1 {
  background-color: #10154e;
  border-radius: 20px;
  background-image: url(../img/decorations/wave.svg);
  background-size: contain;
  background-position-x: left;
  box-shadow: 0 36px 66px -42px #11164e;
  position: relative;
}

.hero.has_style1::before {
  content: "";
  position: absolute;
  bottom: 0rem;
  right: -5rem;
  background: url(../img/decorations/orange_circle.svg);
  background-size: contain;
  background-repeat: no-repeat;
  height: 6rem;
  width: 15rem;
}

@media screen and (max-width: 768px) {
  .hero.has_style1 .hero_wrap {
    padding: 5rem 1rem;
  }
}

.hero.has_style1 .hero_title {
  color: #683769;
  font-size: calc(36px + 18 * ( (100vw - 576px) / 1024));
  text-align: left;
  z-index: 1;
}

@media screen and (max-width: 576px) {
  .hero.has_style1 .hero_title {
    font-size: 36px;
  }
}

@media screen and (min-width: 1600px) {
  .hero.has_style1 .hero_title {
    font-size: 54px;
  }
}

@media screen and (max-width: 991px) {
  .hero.has_style1 .hero_title {
    text-align: center;
    line-height: 1.2;
  }
}

.hero.has_style1 .hero_text {
  font-size: 16px;
  z-index: 1;
  opacity: 0.85;
}

@media screen and (max-width: 991px) {
  .hero.has_style1 .hero_text {
    text-align: center;
  }
}

.hero.has_style1 .img_wrap {
  transform: translate(-45%, 0%);
  z-index: -1;
}

.hero.has_style1 .img_wrap img {
  width: 147%;
}

@media screen and (max-width: 991px) {
  .hero.has_style1 .img_wrap {
    transform: translate(0, 0);
  }
  .hero.has_style1 .img_wrap img {
    margin-top: 20px;
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .hero.has_style1 .btn_hero {
    width: 100%;
    margin-bottom: 10px;
  }
}

.hero.has_style1 .header-shape {
  position: absolute;
  transform: scale(0.9);
  left: -4rem;
  top: -10rem;
  z-index: -1;
}

@media screen and (max-width: 991px) {
  .hero.has_style1 .header-shape {
    display: none;
  }
}

.hero.has_style2 {
  overflow: hidden;
  background-color: #faf4f6;
  position: relative;
  position: relative;
}

@media screen and (max-width: 991px) {
  .hero.has_style2 {
    height: auto;
  }
}

.hero.has_style2 .dots {
  position: absolute;
  opacity: 0.3;
  top: 2rem;
}

.hero.has_style2 .hero_wrap {
  padding: 36px 0 4rem;
  height: 100%;
}

.hero.has_style2::before {
  content: "";
  position: absolute;
  content: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  border-radius: 0 0 60px 0;
  background: linear-gradient(45deg, #4c3d69 0%, #c73e67 100%);
  z-index: -2;
}

@media screen and (max-width: 991px) {
  .hero.has_style2::before {
    width: 100%;
  }
}

.hero.has_style2::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -2rem;
  background: url(../img/decorations/mix_shapes.png);
  background-size: contain;
  background-repeat: no-repeat;
  height: 20rem;
  width: 20rem;
  z-index: -1;
}

.hero.has_style2 .hero_form {
  background: white;
  box-shadow: 52px 52px 74px 0 rgba(115, 131, 169, 0.08);
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 5px;
  max-width: 30rem;
  border: solid 1px transparent;
  transition: all 0.2s ease-in-out;
  width: 100%;
}

.hero.has_style2 .hero_form:hover {
  border: solid 1px #be346a;
}

.hero.has_style2 .hero_form input {
  height: 30px;
  border: none;
  box-shadow: none;
}

.hero.has_style2 .hero_form .btn {
  padding: 18px;
  width: 60%;
}

.hero.has_style2 .hero_title {
  font-size: calc(46px + 18 * ( (100vw - 576px) / 1024));
  text-align: left;
  z-index: 1;
}

@media screen and (max-width: 576px) {
  .hero.has_style2 .hero_title {
    font-size: 46px;
  }
}

@media screen and (min-width: 1600px) {
  .hero.has_style2 .hero_title {
    font-size: 64px;
  }
}

@media screen and (max-width: 991px) {
  .hero.has_style2 .hero_title {
    text-align: center;
    font-size: 40px;
  }
}

.hero.has_style2 .hero_text {
  font-size: 17px;
  line-height: 1.5;
  margin-bottom: 20px;
  color: #515664;
  z-index: 1;
}

@media screen and (max-width: 991px) {
  .hero.has_style2 .hero_text {
    text-align: center;
    font-size: 15px;
    margin-top: 20px;
  }
}

.hero.has_style2 .img_wrap {
  text-align: center;
  margin: auto;
  min-height: 35rem;
}

.hero.has_style2 .img_wrap img {
  width: 100%;
  max-width: 900px;
}

@media screen and (max-width: 991px) {
  .hero.has_style2 .img_wrap img {
    margin-top: 20px;
    width: 100%;
    max-width: 700px;
  }
}

@media screen and (max-width: 991px) {
  .hero.has_style2 .img_wrap {
    min-height: auto !important;
    margin-top: 20px;
  }
}

.hero.has_style3 {
  position: relative;
  position: relative;
}

.hero.has_style3::before {
  content: "";
  position: absolute;
  background: #edf0f2;
  height: 100%;
  width: 80%;
  left: 0;
  top: 0;
  z-index: -2;
}

@media screen and (max-width: 991px) {
  .hero.has_style3::before {
    width: 100%;
  }
}

.hero.has_style3::after {
  content: "";
  position: absolute;
  bottom: -4rem;
  right: 10rem;
  background: url(../img/decorations/dots.svg);
  background-size: contain;
  background-repeat: no-repeat;
  height: 10rem;
  width: 10rem;
  z-index: -1;
}

.hero.has_style3 .container,
.hero.has_style3 .row {
  height: auto;
}

.hero.has_style3 .hero_form {
  background: white;
  box-shadow: 52px 52px 74px 0 rgba(115, 131, 169, 0.08);
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 5px;
  max-width: 30rem;
  width: 100%;
}

.hero.has_style3 .hero_form input {
  height: 30px;
  border: none;
  box-shadow: none;
}

.hero.has_style3 .hero_form .btn {
  padding: 18px;
  width: 60%;
}

.hero.has_style3 .img_wrap {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
}

.hero.has_style3 .img_wrap .deco_hero3 {
  position: absolute;
  bottom: 2rem;
  left: -4rem;
}

@media screen and (max-width: 991px) {
  .hero.has_style3 .img_wrap .deco_hero3 {
    bottom: 0;
    left: 0;
  }
}

.hero.has_style3 .img_wrap img {
  margin-left: auto;
  margin-right: auto;
  height: auto;
  border-radius: 10px;
}

.hero.has_style3 .hero_title {
  color: #10154e;
  margin-bottom: 20px;
  text-align: left;
  z-index: 1;
  font-size: calc(40px + 24 * ( (100vw - 576px) / 1024));
}

@media screen and (max-width: 576px) {
  .hero.has_style3 .hero_title {
    font-size: 40px;
  }
}

@media screen and (min-width: 1600px) {
  .hero.has_style3 .hero_title {
    font-size: 64px;
  }
}

@media screen and (max-width: 991px) {
  .hero.has_style3 .hero_title {
    text-align: center;
    margin-bottom: 20px;
  }
}

.hero.has_style3 .hero_text {
  font-size: 17px;
  line-height: 1.5;
  letter-spacing: -0.5px;
  font-weight: normal;
  margin-bottom: 20px;
  color: #6f7583;
  z-index: 1;
}

@media screen and (max-width: 991px) {
  .hero.has_style3 .hero_text {
    text-align: center;
    margin-left: auto !important;
    mgright: auto !important;
  }
}

.hero.has_style3 .swiper-button-prev {
  height: 3rem;
  width: 3rem;
  bottom: 0;
  top: auto;
  left: auto;
  left: 0 !important;
  background: #10154e;
  border-radius: 0;
  transition: all 0.2s ease-in-out;
  outline: none;
  border-radius: 0 0 0 10px;
}

.hero.has_style3 .swiper-button-prev:hover {
  color: #be346a;
}

.hero.has_style3 .swiper-button-next {
  height: 3rem;
  width: 3rem;
  bottom: 0;
  top: auto;
  left: 3.1rem !important;
  background: #10154e;
  border-radius: 0;
  outline: none;
  border-radius: 0 10px 0 0;
}

.hero.has_style3 .swiper-button-next:hover {
  color: #be346a;
}

.hero.has_style3 .swiper-button-next::after,
.hero.has_style3 .swiper-button-prev::after {
  color: white;
  font-size: 15px;
  font-weight: 700;
  transition: all 0.2s ease-in-out 0s;
}

.hero.has_style4 {
  background-size: cover;
  height: auto;
  background-position: right bottom;
  background-color: #10154e;
  z-index: 1;
  z-index: 0;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
}

.hero.has_style4::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.308);
  background: rgba(0, 0, 0, 0.479);
}

@media screen and (min-width: 1025px) {
  .hero.has_style4::before {
    background: rgba(0, 0, 0, 0.089);
  }
}

.hero.has_style4::after {
  content: "";
  position: absolute;
  bottom: -4rem;
  left: 50%;
  transform: translateX(-50%);
  background-image: url(../img/decorations/circles.svg);
  background-size: contain;
  height: 6rem;
  width: 14rem;
  background-repeat: no-repeat;
  z-index: 2;
}

@media screen and (max-width: 991px) {
  .hero.has_style4 {
    height: auto;
    padding-bottom: 60px;
  }
}

.hero.has_style4 .hero_wrap {
  height: 100%;
  padding: 10rem 0 10rem;
  overflow: hidden;
}

@media screen and (max-width: 991px) {
  .hero.has_style4 .hero_wrap {
    padding: 60px 0 20px;
  }
}

.hero.has_style4 .hero_title {
  font-size: 60px;
  color: #fff;
  max-width: 60rem;
  margin-bottom: 20px;
  line-height: 1;
}

@media screen and (max-width: 991px) {
  .hero.has_style4 .hero_title {
    font-size: 40px;
    text-align: center;
  }
}

.hero.has_style4 .play_flex img {
  transition: 0.2s ease-in-out;
}

.hero.has_style4 .play_flex img:hover {
  transform: scale(1.1);
  opacity: 0.7;
}

@media screen and (max-width: 991px) {
  .hero.has_style4 .play_flex {
    justify-content: center !important;
  }
}

.hero.has_style4 .hero_text {
  color: #fff;
  max-width: 30rem;
  margin-left: 0;
  margin-bottom: 27px;
  font-size: 15.5px;
}

@media screen and (max-width: 992px) {
  .hero.has_style4 .hero_text {
    text-align: center;
    margin: auto;
    margin-bottom: 27px;
  }
}

.hero.has_style4 .price_wrap {
  background: #fff;
  padding: 3rem;
  border-radius: 5px;
  box-shadow: -1px 20px 80px -14px #27073f21;
  position: relative;
}

.hero.has_style4 .price_wrap::before {
  content: "";
  position: absolute;
  height: 1rem;
  border-radius: 5px 5px 0 0;
  background: linear-gradient(45deg, #4c3d69 0%, #c73e67 100%);
  width: 100%;
  top: 0;
  left: 0;
}

.hero.has_style4 .price_wrap .btn {
  padding: 20px 20px;
}

.hero.has_style5 {
  background-size: cover;
  background-color: #10154e;
  background-image: url(../img/decorations/wave.svg);
  background-attachment: fixed;
  background-size: contain;
  background-position-x: right;
  box-shadow: 0 36px 66px -42px #11164e;
}

.hero.has_style5 .container {
  position: relative;
}

.hero.has_style5 .hero_wrap {
  padding: 4rem 0;
}

.hero.has_style5 .swiper-button-prev {
  height: 3rem;
  width: 3rem;
  top: 3.6rem;
  left: 0;
  right: auto;
  background: #be346a;
  border: solid 3px #10154e;
  border-radius: 0%;
  transition: all 0.2s ease-in-out 0s;
  border-radius: 5px;
}

.hero.has_style5 .swiper-button-prev:hover {
  background: #683769;
}

@media screen and (max-width: 991px) {
  .hero.has_style5 .swiper-button-prev {
    display: none;
  }
}

.hero.has_style5 .swiper-button-next {
  height: 3rem;
  width: 3rem;
  top: 3.6rem;
  border: solid 3px #10154e;
  left: 4rem;
  right: auto;
  background: #be346a;
  border-radius: 5px;
}

.hero.has_style5 .swiper-button-next:hover {
  background: #683769;
}

@media screen and (max-width: 991px) {
  .hero.has_style5 .swiper-button-next {
    display: none;
  }
}

.hero.has_style5 .swiper-button-next::after,
.hero.has_style5 .swiper-button-prev::after {
  color: white;
  font-size: 15px;
  font-weight: 700;
  transition: all 0.2s ease-in-out 0s;
}

.hero.has_style5 .hero_title {
  font-size: 64px;
  color: white;
  max-width: 60rem;
  margin-bottom: 20px;
  line-height: 1.2;
}

@media screen and (max-width: 992px) {
  .hero.has_style5 .hero_title {
    font-size: 60px;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .hero.has_style5 .hero_title {
    font-size: 40px;
  }
}

.hero.has_style5 .hero_text {
  color: white;
  max-width: 30rem;
  margin-bottom: 20px;
  font-size: 15.5px;
  margin-left: 0;
}

@media screen and (max-width: 992px) {
  .hero.has_style5 .hero_text {
    text-align: center;
    margin: auto;
    margin-bottom: 27px;
  }
}

.hero.has_style5 .numbers {
  display: flex;
  background: linear-gradient(45deg, #4c3d69 0%, #c73e67 100%);
  position: absolute;
  right: 5px;
  padding: 20px 30px;
  box-shadow: 0 33px 88px 0 #1d0e6211;
  border-radius: 5px;
  color: white;
  z-index: 2;
}

.hero.has_style5 .numbers h2 {
  color: white;
}

@media screen and (max-width: 768px) {
  .hero.has_style5 .numbers {
    left: 15px;
    right: 15px;
  }
}

.hero.has_style5 .img_wrap {
  margin-top: 4rem;
}

.hero.has_style5 .img_wrap img {
  object-fit: cover;
  height: 30rem;
  width: 100%;
}

.hero.has_style5 .text-gray {
  margin-top: 20px;
  color: #858c9b;
}

.hero.has_style5 .card-form {
  padding: 32px;
  border-radius: 0.5rem;
  box-shadow: 0 15px 50px -10px rgba(21, 37, 72, 0.1);
}

@media screen and (max-width: 991px) {
  .hero.has_style5 .card-form {
    margin-top: 30px;
  }
}

.hero.has_style6 {
  overflow: hidden;
  position: relative;
}

.hero.has_style6::before {
  content: "";
  position: absolute;
  background: #f7f5f7;
  height: 100%;
  width: 60%;
  left: 0;
  border-radius: 0 0 50px 0;
  top: 0;
  z-index: -2;
}

.hero.has_style6 .img_wrap {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
}

.hero.has_style6 .img_wrap .deco_hero6 {
  position: absolute;
  bottom: -2rem;
  left: -4rem;
  width: 100%;
  z-index: -1;
}

.hero.has_style6 .img_wrap img {
  max-height: 60rem;
}

.hero.has_style6 .hero_title {
  color: #10154e;
  margin-bottom: 20px;
  text-align: left;
  z-index: 1;
  font-size: calc(46px + 18 * ( (100vw - 576px) / 1024));
}

@media screen and (max-width: 576px) {
  .hero.has_style6 .hero_title {
    font-size: 46px;
  }
}

@media screen and (min-width: 1600px) {
  .hero.has_style6 .hero_title {
    font-size: 64px;
  }
}

@media screen and (max-width: 991px) {
  .hero.has_style6 .hero_title {
    text-align: center;
    font-size: 40px;
    margin-bottom: 20px;
  }
}

.hero.has_style6 .hero_text {
  font-size: 17px;
  line-height: 1.5;
  font-weight: normal;
  margin-bottom: 20px;
  color: #54587f;
  z-index: 1;
}

@media screen and (max-width: 991px) {
  .hero.has_style6 .hero_text {
    text-align: center;
  }
}

.hero.is_dark {
  margin-bottom: 5rem;
  background: #10154e;
}

.hero.is_dark .navbar .menu .line-menu {
  background: #10154e;
}

.hero.is_dark .hero_wrap {
  height: 100%;
  padding: 120px 0;
}

.hero.is_dark .hero_textription {
  margin-top: 20px;
  color: white;
  max-width: 40rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 20px;
}

.hero.is_dark .hero_title {
  color: white;
  margin-bottom: 30px;
}

@media screen and (max-width: 992px) {
  .hero.is_dark .hero_title {
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .hero.is_dark .hero_title {
    font-size: 40px;
  }
}

.hero.is_dark .hero-img {
  text-align: center;
  margin: auto;
}

.hero.is_dark .hero-img img {
  width: 130%;
}

@media screen and (max-width: 992px) {
  .hero.is_dark .hero-img img {
    padding-top: 5rem;
    width: 80%;
  }
}

.hero.is_dark .hero_wrap {
  overflow: hidden;
}

.hero.has_bg {
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero.has_bg .hero_wrap {
  padding: 200px 0;
}

.hero.has_bg .header-title {
  color: white;
  font-size: calc(46px + 18 * ( (100vw - 576px) / 1024));
  line-height: 1;
  margin-bottom: 30px;
}

@media screen and (max-width: 576px) {
  .hero.has_bg .header-title {
    font-size: 46px;
  }
}

@media screen and (min-width: 1600px) {
  .hero.has_bg .header-title {
    font-size: 64px;
  }
}

.hero.has_bg .item_block {
  display: flex;
  align-items: center;
  border-radius: 50px;
  padding: 10px 20px 10px 10px;
  border: solid 1px #ffffff45;
  box-shadow: 0 33px 88px 0 #1d0e6211;
  color: #fff;
  margin-bottom: 30px;
}

.hero.has_bg .item_block span {
  color: #fff;
  margin-left: 10px;
}

.hero.has_bg .item_block svg {
  height: 3rem;
  width: 3rem;
  fill: white;
  background: #be346a;
  border-radius: 50%;
  padding: 10px;
}

.hero.has_style_deco {
  position: relative;
  position: relative;
}

.hero.has_style_deco::before {
  content: "";
  position: absolute;
  background-color: #e6a5be;
  height: 80vh;
  width: 50%;
  right: 0;
  top: 0;
  border-radius: 0 0 0 30px;
}

.hero.has_style_deco .line-menu {
  background: #150d4c;
}

.hero.has_style_deco .hero_wrap {
  padding: 6rem 0 6rem;
  background-repeat: no-repeat;
  background-position: center;
}

@media screen and (max-width: 992px) {
  .hero.has_style_deco .hero_wrap {
    padding: 7rem 0 7rem;
  }
}

@media screen and (max-width: 768px) {
  .hero.has_style_deco .hero_wrap {
    padding: 5rem 0 5rem;
  }
}

.hero.has_style_deco .hero_title {
  color: #683769;
  font-size: calc(46px + 18 * ( (100vw - 576px) / 1024));
  margin-bottom: 40px;
  text-align: left;
}

@media screen and (max-width: 576px) {
  .hero.has_style_deco .hero_title {
    font-size: 46px;
  }
}

@media screen and (min-width: 1600px) {
  .hero.has_style_deco .hero_title {
    font-size: 64px;
  }
}

@media screen and (max-width: 992px) {
  .hero.has_style_deco .hero_title {
    font-size: 50px;
  }
}

@media screen and (max-width: 768px) {
  .hero.has_style_deco .hero_title {
    text-align: center;
    font-size: 40px;
    margin-bottom: 20px;
  }
}

.hero.has_style_deco .hero_text {
  font-size: 1.25rem;
  line-height: 1.5;
  letter-spacing: -0.5px;
  font-weight: normal;
  margin-bottom: 40px;
  color: #10154e;
}

.hero.has_style_deco .img_wrap {
  transform: translate(10%, -6rem);
}

.hero.has_style_deco .img_wrap img {
  width: 100%;
  max-width: 500px;
}

@media screen and (max-width: 992px) {
  .hero.has_style_deco .img_wrap img {
    margin-top: 20px;
    width: 100%;
    max-width: 100%;
  }
}

.hero.has_style_deco .header-shape {
  position: absolute;
  transform: scale(0.9);
  left: -4rem;
  top: -10rem;
  z-index: -1;
}

.hero_page {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat !important;
}

.hero_page .hero_wrap {
  padding: 120px 0;
}

.hero_page.has_style1 {
  background-size: contain;
  position: relative;
  z-index: 0;
}

.hero_page.has_style1 .hero_img {
  max-width: 40rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin-bottom: 30px;
}

.hero_page.has_style1 .hero_wrap {
  padding: 80px 0;
  position: relative;
  position: relative;
}

.hero_page.has_style1 .hero_wrap::before {
  content: "";
  position: absolute;
  left: 0rem;
  top: 0;
  width: 10rem;
  height: 100%;
  background-image: url(../img/decorations/pattern.svg);
  background-size: contain;
  z-index: -1;
  background-repeat: no-repeat;
}

.hero_page.has_style1 .hero_wrap::after {
  content: "";
  position: absolute;
  right: 0rem;
  bottom: 3rem;
  width: 10rem;
  height: 10rem;
  background-image: url(../img/decorations/pattern.svg);
  background-size: contain;
  z-index: -1;
  background-repeat: no-repeat;
}

.hero_page.has_style1 .hero_title {
  font-size: calc(46px + 18 * ( (100vw - 576px) / 1024));
  line-height: 1;
  text-align: center;
  margin-bottom: 20px;
}

@media screen and (max-width: 576px) {
  .hero_page.has_style1 .hero_title {
    font-size: 46px;
  }
}

@media screen and (min-width: 1600px) {
  .hero_page.has_style1 .hero_title {
    font-size: 64px;
  }
}

.hero_page.has_style1 .hero_text {
  margin-bottom: 0;
  margin: 0;
  text-align: center;
  max-width: 40rem;
  color: #5a6875;
}

.hero_page.has_style2 {
  background-color: #f3f8ff;
  background-image: url(../images/others/Hero_Pattern.svg);
  background-position: center bottom;
  background-size: contain;
}

.hero_page.has_style2 .title {
  text-align: center;
  color: #10154e;
}

.hero_page.has_style2 .hero_textription {
  max-width: 40rem;
  color: #10154e;
}

.hero_page.has_style2 .hero_wrap {
  padding: 100px 0;
}

.hero_page.has_bg {
  background-color: #f3f8ff;
  background-size: cover;
  background-position: right top;
  z-index: 0;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.hero_page.has_bg::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.308);
  background: rgba(8, 3, 43, 0.562) !important;
}

.hero_page.has_bg .title {
  text-align: center;
  color: #10154e;
}

.hero_page.has_bg .hero_textription {
  max-width: 40rem;
  color: #10154e;
}

.hero_page.has_bg .hero_wrap {
  padding: 100px 0;
}

.hero_page.has_bg {
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.hero_page.has_bg .hero_wrap {
  padding: 150px 0;
}

@media screen and (max-width: 991px) {
  .hero_page.has_bg .hero_wrap {
    padding: 70px 0;
  }
}

.hero_page.has_bg::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.308);
}

.hero_page.has_bg .title {
  color: white;
  font-size: calc(40px + 24 * ( (100vw - 576px) / 1024));
}

@media screen and (max-width: 576px) {
  .hero_page.has_bg .title {
    font-size: 40px;
  }
}

@media screen and (min-width: 1600px) {
  .hero_page.has_bg .title {
    font-size: 64px;
  }
}

.hero_page.has_bg .hero_textription {
  max-width: 40rem;
  color: white;
}

.hero_page.is_dark {
  background: #10154e;
}

.hero_page.is_dark .hero_wrap {
  padding: 100px 0;
}

.hero_page.is_dark .title {
  font-size: calc(46px + 18 * ( (100vw - 576px) / 1024));
  line-height: 1;
  color: white;
  margin-bottom: 20px;
}

@media screen and (max-width: 576px) {
  .hero_page.is_dark .title {
    font-size: 46px;
  }
}

@media screen and (min-width: 1600px) {
  .hero_page.is_dark .title {
    font-size: 64px;
  }
}

.hero_page.is_dark .description {
  color: white;
  margin-bottom: 0;
  margin: 0;
}

.elements_hero {
  background: linear-gradient(45deg, #4c3d69 0%, #c73e67 100%);
  z-index: 0;
  overflow: hidden;
  position: relative;
}

.elements_hero::before {
  content: "";
  position: absolute;
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../img/hero.png);
  z-index: -1;
}

.elements_hero .wrap {
  width: 50%;
}

@media screen and (max-width: 991px) {
  .elements_hero .wrap {
    width: 100%;
  }
}

.pattern_hero {
  position: relative;
  position: relative;
}

.pattern_hero::before {
  content: "";
  position: absolute;
  left: -9rem;
  top: 0;
  width: 10rem;
  height: 100%;
  background-image: url(../img/decorations/pattern.svg);
  background-size: contain;
  z-index: -1;
  background-repeat: no-repeat;
}

.pattern_hero::after {
  content: "";
  position: absolute;
  right: -9rem;
  bottom: -6rem;
  width: 10rem;
  height: 10rem;
  background-image: url(../img/decorations/pattern.svg);
  background-size: contain;
  z-index: -1;
  background-repeat: no-repeat;
}

.banner_title_inner {
  position: relative;
}

.banner_title_inner::before {
  content: "";
  position: absolute;
  right: -9rem;
  top: -4rem;
  width: 10rem;
  height: 10rem;
  background-image: url(../img/decorations/pattern.svg);
  background-size: contain;
  z-index: -1;
  background-repeat: no-repeat;
  transform: rotate(285deg);
}

.client_logos {
  /*====================================
        ========= has style1
    =====================================*/
  /*====================================
        ========= has style2
    =====================================*/
  /*====================================
        ========= has style3
    =====================================*/
}

.client_logos .has_line {
  position: relative;
  transform: translateY(44px);
  width: 100%;
  height: 2px;
  background: #eaeaee;
  bottom: 29px;
}

.client_logos .text_line {
  text-align: center;
  padding: 0 10px;
  position: relative;
  background: white;
  width: max-content;
  color: #10154e;
  position: relative;
}

.client_logos.has_style1 .company-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 992px) {
  .client_logos.has_style1 .company-item {
    display: flex;
    justify-content: center;
  }
}

.client_logos.has_style1 .company-item img {
  max-width: 7rem;
  margin-bottom: 30px;
  transition: 0.25s ease-in-out;
}

.client_logos.has_style1 .company-item img:hover {
  transform: scale(1.1);
}

.client_logos.has_style2 .brands {
  box-shadow: 0 33px 88px 0 #1d0e6211;
  border: 1px solid #ebedf3;
  padding: 60px 0 30px;
}

.client_logos.has_style2 .company-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 992px) {
  .client_logos.has_style2 .company-item {
    display: flex;
    justify-content: center;
  }
}

.client_logos.has_style2 .company-item img {
  max-width: 7rem;
  margin-bottom: 30px;
  transition: 0.25s ease-in-out;
}

.client_logos.has_style2 .company-item img:hover {
  transform: scale(1.1);
}

.client_logos.has_style3 .company-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 992px) {
  .client_logos.has_style3 .company-item {
    display: flex;
    justify-content: center;
  }
}

.client_logos.has_style3 .company-item img {
  max-width: 7rem;
  margin-bottom: 30px;
  transition: 0.25s ease-in-out;
}

.client_logos.has_style3 .company-item img:hover {
  transform: scale(1.1);
}

.content {
  /*====================================
        ========= in software
    =====================================*/
}

.content .left_icon {
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  background: linear-gradient(45deg, #4c3d69 0%, #c73e67 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.content .left_icon [class^="ri-"] {
  color: #fff;
  font-size: 1.7rem;
}

.content .list {
  border-radius: 5px;
  padding: 10px 20px;
  background: #faf4f6;
  margin-bottom: 20px;
}

.content .list strong {
  color: #10154e;
}

.content .list.has_style1 {
  background: white;
  box-shadow: 0 33px 88px 0 #1d0e6211;
  border: solid 1px #e8eaee;
}

.content .list.has_style1 .icon {
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.content .list.has_style1 [class^="ri-"],
.content .list.has_style1 [class*=" ri-"] {
  font-size: 22px;
  color: #be346a;
}

.content .list.has_style2 .icon {
  height: 3rem;
  width: 3rem;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  flex: none;
}

.content .list.has_style2 .icon [class^="ri-"],
.content .list.has_style2 .icon [class*=" ri-"] {
  font-size: 22px;
}

.content .list.has_style3 {
  border-radius: 5px;
  padding: 0;
  background: #fff;
  margin-bottom: 0;
}

.content .list.has_style3 .icon {
  height: 3rem;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  flex: none;
}

.content .list.has_style3 .icon [class^="ri-"],
.content .list.has_style3 .icon [class*=" ri-"] {
  font-size: 22px;
  color: #be346a;
}

.content .number_wrap {
  background: #10154e;
  padding: 60px 30px;
  box-shadow: 0 36px 66px -42px #11164e;
  border-radius: 5px;
  position: absolute;
  width: max-content;
  left: 0;
  min-width: 38rem;
}

@media screen and (max-width: 991px) {
  .content .number_wrap {
    position: relative;
    min-width: 0;
    width: -webkit-fill-available;
    margin: 0 15px 60px;
    padding: 30px 15px;
  }
}

.content .content_numbers {
  text-align: center;
  background-color: #10154e;
  border-radius: 5px;
  margin-bottom: 20px;
  color: white;
  padding: 10px;
}

.content .content_numbers p {
  color: white;
}

.content.in_software .soft_img {
  width: 100%;
}

.feautures {
  position: relative;
  /*====================================
        ========= has style1
    =====================================*/
  /*====================================
        ========= has style2
    =====================================*/
  /*====================================
        ========= has style3
    =====================================*/
  /*====================================
        ========= has style4
    =====================================*/
  /*====================================

        ========= has style x
    =====================================*/
  /*====================================
        ========= has style bg
    =====================================*/
  /*====================================
        ========= has style5
    =====================================*/
  /*====================================
        ========= has style6
    =====================================*/
}

.feautures .box_text {
  font-size: 14px;
}

.feautures.has_style1 .box {
  box-shadow: 0 33px 88px 0 #1d0e6211;
  border: solid 1px #eaeaee;
  margin-top: 3rem;
  transition: 0.35s cubic-bezier(0.4, 0, 0.2, 1);
}

@media screen and (max-width: 991px) {
  .feautures.has_style1 .box {
    margin-top: 0;
  }
}

.feautures.has_style1 .box.is_active {
  box-shadow: none;
  background: #10154e;
  margin-top: 0rem;
  box-shadow: 0 36px 66px -42px #11164e;
  border: none;
}

.feautures.has_style1 .box.is_active .box_icon {
  background: linear-gradient(45deg, #4c3d69 0%, #c73e67 100%);
}

.feautures.has_style1 .box.is_active .box_title {
  color: #fff;
}

.feautures.has_style1 .box.is_active .box_text {
  color: rgba(255, 255, 255, 0.575) !important;
}

.feautures.has_style1 .box .box_icon {
  margin-bottom: 15px;
  text-align: center;
  height: 4rem;
  width: 4rem;
  border-radius: 20px;
  background: #683769;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: auto;
}

.feautures.has_style1 .box .box_icon img {
  max-height: 5rem;
}

.feautures.has_style1 .box .box_icon ion-icon {
  color: #be346a;
  font-size: 35px !important;
}

.feautures.has_style1 .box .box_icon svg {
  fill: #be346a;
}

.feautures.has_style1 .box .box_icon [class^="ri-"] {
  fill: #be346a !important;
  font-size: 1.6rem;
  color: #fff;
}

.feautures.has_style1 .box .box_title {
  margin-bottom: 10px;
  text-align: center;
}

.feautures.has_style1 .box:hover {
  transform: translate3d(0, 10px, 50px);
}

.feautures.has_style2 .box {
  padding: 0;
}

.feautures.has_style2 .box .box_icon {
  margin-bottom: 20px;
  text-align: left;
  background: #fff;
  box-shadow: 0 33px 88px 0 #1d0e6211;
  width: fit-content;
  height: 4rem;
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.feautures.has_style2 .box .box_icon img {
  max-height: 5rem;
}

.feautures.has_style2 .box .box_icon ion-icon {
  color: #be346a;
  font-size: 30px !important;
}

.feautures.has_style2 .box .box_icon svg {
  fill: #be346a;
}

.feautures.has_style2 .box .box_icon [class^="ri-"] {
  color: #be346a !important;
  font-size: 1.7rem;
}

.feautures.has_style2 .box .box_title {
  margin-bottom: 10px;
}

.feautures.has_style2 .box .box_text {
  margin: 0;
}

.feautures.has_style3 .swiper-slide {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) !important;
}

.feautures.has_style3 .swiper-slide-active .box {
  background: linear-gradient(45deg, #4c3d69 0%, #c73e67 100%) !important;
  color: #fff;
  transition: all 200ms linear;
}

.feautures.has_style3 .swiper-slide-active .box .box_title {
  color: #fff;
}

.feautures.has_style3 .swiper-slide-active .box .box_text {
  color: rgba(255, 255, 255, 0.712);
}

.feautures.has_style3 .swiper-slide-active .box .box_icon {
  background: rgba(255, 255, 255, 0.096);
}

.feautures.has_style3 .swiper-slide-active .box [class^="ri-"] {
  color: #fff !important;
}

.feautures.has_style3 .box {
  border: solid 1px #ebedf3;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) !important;
  background: #fff;
}

.feautures.has_style3 .box .box_icon {
  margin-bottom: 20px;
  text-align: left;
  background: #faf4f6;
  width: fit-content;
  height: 4rem;
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  margin-right: 20px;
}

.feautures.has_style3 .box .box_icon img {
  max-height: 5rem;
}

.feautures.has_style3 .box .box_icon ion-icon {
  color: #be346a;
  font-size: 30px !important;
}

.feautures.has_style3 .box .box_icon svg {
  fill: #be346a;
}

.feautures.has_style3 .box .box_icon [class^="ri-"] {
  color: #be346a;
  font-size: 1.7rem;
}

.feautures.has_style3 .box .box_title {
  margin-bottom: 10px;
}

.feautures.has_style3 .box .box_text {
  margin: 0;
}

.feautures.has_style3 .swiper-button-prev {
  height: 3rem;
  width: 3rem;
  top: 50%;
  left: auto;
  right: 4rem !important;
  background: #683769;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  outline: none;
}

@media screen and (max-width: 991px) {
  .feautures.has_style3 .swiper-button-prev {
    display: none;
  }
}

.feautures.has_style3 .swiper-button-next {
  height: 3rem;
  width: 3rem;
  top: 50%;
  right: 0px !important;
  background: #be346a;
  border-radius: 50%;
  outline: none;
}

@media screen and (max-width: 991px) {
  .feautures.has_style3 .swiper-button-next {
    visibility: hidden;
  }
}

.feautures.has_style3 .swiper-button-next::after,
.feautures.has_style3 .swiper-button-prev::after {
  color: white;
  font-size: 15px;
  font-weight: 700;
  transition: all 0.2s ease-in-out 0s;
}

.feautures.has_style4 .box {
  padding: 0;
}

.feautures.has_style4 .box.is_active .box_title {
  color: #fff;
}

.feautures.has_style4 .box.is_active .box_text {
  color: #fff;
}

.feautures.has_style4 .box.is_active .box_icon {
  background: rgba(255, 255, 255, 0.096);
}

.feautures.has_style4 .box.is_active [class^="ri-"] {
  color: #fff !important;
}

.feautures.has_style4 .box .box_icon {
  margin-bottom: 20px;
  text-align: left;
  background: rgba(255, 255, 255, 0.096);
  width: fit-content;
  height: 4rem;
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  margin-right: 20px;
}

.feautures.has_style4 .box .box_icon img {
  max-height: 5rem;
}

.feautures.has_style4 .box .box_icon ion-icon {
  color: #be346a;
  font-size: 30px !important;
}

.feautures.has_style4 .box .box_icon svg {
  fill: #be346a;
}

.feautures.has_style4 .box .box_icon [class^="ri-"] {
  color: #fff;
  font-size: 1.7rem;
}

.feautures.has_style4 .box .box_title {
  margin-bottom: 10px;
}

.feautures.has_style4 .box .box_text {
  margin: 0;
}

.feautures.has_stylex .box {
  padding: 0;
}

.feautures.has_stylex .box .box_icon {
  margin-bottom: 20px;
  text-align: left;
  background: linear-gradient(45deg, #4c3d69 0%, #c73e67 100%);
  width: fit-content;
  height: 4rem;
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  margin-right: 20px;
  position: relative;
  position: relative;
}

.feautures.has_stylex .box .box_icon::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 90%;
  background: rgba(255, 255, 255, 0.226);
  z-index: -1;
  bottom: -5px;
  border-radius: 20px;
}

.feautures.has_stylex .box .box_icon::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 50%;
  background: rgba(255, 255, 255, 0.13);
  z-index: -1;
  bottom: -10px;
  border-radius: 20px;
}

.feautures.has_stylex .box .box_icon img {
  max-height: 5rem;
}

.feautures.has_stylex .box .box_icon ion-icon {
  color: #be346a;
  font-size: 30px !important;
}

.feautures.has_stylex .box .box_icon svg {
  fill: #be346a;
}

.feautures.has_stylex .box .box_icon [class^="ri-"] {
  color: #fff;
  font-size: 1.7rem;
}

.feautures.has_stylex .box .box_title {
  margin-bottom: 10px;
}

.feautures.has_stylex .box .box_text {
  margin: 0;
}

.feautures.has_style_bg {
  background-size: contain;
  background-repeat: no-repeat;
}

.feautures.has_style5 .box {
  box-shadow: 0 33px 88px 0 #1d0e6211;
}

.feautures.has_style5 .box.is_active {
  background: #683769;
}

.feautures.has_style5 .box.is_active .box_title {
  color: #fff;
}

.feautures.has_style5 .box.is_active .box_text {
  color: #fff;
}

.feautures.has_style5 .box.is_active .box_icon {
  background: rgba(255, 255, 255, 0.096);
}

.feautures.has_style5 .box.is_active [class^="ri-"] {
  color: #fff !important;
}

.feautures.has_style5 .box .box_icon {
  margin-bottom: 20px;
  text-align: left;
  background: #683769;
  width: fit-content;
  height: 4rem;
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  margin-right: 20px;
}

.feautures.has_style5 .box .box_icon img {
  max-height: 5rem;
}

.feautures.has_style5 .box .box_icon ion-icon {
  color: #be346a;
  font-size: 30px !important;
}

.feautures.has_style5 .box .box_icon svg {
  fill: #be346a;
}

.feautures.has_style5 .box .box_icon [class^="ri-"] {
  color: #fff;
  font-size: 1.7rem;
}

.feautures.has_style5 .box .box_title {
  margin-bottom: 10px;
}

.feautures.has_style5 .box .box_text {
  margin: 0;
}

.feautures.has_style6 .box {
  border: solid 1px #ebedf3;
}

.feautures.has_style6 .box.is_active {
  box-shadow: 0 33px 88px 0 #1d0e6211;
}

.feautures.has_style6 .box .box_icon {
  margin-bottom: 20px;
  text-align: left;
  background: #faf4f6;
  width: fit-content;
  height: 4rem;
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  margin-right: 20px;
}

.feautures.has_style6 .box .box_icon img {
  max-height: 5rem;
}

.feautures.has_style6 .box .box_icon ion-icon {
  color: #be346a;
  font-size: 30px !important;
}

.feautures.has_style6 .box .box_icon svg {
  fill: #be346a;
}

.feautures.has_style6 .box .box_icon [class^="ri-"] {
  color: #be346a;
  font-size: 1.7rem;
}

.feautures.has_style6 .box .box_title {
  margin-bottom: 10px;
}

.feautures.has_style6 .box .box_text {
  margin: 0;
}

.pricing {
  position: relative;
}

.pricing .plans {
  background: #fff;
  box-shadow: 0 33px 88px 0 #1d0e6211;
  border-radius: 5px;
  border: solid 1px #eaeaee;
  padding: 0 0 20px;
  position: relative;
  position: relative;
}

.pricing .plans::before {
  content: "";
  position: absolute;
  background-image: url(../img/decorations/4.png);
  background-size: contain;
  background-repeat: no-repeat;
  top: -2rem;
  z-index: -1;
  right: -8rem;
  height: 20rem;
  width: 20rem;
}

.pricing .plans::after {
  content: "";
  position: absolute;
  background-image: url(../img/decorations/3.png);
  background-size: contain;
  background-repeat: no-repeat;
  bottom: -2rem;
  z-index: -1;
  left: -8rem;
  height: 20rem;
  width: 20rem;
}

.pricing .plan-item .plan_text {
  margin-bottom: 0;
  padding-bottom: 30px;
  border-bottom: 1px solid #b4bec74b;
}

.pricing .plan-item.has_style1 {
  background: #fff;
  box-shadow: 0 33px 88px 0 #1d0e6211;
  border-radius: 0.5rem;
  position: relative;
  transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 5px;
  border: solid 1px #eaeaee;
  margin-bottom: 30px;
}

.pricing .plan-item.has_style1:hover {
  transform: translateY(20px);
}

.pricing .plan-item.has_style1.is_active {
  background: linear-gradient(45deg, #4c3d69 0%, #c73e67 100%);
}

.pricing .plan-item.has_style1.is_active .plan-title {
  color: #fff;
}

.pricing .plan-item.has_style1.is_active .plan_text {
  color: rgba(255, 255, 255, 0.74);
  margin-bottom: 0 !important;
}

.pricing .plan-item.has_style1.is_active .plan-price {
  color: #fff;
}

.pricing .plan-item.has_style1.is_active .price-var {
  color: rgba(255, 255, 255, 0.74);
}

.pricing .plan-item.has_style1.is_active .list-items .item {
  color: #fff;
}

.pricing .plan-item.has_style1.is_active .list-items .item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
  height: 20px;
  width: 20px;
  background-size: contain;
}

.pricing .plan-item.has_style1.is_active .list-items .item ion-icon {
  background: #be346a;
  color: #fff;
  border-radius: 50%;
  padding: 2px;
  margin-right: 10px;
}

.pricing .plan-item.has_style1.is_active .list-items .item img {
  color: #fff;
  padding: 2px;
  margin-right: 10px;
}

@media screen and (max-width: 768px) {
  .pricing .plan-item.has_style2 {
    margin-bottom: 0;
  }
}

.pricing .plan-item.has_style2.is_active {
  border-left: solid 1px #eaeaee;
  border-right: solid 1px #eaeaee;
}

@media screen and (max-width: 991px) {
  .pricing .plan-item.has_style2.is_active {
    border-left: 0;
    border-right: 0;
    border-top: solid 1px #eaeaee;
    border-bottom: solid 1px #eaeaee;
  }
}

.pricing .plan-item .plan_head {
  margin: 2rem;
  margin-bottom: 0;
  position: relative;
}

.pricing .plan-item .plan_head .type {
  padding: 5px 10px;
  background: #e6e7ec;
  color: #858c9b;
  font-size: 14px;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 3px;
}

.pricing .plan-item .plan-title {
  font-size: 30px;
  color: #10154e;
  font-family: "Sora-Bold";
  margin-bottom: 15px;
  position: relative;
}

.pricing .plan-item .price-var {
  color: #be346a;
  margin-left: 2px;
  font-weight: 600;
}

.pricing .plan-item .btn-plan {
  padding: 25px 15px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.pricing .plan-item .plan-price {
  font-size: 3.5rem;
  line-height: 100%;
  margin-bottom: 30px;
  font-weight: 600;
}

.pricing .plan-item .plan-price.has_dollar {
  position: relative;
}

.pricing .plan-item .plan-price.has_dollar::before {
  content: "";
  position: absolute;
  content: "$";
  font-size: 31px;
  top: -21px;
  left: -24px;
  color: #ffffffa6;
}

.pricing .plan-item .plan-price.is_primary {
  position: relative;
}

.pricing .plan-item .plan-price.is_primary::before {
  content: "";
  position: absolute;
  content: "$";
  font-size: 31px;
  top: -21px;
  left: -24px;
  color: #be346a !important;
}

@media screen and (max-width: 768px) {
  .pricing .plan-item .plan-price {
    font-size: 2.5rem;
  }
}

.pricing .plan-item .list-items {
  margin-bottom: 30px;
  padding: 0;
  margin-left: 0;
}

.pricing .plan-item .list-items .item {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  color: #767c89;
  font-size: 14px;
}

.pricing .plan-item .list-items .item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z' fill='rgb(190 52 95)'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  height: 20px;
  width: 20px;
  background-size: contain;
}

.pricing .plan-item .list-items .item ion-icon {
  background: #be346a;
  color: #fff;
  border-radius: 50%;
  padding: 2px;
  margin-right: 10px;
}

.pricing .plan-item .list-items .item img {
  color: #fff;
  padding: 2px;
  margin-right: 10px;
}

.pricing .plan-item.is-active {
  position: relative;
  z-index: 0;
  padding-top: 40px;
}

.pricing .plan-item.is-active .plan_head {
  background: #fff;
  box-shadow: 52px 52px 74px 0 rgba(115, 131, 169, 0.08);
  margin: 0 40px 0;
  border-radius: 30px;
  position: relative;
}

.pricing .plan-item.is-active .plan_head::before {
  content: "";
  position: absolute;
  background: url(../images/others/plan.svg);
  background-size: cover;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.pricing .plan-item.is-active .plan_head .plan-title,
.pricing .plan-item.is-active .plan_head .plan-price {
  color: #10154e;
}

.pricing .plan-item .plan-body {
  padding: 30px;
}

.pricing .plan-single {
  background: #fff;
  box-shadow: 0 33px 88px 0 #1d0e6211;
  border-radius: 5px;
  border: solid 1px #eaeaee;
  padding: 5rem;
}

@media screen and (max-width: 768px) {
  .pricing .plan-single {
    padding: 3rem;
  }
}

.pricing .plan-single .desc-plan {
  max-width: 40rem;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 576px) {
  .pricing .plan-single {
    padding: 2rem;
  }
}

.pricing .plan-single.top-header {
  margin-top: -4rem;
}

.pricing .plan-single .box_plan {
  box-shadow: 0 33px 88px 0 #1d0e6211;
  background: linear-gradient(45deg, #4c3d69 0%, #c73e67 100%);
  color: white;
  position: relative;
  border-radius: 5px;
  z-index: 0;
  padding: 50px;
}

.pricing .plan-single .title {
  text-align: center;
  margin-bottom: 40px;
  font-size: 25px;
  color: white;
}

.pricing .plan-single .price {
  font-size: 6.25rem;
  text-align: center;
  color: white;
}

.pricing .plan-single .width {
  width: 100%;
  display: flex;
  justify-content: center;
}

.pricing .plan-single .billed {
  margin-bottom: 20px;
  color: white;
}

.pricing .plan-single .pricing {
  font-size: 35px;
}

.pricing .plan-single .list-title {
  margin-bottom: 20px;
}

.pricing .plan-single .list_wrap {
  padding-top: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-top: solid 1px #eaeaee;
  border-bottom: solid 1px #eaeaee;
}

.pricing .plan-single .list-items {
  margin-bottom: 20px;
  padding: 0;
  margin-left: 0;
}

.pricing .plan-single .list-items .item {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  color: #767c89;
}

.pricing .plan-single .list-items .item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z' fill='rgb(190 52 95)'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  height: 20px;
  width: 20px;
  background-size: contain;
}

.pricing .plan-single .list-items .item ion-icon {
  background: #be346a;
  color: #fff;
  border-radius: 50%;
  padding: 2px;
  margin-right: 10px;
}

.pricing .plan-single .list-items .item img {
  color: #fff;
  padding: 2px;
  margin-right: 10px;
}

.pricing .plan-single .paragraph {
  font-size: 13px;
  text-align: center;
  color: rgba(190, 52, 106, 0.7);
}

.pricing .plan_list .tab_menu {
  height: 2rem;
  border-radius: 5px;
  border: solid 1px #be346a;
  display: flex;
}

.pricing .plan_list .tab_menu .type {
  padding: 15px 20px;
}

.pricing .plan_list .tab_menu .type a {
  color: #be346a;
  display: block;
}

.pricing .plan_list .plan_head {
  background: #fff;
  box-shadow: 0 33px 88px 0 #1d0e6211;
  border-radius: 5px;
  border: solid 1px #eaeaee;
  padding: 30px;
  position: relative;
}

.pricing .plan_list .plan_head::after {
  content: "";
  position: absolute;
  background-image: url(../img/decorations/3.png);
  background-size: contain;
  background-repeat: no-repeat;
  bottom: -5rem;
  z-index: -1;
  left: -4rem;
  height: 20rem;
  width: 20rem;
}

.pricing .plan_list .plan_head .plan-price {
  font-size: 4rem;
  line-height: 100%;
  margin-bottom: 20px;
  font-weight: 600;
}

.pricing .plan_list .plan_head .plan-price.has_dollar {
  position: relative;
}

.pricing .plan_list .plan_head .plan-price.has_dollar::before {
  content: "";
  position: absolute;
  content: "$";
  font-size: 31px;
  top: -21px;
  left: -24px;
  color: #ffffffa6;
}

.pricing .plan_list .plan_head .plan-price.is_primary {
  position: relative;
}

.pricing .plan_list .plan_head .plan-price.is_primary::before {
  content: "";
  position: absolute;
  content: "$";
  font-size: 31px;
  top: -21px;
  left: -24px;
  color: #be346a !important;
}

@media screen and (max-width: 768px) {
  .pricing .plan_list .plan_head .plan-price {
    font-size: 2.5rem;
  }
}

.pricing .plan_list .plan_body {
  background: #10154e;
  padding: 30px 30px 10px;
  box-shadow: 0 33px 88px 0 #1d0e6211;
  border-radius: 5px;
}

.pricing .plan_list .list_items {
  margin-bottom: 20px;
  padding: 0;
  margin-left: 0;
}

.pricing .plan_list .list_items .item {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  color: white;
}

.pricing .plan_list .list_items .item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z' fill='rgb(190 52 95)'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  height: 20px;
  width: 20px;
  background-size: contain;
}

.pricing .plan_list .list_items .item ion-icon {
  background: #be346a;
  color: #fff;
  border-radius: 50%;
  padding: 2px;
  margin-right: 10px;
}

.pricing .plan_list .list_items .item img {
  color: #fff;
  padding: 2px;
  margin-right: 10px;
}

.case_study .case_box .img {
  border-radius: 5px;
  position: relative;
  z-index: 0;
  transition: 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.case_study .case_box .box_wrap {
  border-radius: 5px;
  padding: 10px 20px;
  box-shadow: 0 33px 88px 0 #1d0e6211;
  margin: -20px 20px 0 20px;
  position: relative;
  background: #fff;
  z-index: 2;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.case_study .case_box .box_title {
  transition: 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}

.case_study .case_box .tags {
  transition: 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 1;
}

.case_study .case_box:hover .tags {
  transform: translateY(20px);
  opacity: 0;
}

.case_study .case_box:hover .box_title {
  transform: translateY(-10px);
}

.case_study .case_box:hover .box_wrap {
  transform: translateY(-40px);
}

.case_study .case_box:hover .img {
  filter: opacity(0.5);
}

.item__number {
  margin-bottom: 30px;
}

.item__number h4 {
  color: #be346a;
}

.item__number .icon {
  margin-bottom: 20px;
  text-align: left;
  background: #10154e;
  height: 4rem;
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  margin-right: 20px;
}

.item__number .icon [class^="ri-"] {
  color: #fff;
  font-size: 1.7rem;
}

.team .team_box.has_style1 {
  margin-bottom: 30px;
  position: relative;
}

.team .team_box.has_style1 .team-name {
  text-align: center;
}

.team .team_box.has_style1 .team-social {
  background-color: white;
  position: absolute;
  top: 44%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 20px;
  border-radius: 50px;
  box-shadow: 52px 52px 74px 0 rgba(115, 131, 169, 0.08);
  margin-top: -20px;
  transition: 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  z-index: 1;
}

.team .team_box.has_style1 .team-social ul {
  display: flex;
}

.team .team_box.has_style1 .team-social a {
  color: #10154e;
  margin: 0 5px;
  line-height: 100%;
}

.team .team_box.has_style1 .team-social a:hover {
  color: #be346a;
}

.team .team_box.has_style1 .team-social a svg {
  height: 0.8rem;
}

.team .team_box.has_style1 .team-social a [class^="ri-"] {
  font-size: 22px;
  line-height: 100%;
  padding-top: 10px;
}

.team .team_box.has_style1 .badge_team {
  position: absolute;
  top: -10px;
  z-index: 1;
  background: #be346a;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  font-size: 13px;
}

.team .team_box.has_style1 .team-img {
  overflow: hidden;
  margin-bottom: 20px;
  border-radius: 12px;
  position: relative;
  z-index: 0;
  border: solid 10px #fff;
  z-index: 0;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.team .team_box.has_style1 .team-img img {
  object-fit: cover;
  width: 100%;
}

.team .team_box.has_style1 .team-img::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.308);
  z-index: 1;
  opacity: 0;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.team .team_box.has_style1:hover .team-social {
  margin-top: 0;
  opacity: 1;
}

.team .team_box.has_style1:hover .team-img::before {
  opacity: 1;
}

.team .team_box.has_style2 {
  margin-bottom: 30px;
  position: relative;
}

.team .team_box.has_style2 .team_name {
  text-align: center;
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 2;
  color: #fff;
  opacity: 0;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translateY(2rem);
}

.team .team_box.has_style2 .team_type {
  transform: translateY(2rem);
}

.team .team_box.has_style2 .team_img {
  height: 20rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border: solid 10px #fff;
  border-radius: 10px;
  box-shadow: 0 33px 88px 0 #1d0e6211;
  overflow: hidden;
  margin-bottom: 20px;
  position: relative;
  z-index: 0;
  transition: all 0.3 cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 0;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.team .team_box.has_style2 .team_img img {
  object-fit: cover;
  width: 100%;
}

.team .team_box.has_style2 .team_img::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.308);
  z-index: 1;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.522), transparent) !important;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.team .team_box.has_style2:hover .team_img {
  transform: translateY(10px);
}

.team .team_box.has_style2:hover .team_name,
.team .team_box.has_style2:hover .team_type {
  opacity: 1;
  transform: translateY(0);
}

.testimonial_box {
  background: #faf4f6;
  margin: 0;
  border-radius: 20px;
  margin-bottom: 30px;
  background-color: #10154e;
  box-shadow: 0px 26px 26px -26px #11164e;
  padding: 30px;
}

.testimonial_box .name {
  color: rgba(255, 255, 255, 0.774);
}

.testimonial_box .quote {
  margin-bottom: 10px;
  color: white;
  font-size: 17px;
  position: relative;
}

.testimonial_box .quote::before {
  content: "";
  position: absolute;
  right: 0px;
  bottom: -4rem;
  height: 5rem;
  width: 5rem;
  opacity: 0.2;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M19.417 6.679C20.447 7.773 21 9 21 10.989c0 3.5-2.457 6.637-6.03 8.188l-.893-1.378c3.335-1.804 3.987-4.145 4.247-5.621-.537.278-1.24.375-1.929.311-1.804-.167-3.226-1.648-3.226-3.489a3.5 3.5 0 0 1 3.5-3.5c1.073 0 2.099.49 2.748 1.179zm-10 0C10.447 7.773 11 9 11 10.989c0 3.5-2.457 6.637-6.03 8.188l-.893-1.378c3.335-1.804 3.987-4.145 4.247-5.621-.537.278-1.24.375-1.929.311C4.591 12.322 3.17 10.841 3.17 9a3.5 3.5 0 0 1 3.5-3.5c1.073 0 2.099.49 2.748 1.179z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
  background-size: contain;
  background-position-y: bottom;
}

.testimonial .testimonial_box2 {
  background: #faf4f6;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 30px;
  color: #10154e;
}

@media screen and (max-width: 991px) {
  .testimonial .testimonial_box2 {
    max-width: 37rem;
    margin: auto;
  }
}

.testimonial .testimonial_box2 .name {
  color: #10154e;
  margin-top: 60px;
  display: block;
}

@media screen and (max-width: 991px) {
  .testimonial .testimonial_box2 .name {
    margin-top: 30px;
  }
}

.testimonial .testimonial_box2 .testimonial_img {
  height: 25rem;
  width: 100%;
  overflow: hidden;
  position: relative;
}

@media screen and (max-width: 991px) {
  .testimonial .testimonial_box2 .testimonial_img {
    height: 20rem;
  }
}

.testimonial .testimonial_box2 .testimonial_img img {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 1;
}

.testimonial .testimonial_box2 .content {
  padding: 30px;
}

@media screen and (max-width: 991px) {
  .testimonial .testimonial_box2 .content {
    padding: 30px 15px;
  }
}

.testimonial .testimonial_box2 .quote {
  margin-bottom: 10px;
  color: #10154e;
  font-size: 22px;
  position: relative;
}

@media screen and (max-width: 991px) {
  .testimonial .testimonial_box2 .quote {
    font-size: 15.5px;
  }
}

.testimonial .testimonial_box2 .quote::before {
  content: "";
  position: absolute;
  right: 15px;
  bottom: -6rem;
  height: 5rem;
  width: 5rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M19.417 6.679C20.447 7.773 21 9 21 10.989c0 3.5-2.457 6.637-6.03 8.188l-.893-1.378c3.335-1.804 3.987-4.145 4.247-5.621-.537.278-1.24.375-1.929.311-1.804-.167-3.226-1.648-3.226-3.489a3.5 3.5 0 0 1 3.5-3.5c1.073 0 2.099.49 2.748 1.179zm-10 0C10.447 7.773 11 9 11 10.989c0 3.5-2.457 6.637-6.03 8.188l-.893-1.378c3.335-1.804 3.987-4.145 4.247-5.621-.537.278-1.24.375-1.929.311C4.591 12.322 3.17 10.841 3.17 9a3.5 3.5 0 0 1 3.5-3.5c1.073 0 2.099.49 2.748 1.179z' fill='rgb(16 21 78)'/%3E%3C/svg%3E");
  background-size: contain;
  background-position-y: bottom;
}

@media screen and (max-width: 991px) {
  .testimonial .testimonial_box2 .quote::before {
    bottom: -4rem;
    height: 3rem;
    width: 3rem;
  }
}

.testimonial .testimonial_box3 {
  background: #fff;
  box-shadow: 0 33px 88px 0 #1d0e6211;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 30px;
  padding: 40px;
  color: #10154e;
}

.testimonial .swiper-pagination {
  transform: translateY(5px);
}

.testimonial .swiper-pagination.in_dark .swiper-pagination-bullet {
  background: #faf4f6;
}

.process.has_style2 .timeline-primary .timeline-item::before {
  background: linear-gradient(45deg, #4c3d69 0%, #c73e67 100%);
  color: #fff;
  border-radius: 5px;
}

.process .timeline {
  padding-left: 0;
  list-style-type: none;
  background: #10154e;
  border-radius: 20px;
  padding: 60px 30px;
  box-shadow: 0 36px 66px -42px #11164e;
}

.process .timeline-item {
  position: relative;
  display: block;
  text-align: center;
  counter-increment: timeline-counter;
}

.process .timeline-item h4 {
  margin-bottom: 10px;
}

.process .timeline-item p {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.719);
}

.process .timeline-item::before {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  margin: 0 auto 1rem;
  font-size: 2rem;
  border-radius: 50%;
  content: counter(timeline-counter);
  line-height: 100%;
  font-family: "Sora-Bold";
}

.process .timeline-item::after {
  position: absolute;
  top: 2rem;
  left: 0;
  right: 0;
  display: none;
  border-top: 2px solid;
  content: "";
}

.process .timeline-item:first-child::after {
  left: 50%;
  width: 50%;
}

.process .timeline-item:last-child::after {
  width: 50%;
}

.process .timeline-item + .timeline-item {
  margin-top: 2rem;
}

.process .timeline-primary .timeline-item::before {
  background: #be346a;
  color: #fff;
}

.process .timeline-primary .timeline-item.active::before {
  background: linear-gradient(45deg, #4c3d69 0%, #c73e67 100%) !important;
}

.process .timeline-primary .timeline-item::after {
  border-color: #bcb6f6;
}

.process .timeline-primary .timeline-item.active::after {
  border-color: rgba(255, 255, 255, 0.15);
}

.process .timeline-secondary .timeline-item::before {
  background-color: #b5b6c5;
  color: #fff;
}

.process .timeline-expand-xs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.process .timeline-expand-xs .timeline-item {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.process .timeline-expand-xs .timeline-item::after {
  display: block;
}

.process .timeline-expand-xs .timeline-item + .timeline-item {
  margin-top: 0;
}

@media (min-width: 576px) {
  .process .timeline-expand-sm {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .process .timeline-expand-sm .timeline-item {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .process .timeline-expand-sm .timeline-item::after {
    display: block;
  }
  .process .timeline-expand-sm .timeline-item + .timeline-item {
    margin-top: 0;
  }
}

@media (min-width: 768px) {
  .process .timeline-expand-md {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .process .timeline-expand-md .timeline-item {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .process .timeline-expand-md .timeline-item::after {
    display: block;
  }
  .process .timeline-expand-md .timeline-item + .timeline-item {
    margin-top: 0;
  }
}

@media (min-width: 992px) {
  .process .timeline-expand-lg {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .process .timeline-expand-lg .timeline-item {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .process .timeline-expand-lg .timeline-item::after {
    display: block;
  }
  .process .timeline-expand-lg .timeline-item + .timeline-item {
    margin-top: 0;
  }
}

@media (min-width: 1200px) {
  .process .timeline-expand-xl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .process .timeline-expand-xl .timeline-item {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .process .timeline-expand-xl .timeline-item::after {
    display: block;
  }
  .process .timeline-expand-xl .timeline-item + .timeline-item {
    margin-top: 0;
  }
}

.showcase .title_sections {
  margin-bottom: 3.125rem;
}

.showcase .item_mywork {
  margin-bottom: 3.125rem;
  display: block;
  text-decoration: none;
  position: relative;
}

.showcase .item_mywork .mg_img {
  border-radius: 12px;
  position: relative;
}

.showcase .item_mywork .icon_played {
  position: absolute;
  left: 40%;
  top: 40%;
  z-index: 2;
}

.showcase .item_mywork .item_pic {
  width: 100%;
  height: 490px !important;
  object-fit: cover;
  border-radius: 12px;
}

.showcase .item_mywork .info_work {
  margin-top: 1.5rem;
  position: relative;
}

.showcase .item_mywork .info_work h4 {
  font-size: 18px;
  margin-bottom: 0.3rem;
  font-weight: 600;
  color: #10154e;
}

.showcase .item_mywork .info_work p {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0;
  color: #6c7a87;
  -webkit-transition: 0.3s !important;
  -moz-transition: 0.3s !important;
  -o-transition: 0.3s !important;
  -ms-transition: 0.3s !important;
  transition: 0.3s !important;
}

.showcase .item_mywork .info_work .link_view {
  opacity: 0;
  color: #be346a;
  font-weight: 500;
  font-size: 14px;
  position: absolute;
  left: 0;
  bottom: -22px;
  -webkit-transition: 0.4s !important;
  -moz-transition: 0.4s !important;
  -o-transition: 0.4s !important;
  -ms-transition: 0.4s !important;
  transition: 0.4s !important;
}

.showcase .item_mywork:hover .info_work p {
  opacity: 0;
}

.showcase .item_mywork:hover .info_work .link_view {
  opacity: 1;
  bottom: -3px;
}

.showcase .showcase_item {
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.showcase .showcase_item .img_item {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 5px;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.showcase .showcase_item:hover {
  transform: translateY(-10px);
}

.showcase .showcase_item:hover .img_item {
  opacity: 0.7;
}

.showcase .showcase_item .price {
  color: #be346a;
  text-align: right;
}

.showcase .showcase_item .place {
  font-size: 13px;
  text-align: right;
  display: block;
}

.showcase .swiper-button-prev {
  height: 3rem;
  width: 3rem;
  top: 11rem;
  left: auto;
  right: 4rem !important;
  background: #be346a;
  border-radius: 0%;
  transition: all 0.2s ease-in-out 0s;
  border-radius: 5px;
}

.showcase .swiper-button-prev:hover {
  background: #683769;
}

@media screen and (max-width: 991px) {
  .showcase .swiper-button-prev {
    display: none;
  }
}

.showcase .swiper-button-next {
  height: 3rem;
  width: 3rem;
  top: 11rem;
  right: 0px !important;
  background: #be346a;
  border-radius: 5px;
}

.showcase .swiper-button-next:hover {
  background: #683769;
}

@media screen and (max-width: 991px) {
  .showcase .swiper-button-next {
    display: none;
  }
}

.showcase .swiper-button-next::after,
.showcase .swiper-button-prev::after {
  color: white;
  font-size: 15px;
  font-weight: 700;
  transition: all 0.2s ease-in-out 0s;
}

.call2action.has_style1 {
  background: linear-gradient(45deg, #4c3d69 0%, #c73e67 100%);
  position: relative;
  position: relative;
}

.call2action.has_style1::before {
  content: "";
  position: absolute;
  background: url(../img/decorations/circle.svg);
  height: 15rem;
  width: 15rem;
  background-size: contain;
  background-repeat: no-repeat;
  top: -5rem;
  opacity: 0.3;
}

.call2action.has_style1::after {
  content: "";
  position: absolute;
  background: url(../img/decorations/circle.svg);
  height: 15rem;
  width: 15rem;
  background-size: contain;
  background-repeat: no-repeat;
  bottom: -5rem;
  right: 0;
  opacity: 0.3;
}

.call2action.has_style2 {
  background: #10154e;
  overflow: hidden;
  position: relative;
}

.call2action.has_style2::before {
  content: "";
  position: absolute;
  background: url(../img/decorations/circle.svg);
  height: 15rem;
  width: 15rem;
  background-size: contain;
  background-repeat: no-repeat;
  top: -5rem;
  opacity: 0.3;
}

.call2action.has_style2 .form_call2action {
  background: white;
  border-radius: 5px;
  box-shadow: 0 33px 88px 0 #1d0e6211;
  padding: 30px;
}

@media screen and (max-width: 991px) {
  .call2action.has_style2 .form_call2action {
    padding: 15px;
  }
}

.call2action.has_style3 {
  background: #faf4f6;
  border-radius: 10px;
  padding: 30px;
}

.call2action.has_style3 .img_top {
  margin-top: -4rem;
  height: 4rem;
  width: 4rem;
  margin-bottom: 20px;
}

.call2action.has_style4 {
  background: #faf4f6;
}

.call2action.has_style5 {
  background: linear-gradient(45deg, #4c3d69 0%, #c73e67 100%);
}

.call2action.has_style5 .form_call2action {
  background: rgba(255, 255, 255, 0.048);
  border-radius: 5px;
  padding: 30px;
  width: 100%;
}

@media screen and (max-width: 991px) {
  .blog_sidebar {
    margin-top: 60px;
  }
}

.blog_sidebar .widget {
  margin-bottom: 30px;
  padding: 30px;
  background-color: #fff;
  border: solid 1px #ededed;
  border-radius: 10px;
  box-shadow: 0 33px 88px 0 #1d0e6211;
}

.blog_sidebar .widget .widget-title {
  font-size: 18px;
  text-transform: capitalize;
  letter-spacing: normal;
  padding-bottom: 10px;
  margin-bottom: 20px;
  color: #10154e !important;
  font-weight: bold;
  position: relative;
  border-bottom: 1px solid #edeef1;
}

.blog_sidebar .widget .widget-title::after {
  content: "";
  width: 50px;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: -1px;
  background-color: #0b2238;
}

.blog_sidebar .social-widget {
  display: flex;
}

.blog_sidebar .social-widget a {
  color: #10154e;
}

.blog_sidebar .social-widget i {
  font-size: 24px;
}

.blog_sidebar .social-widget .social_link {
  margin-right: 20px;
  height: 3rem;
  width: 3rem;
  border: 1px soli transparent;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  background: white;
}

.blog_sidebar .social-widget .social_link.facebook {
  background: #4267b2;
  color: #fff;
}

.blog_sidebar .social-widget .social_link.dribbble {
  background: #ea4c89;
  color: #fff;
}

.blog_sidebar .social-widget .social_link.youtube {
  background: #c4302b;
  color: #fff;
}

.blog_sidebar .social-widget .social_link.twitter {
  background: #00acee;
  color: #fff;
}

.blog_sidebar .social-widget .social_link:hover {
  border: 1px solid #ededed;
  background: white !important;
  color: #10154e;
  box-shadow: 0 5px 34px rgba(3, 3, 38, 0.071);
}

.blog_sidebar .Recent_Articles-widget .item {
  display: flex;
}

.blog_sidebar .Recent_Articles-widget .item .thumb img {
  width: 70px;
  margin-right: 10px;
  border-radius: 5px;
  transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

.blog_sidebar .Recent_Articles-widget .item .info {
  padding-bottom: 20px;
  border-bottom: solid 1px #ededed;
  margin-bottom: 20px;
}

.blog_sidebar .Recent_Articles-widget .item .info.reset {
  padding-bottom: 0;
  border-bottom: 0;
  margin-bottom: 0;
}

.blog_sidebar .Recent_Articles-widget .item .info .title {
  font-size: 15px;
  margin-bottom: 4px;
}

.blog_sidebar .Recent_Articles-widget .item .info .title a {
  color: #10154e;
}

.blog_sidebar .Recent_Articles-widget .item .info .title a:hover {
  color: #be346a;
}

.blog_sidebar .Recent_Articles-widget .item .info .time {
  font-size: 12px;
  color: #86819e;
}

.blog_sidebar .Recent_Articles-widget .item:hover img {
  filter: saturate(1.6);
}

.blog_sidebar .Recent_Articles-widget .swiper-buttons-styling {
  display: inline-block;
  border: 1px solid #ededed;
  box-shadow: 0 5px 34px rgba(0, 0, 0, 0.071);
  padding: 5px 4px;
  border-radius: 5px;
  display: flex;
  width: fit-content;
}

.blog_sidebar .Recent_Articles-widget .swiper-button-prev {
  border-right: 1px solid #ededed;
}

.blog_sidebar .Recent_Articles-widget .swiper-button-next,
.blog_sidebar .Recent_Articles-widget .swiper-button-prev {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  color: #bfbfbf;
  margin: 0 !important;
  height: auto !important;
  padding: 5px 0 !important;
}

.blog_sidebar .Recent_Articles-widget .swiper-button-next:after,
.blog_sidebar .Recent_Articles-widget .swiper-button-prev:after {
  font-size: 17px !important;
}

.blog_sidebar .Recent_Articles-widget .swiper-button-next:hover,
.blog_sidebar .Recent_Articles-widget .swiper-button-prev:hover {
  color: #be346a;
}

.blog_sidebar .ads-widget img {
  width: 100%;
  border-radius: 5px;
}

.blog_sidebar .cat-item {
  padding-bottom: 10px;
  border-bottom: solid 1px #f3f8ff;
  margin-bottom: 10px;
}

.blog_sidebar .cat-item a {
  color: #10154e;
}

.blog_sidebar .cat-item a:hover {
  padding-left: 5px;
  color: #be346a;
}

.blog_sidebar .cat-item:last-child {
  padding-bottom: 0;
  border-bottom: 0;
  margin-bottom: 0;
}

.blog_sidebar .tag_cloud a {
  display: inline-block;
  font-size: 13.5px;
  padding: 2px 10px;
  background-color: #faf4f6;
  margin-right: 5px;
  text-transform: capitalize;
  margin-bottom: 10px;
  border-radius: 5px;
  color: #10154e;
}

.blog_sidebar .tag_cloud a:hover {
  background-color: #10154e;
  color: #f3f8ff;
}

.tag_cloud {
  margin-bottom: -10px;
}

.tag_cloud .p_tags {
  display: flex;
  width: min-content;
  line-height: 2;
  display: block;
  margin-right: 20px;
}

.tag_cloud a {
  padding: 2px 10px;
  background-color: #f3f8ff;
  margin-right: 5px;
  margin-bottom: 10px;
  border: solid 1px transparent;
  color: #10154e;
}

.tag_cloud a:hover {
  background-color: white;
  color: #be346a;
  box-shadow: 52px 52px 74px 0 rgba(115, 131, 169, 0.08);
  border: solid 1px #eee;
}

/* Layouts > _sections.scss                                                    | iokit - creabik */
/*** TABLE OF CONTENTS
==================================================
    ========= Global
    ========= section index feautures
    ========= section companies
    ========= section feautures
    ========= section feautures2
    ========= section works
    ========= section team
    ========= section works2
    ========= section works3
    ========= section testimonials
    ========= section testimonial2
    ========= section testimonial3
    ========= section testimonial4
    ========= section About
    ========= section blog
    ========= section talk
    ========= section video
    ========= section Plans
    ========= section counter
    ========= section services
    ========= section services 2
    ========= section services 3
    ========= section contact
    ========= section jobs
    ========= section trial
=============================================== ***/
.section_head {
  margin-bottom: 40px;
  width: 100%;
}

@media screen and (max-width: 992px) {
  .section_head {
    margin-bottom: 30px !important;
  }
}

.section-subtitle {
  color: #683769;
  margin-bottom: 15px;
}

.section-subtitle.iscenter {
  max-width: auto;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.section_title {
  font-size: calc(36px + 4 * ( (100vw - 576px) / 1024));
  line-height: 1.2;
  max-width: 40rem;
  color: #10154e;
  text-align: left;
  text-transform: capitalize;
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
}

@media screen and (max-width: 576px) {
  .section_title {
    font-size: 36px;
  }
}

@media screen and (min-width: 1600px) {
  .section_title {
    font-size: 40px;
  }
}

@media screen and (max-width: 992px) {
  .section_title {
    font-size: 40px;
  }
}

@media screen and (max-width: 768px) {
  .section_title {
    font-size: 35px;
  }
}

@media screen and (max-width: 576px) {
  .section_title {
    font-size: 30px;
  }
}

.section_title ::before {
  content: "";
  position: absolute;
  top: -10px;
  height: 4px;
  left: 0;
  width: 40px;
  background: #be346a;
}

.section_title ::after {
  position: absolute;
  content: "";
  width: 10px;
  height: 4px;
  background-color: #282828;
  left: 42px;
  top: -10px;
}

.section_title .has-line {
  position: relative;
}

.section_title .has-line::before {
  content: "";
  position: absolute;
  height: 0.7rem;
  bottom: 0.2rem;
  width: 103%;
  left: 0;
  background: #be346a;
  z-index: 0;
  opacity: 0.2;
}

.section_title.is-left {
  max-width: 30rem;
  margin-left: 0;
  margin-right: 0;
  text-align: left;
}

.section_title.is-center {
  max-width: auto;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  max-width: 60rem;
}

.section_title.is-center ::before {
  content: "";
  position: absolute;
  top: -10px;
  height: 4px;
  left: 50%;
  width: 40px;
  background: #be346a;
}

.section_title.is-center ::after {
  position: absolute;
  content: "";
  width: 10px;
  height: 4px;
  background-color: #282828;
  left: 50%;
  top: -10px;
}

.section {
  /*=========================
          ======= Global
      ===========================*/
  position: relative;
  z-index: 0;
  /*====================================
      ========= section index demos
      =====================================*/
  /*====================================
      ========= section index feautures
      =====================================*/
  /*====================================
    ========= section feautures
    =====================================*/
  /*====================================
    ========= section feautures2
    =====================================*/
  /*====================================
    ========= section feautures3
    =====================================*/
  /*====================================
    ========= section About
    =====================================*/
  /*====================================
    ========= section  call2action
    =====================================*/
  /*====================================
    ========= section  call2action2
    =====================================*/
  /*====================================
    ========= section works
    =====================================*/
  /*====================================
    ========= section Plans
    =====================================*/
  /*====================================
    ========= section companies
    =====================================*/
  /*====================================
    ========= section companies 2
    =====================================*/
  /*====================================
    ========= section companies 3
    =====================================*/
  /*====================================
    ========= section testimonials
    =====================================*/
  /*====================================
    ========= section testimonials 2
    =====================================*/
  /*====================================
    ========= section testimonials 3
    =====================================*/
  /*====================================
    ========= section integration 
    =====================================*/
  /*====================================
    ========= section counter
    =====================================*/
  /*====================================
    ========= section counter 2
    =====================================*/
  /*====================================
    ========= section Blog
    =====================================*/
  /*====================================
    ========= section Steps
    =====================================*/
  /*====================================
    ========= section Albums
    =====================================*/
}

.section.section_light {
  background: #faf4f6;
}

.section.section_dark {
  background-color: #10154e;
  background-image: url(../img/decorations/wave.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: right;
  box-shadow: 0 36px 66px -42px #11164e;
}

.section.section_dark * {
  color: white;
}

.section.section_dark p {
  color: rgba(255, 255, 255, 0.719);
}

.section.is-lg {
  padding-top: 140px;
  padding-bottom: 140px;
}

@media screen and (max-width: 992px) {
  .section.is-lg {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

.section.is_sm {
  padding-top: 100px;
  padding-bottom: 100px;
}

@media screen and (max-width: 992px) {
  .section.is_sm {
    padding: 80px 0;
  }
}

.section .section-title-sm {
  margin-bottom: 1.5rem;
  font-size: 45px;
  line-height: 1.4;
  max-width: 57rem;
  margin-left: auto;
  margin-right: auto;
  color: #0f072e;
  text-align: center;
  text-transform: lowercase;
}

.section .section-title-sm .has-line {
  position: relative;
}

.section .section-title-sm .has-line::before {
  content: "";
  position: absolute;
  height: 0.7rem;
  bottom: 0.2rem;
  width: 103%;
  left: 0;
  background: #be346a;
  z-index: 0;
  opacity: 0.2;
}

.section .section-title-sm.is-left {
  max-width: 30rem;
  margin-left: 0;
  margin-right: 0;
  text-align: left;
}

.section .section_description {
  max-width: 35rem;
  text-align: left;
  margin-bottom: 20px;
  margin-left: 0;
  margin-top: 0;
}

.section .section_description.is-center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.section.is-dark {
  color: #fff;
}

.section.is-dark .section_title {
  color: #fff;
  font-size: 45px;
  text-align: left;
  text-transform: capitalize;
  position: relative;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .section.is-dark .section_title {
    font-size: 25px;
  }
}

.section.is-dark .section_description {
  color: white;
}

.section .section-shape1 {
  position: absolute;
  top: 0rem;
  right: 0rem;
  z-index: -1;
  height: 100%;
}

.section .section-particle {
  position: absolute;
  background-size: cover;
  height: 50rem;
  width: 50rem;
  top: 0;
  left: -13rem;
  z-index: 0;
}

.section .section-particle.in-right {
  right: -13rem !important;
  left: auto;
  transform: scaleX(-1);
}

@media screen and (max-width: 576px) {
  .section .section-particle {
    width: 70%;
    left: -1rem;
  }
}

.section .section-shape2 {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  z-index: -1;
  width: 100%;
  max-width: 32rem;
  width: 100%;
  overflow: hidden;
}

@media screen and (max-width: 576px) {
  .section .section-shape2 {
    transform: translate(50%, -50%) scale(1.5);
  }
}

.section .lines {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.section.section-grey-half {
  background: #683769;
}

.section.section-grey-half::before {
  content: "";
  position: absolute;
  bottom: 0;
  height: 40%;
  width: 100%;
  background: rgba(255, 255, 255, 0.911);
}

.section.section-grey {
  background: #f3f8ff;
}

.section.primary-bg {
  background: #10154e;
}

.section .box .box-icon {
  margin-bottom: 14px;
}

.section .box .box-icon ion-icon {
  font-size: 2.5rem;
  line-height: 100%;
}

.section.section-index-feautures {
  background-color: #2f1893;
  position: relative;
}

.section.section-feautures {
  overflow: hidden;
}

.section.section-feautures .feat-item-box {
  background: #fff;
  padding: 0.6rem 2rem;
  width: 17.5rem;
  height: 5rem;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  font-family: "Sora-Bold";
}

.section.section-feautures .feat-item-box p {
  color: #232323 !important;
}

.section.section-feautures .product-img {
  text-align: right;
}

.section.section-feautures .product-img img {
  width: 90%;
}

.section.section-feautures .divider-light-top {
  background: url(../images/others/divider-white2.svg);
  background-repeat: no-repeat;
  width: 100%;
  height: 6rem;
  background-position: top;
  z-index: 0;
}

.section.section-feautures .box {
  padding: 40px 30px;
  border-radius: 10px;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
  cursor: default;
}

.section.section-feautures .box.box.has-style1 {
  background: rgba(190, 52, 106, 0.1);
  -webkit-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.section.section-feautures .box.box.has-style1 .box-icon {
  margin-bottom: 14px;
  text-align: center;
}

.section.section-feautures .box.box.has-style1 .box-title {
  margin-bottom: 14px;
  text-align: center;
}

.section.section-feautures .box.box.has-style1 .box-desc {
  text-align: center;
}

.section.section-feautures .has-secondary-bg {
  background: white;
  box-shadow: 0 14px 33px -3px rgba(0, 0, 0, 0.078);
  transition: 0.2s cubic-bezier(0.5, 0, 0.35, 1);
}

.section.section-feautures .has-secondary-bg .box-title {
  color: #232323;
}

.section.section-feautures .has-secondary-bg .box-desc {
  color: #2a3543;
}

.section.section-feautures .box-icon {
  margin-bottom: 15px;
}

.section.section-feautures .box-icon ion-icon {
  color: #fff;
  font-size: 2.5rem;
  line-height: 100%;
}

.section.section-feautures .box-icon .ellipse-white img {
  width: 80%;
}

.section.section-feautures .box-icon .ellipse img {
  width: 80%;
}

.section.section-feautures .has-left-icon .box-title {
  margin: 5px 0 10px;
}

.section.section-feautures .col-auto {
  padding: 0;
  margin: 0;
}

.section.section-feautures .col {
  margin-left: 10px;
}

.section.section-feautures .has-primary-bg {
  background: #2d3a58;
  box-shadow: 0 0 0 0 #eee;
  box-shadow: 0 14px 33px -3px rgba(0, 0, 0, 0.078);
  transition: 0.2s cubic-bezier(0.5, 0, 0.35, 1);
  border: solid 1px #c2e6e2d7;
}

.section.section-feautures .has-primary-bg.has-style-2 {
  border: none;
}

.section.section-feautures .has-primary-bg .box-title {
  color: white;
}

.section.section-feautures .has-primary-bg .box-desc {
  color: white;
}

.section.section-feautures .img-boxes .img-box img {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 7px 21px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 992px) {
  .section.section-feautures2 {
    margin-top: 0;
  }
}

.section.section-feautures2 .box {
  padding: 40px 30px;
  border-radius: 10px;
  margin-bottom: 30px;
  position: relative;
  cursor: default;
}

.section.section-feautures2 .box.box.has-style2 {
  background: #fff;
  box-shadow: 0 15px 29px -4px #3f49621f;
  transition: 0.25s ease-in-out;
}

.section.section-feautures2 .box.box.has-style2.dark-bg {
  background-color: #2d3a58;
}

.section.section-feautures2 .box.box.has-style2.dark-bg .box-title {
  color: white;
}

.section.section-feautures2 .box.box.has-style2.dark-bg .box-desc {
  color: white;
}

.section.section-feautures2 .box.box.has-style2 .box-icon {
  margin-bottom: 14px;
  text-align: center;
}

.section.section-feautures2 .box.box.has-style2 .box-icon img {
  max-height: 5rem;
}

.section.section-feautures2 .box.box.has-style2 .box-title {
  margin-bottom: 14px;
  text-align: center;
}

.section.section-feautures2 .box.box.has-style2 .box-desc {
  color: #232323;
  text-align: center;
}

@media screen and (max-width: 992px) {
  .section.section-feautures3 {
    margin-top: 0;
  }
}

.section.section-feautures3 .feautures3-box .section_head .section_description {
  color: #2a3543;
}

.section.section-feautures3 .feautures3-box .section_head .feat-head {
  margin-top: 4rem;
}

.section.section-feautures3 .feautures3-box .section_head .feat-head .feat-item {
  color: #2a3543;
}

.section.section-feautures3 .feautures3-box .box {
  padding: 40px 30px;
  border-radius: 10px;
  margin-bottom: 30px;
  position: relative;
  cursor: default;
}

.section.section-feautures3 .feautures3-box .box.box.has-style3 {
  border: 1.5px solid #eaeef7;
  background: #fff;
  box-shadow: 0 15px 29px -4px #3f49621f;
  -webkit-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.section.section-feautures3 .feautures3-box .box.box.has-style3 .box-icon {
  margin-bottom: 14px;
  text-align: center;
}

.section.section-feautures3 .feautures3-box .box.box.has-style3 .box-title {
  margin-bottom: 14px;
  text-align: center;
}

.section.section-feautures3 .feautures3-box .box.box.has-style3 .box-desc {
  color: #2a3543;
  text-align: center;
}

.section.section-about {
  position: relative;
  overflow: hidden;
}

@media screen and (max-width: 992px) {
  .section.section-about .section_title {
    text-align: center;
    margin: auto;
  }
}

.section.section-about .section_description {
  max-width: 40rem;
}

.section.section-about .section_description.is_sm {
  max-width: 26rem;
}

@media screen and (max-width: 992px) {
  .section.section-about .section_description {
    margin-left: auto;
    margin-right: auto;
  }
}

.section.section-about .play-desc {
  color: #0f072e;
}

.section.section-about .watch-more {
  color: #5a52ff;
}

.section.section-about .about-img {
  width: 100%;
  margin: auto;
}

.section.section-about .about-img img {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 15px 25px 0 rgba(93, 90, 129, 0.1);
}

.section.section-about .about-product-img {
  width: 95%;
  margin: auto;
}

.section.section-about .about-product-img img {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 15px 25px 0 rgba(93, 90, 129, 0.1);
}

.section.section-about .has-overlay {
  z-index: 0;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.section.section-about .has-overlay::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.308);
  background-color: #5731ab;
  opacity: 0.56;
  border-radius: 5px;
}

.section.section-about .screen {
  width: 160%;
  margin-left: 9rem;
}

@media screen and (max-width: 992px) {
  .section.section-about .screen {
    width: 100%;
    margin: 0;
  }
}

.section.section-about .about-img2 {
  width: 100%;
}

.section.section-call2action {
  overflow: hidden;
}

.section.section-call2action .call2action-box {
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 18px 35px 3px rgba(51, 29, 105, 0.18);
  transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

.section.section-call2action .call2action-box:hover {
  box-shadow: none;
}

.section.section-call2action .call2action-box img {
  width: 100%;
}

.section.section-call2action .call2action-box .box-right {
  padding-left: 30px;
}

@media screen and (max-width: 991px) {
  .section.section-call2action .call2action-box {
    padding: 60px 30px;
  }
  .section.section-call2action .call2action-box .col-lg-6 {
    padding: 0;
  }
  .section.section-call2action .call2action-box .box-right {
    padding-left: 0;
  }
}

.section.section-call2action2 {
  background-color: #10154e;
}

.section.section-call2action2 .divider {
  position: absolute;
  background: url(../images/others/divider-dark.svg);
  background-repeat: no-repeat;
  width: 100%;
  height: 6rem;
  background-position: bottom;
  top: 90%;
  z-index: 0;
}

.section.section-call2action2 .call2action-box .section_title {
  max-width: 45rem;
}

.section.section-call2action2 .call2action-box .section_description {
  max-width: 30rem;
}

.section.section-works .work-boxes-slick {
  margin: 0 -20px;
}

@media screen and (max-width: 768px) {
  .section.section-works .work-boxes-slick {
    margin: 0 0;
  }
}

.section.section-works .work-box {
  margin: 0 20px;
  overflow: hidden;
  position: relative;
  z-index: 0;
  margin-bottom: 2rem;
  transition: 0.25s;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px 5px 20px 0px rgba(130, 136, 147, 0.15);
}

@media screen and (max-width: 576px) {
  .section.section-works .work-box {
    margin-left: 0;
    margin-right: 0;
  }
}

.section.section-works .work-box::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(45deg, #5731ab 0%, #321c64 100%);
  opacity: 0;
  z-index: 1;
  transition: 0.25s;
  transition: 0.25s;
}

.section.section-works .work-box .work-head {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 30px;
  transform: translate3d(0, 150%, 0);
  transition: transform 0.6s;
  z-index: 3;
}

.section.section-works .work-box .work-head .title a {
  color: white;
  font-size: 24px;
}

.section.section-works .work-box .work-img {
  position: relative;
  width: 100%;
  z-index: 0;
  transition: 0.25s;
}

.section.section-works .work-box:hover::before {
  opacity: 0.8;
}

.section.section-works .work-box:hover .work-head {
  transform: translate3d(0, 0, 0);
}

.section.section-works .work-box.is-active .work-head {
  transform: translate3d(0, 0, 0);
}

.section.section-works .work-box.is-active::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: #10154e;
  opacity: 0.5;
  z-index: 1;
  transition: 0.25s;
  transition: 0.25s;
}

.section.section-plans {
  position: relative;
  overflow: hidden;
}

.section.section-plans .is-dark-green {
  color: white !important;
  background: #002b44 !important;
}

.section.section-plans .is-dark-green .plan-title {
  color: white !important;
}

.section.section-plans .is-dark-green .item {
  color: white !important;
}

.section.section-plans .pricing-toggle {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}

.section.section-plans .pricing-toggle svg {
  fill: #59c894;
  margin-right: 15px;
  position: relative;
  top: 15px;
  -webkit-transform: rotate(20deg);
  -o-transform: rotate(20deg);
  transform: rotate(20deg);
}

.section.section-plans .pricing-toggle .pricing-switch {
  color: #2d3a58;
  line-height: 34px;
}

.section.section-plans .pricing-toggle .pricing-switch .switch {
  margin: 0 10px;
}

.section.section-plans .slider {
  background-color: #3d8aed;
  bottom: 0;
  cursor: pointer;
  left: 0;
  right: 0;
  top: 0;
}

.section.section-plans .slider,
.section.section-plans .slider:before {
  position: absolute;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.section.section-plans .slider:before {
  background-color: #fff;
  bottom: 4px;
  content: "";
  height: 26px;
  left: 4px;
  width: 26px;
}

.section.section-plans input:checked + .slider {
  background-color: #5a52ff;
}

.section.section-plans input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -o-transform: translateX(26px);
  transform: translateX(26px);
}

.section.section-plans .slider.round {
  border-radius: 34px;
}

.section.section-plans .slider.round:before {
  border-radius: 50%;
}

.section.section-plans .switch {
  display: inline-block;
  height: 34px;
  position: relative;
  width: 60px;
}

.section.section-plans .switch input {
  height: 0;
  opacity: 0;
  width: 0;
}

.section.section-plans .rounded-underline {
  position: relative;
  text-decoration: none;
}

.section.section-plans .rounded-underline:before {
  border-radius: 3px;
  bottom: 0;
  content: "";
  height: 6px;
  position: absolute;
  width: 100%;
}

@media (max-width: 991.98px) {
  .section.section-plans .rounded-underline:before {
    display: none;
  }
}

.section.section-plans .in-right {
  transform: translateX(-30px);
}

@media screen and (max-width: 992px) {
  .section.section-plans .in-right {
    transform: none;
  }
}

.section.section-plans .in-left {
  transform: translateX(30px);
  position: relative;
  z-index: -1;
}

@media screen and (max-width: 992px) {
  .section.section-plans .in-left {
    transform: none;
  }
}

.section.section-plans .plan-item {
  border-radius: 0.5rem;
  position: relative;
  transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

.section.section-plans .plan-item:hover {
  transform: translateY(-1rem);
}

.section.section-plans .plan-item .best-choose {
  position: absolute;
  width: 13rem;
  top: -2rem;
  border-radius: 5px 5px 0 0;
  background-color: #be346a;
  padding: 5px 30px;
  font-size: 15px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: #fff;
}

.section.section-plans .plan-item .best-choose::after {
  content: "";
  background-image: url(../images/others/arrow-bottom.svg);
  background-repeat: no-repeat;
  height: 1rem;
  width: 100%;
  position: absolute;
  bottom: -13px;
  top: auto;
  right: -93px;
}

.section.section-plans .plan-item .best-choose.bg_green {
  position: relative;
}

.section.section-plans .plan-item .best-choose.bg_green::after {
  content: "";
  position: absolute;
  content: none;
}

@media screen and (max-width: 768px) {
  .section.section-plans .plan-item {
    margin-bottom: 3rem;
  }
}

.section.section-plans .plan-item .plan-head {
  padding: 2rem;
}

.section.section-plans .plan-item .plan-title {
  font-size: 30px;
  color: #10154e;
  font-family: "Sora-Bold";
  margin-bottom: 20px;
  position: relative;
}

.section.section-plans .plan-item .price-var {
  color: #be346a;
  margin-left: 2px;
  font-weight: 600;
}

.section.section-plans .plan-item .btn-plan {
  padding: 25px 15px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.section.section-plans .plan-item .plan-price {
  font-size: 4rem;
  line-height: 100%;
  margin-bottom: 20px;
  font-weight: 600;
}

.section.section-plans .plan-item .plan-price.has_dollar {
  position: relative;
}

.section.section-plans .plan-item .plan-price.has_dollar::before {
  content: "";
  position: absolute;
  content: "$";
  font-size: 31px;
  top: -21px;
  left: -24px;
  color: #ffffffa6;
}

.section.section-plans .plan-item .plan-price.is_primary {
  position: relative;
}

.section.section-plans .plan-item .plan-price.is_primary::before {
  content: "";
  position: absolute;
  content: "$";
  font-size: 31px;
  top: -21px;
  left: -24px;
  color: #be346a !important;
}

@media screen and (max-width: 768px) {
  .section.section-plans .plan-item .plan-price {
    font-size: 2.5rem;
  }
}

.section.section-plans .plan-item .list-items {
  margin-bottom: 1rem;
  padding: 0;
  margin-left: 0;
}

.section.section-plans .plan-item .list-items .item {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  color: #767c89;
}

.section.section-plans .plan-item .list-items .item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-image: url(../images/others/check.svg);
  background-repeat: no-repeat;
  height: 20px;
  width: 20px;
  background-size: contain;
}

.section.section-plans .plan-item .list-items .item ion-icon {
  background: #be346a;
  color: #fff;
  border-radius: 50%;
  padding: 2px;
  margin-right: 10px;
}

.section.section-plans .plan-item .list-items .item img {
  color: #fff;
  padding: 2px;
  margin-right: 10px;
}

.section.section-plans .plan-item.is-active {
  position: relative;
  z-index: 0;
  padding-top: 40px;
}

.section.section-plans .plan-item.is-active .plan-head {
  background: #fff;
  box-shadow: 52px 52px 74px 0 rgba(115, 131, 169, 0.08);
  margin: 0 40px 0;
  border-radius: 30px;
  position: relative;
}

.section.section-plans .plan-item.is-active .plan-head::before {
  content: "";
  position: absolute;
  background: url(../images/others/plan.svg);
  background-size: cover;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.section.section-plans .plan-item.is-active .plan-head .plan-title,
.section.section-plans .plan-item.is-active .plan-head .plan-price {
  color: #10154e;
}

.section.section-plans .plan-item .plan-body {
  padding: 40px;
}

.section.section-plans .plan-item.has-style1 {
  background: #fff;
  box-shadow: 52px 52px 74px 0 rgba(115, 131, 169, 0.08);
  border-radius: 30px;
}

.section.section-plans .plan-item.has-style1.first-price {
  margin-top: 3rem;
}

@media screen and (max-width: 992px) {
  .section.section-plans .plan-item.has-style1.first-price {
    margin-top: 0;
  }
}

.section.section-plans .plan-item.has-style2 {
  background-color: #fff;
  box-shadow: 0 33px 88px 0 #1d0e6211;
}

@media screen and (max-width: 768px) {
  .section.section-plans .plan-item.has-style2 {
    border: solid 1px #5a52ff;
  }
}

.section.section-plans .plan-item.has-style2 .plan-title {
  color: #5a52ff;
  font-size: 20px;
  margin-bottom: 40px;
}

.section.section-plans .plan-item.has-style2 .price-var {
  color: #be346a;
  font-size: 27px;
  margin: -10px 0 0 30px;
  font-weight: 600;
}

.section.section-plans .plan-item.has-style2 .plan-price {
  font-size: 3.5rem;
  line-height: 100%;
  margin-bottom: 20px;
  margin-left: 6px;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .section.section-plans .plan-item.has-style2 .plan-price {
    font-size: 2.5rem;
  }
}

.section.section-plans .plan-item.has-style2 .plan-desc {
  color: #2a3543;
}

.section.section-plans .plan-item.has-style2 .list-items .item {
  color: #232323;
}

.section.section-plans .plan-item.has-style2 .list-items .item img {
  color: #fff;
  padding: 2px;
  margin-right: 10px;
}

.section.section-plans .plan-item.has-style2 .list-items .item-1 {
  color: #767c89;
}

.section.section-plans .plan-info {
  max-width: 39rem;
}

.section.section-plans .plan-info a {
  color: #fff;
}

.section.section-companies .box-companies {
  background-color: #f3f8ff;
}

.section.section-companies .box-companies .brands {
  padding: 4rem 2rem;
  width: 100%;
  position: relative;
}

.section.section-companies .box-companies .has-style1 {
  display: flex;
  justify-content: center;
}

.section.section-companies .box-companies .has-style1 .company-item {
  display: flex;
}

@media screen and (max-width: 992px) {
  .section.section-companies .box-companies .has-style1 .company-item {
    display: flex;
    justify-content: center;
  }
}

.section.section-companies .box-companies .has-style1 .company-item img {
  height: 2rem;
  margin-bottom: 30px;
  transition: 0.25s ease-in-out;
}

.section.section-companies .box-companies .has-style1 .company-item img:hover {
  transform: scale(1.1);
}

.section.section-companies2 .box-companies .section-subtitle {
  color: #d0d0d0;
}

.section.section-companies2 .box-companies .section_description {
  max-width: 39rem;
}

.section.section-companies2 .box-companies .brands {
  width: 100%;
  position: relative;
}

.section.section-companies2 .box-companies .has-style1 {
  display: flex;
  justify-content: center;
}

.section.section-companies2 .box-companies .has-style1 .company-item {
  display: flex;
}

@media screen and (max-width: 992px) {
  .section.section-companies2 .box-companies .has-style1 .company-item {
    display: flex;
    justify-content: center;
  }
}

.section.section-companies2 .box-companies .has-style1 .company-item img {
  max-height: 2rem;
  margin-bottom: 30px;
  transition: 0.25s ease-in-out;
}

.section.section-companies2 .box-companies .has-style1 .company-item img:hover {
  transform: scale(1.1);
}

.section.section-companies3 {
  z-index: 0;
}

.section.section-companies3 .box-companies {
  margin: auto;
  max-width: 65rem;
  padding-top: 60px;
  background-color: white;
  box-shadow: 52px 52px 74px 0 rgba(115, 131, 169, 0.08);
  border-radius: 20px;
}

.section.section-companies3 .box-companies .section-subtitle {
  color: #d0d0d0;
}

.section.section-companies3 .box-companies .section_description {
  color: #232323;
  max-width: 29rem;
}

.section.section-companies3 .box-companies .brands {
  width: 100%;
  position: relative;
}

.section.section-companies3 .box-companies .has-style3 {
  display: flex;
  justify-content: center;
  border-top: 1px solid #d5e6ef;
  padding: 28px;
}

.section.section-companies3 .box-companies .has-style3 .company-item {
  display: flex;
}

@media screen and (max-width: 992px) {
  .section.section-companies3 .box-companies .has-style3 .company-item {
    display: flex;
    justify-content: center;
  }
}

.section.section-companies3 .box-companies .has-style3 .company-item img {
  height: 2rem;
  margin-bottom: 30px;
  transition: 0.25s ease-in-out;
}

.section.section-companies3 .box-companies .has-style3 .company-item img:hover {
  transform: scale(1.1);
}

.section.section-testimonial {
  position: relative;
  z-index: 0;
}

.section.section-testimonial .section-particle {
  top: -3.6rem;
}

.section.section-testimonial .section_title {
  max-width: 50rem;
  padding-bottom: 15px;
}

.section.section-testimonial .client-wrap {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

@media screen and (max-width: 576px) {
  .section.section-testimonial .client-wrap {
    padding-left: 0;
  }
}

.section.section-testimonial .client-wrap.is-white .client {
  color: #2d3a58;
}

.section.section-testimonial .client-wrap.is-white .client-quote {
  color: #2d3a58;
  text-align: left;
}

@media screen and (max-width: 991px) {
  .section.section-testimonial .client-wrap.is-white .client-quote {
    max-width: 60rem;
  }
}

.section.section-testimonial .client-wrap.is-white .client-name {
  color: #ff6b84;
}

.section.section-testimonial .stars {
  max-height: 1.2rem;
  margin-bottom: 0.5rem;
}

.section.section-testimonial .client {
  text-transform: uppercase;
  color: #ffffff;
  margin-top: 40px;
  margin-bottom: 20px;
}

.section.section-testimonial .client-img {
  border-radius: 50%;
  overflow: hidden;
  background-color: #677ced1f;
  height: 20rem;
  width: 20rem;
  padding: 4rem;
}

.section.section-testimonial .client-img img {
  width: 100%;
  vertical-align: middle;
  max-height: 25rem;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 992px) {
  .section.section-testimonial .client-img img {
    width: 80%;
  }
}

.section.section-testimonial .client-name {
  margin-top: 20px;
  color: #fff;
  text-align: left;
  margin-right: 15px;
}

.section.section-testimonial .testi-icon {
  position: absolute;
  top: 57%;
  right: 10%;
  width: 33%;
}

.section.section-testimonial .testi-icon img {
  width: 100%;
}

@media screen and (max-width: 992px) {
  .section.section-testimonial .testi-icon {
    width: 20%;
  }
}

.section.section-testimonial .testimonial-box {
  display: flex;
}

@media screen and (max-width: 768px) {
  .section.section-testimonial .testimonial-box {
    flex-direction: column;
  }
}

.section.section-testimonial .testimonial-box .box-left {
  background-size: cover;
  height: inherit;
}

.section.section-testimonial .testimonial-box .box-left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 0;
}

.section.section-testimonial .testimonial-box .box-right {
  background: #002b44;
  width: 75%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 60px;
}

.section.section-testimonial .testimonial-box .box-right .testimonial-quote {
  flex-grow: 1;
  font-size: 1.875rem;
  align-items: center;
  display: flex;
  color: white;
  text-align: center;
  margin-bottom: 60px;
}

@media screen and (max-width: 992px) {
  .section.section-testimonial .testimonial-box .box-right .testimonial-quote {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 768px) {
  .section.section-testimonial .testimonial-box .box-right .testimonial-quote {
    font-size: 20px;
  }
}

.section.section-testimonial .testimonial-box .box-right .logo {
  box-sizing: content-box;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
}

.section.section-testimonial .testimonial-box .box-right .logo img {
  height: 2.5rem;
}

.section.section-testimonial .testimonial-box .box-right .title {
  text-align: center;
  margin: 30px 0;
  color: white;
}

.section.section-testimonial.has-bg {
  padding: 150px 0px 230px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../images/bg/testi-bg.png);
  background-position: center;
  position: relative;
  border-radius: 5px;
  z-index: 0;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.section.section-testimonial.has-bg::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.308);
  background-color: #5731ab;
  opacity: 0.4;
  z-index: -1;
}

.section.section-testimonial.has-bg .section-title-sm {
  color: white;
}

.section.section-testimonial.has-bg .testi-icon {
  position: absolute;
  top: 8%;
  right: 2%;
  width: 12%;
}

.section.section-testimonial.has-bg .testi-icon img {
  width: 100%;
}

.section.section-testimonial2 {
  position: relative;
  overflow: hidden;
}

.section.section-testimonial2 .play-area {
  position: absolute;
  z-index: 1;
  left: 51%;
  top: 55%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-left: 23px;
}

@media screen and (max-width: 992px) {
  .section.section-testimonial2 .play-area {
    display: none;
  }
}

.section.section-testimonial2 .play-area .play-btn {
  background: #10154e;
  height: 4rem;
  width: 4rem;
  border-radius: 100%;
  padding: 0.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.section.section-testimonial2 .play-area .play-btn img {
  width: 35%;
  z-index: 2;
  border-radius: 0;
}

.section.section-testimonial2 .play-area .play-btn::before {
  content: "";
  position: absolute;
  bottom: 50%;
  right: 50%;
  border-radius: 100%;
  transform: translate(50%, 50%);
  background-image: url(../images/bg/color-effect.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 150%;
  z-index: -1;
  animation-name: anim2;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0, 0, 0.41, 1.87);
  width: 150%;
}

.section.section-testimonial2 .testi-boxes {
  background-color: #eeedff;
}

.section.section-testimonial2 .testi-boxes .team-box .team-thumb {
  display: flex;
  justify-content: center;
  margin: auto;
}

.section.section-testimonial2 .testi-boxes .team-box .team-thumb img {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 15px 25px 0 rgba(93, 90, 129, 0.1);
}

@media screen and (max-width: 992px) {
  .section.section-testimonial2 .testi-boxes .team-box .team-thumb img {
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .section.section-testimonial2 .testi-boxes .team-box .team-thumb img {
    width: 50%;
  }
}

@media screen and (max-width: 768px) {
  .section.section-testimonial2 .testi-boxes .team-box .team-name {
    display: flex;
    justify-content: center;
    margin: auto;
    text-align: center;
    max-width: 20rem;
  }
}

.section.section-testimonial2 .testi-boxes .team-box .team-quote {
  color: #2a3543;
}

@media screen and (max-width: 768px) {
  .section.section-testimonial2 .testi-boxes .team-box .team-quote {
    text-align: center;
    margin: auto;
    max-width: 20rem;
  }
}

.section.section-testimonial2 .testi-boxes .team-box a {
  color: #5a52ff;
}

@media screen and (max-width: 768px) {
  .section.section-testimonial2 .testi-boxes .team-box a {
    display: flex;
    justify-content: center;
    margin: auto;
  }
}

.section.section-testimonial2 .testi-boxes .team-box .team-info {
  margin-top: auto;
  margin-bottom: auto;
}

.section.section-testimonial2 .testimonial-box {
  background-color: #f3f2fc;
}

@media screen and (max-width: 768px) {
  .section.section-testimonial2 .testimonial-box {
    margin: auto;
    width: 80%;
  }
}

.section.section-testimonial2 .testimonial-box .box-left {
  z-index: 0;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.section.section-testimonial2 .testimonial-box .box-left::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.308);
  background-color: #321c64;
  opacity: 0.7;
  border-radius: 10px;
}

.section.section-testimonial2 .testimonial-box .box-left.overlay-none {
  z-index: 0;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.section.section-testimonial2 .testimonial-box .box-left.overlay-none::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.308);
  opacity: 0;
}

.section.section-testimonial2 .testimonial-box .box-left img {
  width: 100%;
  box-shadow: 0 15px 25px 0 rgba(93, 90, 129, 0.1);
  border-radius: 10px;
}

.section.section-testimonial2 .testimonial-box .box-left .play-area {
  position: absolute;
  z-index: 0;
  left: 51%;
  top: 55%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.section.section-testimonial2 .testimonial-box .box-left .play-area .play-btn {
  background: #10154e;
  height: 4rem;
  width: 4rem;
  border-radius: 100%;
  padding: 0.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.section.section-testimonial2 .testimonial-box .box-left .play-area .play-btn img {
  width: 100%;
  z-index: 2;
  border-radius: 0;
}

.section.section-testimonial2 .testimonial-box .box-left .play-area .play-btn::before {
  content: "";
  position: absolute;
  bottom: 50%;
  right: 50%;
  border-radius: 100%;
  transform: translate(50%, 50%);
  background-image: url(../images/bg/color-effect.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 150%;
  z-index: -1;
  animation-name: anim2;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0, 0, 0.41, 1.87);
  width: 150%;
}

.section.section-testimonial2 .testimonial-box .box-left .section-particle {
  position: absolute;
  background-size: contain;
  height: 51rem;
  width: 50rem;
  top: 0rem;
  left: -8rem;
  z-index: -2;
}

.section.section-testimonial2 .testimonial-box .box-left .section-particle img {
  width: 100%;
  border-radius: 0;
  box-shadow: none;
}

@media screen and (max-width: 992px) {
  .section.section-testimonial2 .testimonial-box .box-left .section-particle {
    width: 40rem;
  }
}

.section.section-testimonial2 .testimonial-box .box-right {
  margin: 0 30px;
}

.section.section-testimonial2 .testimonial-box .box-right .client-quote {
  font-family: "Sora-Bold";
  margin: 0;
  font-size: 20px;
  color: #0f072e;
}

.section.section-testimonial2 .testimonial-box .box-right .client-info {
  margin-top: 5%;
}

@media screen and (max-width: 768px) {
  .section.section-testimonial2 .testimonial-box .box-right {
    margin-top: 1rem;
  }
}

.section.section-testimonial3 {
  position: relative;
  overflow: hidden;
}

.section.section-testimonial3 .testi-body {
  margin-bottom: 120px;
}

.section.section-testimonial3 .testi-body .testi-box {
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 33px 88px 0 #1d0e6211;
  margin-bottom: 1rem;
}

.section.section-testimonial3 .testi-body .testi-box .stars img {
  width: 31%;
}

.section.section-testimonial3 .testi-body .testi-box .section_description {
  margin: 10px 0 30px;
}

.section.section-testimonial3 .testi-body .testi-box .customer-info .customer-img {
  margin-right: 1rem;
}

.section.section-testimonial3 .testi-body .testi-box .customer-info .customer-img img {
  border-radius: 50%;
  max-height: 4rem;
}

.section.section-testimonial3 .testi-body .testi-box .customer-info .customer-position {
  text-transform: uppercase;
}

.section.section-testimonial3 .blog-feautures .support-img img {
  border-radius: 30px;
  position: absolute;
}

.section.section-testimonial3 .blog-feautures .support-img .support-2 {
  left: 50px;
}

.section.section-testimonial3 .blog-feautures .support-img .support-3 {
  left: 88px;
}

.section.section-testimonial3 .blog-feautures .feauture-desc {
  max-width: 17rem;
}

.section.section-testimonial3 .blog-feautures p {
  max-width: 17rem;
}

.section.section-testimonial3.has-margin {
  margin-top: -12rem;
  z-index: 2;
}

.section.integration {
  position: relative;
  overflow: hidden;
}

.section.integration .integration-list-left {
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 5rem;
}

.section.integration .integration-list-left li {
  min-width: 76px;
  min-height: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background-color: #fff;
  box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.06);
}

.section.integration .integration-list-left li img {
  width: 40px;
}

.section.integration .content {
  background-image: url(../images/others/cover.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
  height: 650px;
}

@media screen and (max-width: 992px) {
  .section.integration .content {
    height: 100%;
    padding: 60px 0;
  }
}

.section.integration .integration-list-left li:nth-of-type(1) {
  position: relative;
  margin-top: -30rem;
}

.section.integration .integration-list-left li:nth-of-type(2) {
  margin-bottom: -26rem;
  margin-left: -5rem;
}

.section.integration .integration-list-left li:nth-of-type(3) {
  margin-top: -4rem;
  margin-left: -11.5rem;
}

.section.integration .integration-list-right {
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: -7.5rem;
}

.section.integration .integration-list-right li {
  min-width: 76px;
  min-height: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background-color: #fff;
  box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.06);
}

.section.integration .integration-list-right li:nth-of-type(1) {
  position: relative;
  margin-bottom: -26rem;
  margin-left: 6rem;
}

.section.integration .integration-list-right li img {
  width: 40px;
}

.section.integration .integration-list-right li:nth-of-type(3) {
  margin-top: -4rem;
  position: relative;
  margin-left: 2rem;
}

.section.integration .integration-list-right li:nth-of-type(2) {
  margin-top: -30rem;
  position: relative;
  margin-left: -76px;
}

.section.integration .heading {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (max-width: 992px) {
  .section.integration .integration-block {
    display: none;
  }
}

.section.integration .integration-icons {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 992px) {
  .section.integration .integration-icons {
    margin-bottom: 3rem;
  }
}

.section.integration .integration-icons img {
  width: 100%;
}

.section.integration .integration-right {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
}

@media screen and (max-width: 992px) {
  .section.integration .integration-right {
    height: 24rem;
  }
}

.section.integration .integration-right .section_title {
  font-size: 50px !important;
  max-width: 20rem;
}

@media screen and (max-width: 768px) {
  .section.integration .integration-right .section_title {
    font-size: 35px !important;
    max-width: 19rem;
  }
}

@media screen and (max-width: 576px) {
  .section.integration .integration-right .section_title {
    max-width: 14rem;
  }
}

.section.integration .integration-right .section-shape {
  position: absolute;
  z-index: -1;
  width: 90%;
  top: 10%;
  overflow: hidden;
}

@media screen and (max-width: 992px) {
  .section.integration .integration-right .section-shape {
    top: 5%;
    width: 30rem;
  }
}

@media screen and (max-width: 768px) {
  .section.integration .integration-right .section-shape {
    top: 10%;
    width: 25rem;
  }
}

@media screen and (max-width: 576px) {
  .section.integration .integration-right .section-shape {
    width: 22rem;
  }
}

.section.section-counter .divider-bottom {
  background: url(../images/others/divider-white2.svg);
  background-repeat: no-repeat;
  width: 100%;
  height: 6rem;
  background-position: bottom;
  z-index: 0;
}

.section.section-counter .section_head .section_title {
  max-width: 40rem;
}

.section.section-counter .counters {
  margin-top: 70px;
}

.section.section-counter .counter-item {
  background: #be346a;
  height: 10rem;
  width: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 50%;
  transition: 0.25s;
  cursor: default;
  position: relative;
}

.section.section-counter .counter-item::before {
  content: "";
  position: absolute;
  right: -63%;
  height: 2rem;
  width: 5rem;
  background: url(../images/others/arrow.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.section.section-counter .counter-item.no_before {
  position: relative;
}

.section.section-counter .counter-item.no_before::before {
  content: "";
  position: absolute;
  content: none;
}

@media screen and (max-width: 768px) {
  .section.section-counter .counter-item {
    position: relative;
  }
  .section.section-counter .counter-item::before {
    content: "";
    position: absolute;
    content: none;
  }
}

.section.section-counter .counter-item .counter-number {
  color: white;
  font-size: 40px;
  text-align: center;
  line-height: 1;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .section.section-counter .counter-item .counter-number {
    font-size: 30px;
  }
}

.section.section-counter .counter-item .counter-name {
  color: rgba(255, 255, 255, 0.815);
  text-transform: uppercase;
  font-size: 15.5px;
  text-align: center;
  margin: 0;
}

.section.section-counter2 {
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.section.section-counter2 .counter-box .section_head .section_title {
  max-width: 37rem;
}

.section.section-counter2 .counter-box .icon-sm {
  width: 80%;
}

.section.section-counter2 .counter-box .counter-icon {
  background-repeat: no-repeat;
  height: 7rem;
  width: 12rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.section.section-counter2 .counter-box .counter-icon [class^="ri-"] {
  font-size: 40px;
}

.section.section-counter2 .counter-box .counter-icon .icon {
  position: absolute;
  top: 17%;
  left: 43%;
}

@media screen and (max-width: 576px) {
  .section.section-counter2 .counter-box .counter-icon .icon {
    left: 40%;
  }
}

.section.section-counter2 .counter-box .counter-item .counter-number {
  font-size: 40px;
  text-align: center;
  line-height: 1;
}

@media screen and (max-width: 768px) {
  .section.section-counter2 .counter-box .counter-item .counter-number {
    font-size: 20px;
  }
}

.section.section-counter2 .counter-box .counter-item .counter-name {
  text-transform: uppercase;
  font-size: 15.5px;
  text-align: center;
}

.section.section-counter2 .counter-box .is-dark .counter-item .counter-number {
  color: #683769;
  font-size: 40px;
  text-align: center;
  line-height: 1;
}

@media screen and (max-width: 768px) {
  .section.section-counter2 .counter-box .is-dark .counter-item .counter-number {
    font-size: 20px;
  }
}

.section.section-counter2 .counter-box .is-dark .counter-item .counter-name {
  color: #683769;
  text-transform: uppercase;
  font-size: 15.5px;
  text-align: center;
}

.section.section-blog img {
  width: 100%;
  border-radius: 5px;
}

.section.section-blog a {
  color: #2d3a58;
}

.section.section-blog .has-style1 .blog-card {
  background-image: url(../images/bg/post-img.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  width: 100%;
  height: 102%;
}

@media screen and (max-width: 768px) {
  .section.section-blog .has-style1 .blog-card {
    margin-bottom: 5rem;
  }
}

.section.section-blog .has-style1 .blog-card .blog-img {
  width: 100%;
}

@media screen and (max-width: 992px) {
  .section.section-blog .has-style1 .blog-card .post-content {
    padding: 58% 5% 1%;
  }
}

.section.section-blog .has-style1 .blog-card .post-content .content-wrap {
  position: absolute;
  padding: 10px;
  bottom: 10px;
  width: 90%;
}

.section.section-blog .has-style1 .blog-card .post-content .content-wrap .post-title a {
  color: white;
}

.section.section-blog .has-style1 .blog-card .post-content .content-wrap .post-desc {
  margin-top: 3%;
}

.section.section-blog .has-style1 .blog-card .post-content .content-wrap .post-desc .post-author a {
  color: white;
}

@media screen and (max-width: 768px) {
  .section.section-blog .has-style2 .post-title {
    font-size: 22px;
  }
}

.section.section-blog .has-style2 .post-desc .post-date {
  color: #5a52ff;
}

.section.section-steps {
  overflow: hidden;
}

.section.section-steps .steps-img {
  width: 160%;
  border-radius: 15px 0 0 15px;
}

@media screen and (max-width: 992px) {
  .section.section-steps .steps-img {
    width: 80%;
    border-radius: 10px;
    margin: 2rem;
  }
}

.section.section-albums {
  overflow: hidden;
}

.section.section-albums img {
  width: 100%;
  margin-bottom: 30px;
}

.shape {
  position: absolute;
  bottom: 10rem;
  right: 0rem;
  bottom: 100px;
  z-index: -1;
}

.shape img {
  width: 100%;
  max-width: 20rem;
}

.divider.divider-bottom {
  top: 1px;
}

section > .divider-bottom {
  margin-top: calc(3.75rem - 5vw);
}

.divider {
  width: 100%;
  position: relative;
  height: 5vw;
  pointer-events: none;
  -webkit-mask-image: url(../img/decorations/devider.svg);
  mask-image: url(../img/decorations/devider.svg);
  -webkit-mask-size: 100% 101%;
  mask-size: 100% 101%;
  background: #fff;
}

.is_white {
  background-color: #fff !important;
}

.is_light {
  background-color: #faf4f6 !important;
}

.footer {
  padding: 100px 0 50px;
}

.footer.has_style1 {
  background: #10154e;
}

.footer.has_style1 .copyright {
  color: #fff;
  opacity: 0.7;
  font-size: 14px;
  margin-bottom: 0;
}

.footer.has_style1 .list-title {
  color: #fff;
}

.footer.has_style1 .social_footer a,
.footer.has_style1 .social_footer i {
  color: white;
}

.footer.has_style1 .list-items {
  color: #fff;
}

.footer.has_style1 .list-items li a {
  color: #fff;
  opacity: 0.85;
}

.footer.has_style1 .list-items li a:hover {
  color: #be346a;
}

.footer.has_style2 {
  background: #fff;
}

.footer .footer_text {
  max-width: 30rem;
  margin-left: 0;
}

.footer .social_footer a,
.footer .social_footer i {
  color: #10154e;
  font-size: 1.5rem;
}

.footer .footer-desc {
  margin-bottom: 2rem;
}

.footer .footer-desc .logo {
  margin-bottom: 1rem;
}

.footer .footer-desc .logo img {
  height: 2.5rem;
}

.footer .footer-desc .social-footer {
  margin-top: 1rem;
}

.footer .footer-desc .social-footer a img {
  width: 18px;
  margin-right: 8px;
}

.footer .footer-desc .social-footer a ion-icon {
  font-size: 25px;
  color: white;
}

.footer .list-title {
  font-size: 1.1rem;
  position: relative;
  margin-bottom: 1.3rem;
}

.footer .list-items {
  margin-bottom: 2rem;
}

.footer .list-items li {
  margin-bottom: 1rem;
}

.footer .list-items li a {
  color: #2e3158;
}

.footer .list-items li a:hover {
  padding-left: 0.5rem;
  color: #be346a;
}

.footer .copyright {
  margin-top: 35px;
  font-size: 14px;
  margin-bottom: 0;
}

.footer .copyright p {
  padding: 2rem 0 2rem;
  font-size: 1rem;
  margin: 0;
  color: #a5a5a5;
}

.footer_index {
  background: #10154e !important;
  padding: 20px 0;
}

.footer_index img {
  max-height: 40px;
}

.footer_index .links a {
  color: #faf4f6;
  padding: 0 5px;
  text-transform: lowercase;
}

.block {
  padding: 30px;
  margin-bottom: 30px;
}

.block .title {
  font-size: 20px;
}

.block .title_top {
  font-size: 20px;
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: solid 1px #ebeaef;
  font-size: calc(24px + 2 * ( (100vw - 576px) / 1024));
}

@media screen and (max-width: 576px) {
  .block .title_top {
    font-size: 24px;
  }
}

@media screen and (min-width: 1600px) {
  .block .title_top {
    font-size: 26px;
  }
}

.block li:not(:last-child) {
  margin-bottom: 5px;
}

.block li a {
  color: #526792 !important;
}

.block .list-item.is_active a {
  color: #be346a;
}

.block_colored {
  padding: 70px;
  border-radius: 20px;
}

.block_colored.is_orange {
  background: #ff784b;
}

.block_colored.is_orange h1,
.block_colored.is_orange h2,
.block_colored.is_orange h3,
.block_colored.is_orange h4,
.block_colored.is_orange h5,
.block_colored.is_orange h6,
.block_colored.is_orange p {
  color: white;
}

.block_colored.is_green {
  background: #3dcabd;
}

.block_colored.is_green h1,
.block_colored.is_green h2,
.block_colored.is_green h3,
.block_colored.is_green h4,
.block_colored.is_green h5,
.block_colored.is_green h6,
.block_colored.is_green p {
  color: white;
}

.dark_block {
  background: #be346a;
}

.dark_block h1,
.dark_block h2,
.dark_block h3,
.dark_block h4,
.dark_block h5,
.dark_block h6,
.dark_block span,
.dark_block p {
  color: white;
}

.client_slick .slick-next {
  left: 25%;
  bottom: -13%;
  top: auto;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E"), #be346a;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  position: absolute !important;
  background-repeat: no-repeat;
  background-position: center;
  border: solid 5px white;
}

.client_slick .slick-prev {
  left: 18%;
  bottom: -13%;
  top: auto;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E"), #be346a;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  position: absolute !important;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 1 !important;
  z-index: 2;
  border: solid 5px white;
}

@media screen and (max-width: 991px) {
  .client_slick .slick-next {
    left: 51%;
    bottom: auto;
    top: 0;
    width: 3rem;
    height: 3rem;
  }
  .client_slick .slick-prev {
    left: 44%;
    bottom: auto;
    top: 0;
    width: 3rem;
    height: 3rem;
  }
}

@media screen and (max-width: 768px) {
  .client_slick .slick-prev {
    left: 40%;
  }
}

.page_light {
  background-color: rgba(243, 248, 255, 0.3);
}

.subscribe {
  background: #10154e;
  color: #fff;
}

.subscribe .section_head {
  margin-bottom: 30px;
}

.subscribe .section_title {
  color: #fff;
  font-size: calc(40px + 14 * ( (100vw - 576px) / 1024));
}

@media screen and (max-width: 576px) {
  .subscribe .section_title {
    font-size: 40px;
  }
}

@media screen and (min-width: 1600px) {
  .subscribe .section_title {
    font-size: 54px;
  }
}

.subscribe p {
  color: #fff;
}

.team-box.has_style1 {
  margin-bottom: 30px;
}

.team-box.has_style1 .team-name {
  text-align: center;
}

.team-box.has_style1 .team-social {
  background-color: white;
  position: absolute;
  top: 44%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 20px;
  border-radius: 50px;
  box-shadow: 52px 52px 74px 0 rgba(115, 131, 169, 0.08);
  margin-top: -20px;
  transition: 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  z-index: 1;
}

.team-box.has_style1 .team-social ul {
  display: flex;
}

.team-box.has_style1 .team-social a {
  color: #10154e;
  margin: 0 5px;
  line-height: 100%;
}

.team-box.has_style1 .team-social a:hover {
  color: #be346a;
}

.team-box.has_style1 .team-social a svg {
  height: 0.8rem;
}

.team-box.has_style1 .team-social a ion-icon {
  height: 1rem;
  padding-top: 7px;
  font-size: 18px;
}

.team-box.has_style1 .badge {
  position: absolute;
  top: -10px;
  z-index: 1;
  background: #be346a;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
}

.team-box.has_style1 .team-img {
  overflow: hidden;
  margin-bottom: 20px;
  border-radius: 30px;
  position: relative;
  z-index: 0;
  z-index: 0;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.team-box.has_style1 .team-img img {
  object-fit: cover;
  width: 100%;
}

.team-box.has_style1 .team-img::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.308);
  z-index: 1;
  opacity: 0;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.team-box.has_style1:hover .team-social {
  margin-top: 0;
  opacity: 1;
}

.team-box.has_style1:hover .team-img::before {
  opacity: 1;
}

.process_block {
  border-radius: 20px;
  background: white;
  box-shadow: 52px 52px 74px 0 rgba(115, 131, 169, 0.08);
  padding: 20px;
}

.process-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 1.125rem;
  background: rgba(190, 52, 106, 0.05) !important;
}

.process-circle:empty:after {
  content: "";
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: #be346a;
}

.process-vertical {
  padding: 0;
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
}

.process-vertical li {
  display: flex;
  align-items: center;
}

.process-vertical li .process-circle {
  margin-right: 25px;
}

.process-vertical li:not(:last-child) {
  position: relative;
  margin-bottom: 25px;
}

@media screen and (min-width: 1025px) {
  .process-vertical li {
    width: 50%;
    margin-left: 50%;
  }
  .process-vertical li .process-circle {
    margin-left: -25px;
  }
  .process-vertical li:nth-child(even) {
    flex-direction: row-reverse;
    text-align: right;
    margin-left: 0;
    margin-right: 50%;
  }
  .process-vertical li:nth-child(even) .process-circle {
    margin-right: -25px;
    margin-left: 25px;
  }
  .process-vertical li:not(:last-child) {
    padding-bottom: 75px;
    margin-bottom: 0;
  }
  .process-vertical li:not(:last-child):after {
    content: "";
    display: block;
    width: 2px;
    height: 50px;
    background: #e5e0eb;
    position: absolute;
    top: 4.125rem;
  }
  .text-light .process-vertical li:not(:last-child):after {
    background: rgba(255, 255, 255, 0.25);
  }
}

.career_wrap h3 {
  font-size: 25px;
}

.career_wrap h3:not(:first-child) {
  margin: 20px 0;
}

.block_404 {
  margin: 0;
  height: calc(100vh - 100px);
  position: relative;
}

.block_404 .title_404 {
  position: absolute;
  top: -50%;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  font-size: 20rem;
  font-family: "Sora-Bold";
  color: white;
  text-shadow: 0 0 74px #35356c0a;
  line-height: 100%;
}

@media screen and (max-width: 1024px) {
  .block_404 {
    margin: 100px 0;
    height: max-content;
  }
  .block_404 .title_404 {
    position: absolute;
    top: 0;
    font-size: 10rem;
  }
}

.block_thanks {
  margin: 0;
  height: calc(100vh - 100px);
  position: relative;
}

.block_thanks .title_404 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  font-size: 10rem;
  font-family: "Sora-Bold";
  color: white;
  text-shadow: 0 0 74px #35356c0a;
  line-height: 100%;
}

@media screen and (max-width: 1024px) {
  .block_thanks {
    margin: 100px 0;
    height: max-content;
  }
  .block_thanks .title_404 {
    position: absolute;
    top: 0;
    font-size: 10rem;
  }
}

.block_reviews .reviews_box {
  box-shadow: 52px 52px 74px 0 rgba(115, 131, 169, 0.08);
  padding: 30px;
  margin-bottom: 30px;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  background: white;
  position: relative;
}

.block_reviews .reviews_box:hover {
  transform: scale(1.06);
}

.block_reviews .reviews_box::before {
  content: "";
  position: absolute;
  bottom: 15px;
  right: 15px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M4.583 17.321C3.553 16.227 3 15 3 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179zm10 0C13.553 16.227 13 15 13 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179z' fill='rgba(243,242,252,1)'/%3E%3C/svg%3E");
  background-size: contain;
  height: 3rem;
  width: 3rem;
  background-repeat: no-repeat;
}

.block_reviews .reviews_box .stars {
  display: flex;
}

.block_reviews .reviews_box .stars img {
  max-height: 1.5rem;
}

.block_reviews .reviews_box .quote {
  margin: 20px 0;
}

.block_reviews .reviews_box .avatar {
  height: 4rem;
  width: 4rem;
}

.reviews_slick .slick-list {
  overflow: visible !important;
}

.reviews_slick .reviews_box {
  margin: 0 20px;
  opacity: 0.6;
  transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

.reviews_slick .reviews_box.slick-center {
  opacity: 1;
}

.block_faq {
  max-width: 45rem;
  margin-left: auto;
  margin-right: auto;
}

.block_case {
  box-shadow: 0 33px 88px 0 #1d0e6211;
  margin-bottom: 30px;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 5px;
  position: relative;
  background: white;
}

.block_case .img_case {
  width: 100%;
}

.block_case .case_wrap {
  padding: 30px;
}

.block_case .logo {
  position: absolute;
  top: 15px;
  left: 15px;
  background: white;
  border-radius: 5px;
  padding: 5px 10px;
  box-shadow: 0 40px 40px -20px rgba(23, 29, 48, 0.1);
}

.block_case .logo img {
  height: 1rem;
}

.block_case2 {
  display: flex;
  box-shadow: 0 33px 88px 0 #1d0e6211;
  margin-bottom: 30px;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 5px;
  position: relative;
  background: white;
  overflow: hidden;
}

.block_case2 .img_case {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.block_case2 .case_wrap {
  padding: 30px;
  position: relative;
}

.block_case2 .logo {
  position: absolute;
  top: 15px;
  right: 15px;
  background: white;
  border-radius: 5px;
  padding: 5px 10px;
  box-shadow: 52px 52px 74px 0 rgba(115, 131, 169, 0.08);
}

.block_case2 .logo img {
  height: 1rem;
}

.block_timer {
  border-radius: 10px;
  padding: 20px 40px;
  background: #be346a;
  overflow: hidden;
}

.block_timer .dots {
  color: white;
  font-size: calc(64px + 8 * ( (100vw - 576px) / 1024));
}

@media screen and (max-width: 576px) {
  .block_timer .dots {
    font-size: 64px;
  }
}

@media screen and (min-width: 1600px) {
  .block_timer .dots {
    font-size: 72px;
  }
}

@media screen and (max-width: 1024px) {
  .block_timer .dots {
    font-size: calc(26px + 46 * ( (100vw - 576px) / 1024));
  }
}

@media screen and (max-width: 1024px) and (max-width: 576px) {
  .block_timer .dots {
    font-size: 26px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 1600px) {
  .block_timer .dots {
    font-size: 72px;
  }
}

.block_timer .number {
  color: white;
  font-size: 50px !important;
  font-size: calc(64px + 8 * ( (100vw - 576px) / 1024));
}

@media screen and (max-width: 576px) {
  .block_timer .number {
    font-size: 64px;
  }
}

@media screen and (min-width: 1600px) {
  .block_timer .number {
    font-size: 72px;
  }
}

@media screen and (max-width: 1024px) {
  .block_timer .number {
    font-size: calc(26px + 46 * ( (100vw - 576px) / 1024));
  }
}

@media screen and (max-width: 1024px) and (max-width: 576px) {
  .block_timer .number {
    font-size: 26px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 1600px) {
  .block_timer .number {
    font-size: 72px;
  }
}

.block_timer .dots {
  padding: 0 20px;
  height: auto !important;
  margin-top: 0;
  font-size: 50px !important;
}

.block_contact_form .content {
  padding: 30px;
  box-shadow: 52px 52px 74px 0 rgba(115, 131, 169, 0.08);
  border-radius: 5px;
  margin-bottom: 30px;
  background: white;
  position: relative;
  border-radius: 20px;
}

.block_help {
  padding: 30px;
  box-shadow: 52px 52px 74px 0 rgba(115, 131, 169, 0.08);
  border-radius: 5px;
  margin-bottom: 30px;
  background: white;
  position: relative;
  transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

.block_help .icon {
  margin-right: 20px;
}

.block_help .icon svg {
  fill: #be346a !important;
  width: 55px;
}

.block_help:hover {
  background: #f5d9e4;
  box-shadow: none;
}

.help_wrap p,
.help_wrap h3,
.help_wrap h4,
.help_wrap h5,
.help_wrap h6,
.help_wrap span {
  margin-top: 0;
  margin-bottom: 20px;
}

.block_popup {
  margin-bottom: 60px;
  cursor: pointer;
}

.block_popup:hover img {
  transform: scale(1.02);
}

.block_popup img {
  width: 100%;
  transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  margin-bottom: 30px;
}

.block_popup .link {
  color: white;
  background: transparent !important;
}

.ticket_block {
  padding: 60px;
  background: #be346a;
  border-radius: 5px;
  box-shadow: 0 40px 40px -20px rgba(23, 29, 48, 0.1);
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  position: relative;
  position: relative;
}

.ticket_block::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 20px;
  width: 20px;
  background: white;
  border-radius: 50px;
  left: -10px;
}

.ticket_block::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 20px;
  width: 20px;
  background: white;
  border-radius: 50px;
  right: -10px;
}

.ticket_block .wrap {
  position: relative;
}

.ticket_block .wrap::before {
  content: "";
  position: absolute;
  background: url(../assets/images/others/circle_decoration.png);
  top: -72%;
  left: 15%;
  height: 100%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

.ticket_block .title {
  color: white;
  margin-bottom: 20px;
}

.ticket_block .description {
  color: white;
  margin-bottom: 20px;
  max-width: 15rem;
  font-size: 19px;
}

.ticket_block .price {
  font-size: calc(46px + 18 * ( (100vw - 576px) / 1024));
  line-height: 1;
  color: white;
  font-family: "Sora-Bold";
}

@media screen and (max-width: 576px) {
  .ticket_block .price {
    font-size: 46px;
  }
}

@media screen and (min-width: 1600px) {
  .ticket_block .price {
    font-size: 64px;
  }
}

.event_block {
  padding: 30px;
  margin-bottom: 30px;
  border-radius: 5px;
  box-shadow: 0 33px 88px 0 #1d0e6211;
  background: white;
}

.event_block .title {
  font-size: calc(36px + 4 * ( (100vw - 576px) / 1024));
}

@media screen and (max-width: 576px) {
  .event_block .title {
    font-size: 36px;
  }
}

@media screen and (min-width: 1600px) {
  .event_block .title {
    font-size: 40px;
  }
}

.event_block .date {
  font-size: calc(36px + 4 * ( (100vw - 576px) / 1024));
  color: #be346a;
}

@media screen and (max-width: 576px) {
  .event_block .date {
    font-size: 36px;
  }
}

@media screen and (min-width: 1600px) {
  .event_block .date {
    font-size: 40px;
  }
}

@media screen and (max-width: 991px) {
  .event_block .date {
    text-align: left;
  }
}

.event_block .description {
  margin-top: 20px;
}

.tools_block {
  margin: 30px 0;
  display: flex;
  justify-content: center;
}

.tools_block img {
  height: 2.5rem;
  transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

.tools_block img:hover {
  transform: scale(1.1);
}

.courses_block .item {
  margin-bottom: 30px;
  border-radius: 5px;
  box-shadow: 0 33px 88px 0 #1d0e6211;
  position: relative;
  background: white;
  display: flex;
}

.courses_block .item .thumb_wrap {
  position: relative;
}

.courses_block .item .item_wrap {
  padding: 20px;
  padding-left: 2rem;
  position: relative;
}

.courses_block .item .item_wrap .info {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -3.3rem;
  width: 4rem;
  height: 4rem;
  box-shadow: 0 33px 88px 0 #1d0e6211;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #be346a;
  color: white;
  border: solid 5px white;
  box-shadow: 0 40px 40px -20px rgba(23, 29, 48, 0.1);
}

.courses_block .item .item_wrap .info h4 {
  font-size: 18px;
  color: white;
  margin: 0;
  padding: 0;
  line-height: 100%;
}

.courses_block .item .item_wrap .info span {
  font-size: 13px;
}

.courses_block .item .thumb {
  width: 10rem;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: 0 33px 88px 0 #1d0e6211;
  margin-right: 20px;
}

.courses_block .item .title {
  margin: 0 0 20px 0;
  font-size: 22px;
}

.courses_block .item .item_meta {
  display: flex;
  justify-content: flex-start;
}

.courses_block .item .item_meta div {
  font-size: 13px;
  color: #524e63;
}

.courses_block .item .item_meta svg {
  fill: #524e63;
}

.numbers_block {
  border-right: 1px solid #dfdbe9;
  padding: 40px;
}

.numbers_block.none {
  border-right: 0 !important;
}

.numbers_block .number {
  color: #be346a;
}

.price-form {
  padding: 0 20px;
}

.price-form input {
  height: 20px;
  margin: 15px 0;
}

.discount-plain-text {
  text-align: center;
  font-size: 13px;
  color: #84879b;
}

.price_form2 {
  padding: 0 15px;
}

.discount-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 20px;
}

.pricing-table {
  background: #fff;
  border-radius: 30px;
  box-shadow: 52px 52px 74px 0 rgba(115, 131, 169, 0.08);
}

.pricing-table table {
  display: block;
}

.pricing-table table thead {
  display: block;
}

.pricing-table table thead tr {
  display: block;
}

.pricing-table table thead tr .sin-title-box {
  padding: 0 20px;
  height: 60px;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.56px;
}

.pricing-table table tbody {
  display: block;
}

.pricing-table table tbody tr {
  display: block;
}

.pricing-table table .title-col {
  min-width: 290px;
}

.pricing-table table .basic-col {
  min-width: 16rem;
}

.pricing-table table .standard-col {
  min-width: 16rem;
}

.pricing-table table .premium-col {
  min-width: 16rem;
}

.pricing-table table .ent-col {
  min-width: 195px;
}

.pricing-table table .colored-row {
  border-top: 2px solid #cfd5e340;
  width: initial;
}

.pricing-table table .colored-row:first-child {
  border-top: 0;
}

.pricing-table table .colored-row:first-child .sin-box:before {
  height: 100%;
  top: auto;
  bottom: 0;
}

.pricing-table table .colored-row .sin-box {
  position: relative;
  padding: 0 20px;
  height: 65px;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -0.56px;
}

.pricing-table table .colored-row .sin-box:before {
  content: "";
  background: #ddebfc;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  width: 1px;
}

.pricing-table table .colored-row .sin-box.title-col {
  font-weight: 700;
}

.pricing-table table .colored-row .sin-box .table-icon {
  font-size: 20px;
}

.pricing-table table .colored-row .sin-box .table-icon.neg {
  color: #f04037;
}

.pricing-table table .colored-row .sin-box .table-icon.pos {
  color: #67d216;
}

.pricing-table table .price-widget-block {
  padding-left: 22px;
  margin-top: 50px;
}

.pricing-table table .price-widget-block .title {
  font-size: 21px;
  font-weight: 700;
  letter-spacing: -0.66px;
  padding-bottom: 5px;
}

.pricing-table table .price-widget-block .subtitle {
  font-size: 15px;
  font-weight: 300;
  letter-spacing: -0.46px;
  padding-bottom: 26px;
}

.pricing-table table .price-widget-block .btn {
  width: 167px;
  height: 50px;
  border-radius: 5px;
  background-color: #be346a;
  color: white;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.56px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.pricing-table table .price-widget-block .btn:hover {
  background-color: #be346a;
  color: white;
}

.privacy_block {
  padding: 60px !important;
  box-shadow: 0 33px 88px 0 #1d0e6211;
}

@media screen and (max-width: 991px) {
  .privacy_block {
    padding: 15px !important;
  }
}

.privacy_block .badge {
  background: #be346a;
  height: 5px;
  width: 5px;
  margin-top: 14px !important;
  border-radius: 50%;
}

.block_list {
  position: relative;
  padding-top: 60px;
  padding-bottom: 44px;
  box-shadow: 52px 52px 74px 0 rgba(115, 131, 169, 0.08);
}

.block_list .list_item {
  border-bottom: solid 1px #dfdce6;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.block_list .list_item:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.contact_wrap_dark {
  max-width: 60rem;
  border-radius: 5px;
  background: #10154e;
  padding: 60px 30px;
}

.contact_wrap_dark label {
  color: white;
}

.contact_wrap_dark p {
  color: white;
}

.steps_block .ellipse {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  background: #be346a;
  display: flex;
  justify-content: center;
  align-items: center;
}

.steps_block .ellipse .num {
  color: white;
  text-align: center;
}

.steps_block .description {
  max-width: 20rem;
  margin-left: 4rem;
  margin-bottom: 30px;
}

.steps_block .title {
  margin-left: 1rem;
  line-height: 1.1;
}

.feauture_item {
  margin-bottom: 120px;
  padding: 40px;
  box-shadow: 52px 52px 74px 0 rgba(115, 131, 169, 0.08);
  background: white;
  border-radius: 30px;
}

.feauture_item:last-child {
  margin-bottom: 0;
}

.img_2 {
  transform: translateY(-3rem);
}

.img_3 {
  transform: translateY(-200px);
}

.block_plugin_free {
  margin: 50px 50px 0;
  border-radius: 5px;
  background: linear-gradient(104deg, white, white, white, rgba(255, 255, 255, 0.522));
  padding: 50px 0;
}

@media screen and (max-width: 991px) {
  .block_plugin_free {
    margin: 50px 15px 0;
  }
}

.block_plugin_free .item_plugins {
  margin-top: 50px;
  text-align: center;
}

.block_plugin_free .icon {
  height: 4rem;
  width: 4rem;
  padding: 1rem;
  background: linear-gradient(45deg, #4c3d69 0%, #c73e67 100%);
  margin: 0 auto;
  border-radius: 3px;
  margin-bottom: 15px;
  color: white;
}

.block_plugin_free .icon i {
  color: white;
  line-height: 2rem;
  font-size: 24px;
}

.block_ststues_elements .item {
  color: white;
}

.block_ststues_elements .item h3,
.block_ststues_elements .item p {
  color: white;
}

.block_main_features {
  background: #2a1249;
  color: white;
}

.block_main_features .hr {
  background: #ffffff3d;
  height: 2px;
}

.block_main_features .block {
  margin: 0;
}

.block_main_features .block .title {
  margin-bottom: 20px;
}

.block_main_features .section_title {
  color: white;
}

.bg_back {
  border-radius: 30px;
  position: relative;
}

.bg_back::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  background-image: url(../images/others/lines.svg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 60%;
  width: 100%;
  border-radius: 30px;
  background-color: #faf4f6;
}

.bg_back img {
  width: 100%;
  max-width: 20rem;
}

.bg_back_wrap {
  border-radius: 30px;
  position: relative;
}

.bg_back_wrap::before {
  content: "";
  position: absolute;
  height: 60%;
  width: 100%;
  border-radius: 30px;
  background-color: #faf4f6;
  top: 0;
  transform: translateX(-50%);
  left: 50%;
}

.bg_back_wrap img {
  width: 100%;
  max-width: 20rem;
}

.app_block {
  border-radius: 60px;
  background: #faf4f6;
  padding: 0 50px;
  position: relative;
}

.app_block::before {
  content: "";
  position: absolute;
  background: #be346a;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  height: 70%;
}

.app_block .img_center {
  width: 100%;
  max-width: 20rem;
}

/*=========================
======= progress 
===========================*/
.progress-work {
  margin-top: 80px;
  /* This for loop creates the 	necessary css animation names 
Due to the split circle of progress-left and progress right, we must use the animations on each side. 
*/
}

@media screen and (max-width: 992px) {
  .progress-work {
    margin-top: 70px;
  }
}

.progress-work .progress {
  width: 200px;
  height: 200px;
  line-height: 200px;
  background: none;
  margin: 0 auto;
  box-shadow: none;
  position: relative;
  margin-bottom: 30px;
}

@media screen and (max-width: 992px) {
  .progress-work .progress {
    width: 150px;
    height: 150px;
  }
}

@media screen and (max-width: 768px) {
  .progress-work .progress {
    width: 125px;
    height: 125px;
  }
}

.progress-work .progress:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 7px solid #f3f3f3;
  position: absolute;
  top: 0;
  left: 0;
}

.progress-work .progress > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.progress-work .progress .progress-left {
  left: 0;
}

.progress-work .progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 7px;
  border-style: solid;
  position: absolute;
  top: 0;
  border-color: #be346a;
}

.progress-work .progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}

.progress-work .progress .progress-right {
  right: 0;
}

.progress-work .progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
}

.progress-work .progress .progress-value {
  display: flex;
  border-radius: 50%;
  font-size: 36px;
  text-align: center;
  line-height: 20px;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-weight: bold;
  width: 100%;
}

.progress-work .progress .progress-value div {
  margin-top: 10px;
}

.progress-work .progress .progress-value span {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: normal;
}

.progress-work.is-blue .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 7px;
  border-style: solid;
  position: absolute;
  top: 0;
  border-color: #563cc9 !important;
}

.progress-work .progress[data-percentage="10"] .progress-right .progress-bar {
  animation: loading-1 1.5s linear forwards;
}

.progress-work .progress[data-percentage="10"] .progress-left .progress-bar {
  animation: 0;
}

.progress-work .progress[data-percentage="20"] .progress-right .progress-bar {
  animation: loading-2 1.5s linear forwards;
}

.progress-work .progress[data-percentage="20"] .progress-left .progress-bar {
  animation: 0;
}

.progress-work .progress[data-percentage="30"] .progress-right .progress-bar {
  animation: loading-3 1.5s linear forwards;
}

.progress-work .progress[data-percentage="30"] .progress-left .progress-bar {
  animation: 0;
}

.progress-work .progress[data-percentage="40"] .progress-right .progress-bar {
  animation: loading-4 1.5s linear forwards;
}

.progress-work .progress[data-percentage="40"] .progress-left .progress-bar {
  animation: 0;
}

.progress-work .progress[data-percentage="50"] .progress-right .progress-bar {
  animation: loading-5 1.5s linear forwards;
}

.progress-work .progress[data-percentage="50"] .progress-left .progress-bar {
  animation: 0;
}

.progress-work .progress[data-percentage="60"] .progress-right .progress-bar {
  animation: loading-5 1.5s linear forwards;
}

.progress-work .progress[data-percentage="60"] .progress-left .progress-bar {
  animation: loading-1 1.5s linear forwards 1.5s;
}

.progress-work .progress[data-percentage="70"] .progress-right .progress-bar {
  animation: loading-5 1.5s linear forwards;
}

.progress-work .progress[data-percentage="70"] .progress-left .progress-bar {
  animation: loading-2 1.5s linear forwards 1.5s;
}

.progress-work .progress[data-percentage="80"] .progress-right .progress-bar {
  animation: loading-5 1.5s linear forwards;
}

.progress-work .progress[data-percentage="80"] .progress-left .progress-bar {
  animation: loading-3 1.5s linear forwards 1.5s;
}

.progress-work .progress[data-percentage="90"] .progress-right .progress-bar {
  animation: loading-5 1.5s linear forwards;
}

.progress-work .progress[data-percentage="90"] .progress-left .progress-bar {
  animation: loading-4 1.5s linear forwards 1.5s;
}

.progress-work .progress[data-percentage="100"] .progress-right .progress-bar {
  animation: loading-5 1.5s linear forwards;
}

.progress-work .progress[data-percentage="100"] .progress-left .progress-bar {
  animation: loading-5 1.5s linear forwards 1.5s;
}

@keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(36);
    transform: rotate(36deg);
  }
}

@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(72);
    transform: rotate(72deg);
  }
}

@keyframes loading-3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(108);
    transform: rotate(108deg);
  }
}

@keyframes loading-4 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(144);
    transform: rotate(144deg);
  }
}

@keyframes loading-5 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(180);
    transform: rotate(180deg);
  }
}

@media screen and (max-width: 768px) {
  .contact-info-list-warpper {
    margin-top: 30px;
  }
}

.elements_page .elements_sidebar {
  background: #faf4f6;
  padding: 20px;
  border-radius: 7px;
}

.elements_page .elements_sidebar h5 {
  margin-bottom: 10px;
}

.elements_page .elements_sidebar .nav-item {
  padding: 10px;
  box-shadow: 0px 10px 20px rgba(10, 33, 65, 0.034), 0px 0px 2px rgba(0, 0, 0, 0.062);
  margin-bottom: 10px;
  background: #fff;
  border-radius: 5px;
}

.elements_page .elements_sidebar .nav-item a {
  color: #10154e;
  font-size: 15.5px;
}

.elements_page .top_title {
  position: relative;
}

.elements_page .top_title span {
  position: absolute;
  font-size: 18px;
  background: #be346a;
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 1rem;
}

.elements_page .elements_box {
  box-shadow: 0px 10px 20px rgba(10, 33, 65, 0.05), 0px 0px 2px rgba(0, 0, 0, 0.13);
  padding: 20px;
  margin-top: 30px;
}

.elements_page .elements_box .elements_title {
  font-family: Sora !important;
  font-size: 30px;
  text-transform: capitalize;
  font-family: "Sora" !important;
  margin-bottom: 30px;
}

.elements_page .elements_box .elements_number {
  margin-top: 4px;
  color: #6b7280;
  font-size: 15px;
}

.colors .color_item {
  margin-bottom: 30px;
}

.colors .color_palette {
  height: 10rem;
  border-radius: 5px;
  margin-bottom: 10px;
}

.grid_element .col_grid {
  padding: 20px 5px;
  background-color: #faf4f6;
  text-align: center;
}

.element_block {
  box-shadow: 0px 20px 40px rgba(10, 33, 65, 0.05), 0px 0px 2px rgba(0, 0, 0, 0.13);
  margin-bottom: 40px;
  overflow: hidden;
}

.element_block .element_head {
  border-bottom: solid 1px rgba(10, 33, 65, 0.05);
}

.element_block .element_head .nav-link.active {
  color: #be346a;
  background-color: rgba(190, 52, 106, 0.15);
  border: none;
  border-radius: 5px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  line-height: 100%;
  height: fit-content;
}

.element_block .element_head .nav-link {
  border: none;
  border-radius: 5px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  line-height: 100%;
  height: fit-content;
  margin-right: 0.5rem;
  color: #858c9b;
}

.element_block .element_head .copy {
  color: #858c9b;
  padding-left: 1rem;
  border-radius: 0;
  border-left: solid 1px rgba(107, 114, 128, 0.295);
  font-size: 20px;
  padding-top: 0;
  padding-bottom: 0;
}

.head_elements_hero {
  padding-top: 10rem;
  padding-bottom: 5rem;
}

.index_page {
  background: #ffffff;
}

.index_page .demo {
  border-radius: 2px;
  position: relative;
}

@media screen and (max-width: 768px) {
  .index_page .demo {
    padding: 15px;
  }
}

.index_page .demo-title {
  margin-bottom: 40px;
  font-size: 3rem;
  line-height: 1.5;
  font-weight: bold;
  color: #2d3a58;
  display: block;
}

@media screen and (max-width: 992px) {
  .index_page .demo-title {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 768px) {
  .index_page .demo-title {
    font-size: 2.2rem;
  }
}

.index_page .demo-title .has-line {
  position: relative;
}

.index_page .demo-title .has-line::before {
  content: "";
  position: absolute;
  height: 0.2rem;
  top: -1rem;
  width: 103%;
  left: 0;
  background: #be346a;
  z-index: 0;
}

@media screen and (max-width: 768px) {
  .index_page .demo-title {
    font-size: 1.8rem;
  }
}

.index_page .demo-item {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 30px;
  border-radius: 10px;
}

.index_page .demo-item img {
  border-radius: 5px;
  border: solid 1px #dae6ff !important;
}

.index_page .demo-item .item-title {
  margin: 20px 0;
  font-size: 22px;
}

.index_page .item-img {
  opacity: 1;
}

.index_page .item-img img {
  width: 100%;
  box-shadow: 52px 52px 74px 0 rgba(115, 131, 169, 0.08);
  transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

.index_page .item-img img:hover {
  box-shadow: none;
}

.index_page .buy {
  max-width: 38rem;
  margin: 0 auto;
  overflow: hidden;
  padding: 2rem;
  position: relative;
  box-shadow: 0 6px 12px 1px #e5e5f2;
}

.index_page .buy .pricing {
  border-radius: 5px;
  padding: 2rem;
}

.index_page .buy .pricing-title {
  font-size: 2.5rem;
  font-weight: bold;
  position: relative;
  z-index: 1;
}

.index_page .buy .button {
  position: relative;
  z-index: 1;
}

.index_page .buy::before {
  content: url(../img/others/buy-shape2.svg);
  position: absolute;
  right: 0;
  bottom: 4rem;
  height: 4rem;
  width: 4rem;
  z-index: 0;
}

.index_page .buy::after {
  content: url(../img/others/buy-shape1.svg);
  position: absolute;
  left: 0;
  top: 0;
  height: 4rem;
  width: 4rem;
  z-index: 0;
}

.index_page .buy-list {
  z-index: 1;
}

.index_page .buy-list-item {
  display: flex;
  align-items: center;
}

.index_page .buy-list-item svg {
  background: #be346a;
  border-radius: 20rem;
  padding: 0.2rem;
  color: #fff;
  margin-right: 0.5rem;
}

.index_page .bottom0menu {
  padding: 20px 0;
}

@media screen and (max-width: 768px) {
  .index_page .bottom0menu {
    padding: 10px 0;
  }
}

.index_page .main_docs .docs1 {
  position: relative;
  top: 0%;
  left: 22.5%;
  -webkit-box-shadow: -90px 60px 130px rgba(0, 0, 0, 0.15);
  box-shadow: -90px 60px 130px rgba(0, 0, 0, 0.15);
  max-width: 20rem;
}

@media screen and (max-width: 991px) {
  .index_page .main_docs .docs1 {
    width: 70%;
    position: relative;
  }
}

.index_page .main_codes {
  background: #10154e;
  color: white;
  text-align: left;
  z-index: 0;
}

.index_page .main_codes::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/others/pattern-1.svg);
  background-size: contain;
  z-index: -1;
}

.index_page .main_codes h2 {
  color: white;
}

.index_page .main_codes .description {
  color: rgba(255, 255, 255, 0.664);
  margin-bottom: 30px;
  font-size: 18px;
}

.index_page .main_codes .images img {
  margin-right: 5px;
}

.index_page .main_codes .text-adaptive {
  color: rgba(255, 255, 255, 0.664);
  margin-bottom: 20px;
}

.index_page .main_codes .title {
  color: #ff2272;
  font-size: 18px;
}

.index_page .section-purchase {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
}

.index_page .purchase-title {
  text-align: center;
  background: #be346a;
  font-size: 5rem;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: calc(46px + 18 * ( (100vw - 576px) / 1024));
}

@media screen and (max-width: 576px) {
  .index_page .purchase-title {
    font-size: 46px;
  }
}

@media screen and (min-width: 1600px) {
  .index_page .purchase-title {
    font-size: 64px;
  }
}

.index_page .markerplace_slider {
  border-radius: 5px;
  background: #be346a;
  padding: 100px 0;
  position: relative;
  z-index: 0;
}

.index_page .markerplace_slider::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/others/pattern-1.svg);
  background-size: contain;
  z-index: -1;
}

.index_page .markerplace_slider .market_slick .slick-list {
  padding: 4rem 0 !important;
}

.index_page .markerplace_slider .market_slick .slick-prev {
  left: -4.5rem;
}

.index_page .markerplace_slider .market_slick .slick-next {
  right: -4.5rem;
}

.index_page .markerplace_slider .market_slick .item {
  transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  transform: scale(0.8);
  position: relative;
  opacity: 0.6;
}

.index_page .markerplace_slider .market_slick .item img {
  width: 100%;
  max-width: 40rem;
  border-radius: 5px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1), 0 -100px 0 rgba(0, 0, 0, 0) inset;
  position: relative;
  z-index: 0;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.index_page .markerplace_slider .market_slick .item img::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.308);
  background: rgba(0, 0, 0, 0.199);
}

@media screen and (max-width: 991px) {
  .index_page .markerplace_slider .market_slick .item img {
    max-width: 15rem;
  }
}

.index_page .markerplace_slider .market_slick .slick-center {
  transform: scale(1.3);
  opacity: 1;
  z-index: 9;
}

@media screen and (max-width: 991px) {
  .index_page .markerplace_slider .market_slick .slick-center {
    transform: scale(1);
  }
}

.index_page .markerplace_slider .market_slick .slick-center:hover {
  z-index: 9;
}

.index_page .blog_slick .item {
  padding: 20px 10px;
  opacity: 0.6;
  position: relative;
  z-index: 0;
  transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  transform: scale(0.9);
}

.index_page .blog_slick .item .item-title {
  text-align: center;
}

.index_page .blog_slick .item img {
  width: 100%;
  border: none;
}

.index_page .blog_slick .slick-center {
  opacity: 1;
  transform: scale(1.2);
  z-index: 1;
}

.index_page .slick-next {
  right: 25%;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E"), #10154e;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  position: absolute !important;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 52px 52px 74px 0 rgba(115, 131, 169, 0.08);
}

@media screen and (max-width: 991px) {
  .index_page .slick-next {
    display: none !important;
  }
}

.index_page .slick-prev {
  left: 25%;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E"), #10154e;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  position: absolute !important;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 52px 52px 74px 0 rgba(115, 131, 169, 0.08);
  opacity: 1 !important;
  z-index: 2;
}

@media screen and (max-width: 991px) {
  .index_page .slick-prev {
    display: none !important;
  }
}

.demos_body {
  background: white !important;
  box-shadow: 0 33px 88px 0 #1d0e6211;
  border-radius: 10px;
  margin-top: 100px;
  padding: 80px 40px 80px;
}

.item_preview {
  position: relative;
  margin-bottom: 30px;
  text-align: center;
  display: block;
}

.item_preview .img_cover {
  position: relative;
  border-radius: 8px;
  border: 1px solid rgba(215, 212, 221, 0.671);
  padding: 22px 23px;
  margin-bottom: 1.25rem;
  height: 350px;
  display: flex;
  justify-content: center;
  background: #eee6eb;
  transition: 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.item_preview .img_cover .img {
  position: relative;
  z-index: 0;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.item_preview .img_cover .img::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.308);
  background-color: #0903258c;
  border-radius: 5px;
  z-index: 2;
  transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
}

.item_preview .img_cover img {
  box-shadow: 0 33px 55px 0 #1d0e6226;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  display: block;
  transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

.item_preview .img_cover img:hover {
  transform: scale(1.05);
  opacity: 0.9;
}

.item_preview:hover h6 {
  color: #be346a;
  transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

.item_preview .body_preivew {
  text-align: center;
}

.item_preview .body_preivew span {
  display: block;
  font-size: 18px;
  color: #10154e;
  margin-bottom: 8px;
  font-weight: 500;
}

.item_preview .body_preivew h6 {
  font-size: 16px;
  font-weight: 400;
  color: #10154e;
  margin-bottom: 0;
  text-transform: capitalize;
}

.item_preview .body_preivew h6:hover {
  color: #be346a;
}

.item_preview .links_preview {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.2s ease-in-out;
  opacity: 0;
}

.item_preview .links_preview a {
  display: inline-block;
  border-radius: 8px;
  width: 120px;
  font-size: 14px;
  color: #fff;
  background-color: #111213;
  padding: 11px 13px;
  margin: 0 5px;
  transition: all 0.2s ease-in-out;
}

.item_preview .links_preview a:hover {
  color: #fff;
  background-color: #be346a;
}

.item_preview:hover .img_cover {
  background: transparent;
}

.item_preview:hover .img:before {
  opacity: 1;
}

.item_preview:hover .links_preview {
  opacity: 1;
}

.item_preview:hover .links_preview:hover {
  background: #10154e;
  color: #fff !important;
  outline: none;
  border: none;
}

.item_preview .latest_preview {
  position: absolute;
  border-radius: 5px;
  background-color: #f93542;
  color: #ffffff;
  font-size: 13px;
  top: 40px;
  left: 40px;
  padding: 7px 18px;
}

.upcoming_item .img_cover {
  padding: 0;
  border: 0;
}

.upcoming_item .txt_comming {
  display: inline-block;
  border-radius: 8px;
  background-color: rgba(190, 52, 106, 0.1);
  color: #be346a;
  padding: 0.6rem 1.5rem;
  font-size: 14px;
}

.roadmap-items-container {
  margin-top: 33px;
  margin-left: auto;
}

.roadmap-items-container * {
  color: #10154e !important;
}

.roadmap-items-container .badge-info {
  color: #1565d8 !important;
  background: rgba(21, 101, 216, 0.1);
}

.roadmap-items-container .badge-warning {
  color: #ff8625 !important;
  background: rgba(255, 134, 37, 0.1);
}

.roadmap-items-container .badge {
  margin-left: 10px;
  font-size: 12px;
  padding: 5px 8px;
  border-radius: 4px;
  text-transform: uppercase;
  line-height: 2;
}

.roadmap-items-container .roadmap-items {
  position: relative;
  text-align: left;
  width: 100%;
  margin: 0 auto;
}

.roadmap-items-container .roadmap-items::before {
  content: "";
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  height: 255px;
  background-image: linear-gradient(to bottom, #10154e, rgba(21, 27, 56, 0));
  z-index: 2;
}

.roadmap-items-container .roadmap-items .roadmap-empty {
  opacity: 0.5;
  padding: 18px 24px;
}

.roadmap-items-container .roadmap-items .roadmap-item {
  position: relative;
  border-radius: 8px;
  background-color: #ffffff;
  max-width: 28rem;
  display: block;
  padding: 24px;
  color: #183b56;
}

.roadmap-items-container .roadmap-items .roadmap-item .date {
  color: #5a7184;
  position: absolute;
  top: 30px;
  right: 24px;
}

.roadmap-items-container .roadmap-items .roadmap-item h5 {
  margin: 0 0 16px;
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 991px) {
  .roadmap-items-container .roadmap-items .roadmap-item h5 {
    flex-direction: column;
  }
  .roadmap-items-container .roadmap-items .roadmap-item h5 span {
    display: block;
    margin-top: 10px;
    padding: 10px;
  }
}

.roadmap-items-container .roadmap-items .roadmap-item ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.roadmap-items-container .roadmap-items .roadmap-item ul li {
  line-height: 24px;
  margin: 3px 0;
}

.roadmap-items-container .roadmap-items .roadmap-item ul li::before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z' fill='rgba(47,204,113,1)'/%3E%3C/svg%3E");
  width: 24px;
  height: 24px;
  margin: 0 12px 0 0;
  display: inline-flex;
  background-size: cover;
  vertical-align: top;
}

.roadmap-items-container .roadmap-items .roadmap-item + .roadmap-item {
  margin-top: 24px;
}

.roadmap-items-container .roadmap-items .roadmap-item.roadmap-old {
  opacity: 0.5;
}

.account_page .has_cover .col-md-6 {
  padding: 0;
  margin: 0;
}

.account_page .has_cover .row {
  padding: 0;
  margin: 0;
}

.account_page .has_cover .left_part {
  padding: 30px 22%;
}

@media screen and (min-width: 1025px) {
  .account_page .has_cover .left_part {
    padding: 30px 22%;
  }
}

@media screen and (max-width: 768px) {
  .account_page .has_cover .left_part {
    padding: 30px 50px;
  }
}

@media screen and (max-width: 991px) {
  .account_page .has_cover .left_part {
    padding: 30px 50px;
  }
}

@media screen and (max-width: 768px) {
  .account_page .has_cover .left_part {
    padding: 30px 15px;
  }
}

.account_page .has_cover .left_part .content {
  margin-top: 70%;
  height: 100%;
}

.account_page .has_cover .right_part {
  height: 100vh;
}

@media screen and (max-width: 768px) {
  .account_page .has_cover .right_part {
    display: none;
  }
}

.account_page .has_cover .right_part .right_part_wrap {
  position: fixed;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 90px;
  background: #be346a;
  z-index: 0;
}

.account_page .has_cover .right_part .right_part_wrap::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/others/lines.svg);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  opacity: .1;
}

.account_page.is_basic {
  background-color: rgba(243, 248, 255, 0.3);
}

.account_page.is_basic .sign-form {
  margin-top: 100px;
  background: white;
  border-radius: 5px;
  padding: 60px 30px;
  box-shadow: 0 33px 88px 0 #1d0e6211;
  margin-bottom: 90px;
}

.account_page .row_sign {
  padding: 0 !important;
  margin: 0 !important;
}

.account_page .back_to {
  position: absolute;
  left: 40px;
  top: 20px;
  padding: 10px 20px;
  border-radius: 50px;
  background: #f3f8ff;
  font-size: 14px;
  color: #10154e;
}

.account_page .logo_sign {
  margin-bottom: 60px;
}

.account_page .logo_sign img {
  height: 40px;
}

.account_page .social_sign {
  display: flex;
  justify-content: center;
}

.account_page .social_sign span {
  color: white;
}

.account_page .google_sign {
  padding: 15px 20px;
  background: #DB4437;
  border-radius: 5px;
  color: white;
  width: max-content;
  transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
}

.account_page .google_sign:hover {
  transform: translateY(-5px);
  box-shadow: 0 40px 40px -20px rgba(23, 29, 48, 0.1);
  opacity: .9;
}

@media screen and (max-width: 576px) {
  .account_page .google_sign {
    margin-bottom: 20px;
    width: auto;
  }
}

.account_page .facebook_sign {
  padding: 15px 20px;
  background: #4267B2;
  border-radius: 5px;
  color: white;
  width: max-content;
  transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
}

.account_page .facebook_sign:hover {
  transform: translateY(-5px);
  box-shadow: 0 40px 40px -20px rgba(23, 29, 48, 0.1);
  opacity: .9;
}

@media screen and (max-width: 576px) {
  .account_page .facebook_sign {
    width: auto;
  }
}

.account_page .or_line {
  position: relative;
  transform: translateY(44px);
  width: 100%;
  height: 2px;
  background: #eaeaee;
  top: 50%;
}

.account_page .or {
  text-align: center;
  padding: 0 10px;
  margin-top: 30px;
  margin-bottom: 30px;
  position: relative;
  background: white;
  width: max-content;
  position: relative;
}

.account_page .or::before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background: #eaeaee;
  top: 50%;
  z-index: -1;
}

.account_page .head {
  margin-bottom: 30px;
}

.account_page .head p {
  margin: 0;
}

.account_page .sign_title {
  text-align: center;
  margin-bottom: 10px;
  font-size: calc(36px + 4 * ( (100vw - 576px) / 1024));
  line-height: 1.1;
}

@media screen and (max-width: 576px) {
  .account_page .sign_title {
    font-size: 36px;
  }
}

@media screen and (min-width: 1600px) {
  .account_page .sign_title {
    font-size: 40px;
  }
}

.account_page .quote {
  mix-blend-mode: overlay;
}

.account_page .text-hkgrotesk {
  font-size: 30px;
}

.modal-sign {
  overflow: hidden;
  margin: 2rem 0 0;
}

.modal-sign .close {
  margin-bottom: 20px;
}

.modal-sign .row {
  padding: 0;
  width: 100%;
}

.modal-sign .col {
  padding: 0;
}

.modal-sign .sign-form {
  margin: 0;
  padding: 0;
}

.modal-sign .modal-body {
  padding: 40px;
}

.modal-sign .cover_sign {
  object-fit: cover;
  height: 100%;
  width: inherit;
}

.modal-sign .modal-content {
  overflow: hidden;
}

.modal-sign .modal-content .row {
  padding: 0 !important;
  margin: 0 !important;
}

.modal-sign .modal-content .row .col-lg-6 {
  padding: 0;
}

.author_setting {
  background-color: rgba(243, 248, 255, 0.3);
}

.author_setting_reset {
  height: 60px;
}

.author_setting_wrap {
  margin-bottom: 100px;
  margin-top: -60px;
}

.author_setting_wrap .author_sidebar {
  padding: 30px;
  background: white;
  border-radius: 5px;
  box-shadow: 0 40px 40px -20px rgba(23, 29, 48, 0.1);
}

.author_setting_wrap .author_sidebar p {
  padding: 0;
}

.author_setting_wrap .author_content {
  padding: 30px;
  background: white;
  border-radius: 5px;
  box-shadow: 0 40px 40px -20px rgba(23, 29, 48, 0.1);
}

.author_setting_wrap .sign_title {
  font-size: 25px;
  margin-bottom: 30px;
}

.sidebar_account {
  padding: 30px;
  box-shadow: 52px 52px 74px 0 rgba(115, 131, 169, 0.08);
  border-radius: 5px;
  margin-bottom: 30px;
  background: white;
  position: sticky;
  top: 15px;
  color: rgba(255, 255, 255, 0.89);
  z-index: 0;
}

.sidebar_account a {
  margin-bottom: 5px !important;
  display: block;
  color: #10154e !important;
}

.sidebar_account a:hover {
  padding-left: 5px;
}

.sidebar_account .title {
  margin-bottom: 10px;
}

.sidebar_account .is_active {
  font-weight: bold;
  color: #be346a !important;
  position: relative;
}

.sidebar_account .is_active::before {
  content: "";
  position: absolute;
  content: "";
  position: absolute;
  left: -30px;
  height: 72%;
  top: 2px;
  width: 10px;
  background: #665fff;
  mix-blend-mode: screen;
}

.block_account {
  padding: 30px;
  box-shadow: 0 33px 88px 0 #1d0e6211;
  border-radius: 5px;
  margin-bottom: 30px;
  background: white;
  position: relative;
}

.block_account .title_top {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: solid 1px #ebeaef;
  font-size: calc(24px + 2 * ( (100vw - 576px) / 1024));
}

@media screen and (max-width: 576px) {
  .block_account .title_top {
    font-size: 24px;
  }
}

@media screen and (min-width: 1600px) {
  .block_account .title_top {
    font-size: 26px;
  }
}

.block_account .block_item:not(:last-child) {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: solid 1px #ebeaef;
}

.block_account .Heading {
  font-size: 18px;
  color: #10154e;
}

.block_account small {
  font-size: 15px;
  color: #515090;
}

/*-----------------------------
    section__news
  -----------------------------*/
.section__news {
  background: linear-gradient(#fff, #f9f9f9);
  width: 93%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 20px;
}

@media (max-width: 991px) {
  .section__news {
    margin-top: 3.75rem;
    width: 100%;
  }
}

.section__news .block__news_ch .item:first-of-type {
  margin-top: -3.75rem;
}

.section__news .block__news_ch .item:last-of-type {
  margin-top: -6.875rem;
}

@media (max-width: 767px) {
  .section__news .block__news_ch .item {
    margin-top: inherit !important;
    margin-bottom: 1.875rem;
  }
}

.section__news .block__news_ch .item__news {
  padding: 1.375rem;
  border-radius: 12px;
  background-color: #fff;
  -webkit-transition: 0.4s !important;
  -moz-transition: 0.4s !important;
  -o-transition: 0.4s !important;
  -ms-transition: 0.4s !important;
  transition: 0.4s !important;
}

.section__news .block__news_ch .item__news .head_img {
  margin-bottom: 0.938rem;
}

.section__news .block__news_ch .item__news .head_img img {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  width: 100%;
  height: 140px;
  object-fit: cover;
}

@media (max-width: 767px) {
  .section__news .block__news_ch .item__news .head_img img {
    height: 180px;
  }
}

.section__news .block__news_ch .item__news .body__content span {
  display: block;
  margin-bottom: 0.625rem;
  color: #fd6b3b;
  font-size: 13px;
}

.section__news .block__news_ch .item__news .body__content h3 {
  margin-bottom: 0.625rem;
  font-size: 20px;
  font-weight: 500;
  color: #10154e;
}

.section__news .block__news_ch .item__news .body__content p {
  margin-bottom: 0;
  font-size: 15px;
  color: #6c7a87;
}

.section__news .block__news_ch .item__news .footer__content {
  margin-top: 1.875rem;
}

.section__news .block__news_ch .item__news .footer__content .link__go {
  display: flex;
  justify-content: flex-end;
  color: #7bd88a;
  font-size: 14px;
  -webkit-transition: 0.3s !important;
  -moz-transition: 0.3s !important;
  -o-transition: 0.3s !important;
  -ms-transition: 0.3s !important;
  transition: 0.3s !important;
}

.section__news .block__news_ch .item__news .footer__content .link__go:hover {
  color: #10154e;
}

/*-----------------------------
    news__workspace
  -----------------------------*/
.news__workspace .title_sections {
  margin-bottom: 3.75rem;
}

.news__workspace .fa__news {
  display: block;
}

.news__workspace .fa__news .img__cover {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 1.25rem;
}

.news__workspace .fa__news .img__cover img {
  width: 100%;
  height: 280px;
  object-fit: cover;
  border-radius: 8px;
  -webkit-transform: scale3d(1, 1, 1) !important;
  -moz-transform: scale3d(1, 1, 1) !important;
  -o-transform: scale3d(1, 1, 1) !important;
  -ms-transform: scale3d(1, 1, 1) !important;
  transform: scale3d(1, 1, 1) !important;
  -webkit-transition: 0.4s !important;
  -moz-transition: 0.4s !important;
  -o-transition: 0.4s !important;
  -ms-transition: 0.4s !important;
  transition: 0.4s !important;
}

.news__workspace .fa__news .img__cover.sm_height img {
  height: 180px;
}

.news__workspace .fa__news .body__content span {
  font-size: 15px;
  display: block;
  margin-bottom: 0.625rem;
  font-weight: 400;
}

.news__workspace .fa__news .body__content h3 {
  font-size: 20px;
  margin-bottom: 0.625rem;
  font-weight: 500;
  color: #10154e;
}

.news__workspace .fa__news .body__content p {
  font-size: 15px;
  color: #6c7a87;
  margin-bottom: 0;
  font-weight: 400;
}

.news__workspace .fa__news:hover .img__cover img {
  -webkit-transform: scale3d(1.1, 1.1, 1) !important;
  -moz-transform: scale3d(1.1, 1.1, 1) !important;
  -o-transform: scale3d(1.1, 1.1, 1) !important;
  -ms-transform: scale3d(1.1, 1.1, 1) !important;
  transform: scale3d(1.1, 1.1, 1) !important;
}

.news__workspace .block_end .fa__news:first-child {
  margin-top: -3.125rem;
}

@media (max-width: 991px) {
  .news__workspace .block_end .fa__news:first-child {
    margin-top: 0;
  }
}

/*-----------------------------
    blog_slider
  -----------------------------*/
.blog_slider .swiper-button-next,
.blog_slider .swiper-button-prev {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: rgba(16, 21, 78, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 22px;
  -webkit-transition: 0.3s !important;
  -moz-transition: 0.3s !important;
  -o-transition: 0.3s !important;
  -ms-transition: 0.3s !important;
  transition: 0.3s !important;
}

.blog_slider .swiper-button-next .tio,
.blog_slider .swiper-button-prev .tio {
  font-size: 18px;
  color: #10154e;
  -webkit-transition: 0.3s !important;
  -moz-transition: 0.3s !important;
  -o-transition: 0.3s !important;
  -ms-transition: 0.3s !important;
  transition: 0.3s !important;
}

.blog_slider .swiper-button-next::after,
.blog_slider .swiper-button-prev::after {
  display: none;
}

.blog_slider .swiper-button-next:hover,
.blog_slider .swiper-button-prev:hover {
  background-color: #10154e;
}

.blog_slider .swiper-button-next:hover .tio,
.blog_slider .swiper-button-prev:hover .tio {
  color: #fff;
}

@media (max-width: 767px) {
  .blog_slider .swiper-button-next,
  .blog_slider .swiper-button-prev {
    display: none;
  }
}

.blog_slider .swiper-button-next {
  right: 10px;
  left: auto;
}

.blog_slider .swiper-button-prev {
  right: 4.5rem;
  left: inherit;
}

.blog_slider .title_sections_inner {
  border-bottom: 1px solid #edeef1;
  position: relative;
}

.blog_slider .title_sections_inner h2 {
  margin-bottom: 1.875rem;
}

.blog_slider .title_sections_inner::after {
  content: "";
  width: 50px;
  height: 1px;
  position: absolute;
  left: 0;
  background-color: #10154e;
}

.grid_blog_avatar a {
  display: block;
}

.grid_blog_avatar .cover_blog {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  border-radius: 8px;
}

.grid_blog_avatar .cover_blog img {
  width: 100%;
  border-radius: 8px;
  height: 220px;
  object-fit: cover;
  -webkit-transition: 0.4s cubic-bezier(0.65, 0.05, 0.36, 1) !important;
  -moz-transition: 0.4s cubic-bezier(0.65, 0.05, 0.36, 1) !important;
  -o-transition: 0.4s cubic-bezier(0.65, 0.05, 0.36, 1) !important;
  -ms-transition: 0.4s cubic-bezier(0.65, 0.05, 0.36, 1) !important;
  transition: 0.4s cubic-bezier(0.65, 0.05, 0.36, 1) !important;
  -webkit-transform: scale3d(1, 1, 1) !important;
  -moz-transform: scale3d(1, 1, 1) !important;
  -o-transform: scale3d(1, 1, 1) !important;
  -ms-transform: scale3d(1, 1, 1) !important;
  transform: scale3d(1, 1, 1) !important;
}

.grid_blog_avatar .body_blog .person {
  margin-bottom: 20px;
}

.grid_blog_avatar .body_blog .person img {
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 12px;
  margin-bottom: 5px !important;
}

.grid_blog_avatar .body_blog .person .txt h3 {
  color: #10154e;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 3px;
}

.grid_blog_avatar .body_blog .person .txt time {
  font-size: 12px;
  color: #6c7a87;
  display: block;
}

.grid_blog_avatar .body_blog .title_blog {
  color: #10154e;
  font-weight: 500;
  font-size: 20px;
  -webkit-transition: 0.3s !important;
  -moz-transition: 0.3s !important;
  -o-transition: 0.3s !important;
  -ms-transition: 0.3s !important;
  transition: 0.3s !important;
}

.grid_blog_avatar .body_blog .short_desc {
  color: #6c7a87;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
  display: -webkit-box;
  height: 43px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.grid_blog_avatar .body_blog .link_blog {
  display: block;
}

.grid_blog_avatar .body_blog .link_blog:hover .title_blog {
  color: #be346a;
}

.grid_blog_avatar:hover .cover_blog img {
  -webkit-transform: scale3d(1.12, 1.12, 1) !important;
  -moz-transform: scale3d(1.12, 1.12, 1) !important;
  -o-transform: scale3d(1.12, 1.12, 1) !important;
  -ms-transform: scale3d(1.12, 1.12, 1) !important;
  transform: scale3d(1.12, 1.12, 1) !important;
}

.grid_blog_avatar.list_style {
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .grid_blog_avatar.list_style .body_blog {
    margin-top: 20px;
  }
}

.grid_blog_avatar.list_style .cover_blog {
  margin-bottom: 0;
}

.article_page .user {
  margin-right: 12px;
  width: 40px;
  height: 40px;
  border-radius: 100px;
}

.article_page .blog-author-detail {
  margin-bottom: 40px;
}

.article_page .author-meta p {
  margin: 0;
}

.article_page .article_wrap .tag_cloud {
  margin-bottom: -10px;
}

.article_page .article_wrap .tag_cloud .p_tags {
  display: flex;
  width: min-content;
  line-height: 2;
  display: block;
  margin-right: 20px;
}

.article_page .article_wrap .tag_cloud a {
  padding: 2px 10px;
  background-color: #f3f8ff;
  margin-right: 5px;
  margin-bottom: 10px;
  border: solid 1px transparent;
  color: #10154e;
}

.article_page .article_wrap .tag_cloud a:hover {
  background-color: white;
  color: #be346a;
  box-shadow: 52px 52px 74px 0 rgba(115, 131, 169, 0.08);
  border: solid 1px #eee;
}

.article_page .blog-share-container {
  position: sticky;
  top: 15px;
  left: 0;
}

.article_page .blog-share {
  box-shadow: 0 33px 88px 0 #1d0e6211;
  padding: 20px;
  background: #be346a;
  width: fit-content;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 0;
}

.article_page .blog-share::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/others/lines.svg);
  background-size: cover;
  mix-blend-mode: screen;
  z-index: -1;
}

.article_page .blog-share .share {
  text-align: center;
  margin-bottom: 20px;
  color: white;
}

.article_page .blog-share ul {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.article_page .blog-share ul li {
  width: fit-content;
  margin-bottom: 5px;
}

.article_page .blog-share ul li a {
  width: fit-content;
  color: white;
}

.article_page .blog-share ul li a:hover {
  color: #10154e;
}

.article_page .blog-share a {
  color: #10154e;
}

.article_page .content {
  background: white;
  margin-top: 0;
  box-shadow: 0 33px 88px 0 #1d0e6211;
  padding: 30px;
  margin-bottom: 60px;
}

.article_page .content .inner h2,
.article_page .content .inner h3,
.article_page .content .inner h4,
.article_page .content .inner h5,
.article_page .content .inner h6,
.article_page .content .inner p {
  margin-top: 25px;
  margin-bottom: 25px;
}

.article_page .content img {
  margin-bottom: 20px;
  box-shadow: 0 40px 40px -20px rgba(23, 29, 48, 0.1);
}

.comments_article {
  max-width: 1000px;
  background-color: #faf4f6;
  padding: 30px 30px 10px;
  margin-bottom: 60px;
  border-radius: 5px;
}

.comments_article .avatar {
  border-radius: 100%;
}

.comments_article .comment {
  margin-bottom: 20px;
}

.comments_article .comment-content {
  background: #fff;
  border-radius: 0 15px 15px 15px;
  padding: 20px;
}

.comments_article .is_child {
  padding-left: 60px;
  border-left: solid 2px #eae9f3;
}

.comments_article .is_child .comment-content {
  background: rgba(255, 255, 255, 0.623);
}

@media screen and (max-width: 991px) {
  .comments_article .is_child {
    padding-left: 20px;
  }
}

.reply {
  max-width: 1000px;
  padding: 30px;
  border-right: 5px;
  background: #10154e;
  border-radius: 5px;
}

/*-----------------------------
    content-Sblog
  -----------------------------*/
.content-Sblog {
  margin-top: 50px;
}

.content-Sblog .fixSide_scroll .item {
  margin-bottom: 40px;
}

.content-Sblog .fixSide_scroll .item .profile_user {
  text-align: center;
}

@media (max-width: 991px) {
  .content-Sblog .fixSide_scroll .item .profile_user {
    text-align: left;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}

.content-Sblog .fixSide_scroll .item .profile_user img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

@media (max-width: 991px) {
  .content-Sblog .fixSide_scroll .item .profile_user img {
    margin-right: 15px;
  }
}

.content-Sblog .fixSide_scroll .item .profile_user .txt {
  margin-top: 15px;
}

.content-Sblog .fixSide_scroll .item .profile_user .txt h4 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
}

.content-Sblog .fixSide_scroll .item .profile_user .txt time {
  display: block;
  font-size: 12px;
  color: #6c7a87;
}

.content-Sblog .fixSide_scroll .item .profile_user .btn_profile {
  padding: 9px 27px;
  font-size: 14px;
  margin-top: 15px;
}

@media (max-width: 991px) {
  .content-Sblog .fixSide_scroll .item .profile_user .btn_profile {
    margin-left: 15px;
  }
}

.content-Sblog .fixSide_scroll .share_socail {
  text-align: center;
  display: grid;
  justify-content: center;
  align-items: center;
}

@media (max-width: 991px) {
  .content-Sblog .fixSide_scroll .share_socail {
    text-align: left;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-around;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}

.content-Sblog .fixSide_scroll .share_socail .title {
  font-size: 15px;
  color: #6c7a87;
  margin-bottom: 20px;
}

@media (max-width: 991px) {
  .content-Sblog .fixSide_scroll .share_socail .title {
    margin-bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.content-Sblog .fixSide_scroll .share_socail .btn {
  margin-bottom: 5px;
}

.content-Sblog .fixSide_scroll .share_socail .btn.icon {
  background-color: #fff;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-Sblog .fixSide_scroll .share_socail .btn.icon .tio {
  font-size: 18px;
}

.content-Sblog .fixSide_scroll .share_socail .btn:hover.icon {
  background-color: #f7f8fa;
}

.content-Sblog .body_content p,
.content-Sblog .body_content li {
  font-size: 15px;
  color: #434c55;
}

.content-Sblog .body_content a {
  color: #be346a;
}

.content-Sblog .body_content img {
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 10px;
  width: 100%;
}

.content-Sblog .body_content mark {
  background-color: #17e79b;
  color: #fff;
}

.content-Sblog .body_content .img_md {
  height: 350px;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.content-Sblog .body_content .cover_video {
  position: relative;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.content-Sblog .body_content .cover_video img {
  width: 100%;
  height: 450px;
}

.content-Sblog .body_content .cover_video .icon_played {
  position: absolute;
}

.content-Sblog .body_content .cover_video .btn_video .play_video::after {
  display: none;
}

.content-Sblog .body_content .txt_quotation {
  border-left: 6px solid #7bd88a;
  padding: 15px 25px;
  font-size: 24px;
  color: #10154e;
  font-weight: 500;
}

/*-----------------------------
    simple_blog_dev
  -----------------------------*/
.simple_blog_dev .item_news {
  margin-bottom: 50px;
}

@media (max-width: 991px) {
  .simple_blog_dev .item_news {
    margin-bottom: 30px;
  }
}

.simple_blog_dev .item_news .link_post {
  display: block;
  font-size: 25px;
  font-weight: 500;
  color: #10154e;
  margin-bottom: 5px;
  -webkit-transition: 0.3s !important;
  -moz-transition: 0.3s !important;
  -o-transition: 0.3s !important;
  -ms-transition: 0.3s !important;
  transition: 0.3s !important;
}

.simple_blog_dev .item_news .link_post:hover {
  color: #be346a;
}

.simple_blog_dev .item_news p {
  font-size: 14px;
  color: #6c7a87;
  font-weight: 400;
  margin-bottom: 0;
}

/*-----------------------------
    box_news_gray
  -----------------------------*/
.box_news_gray .grid_blog_avatar {
  padding: 30px;
  border-radius: 8px;
  margin-bottom: 30px;
}

.box_news_gray .grid_blog_avatar .link_blog {
  margin-bottom: 30px;
}

.box_news_gray .grid_blog_avatar .link_blog:hover .title_blog {
  color: #f93542;
}

/*-----------------------------
    blog_masonry
  -----------------------------*/
.blog_masonry .card {
  border-radius: 8px;
  border: 0;
  margin-bottom: 30px;
}

.blog_masonry .card:hover .cover_link::before {
  opacity: 1;
}

.blog_masonry .card:hover .cover_link .main_img {
  -webkit-transform: scale3d(1.1, 1.1, 1) !important;
  -moz-transform: scale3d(1.1, 1.1, 1) !important;
  -o-transform: scale3d(1.1, 1.1, 1) !important;
  -ms-transform: scale3d(1.1, 1.1, 1) !important;
  transform: scale3d(1.1, 1.1, 1) !important;
}

.blog_masonry .card:hover .cover_link .auther_post {
  opacity: 1;
  top: 30px;
}

.blog_masonry .card:hover .card-title {
  color: #be346a !important;
}

.blog_masonry .card .link_poet {
  display: block;
  text-decoration: none;
}

.blog_masonry .card .cover_link {
  position: relative;
  overflow: hidden;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  z-index: 0;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.blog_masonry .card .cover_link .auther_post {
  position: absolute;
  top: 20px;
  left: 30px;
  z-index: 2;
  opacity: 0;
  -webkit-transition: 0.3s cubic-bezier(0.65, 0.05, 0.36, 1) !important;
  -moz-transition: 0.3s cubic-bezier(0.65, 0.05, 0.36, 1) !important;
  -o-transition: 0.3s cubic-bezier(0.65, 0.05, 0.36, 1) !important;
  -ms-transition: 0.3s cubic-bezier(0.65, 0.05, 0.36, 1) !important;
  transition: 0.3s cubic-bezier(0.65, 0.05, 0.36, 1) !important;
}

.blog_masonry .card .cover_link .auther_post img {
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 12px;
}

.blog_masonry .card .cover_link .auther_post .txt h4 {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
}

.blog_masonry .card .cover_link .auther_post .txt p {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
}

.blog_masonry .card .cover_link .main_img {
  object-fit: cover;
  width: 100%;
  -webkit-transition: 0.4s cubic-bezier(0.65, 0.05, 0.36, 1) !important;
  -moz-transition: 0.4s cubic-bezier(0.65, 0.05, 0.36, 1) !important;
  -o-transition: 0.4s cubic-bezier(0.65, 0.05, 0.36, 1) !important;
  -ms-transition: 0.4s cubic-bezier(0.65, 0.05, 0.36, 1) !important;
  transition: 0.4s cubic-bezier(0.65, 0.05, 0.36, 1) !important;
  -webkit-transform: scale3d(1, 1, 1) !important;
  -moz-transform: scale3d(1, 1, 1) !important;
  -o-transform: scale3d(1, 1, 1) !important;
  -ms-transform: scale3d(1, 1, 1) !important;
  transform: scale3d(1, 1, 1) !important;
}

.blog_masonry .card .cover_link::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.308);
  z-index: 1;
  opacity: 0;
}

.blog_masonry .card .cover_link:hover {
  z-index: 0;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.blog_masonry .card .cover_link:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.308);
  background: rgba(5, 6, 51, 0.479);
  z-index: 1;
  opacity: 1;
}

.blog_masonry .card .card-body {
  padding: 30px;
  background-color: #fff;
  box-shadow: 0 33px 88px 0 #1d0e6211;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.blog_masonry .card .card-body .about_post {
  margin-bottom: 12px;
}

.blog_masonry .card .card-body .about_post .c_ategory a {
  color: #10154e;
  display: inline-block;
  font-size: 14px;
}

.blog_masonry .card .card-body .about_post .c_ategory a:hover {
  color: #be346a;
}

.blog_masonry .card .card-body .about_post .c_ategory a::before {
  content: ", ";
}

.blog_masonry .card .card-body .about_post .c_ategory a:first-child::before {
  display: none;
}

.blog_masonry .card .card-body .about_post .dot {
  width: 5px;
  height: 5px;
  border-radius: 30px;
  background-color: #e0e1e4;
  margin: 0 8px;
  display: inline-block;
}

.blog_masonry .card .card-body .about_post time {
  display: inline-block;
  font-size: 13px;
  color: #6c7a87;
}

.blog_masonry .card .card-body .card-title {
  color: #10154e;
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 12px;
  -webkit-transition: 0.3s !important;
  -moz-transition: 0.3s !important;
  -o-transition: 0.3s !important;
  -ms-transition: 0.3s !important;
  transition: 0.3s !important;
}

.blog_masonry .card .card-body .card-text {
  color: #6c7a87;
  font-size: 15px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.blog_masonry.two_column .card-columns {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  -webkit-column-gap: 1.875rem;
  -moz-column-gap: 1.875rem;
  column-gap: 1.875rem;
  orphans: 1;
  widows: 1;
  overflow: visible;
}

.blog_masonry.three_column .card-body .card-title {
  font-size: 20px;
}

.blog_masonry.height_fixed .link_poet .cover_link .main_img {
  height: 250px;
}

@media (max-width: 991px) {
  .blog_masonry .card-columns {
    -webkit-column-count: 2 !important;
    -moz-column-count: 2 !important;
    -o-column-count: 2 !important;
    -ms-column-count: 2 !important;
    column-count: 2 !important;
  }
}

@media (max-width: 767px) {
  .blog_masonry .card-columns {
    -webkit-column-count: 1 !important;
    -moz-column-count: 1 !important;
    -o-column-count: 1 !important;
    -ms-column-count: 1 !important;
    column-count: 1 !important;
  }
}

.section_tag_auther {
  margin-top: 50px;
}

.section_tag_auther .box_tags_psot h4 {
  font-size: 14px;
  color: #10154e;
  margin-bottom: 10px;
}

.section_tag_auther .box_tags_psot a {
  background-color: #f7f8fa;
  border-radius: 8px;
  font-size: 13px;
  color: #10154e;
  padding: 10px 20px;
  margin-right: 8px;
}

.section_tag_auther .block_auther_post {
  background-color: #f7f8fa;
  border-radius: 8px;
  padding: 35px;
}

.section_tag_auther .block_auther_post .img_person {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.section_tag_auther .block_auther_post .txt h4 {
  font-size: 15px;
  font-weight: 500;
  color: #10154e;
  margin-bottom: 5px;
}

.section_tag_auther .block_auther_post .txt p {
  margin-bottom: 0;
  font-size: 14px;
  color: #6c7a87;
}

/*-----------------------------
    sec_comments
  -----------------------------*/
.sec_comments .title_commnt h2 {
  font-size: 20px;
  color: #10154e;
  font-weight: 500;
}

.sec_comments .body_commnt .item_commnt {
  margin-top: 40px;
}

.sec_comments .body_commnt .item_commnt .at_img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 20px;
}

.sec_comments .body_commnt .item_commnt .txt h3 {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 3px;
}

.sec_comments .body_commnt .item_commnt .txt .date {
  display: block;
  font-size: 12px;
  color: #6c7a87;
}

.sec_comments .body_commnt .item_commnt .txt .content {
  margin-top: 15px;
  color: #10154e;
  font-size: 14px;
  margin-bottom: 10px;
}

.sec_comments .body_commnt .item_commnt .txt .btn_repy {
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  color: #10154e;
}

.sec_comments .body_commnt .item_commnt .txt .btn_repy:hover {
  color: #be346a;
}

.sec_comments .form_comment .title_sections {
  margin-bottom: 40px;
}

.sec_comments .form_comment .title_sections h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.blog.has_style1 {
  background: white;
  margin: 0 0 60px;
  overflow: hidden;
  border-radius: 0px;
}

.blog.has_style1 .row {
  padding: 0;
  margin: 0;
}

.blog.has_style1 .col-row {
  padding: 0;
  margin: 0;
}

.blog.has_style_grid {
  background: white;
  margin: 0 0 30px;
  overflow: hidden;
  box-shadow: 52px 52px 74px 0 rgba(115, 131, 169, 0.08);
  border-radius: 10px;
  border: 1px solid #ededed;
}

.blog.has_style_grid .blog-excerpt {
  font-size: 15px;
  margin-bottom: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.blog.has_style_grid .blog-img img {
  width: 100%;
  object-fit: cover;
  height: 14rem;
}

.blog.has_style_grid .blog-title {
  font-size: 24px;
}

.blog.has_style_grid .row {
  padding: 0;
  margin: 0;
}

.blog.has_style_grid .col-row {
  padding: 0;
  margin: 0;
}

.blog .meta.absolute {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 10;
  background-color: white;
  border-radius: 25px;
  box-shadow: 52px 52px 74px 0 rgba(115, 131, 169, 0.08);
  display: flex;
  color: #10154e;
  padding: 6px 12px;
}

.blog .meta.absolute .meta-info {
  color: #10154e;
  font-size: 13px;
  margin: 0 3px;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
}

.blog .meta.absolute .meta-info svg {
  width: 15px;
  height: 15px;
  fill: #2e3158;
  vertical-align: middle;
  margin-top: -3px;
  opacity: 0.5;
}

.blog .meta.absolute .min-read {
  display: inline-block;
  cursor: default;
}

.blog .meta.absolute .min-read span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: #fff;
  background-color: #7169fe;
  text-align: center;
  line-height: 21px;
  font-size: 13px;
  vertical-align: middle;
  margin-top: -4px;
  overflow: hidden;
}

.blog .meta.absolute .views-counter {
  cursor: default;
}

.blog .blog-title {
  margin: 0 0 20px;
  color: #183b56;
  font-size: calc(24px + 16 * ( (100vw - 576px) / 1024));
  line-height: 1.33;
  max-height: 2.66em;
  letter-spacing: 0.2px;
  overflow: hidden;
}

@media screen and (max-width: 576px) {
  .blog .blog-title {
    font-size: 24px;
  }
}

@media screen and (min-width: 1600px) {
  .blog .blog-title {
    font-size: 40px;
  }
}

.blog .blog-title a {
  color: #10154e;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.blog .blog-title a:hover {
  color: #be346a;
}

.blog .blog-excerpt {
  font-size: 18px;
  line-height: 1.78;
  color: #5a7184;
  margin: 0 0 20px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.blog .blog-img {
  position: relative;
}

.blog .blog-img img {
  width: 100%;
  object-fit: cover;
}

.blog .blog-author-detail {
  margin-top: 20px;
}

.blog .blog-author-detail .c-customer-review-meta {
  margin-top: 0;
}

.blog .blog-wrap {
  margin: 30px;
}

.blog .name {
  padding: 0;
}

.blog .author-post {
  text-transform: uppercase;
  color: #7a7d97;
  font-size: 13px;
  margin: 0;
}

.blog .user {
  margin-right: 12px;
  width: 40px;
  height: 40px;
  border-radius: 100px;
}

.blog .post_wrap {
  position: relative;
}

.about_images img {
  box-shadow: 0 33px 88px 0 #1d0e6211;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 14px;
}

.product-search-form {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 34px 15px -26px #24123e;
  margin-top: 30px;
}

.product-search-form .search-btn {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  line-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  border-radius: 0;
  border: none;
  color: #fff;
  font-size: 20px;
}

.product-search-form .search-btn svg {
  margin-right: 10px;
  fill: #fff !important;
}

.marketplace_page .section-title {
  font-size: calc(36px + 4 * ( (100vw - 576px) / 1024));
  line-height: 1.1;
}

@media screen and (max-width: 576px) {
  .marketplace_page .section-title {
    font-size: 36px;
  }
}

@media screen and (min-width: 1600px) {
  .marketplace_page .section-title {
    font-size: 40px;
  }
}

.marketplace_page .products_small {
  margin-bottom: -30px;
}

.marketplace_page .products_small .product-thumb {
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 33px 88px 0 #1d0e6211;
  position: relative;
  margin-bottom: 30px;
}

.marketplace_page .products_small .product-thumb img {
  width: 100%;
}

.marketplace_page .products_small .product-thumb .overlay_content {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: rgba(16, 21, 78, 0.3);
  opacity: 0;
  transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

.marketplace_page .products_small .product-thumb .overlay_content .plus {
  background-color: white;
  position: absolute;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -50%);
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  transition: 0.35s ease-in-out;
  cursor: pointer;
}

.marketplace_page .products_small .product-thumb .overlay_content .plus svg {
  height: 1rem;
  fill: #10154e;
}

.marketplace_page .products_small .product-thumb .overlay_content .plus:hover {
  background: #be346a;
  color: white;
}

.marketplace_page .products_small .product-thumb .overlay_content .plus:hover svg {
  height: 1rem;
  fill: white;
}

.marketplace_page .products_small .product-thumb:hover .overlay_content {
  opacity: 1;
}

.marketplace_page .products_small .product-thumb:hover .overlay_content .plus {
  top: 50%;
}

.marketplace_page .product_big {
  margin-bottom: 30px;
}

.marketplace_page .product_big .product-thumb {
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 33px 88px 0 #1d0e6211;
  position: relative;
  margin-bottom: 30px;
}

.marketplace_page .product_big .product-thumb img {
  width: 100%;
}

.marketplace_page .product_big .product-thumb .overlay_content {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: rgba(16, 21, 78, 0.3);
  opacity: 0;
  transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

.marketplace_page .product_big .product-thumb .overlay_content .preview {
  background: #be346a;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  transition: 0.45s cubic-bezier(0.4, 0, 0.2, 1);
  margin-top: 50px;
  transition-delay: 0.2s;
  opacity: 0;
  visibility: none;
}

.marketplace_page .product_big .product-thumb .overlay_content .preview svg {
  fill: white !important;
}

.marketplace_page .product_big .product-thumb .overlay_content .preview:hover {
  transform: scale(1.1);
  transition-delay: 0s;
}

.marketplace_page .product_big .product-thumb .overlay_content .cart {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  transition: 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  margin-top: 50px;
  opacity: 0;
}

.marketplace_page .product_big .product-thumb .overlay_content .cart svg {
  fill: white !important;
}

.marketplace_page .product_big .product-thumb .overlay_content .cart:hover {
  transform: scale(1.1);
}

.marketplace_page .product_big .product-thumb .overlay_content .action {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  transition: 0.35s ease-in-out;
  cursor: pointer;
}

.marketplace_page .product_big .product-thumb .overlay_content .action svg {
  height: 1rem;
  fill: #10154e;
}

.marketplace_page .product_big .product-thumb:hover .overlay_content {
  opacity: 1;
}

.marketplace_page .product_big .product-thumb:hover .overlay_content .action .preview,
.marketplace_page .product_big .product-thumb:hover .overlay_content .action .cart {
  margin-top: 0px;
  opacity: 1;
  visibility: visible;
}

.marketplace_page .product_big .product-title a {
  color: #10154e;
}

.marketplace_page .product_big .price {
  padding: 5px 10px;
  border-radius: 5px;
  background: #f3f8ff;
}

.marketplace_page .product_big .meta-container {
  font-size: 14.5px;
  margin-top: 10px;
  color: #7c8294;
}

.marketplace_page .product_big .meta-container a {
  color: #858c9b;
  font-family: "Sora-Bold", -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.marketplace_page .seller {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.marketplace_page .seller .info {
  margin-left: 5px;
  border-radius: 5px;
  background: #f3f8ff;
  padding: 12.5px 25px;
  transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  border: solid 1px transparent;
}

.marketplace_page .seller .info:hover {
  background: white;
  box-shadow: 0 33px 88px 0 #1d0e6211;
  border: solid 1px #f3f8ff;
}

.marketplace_page .seller .sales {
  padding: 0;
  margin: 5px 0;
}

.marketplace_page .nav-pills .nav-link.active,
.marketplace_page .nav-pills .show > .nav-link {
  color: inherit;
  background-color: transparent;
  border: none;
}

.marketplace_page .nav-pills .nav-link.active .seller .info,
.marketplace_page .nav-pills .show > .nav-link .seller .info {
  background: white;
  box-shadow: 0 33px 88px 0 #1d0e6211;
  border: solid 1px #f3f8ff;
}

.marketplace_page .nav-pills .nav-link.active .seller .avatar,
.marketplace_page .nav-pills .show > .nav-link .seller .avatar {
  opacity: 0.6;
  filter: saturate(0.5);
}

.product_details .product_wrap .product_sidebar {
  position: sticky;
  top: 15px;
}

.product_details .product_wrap .product_sidebar .account_link {
  margin-top: 20px;
  text-align: center;
}

.product_details .product_wrap .product_sidebar p {
  margin: 0;
}

.product_details .product_wrap .product_sidebar .widget_title {
  background: white;
  border-bottom: solid 1px #f4f4f4;
  padding-bottom: 30px;
  margin-bottom: 30px;
  text-align: center;
  font-size: 15.5px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.product_details .product_wrap .product_sidebar .price {
  margin-bottom: 30px;
  text-align: center;
}

.product_details .product_wrap .product_sidebar .widget {
  padding: 30px;
  border-radius: 30px;
  margin-bottom: 30px;
  box-shadow: 0 33px 88px 0 #1d0e6211;
}

.product_details .product_wrap .product_sidebar .plus_widget {
  background: #be346a;
  position: relative;
  z-index: 0;
}

.product_details .product_wrap .product_sidebar .plus_widget::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/others/lines.svg);
  background-size: cover;
  z-index: -1;
}

.product_details .product_wrap .product_sidebar .info_widget .release-info-block {
  padding: 4px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.product_details .product_wrap .product_sidebar .info_widget .rel-info-tag {
  text-align: left;
  flex: 1;
}

.product_details .product_wrap .product_sidebar .info_widget .rel-info-value {
  text-align: right;
  flex: 1;
}

.product_details .product_wrap .product_sidebar .info_widget .release-info-block span {
  text-align: center;
  flex: auto;
  max-width: 20px;
  font-weight: 400;
}

.product_details .product_details_block {
  margin-bottom: 60px;
}

.product_details .product_details_block p {
  margin-bottom: 30px;
}

.product_details .product_details_block h1,
.product_details .product_details_block h2,
.product_details .product_details_block h3,
.product_details .product_details_block h4,
.product_details .product_details_block h5,
.product_details .product_details_block h6,
.product_details .product_details_block ul {
  margin-bottom: 30px;
}

.product_details .product_details_block li {
  margin-bottom: 20px;
  list-style: disc;
}

.product_details .product_details_block ul {
  margin-left: 60px;
}

.product_details .product_slider {
  margin-bottom: 30px;
}

.product_details .product_slider img {
  width: 100%;
  border-radius: 5px;
}

.product_details .product_slider .product_thumb {
  margin-bottom: 20px;
  transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 52px 52px 74px 0 rgba(115, 131, 169, 0.08);
}

.product_details .product_slider .product_thumb:hover {
  filter: saturate(0.5);
  opacity: 0.6;
}

.product_checkout .checkout_box {
  padding: 30px;
  border-radius: 5px;
  margin-bottom: 30px;
  box-shadow: 0 33px 88px 0 #1d0e6211;
  background: white;
}

.product_checkout .Summary_box {
  padding: 30px;
  border-radius: 5px;
  margin-bottom: 30px;
  background: #f3f8ff;
}

.product_checkout .Summary_box .price {
  width: min-content;
  padding: 10px 20px;
  background-color: white;
  box-shadow: 0 33px 88px 0 #1d0e6211;
  border-radius: 5px;
  font-size: 25px;
  color: #be346a;
}

.product_checkout .Summary_box th {
  padding-bottom: 10px;
  color: #76748f;
}

.product_checkout .form {
  margin-top: 30px;
}

.product_checkout tbody {
  margin-top: 20px;
}

.product_checkout .product_title {
  display: flex;
  padding-right: 20px;
}

.product_checkout .product_title img {
  height: 4rem;
  border-radius: 5px;
  box-shadow: 0 33px 88px 0 #1d0e6211;
  width: auto;
  margin-right: 10px;
}

.product_checkout .cards_inputs svg {
  fill: #a2abbd !important;
  margin: 0 3px;
}

.product_checkout .checkout_title {
  margin-bottom: 20px;
}

.product_checkout .product_checkout_block {
  margin-top: 120px;
}

.product_checkout .payment_method {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: solid 2px #f2f4f7;
}

.product_checkout .payment_method:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}

.product_checkout .payment_method .Payment_logo {
  max-width: 6rem;
}

.product_checkout .payment_method .Payment_cards {
  width: auto;
  height: 1rem;
}

.cta-plus {
  border: none;
  color: white !important;
  padding-top: 20px;
  padding-bottom: 20px;
  cursor: pointer;
}

.confirm_purchase .product_title {
  margin-bottom: 30px;
}

.confirm_purchase .confirm_body th,
.confirm_purchase .confirm_body td {
  width: 68%;
  padding: 10px;
  color: #2b2a38;
}

.confirm_purchase .confirm_box {
  padding: 30px;
  border-radius: 5px;
  margin-bottom: 30px;
  box-shadow: 52px 52px 74px 0 rgba(115, 131, 169, 0.08);
}

.confirm_purchase .confirm_box .confirm_title {
  border-bottom: solid 1px #f4f4f4;
  padding-bottom: 30px;
  margin-bottom: 30px;
  text-align: center;
  font-size: 15.5px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.confirm_purchase .confirm_box.in_download {
  box-shadow: none;
  background: #be346a;
  color: white;
}

.confirm_purchase .confirm_box.in_download .confirm_title {
  color: white;
}

.confirm_purchase .confirm_box.in_download .confirm_title svg {
  fill: white !important;
}

.confirm_purchase .confirm_box.in_download .product_title {
  color: rgba(255, 255, 255, 0.815) !important;
  text-align: center;
}

.documentation_page .site-header {
  background: white;
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
}

.documentation_page .side {
  position: relative;
  width: 21rem;
}

.documentation_page .docs-sidenav {
  position: fixed;
  top: 0;
  height: 100vh;
  overflow-y: scroll;
  border-radius: 5px;
  background: white;
}

.documentation_page .first_title {
  margin-bottom: 30px;
}

.documentation_page .fa-star {
  color: #f3d343;
}

.documentation_page .doc_box {
  padding: 60px 40px;
  box-shadow: -20px -17px 36px -21px rgba(29, 14, 98, 0.1);
  margin-top: 60px;
  border: 1px solid #e9ecf0;
  border-radius: 5px;
  max-width: 65rem;
  margin: 60px 0;
  margin-left: 60px;
}

@media screen and (max-width: 991px) {
  .documentation_page .doc_box {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.documentation_page .doc_box img {
  margin-bottom: 2rem;
}

.documentation_page .doc_box p {
  margin-bottom: 1.5rem !important;
}

.documentation_page .doc_box ul {
  margin-bottom: 2rem;
  border-left: solid 0.3rem #f7f7f7;
  padding-left: 1rem;
  cursor: default;
}

.documentation_page .doc_box ul li {
  line-height: 2;
}

.documentation_page .doc_box .box-title {
  font-size: calc(46px + 18 * ( (100vw - 576px) / 1024));
  line-height: 1.05;
  margin-bottom: 30px;
  border-bottom: solid 2px #f3f8ff;
  padding-bottom: 30px;
  text-transform: capitalize;
}

@media screen and (max-width: 576px) {
  .documentation_page .doc_box .box-title {
    font-size: 46px;
  }
}

@media screen and (min-width: 1600px) {
  .documentation_page .doc_box .box-title {
    font-size: 64px;
  }
}

.documentation_page .doc_box .color-span {
  color: #5a52ff;
}

.documentation_page .doc_box .style_guide .box-title {
  font-size: 26px;
  margin-bottom: 0px;
}

.documentation_page .box-sub-title {
  margin: 30px 0;
  position: relative;
  font-size: calc(36px + 4 * ( (100vw - 576px) / 1024));
  line-height: 1.1;
}

@media screen and (max-width: 576px) {
  .documentation_page .box-sub-title {
    font-size: 36px;
  }
}

@media screen and (min-width: 1600px) {
  .documentation_page .box-sub-title {
    font-size: 40px;
  }
}

.documentation_page .box-sub-title::before {
  content: "#";
  color: #be346a;
}

.documentation_page .box-sub2-title {
  background: rgba(61, 123, 194, 0.078);
  font-size: calc(24px + 2 * ( (100vw - 576px) / 1024));
  margin: 2rem 0;
}

@media screen and (max-width: 576px) {
  .documentation_page .box-sub2-title {
    font-size: 24px;
  }
}

@media screen and (min-width: 1600px) {
  .documentation_page .box-sub2-title {
    font-size: 26px;
  }
}

.documentation_page .box-sub2-title::before {
  content: "##";
  color: #be346a;
}

.documentation_page .structure {
  background: #ffffff;
  border-radius: 5px;
  padding: 30px 60px !important;
  border: solid 1px #f7f7f7 !important;
  box-shadow: 0 33px 88px 0 #1d0e6211;
  transition: 0.3s;
}

.documentation_page .structure:hover {
  box-shadow: none;
}

.documentation_page .structure .fa-folder-open {
  color: #ffe16e;
}

.documentation_page .structure .fa-folder {
  color: #afa8ff;
}

.documentation_page .structure .file {
  color: #ffa8a8;
}

.documentation_page .structure .file2 {
  color: #a8ffe5;
}

.documentation_page .structure .file3 {
  color: #aea8ff;
}

.documentation_page #docsSidenav {
  padding: 60px;
  background-color: #faf4f6;
  border-right: 1px solid #f3f8ff;
  max-width: 21rem;
}

.documentation_page #docsSidenav h5 {
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 1px;
  margin-bottom: 20px !important;
  margin-top: 20px !important;
  display: flex;
  align-items: center;
}

.documentation_page #docsSidenav a {
  font-size: 15px;
}

.documentation_page #docsSidenav .icon {
  margin-right: 10px;
}

.documentation_page #docsSidenav .icon svg {
  fill: #10154e !important;
}

.documentation_page .border {
  margin-bottom: 2rem;
  margin-top: 2rem;
  border-top: 1px solid #eaf2fe;
}

pre[class*="language-"] {
  font-size: 14px;
  padding: 1em;
  margin: 0;
  overflow: auto;
  border: none;
  border-radius: 0.5em;
  box-shadow: none;
  border-radius: 5px;
}

pre[class*="language-"],
:not(pre) > code[class*="language-"] {
  background-color: #1f2937 !important;
}

div.code-toolbar > .toolbar a,
div.code-toolbar > .toolbar .copy,
div.code-toolbar > .toolbar span {
  color: white;
  font-size: 0.8em;
  padding: 10px 1rem;
  background: #be346a !important;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.2);
  border-radius: 0.5em;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: #706699;
}

.token.atrule,
.token.attr-value,
.token.keyword {
  color: #6edfff;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: #baea80;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string,
.token.variable {
  color: #b9a5ca;
}

span.inline-color-wrapper {
  height: 16px;
  width: 16px;
  margin: 0 6px 0 0;
  box-sizing: border-box;
  border: 1px solid #61448a;
  outline: none;
  border-radius: 50%;
}
