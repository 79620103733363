.showcase {
    .title_sections {
        margin-bottom: 3.125rem;
    }
    .item_mywork {
        margin-bottom: 3.125rem;
        display: block;
        text-decoration: none;
        position: relative;
        .mg_img {
            border-radius: 12px;
            position: relative;
        }
        .icon_played {
            position: absolute;
            left: 40%;
            top: 40%;
            z-index: 2;
        }
        .item_pic {
            width: 100%;
            height: 490px !important;
            object-fit: cover;
            border-radius: 12px;
        }
        .info_work {
            margin-top: 1.5rem;
            position: relative;
            h4 {
                font-size: 18px;
                margin-bottom: 0.3rem;
                font-weight: 600;
                color: $color_dark;
            }
            p {
                font-weight: 400;
                font-size: 14px;
                margin-bottom: 0;
                color: $gray-color;
                @include prefixer(transition, 0.3s, webkit moz o ms);
            }
            .link_view {
                opacity: 0;
                color: $color_brand;
                font-weight: 500;
                font-size: 14px;
                position: absolute;
                left: 0;
                bottom: -22px;
                @include prefixer(transition, 0.4s, webkit moz o ms);
            }
        }
        &:hover {
            .info_work {
                p {
                    opacity: 0;
                }
                .link_view {
                    opacity: 1;
                    bottom: -3px;
                }
            }
        }
    }
    .showcase_item {
        transition: 0.3s $transition_function;
        .img_item {
            width: 100%;
            margin-bottom: 10px;
            border-radius: 5px;
            transition: 0.3s $transition_function;
        }
        &:hover {
            transform: translateY(-10px);
            .img_item {
                opacity: 0.7;
            }
        }
        .price {
            color: $color_brand;
            text-align: right;
        }
        .place {
            font-size: 13px;
            text-align: right;
            display: block;
        }
    }
    .swiper-button-prev {
        height: 3rem;
        width: 3rem;
        top: 11rem;
        left: auto;
        right: 4rem !important;
        background: $color_brand;
        border-radius: 0%;
        transition: all 0.2s ease-in-out 0s;
        border-radius: 5px;
        &:hover {
            background: $color_brand_second;
        }
        @include tablet_vert_screen {
            display: none;
        }
    }
    .swiper-button-next {
        height: 3rem;
        width: 3rem;
        top: 11rem;
        right: 0px !important;
        background: $color_brand;
        border-radius: 5px;
        &:hover {
            background: $color_brand_second;
        }
        @include tablet_vert_screen {
            display: none;
        }
    }
    .swiper-button-next::after,
    .swiper-button-prev::after {
        color: rgb(255, 255, 255);
        font-size: 15px;
        font-weight: 700;
        transition: all 0.2s ease-in-out 0s;
    }
}
