/*--------------------------------------------------------------
## box styles
--------------------------------------------------------------*/
.box {
    padding: 40px 30px;
    border-radius: 10px;
    margin-bottom: 30px;
    position: relative;
    overflow: hidden;
    cursor: default;
    &.has-style1 {
        background: $color_light;
        -webkit-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
        .box-icon {
            margin-bottom: 20px;
            text-align: center;
            img {
                max-height: 3rem;
            }
            ion-icon {
                color: $color_brand;
                font-size: 35px !important;
            }
            svg {
                fill: $color_brand;
            }
            [class^="ri-"] {
                fill: $color_brand !important;
                font-size: 2rem;
            }
        }
        .box-title {
            margin-bottom: 14px;
            text-align: center;
        }
        .box-desc {
            text-align: center;
        }
    }
    &.has-style2 {
        background: $color_white;
        box-shadow: $shadow_1;
        transition: 0.25s ease-in-out;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .box-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: $spacer_20;
            text-align: center;
            width: 5rem;
            height: 5rem;
            border-radius: 50%;
            background: $color_brand_light !important;
            z-index: 0;

            img {
                max-height: 3rem;
            }
            ion-icon {
                color: $color_brand;
                font-size: 35px !important;
            }
            svg {
                fill: $color_brand;
            }
            [class^="ri-"] {
                fill: $color_brand !important;
                font-size: 2rem;
            }
        }
        .box-title {
            margin-bottom: $spacer_20;
            text-align: center;
        }
        .box-desc {
            color: #232323;
            text-align: center;
            margin: 0;
        }
    }
    &.is-dark {
        background: $color_brand;
        transition: 0.25s ease-in-out;
        display: flex;
        justify-content: center;
        align-items: center;
        .box-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: $spacer_20;
            text-align: center;
            img {
                max-height: 5rem;
            }
            ion-icon {
                color: $color_white !important;
                font-size: 35px;
            }
        }
        .box-title {
            margin-bottom: $spacer_10;
            text-align: left;
            color: white;
        }
        .box-desc {
            color: white;
            text-align: left;
            margin: 0;
        }
    }
    &.has-left-icon {
        background: $color_white;
        box-shadow: $shadow_1;
        transition: 0.25s ease-in-out;
        display: flex;
        justify-content: center;
        align-items: center;
        .box-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: $spacer_20;
            text-align: center;
            img {
                max-height: 5rem;
            }
            [class^="ri-"] {
                font-size: 35px;
                height: 40px;
                color: $color_brand;
                width: 40px;
            }
        }
        .box-title {
            margin-bottom: $spacer_10;
            text-align: left;
        }
        .box-desc {
            text-align: left;
            margin: 0;
        }
    }
}
.card {
    &.style_1 {
        .card-body {
            box-shadow: $shadow_1;
        }
    }
}
// ============================= box_numbers
.box_numbers {
    padding: 10px;
    border-radius: $border_radius;
    max-width: 25rem;
    box-shadow: $shadow_1;
    overflow: hidden;
    @include before {
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;
        bottom: auto;
        top: 27px;
        left: 46%;
        background-color: transparent;
        background-image: url(../images/others/img_effect.png);
        background-size: contain;
    }
    @include tablet_vert_screen {
        margin-left: auto;
        margin-right: auto;
    }
    &_wrap {
        background: $color_brand;
        padding: 30px;
    }

    .item {
        margin-bottom: 20px;
        padding-bottom: 20px;

        border-bottom: solid 1px rgba(255, 255, 255, 0.199);
    }
    .number {
        color: white;
    }
    .description {
        color: white;
    }
}

.box_img {
    box-shadow: $shadow_3;
    border-radius: 10px;
    background: white;
    padding: $spacer_40;
    transition: 0.35s $transition_function;
    margin-bottom: 30px;
    .box-inner {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .img_wrap {
        width: 5rem;
        height: 5rem;
        background: $color_brand_second;
        border-radius: 50%;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
            color: white;
            font-size: 2rem;
        }
    }
    img {
        width: 70%;
        height: 10rem;
    }

    * {
        text-align: center;
    }
}
