.testimonial {
    &_box {
        background: $color_brand_light;
        margin: 0;
        border-radius: 20px;
        margin-bottom: 30px;
        background-color: $color_dark;
        box-shadow: 0px 26px 26px -26px #11164e;
        padding: 30px;
        .name {
            color: rgba(255, 255, 255, 0.774);
        }
        .quote {
            margin-bottom: 10px;
            color: white;
            font-size: 17px;
            @include before {
                right: 0px;
                bottom: -4rem;
                height: 5rem;
                width: 5rem;
                opacity: 0.2;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M19.417 6.679C20.447 7.773 21 9 21 10.989c0 3.5-2.457 6.637-6.03 8.188l-.893-1.378c3.335-1.804 3.987-4.145 4.247-5.621-.537.278-1.24.375-1.929.311-1.804-.167-3.226-1.648-3.226-3.489a3.5 3.5 0 0 1 3.5-3.5c1.073 0 2.099.49 2.748 1.179zm-10 0C10.447 7.773 11 9 11 10.989c0 3.5-2.457 6.637-6.03 8.188l-.893-1.378c3.335-1.804 3.987-4.145 4.247-5.621-.537.278-1.24.375-1.929.311C4.591 12.322 3.17 10.841 3.17 9a3.5 3.5 0 0 1 3.5-3.5c1.073 0 2.099.49 2.748 1.179z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
                background-size: contain;
                background-position-y: bottom;
            }
        }
    }
    .testimonial_box2 {
        background: $color_brand_light;
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 30px;
        color: $color_dark;
        @include tablet_vert_screen {
            max-width: 37rem;
            margin: auto;
        }

        .name {
            color: $color_dark;
            margin-top: 60px;
            display: block;
            @include tablet_vert_screen {
                margin-top: 30px;
            }
        }
        .testimonial_img {
            height: 25rem;
            width: 100%;
            overflow: hidden;
            position: relative;
            @include tablet_vert_screen {
                height: 20rem;
            }
            img {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center;
                z-index: 1;
            }
        }
        .content {
            padding: 30px;
            @include tablet_vert_screen {
                padding: 30px 15px;
            }
        }
        .quote {
            margin-bottom: 10px;
            color: $color_dark;
            font-size: 22px;
            @include tablet_vert_screen {
                font-size: 15.5px;
            }
            @include before {
                right: 15px;
                bottom: -6rem;
                height: 5rem;
                width: 5rem;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M19.417 6.679C20.447 7.773 21 9 21 10.989c0 3.5-2.457 6.637-6.03 8.188l-.893-1.378c3.335-1.804 3.987-4.145 4.247-5.621-.537.278-1.24.375-1.929.311-1.804-.167-3.226-1.648-3.226-3.489a3.5 3.5 0 0 1 3.5-3.5c1.073 0 2.099.49 2.748 1.179zm-10 0C10.447 7.773 11 9 11 10.989c0 3.5-2.457 6.637-6.03 8.188l-.893-1.378c3.335-1.804 3.987-4.145 4.247-5.621-.537.278-1.24.375-1.929.311C4.591 12.322 3.17 10.841 3.17 9a3.5 3.5 0 0 1 3.5-3.5c1.073 0 2.099.49 2.748 1.179z' fill='rgb(16 21 78)'/%3E%3C/svg%3E");
                background-size: contain;
                background-position-y: bottom;
                @include tablet_vert_screen {
                    bottom: -4rem;
                    height: 3rem;
                    width: 3rem;
                }
            }
        }
    }
    .testimonial_box3 {
        background: $color_white;
        box-shadow: $shadow_3;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 30px;
        padding: 40px;
        color: $color_dark;
    }
    .swiper-pagination {
        transform: translateY(5px);
        &.in_dark {
            .swiper-pagination-bullet {
                background: #faf4f6;
            }
        }
    }
}
