/*--------------------------------------------------------------
## form styles
--------------------------------------------------------------*/

.select-holder {
    position: relative;

    &:after {
        content: "\f3d0";
        font-family: "Ionicons";
        font-weight: 600;
        font-size: 13px;
        position: absolute;
        line-height: 1;
        top: 50%;
        transform: translateY(-50%);
        right: 18px;
        z-index: 10;
        @include animate();
    }
}

select {
    cursor: pointer;
    display: inline-block;
    padding: 15px !important;
    color: #6c757d !important;
}

textarea {
    padding: 15px !important;
}
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="number"],
select,
textarea {
    background: 0 0;
    color: #232226;
    background-color: rgb(255, 255, 255);
    border: 1px solid #e2e4e9;
    width: 100%;
    padding: 15px;
    line-height: 1.5;
    font-weight: 400;
    font-size: inherit;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    position: relative;
    -webkit-appearance: none;
    box-shadow: 0 7px 9px rgba(29, 14, 98, 0.05);

    -webkit-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
    -o-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
    transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
    outline: 0;
    &:focus {
        border: 1px solid rgba($color_brand, 0.5);
        outline: 0;
        box-shadow: none;
    }
    &.is-valid {
        border-bottom: solid 3px $color_success !important;
        border: 1px solid #e2e4e9;
        padding-right: calc(1.5em + 0.75rem);
        background: none !important;
        &:focus {
            border-bottom: solid 3px $color_success !important;
            border: 1px solid #e2e4e9;
            outline: 0;
            box-shadow: none;
        }
    }
    &.is-invalid {
        border-bottom: solid 3px $color_error !important;
        border: 1px solid #e2e4e9;
        padding-right: calc(1.5em + 0.75rem);
        background: none;
        &:focus {
            border-bottom: solid 3px $color_error !important;
            border: 1px solid #e2e4e9;
            outline: 0;
            box-shadow: none;
        }
    }
}
.form-group {
    margin-bottom: 20px !important;
}
.input-group-text {
    padding: 0.375rem 20px !important ;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #ffffff;
    border: 1px solid #e2e4e9;
    border-radius: 0.25rem 0 0 0.25rem;
    min-height: 64px;
}
.textarea {
    width: 100%;
    height: 150px;
    resize: vertical;
}
label {
    color: #77748f;
    margin-bottom: 5px;
}

/*--------------------------------------------------------------
### Radio Checkbox
--------------------------------------------------------------*/
.radio {
    width: 20px;
    height: 20px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    margin-right: 5px;

    & + label {
        text-transform: none;
        font-weight: normal;
        color: inherit;
        vertical-align: middle;
    }

    input {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        cursor: pointer;

        &:checked {
            cursor: default;
        }
    }

    input:checked + .input:after {
        background: $color_dark;
    }

    .input {
        width: 18px;
        height: 18px;
        @include border_radius(50%);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        &:after {
            content: "";
            background: #fff;
            width: 8px;
            height: 8px;
            @include border_radius(50%);
            position: absolute;
            top: 5px;
            left: 5px;
            @include animate();
        }
    }

    &:hover {
        .input:after {
            background: $color_dark;
        }

        input:checked + .input:after {
            background: $color_dark;
        }
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: white;
    background-color: $color_brand;
    box-shadow: $shadow_1;
    width: 20px;
    height: 20px;
}
.custom-control-label {
    margin-left: 5px;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: none;
}
.custom-control-label::after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -30px;
    display: block;
    box-shadow: $shadow_1 !important;
    outline: none;
    width: 20px;
    height: 20px;
    content: "";
    background: no-repeat 50%/50% 50%;
    outline: none !important;
}

.custom-control-label::before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -30px;
    display: block;
    box-shadow: $shadow_1 !important;
    outline: none;
    width: 20px;
    height: 20px;
    content: "";
    background-color: #fff;
    outline: none !important;
}

.custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 2.75rem;
    pointer-events: all;
    border-radius: 24px;
    height: 22px;
    border: none;
    background-color: $color_light;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: white;
    background-color: #5a52ff;
    box-shadow: 0 21px 51px rgba(29, 14, 98, 0.15);
    width: 2.75rem;
    height: 20px;
}
.custom-switch .custom-control-label::after {
    top: 1px;
    left: calc(-14px + 0px);
    width: 18px;
    height: 18px;
    background-color: #ffffff;
    box-shadow: 0 21px 23px -5px rgba(82, 48, 162, 0.12);
    border-radius: 1.5rem;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    left: calc(-47px + 0px);
    top: -9px;
}
.custom-switch .custom-control-label {
    margin-left: 0;
    padding-left: 19px;
}
.custom-select {
    height: 62px;
}
.input-group-prepend {
    .btn {
        padding: 19px 18px;
    }
}

.custom-select-xs {
    height: calc(0.5rem + 0.9375rem * 1.6 + 2px);
    padding: 0.25rem 1.875rem 0.25rem 0.625rem;
    line-height: 1.6;
    font-size: 0.9375rem;
}
.custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: 0.35s ease-in-out;
}

.form_cc_four {
    background-color: $color_white;
    border-radius: 8px;
    border: solid 1px #dfe4e7;
    padding: 2.5rem;
    &.bg_dark {
        label {
            margin-bottom: 5px;
            color: #fff;
        }
        input,
        .form-control {
            background-color: rgba(255, 255, 255, 0.192) !important;
            border: none !important;
            color: #fff !important;
            &::placeholder {
                color: #908ea7 !important;
            }
            &:hover {
                background-color: rgba(255, 255, 255, 0.075) !important;
            }
        }
    }
    @include prefixer(box-shadow, 0px 50px 70px -10px rgba(11, 34, 56, 0.05), webkit moz o ms);
    .item_upload {
        @include minMedium {
            display: flex;
        }
        span {
            font-size: 13px;
            color: $gray-color;
            @include maxMobile {
                display: block;
            }
        }
    }
    .upload__file {
        position: relative;
        overflow: hidden;
        margin-right: 15px;
        background: white;
        color: $color_dark;
        border-radius: 8px;
        @include prefixer(transition, 0.3s, webkit moz o ms);
        input[type="file"] {
            position: absolute;
            opacity: 0;
            top: 0;
            left: -160px;
            font-size: 40px;
            cursor: pointer;
        }
        .btn {
            border-radius: 8px;
            border: 1px dashed #dce0e3;
            color: $color_dark;
            i {
                vertical-align: middle;
                color: $color_dark;
                font-size: 18px;
                margin-right: 4px;
                top: 5px;
            }
        }
        &:hover {
            background-color: $bg-color;
            .btn {
                color: white;
            }
            i {
                color: white;
            }
        }
    }
}
