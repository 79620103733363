.hero {
    position: relative;
    z-index: 1;
    background-repeat: no-repeat;
    .container,
    .row {
        height: 100%;
    }
    .hero_title {
        text-transform: capitalize;
    }
    .hero_wrap {
        padding: 7rem 0;
        @include md {
            padding: 3rem 0 3rem;
        }
    }
    /*====================================
    =========  in index
    =====================================*/
    &.in_index {
        position: relative;
        @include after {
            content: "";
            position: absolute;
            height: 100%;
            width: 100%;
            right: 0;
            top: 0;
            background: $color_dark;
            z-index: -2;
            border-bottom-right-radius: 350px;
            @include tablet_vert_screen {
                border-bottom-right-radius: 50px;
            }
        }
        @include before {
            content: "";
            position: absolute;
            height: 100%;
            width: 100%;
            right: 0;
            background-size: cover;
            background-repeat: no-repeat;
            background-image: url(../img/hero.png);
            z-index: -1;
            background-position: center;
        }

        .hero_wrap {
            padding: 12rem 0 12rem;
            height: 100vh;
            @include before {
                bottom: -4rem;
                left: 50%;
                transform: translateX(-50%);
                background-image: url(../img/decorations/circles.svg);
                background-size: contain;
                height: 6rem;
                width: 14rem;
                background-repeat: no-repeat;
                z-index: 2;
            }

            @include tablet_vert_screen {
                padding: 10rem 0 0;
                height: auto;
            }
        }
        .hero_title {
            @include fluid-type($font_size_h1_mobile, $font_size_h1);
            line-height: 1.1;
            margin-bottom: $spacer_10;
            max-width: 60rem;
            margin-left: auto;
            margin-right: auto;
        }
        .img_right {
            height: 100%;
            width: auto;
            @include tablet_vert_screen {
                display: none;
            }
        }
        .hero_text {
            margin-bottom: 0;
            margin: 0;
            margin-bottom: $spacer_20;
            font-size: 17px;
        }
        .list_index {
            padding-bottom: $spacer_10;
            margin-bottom: $spacer_10;
            padding-top: $spacer_10;
            margin-top: $spacer_10;
            font-size: 20px;
            text-align: center;
        }
        .advantages {
            background: url(../images/others/banner.webp);
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: 0 0;
            overflow: visible;
        }
    }
    /*====================================
    ========= has style1
    =====================================*/
    &.has_style1 {
        background-color: $color_dark;
        border-radius: 20px;
        background-image: url(../img/decorations/wave.svg);
        background-size: contain;
        background-position-x: left;
        box-shadow: 0 36px 66px -42px #11164e;
        @include before {
            bottom: 0rem;
            right: -5rem;
            background: url(../img/decorations/orange_circle.svg);
            background-size: contain;
            background-repeat: no-repeat;
            height: 6rem;
            width: 15rem;
        }
        .hero_wrap {
            @include md {
                padding: 5rem 1rem;
            }
        }
        .hero_title {
            color: $color_brand_second;
            @include fluid-type($font_size_h3_mobile, $font_size_h2);
            text-align: left;
            z-index: 1;
            @include tablet_vert_screen {
                text-align: center;
                line-height: 1.2;
            }
        }
        .hero_text {
            font-size: 16px;
            z-index: 1;
            opacity: 0.85;
            @include tablet_vert_screen {
                text-align: center;
            }
        }
        .img_wrap {
            transform: translate(-45%, 0%);
            z-index: -1;

            img {
                width: 147%;
            }

            @include tablet_vert_screen {
                transform: translate(0, 0);

                img {
                    margin-top: 20px;
                    width: 100%;
                    max-width: 100%;
                }
            }
        }
        .btn_hero {
            @include tablet_vert_screen {
                width: 100%;
                margin-bottom: 10px;
            }
        }
        .header-shape {
            position: absolute;
            transform: scale(0.9);
            left: -4rem;
            top: -10rem;
            z-index: -1;
            @include tablet_vert_screen {
                display: none;
            }
        }
    }
    /*====================================
    ========= has style2
    =====================================*/
    &.has_style2 {
        overflow: hidden;
        background-color: $color_brand_light;
        @include tablet_vert_screen {
            height: auto;
        }
        .dots {
            position: absolute;
            opacity: 0.3;
            top: 2rem;
        }
        .hero_wrap {
            padding: 36px 0 4rem;
            height: 100%;
        }
        @include before {
            content: none;
            top: 0;
            left: 0;
            height: 100%;
            width: 50%;
            border-radius: 0 0 60px 0;
            background: $color_grad;
            z-index: -2;
            @include tablet_vert_screen {
                width: 100%;
            }
        }
        @include after {
            bottom: 0;
            left: -2rem;
            background: url(../img/decorations/mix_shapes.png);
            background-size: contain;
            background-repeat: no-repeat;
            height: 20rem;
            width: 20rem;
            z-index: -1;
        }

        .hero_form {
            background: white;
            box-shadow: $shadow_1;
            display: flex;
            align-items: center;
            padding: 4px;
            border-radius: 5px;
            max-width: 30rem;
            border: solid 1px transparent;
            transition: all 0.2s ease-in-out;

            width: 100%;
            &:hover {
                border: solid 1px $color_brand;
            }

            input {
                height: 30px;
                border: none;
                box-shadow: none;
            }
            .btn {
                padding: 18px;
                width: 60%;
            }
        }
        .hero_title {
            @include fluid-type($font_size_h1_mobile, $font_size_h1);
            text-align: left;
            z-index: 1;
            @include tablet_vert_screen {
                text-align: center;
                font-size: 40px;
            }
        }

        .hero_text {
            font-size: 17px;
            line-height: 1.5;
            margin-bottom: 20px;
            color: rgb(81, 86, 100);
            z-index: 1;
            @include tablet_vert_screen {
                text-align: center;
                font-size: 15px;
                margin-top: 20px;
            }
        }
        .img_wrap {
            text-align: center;
            margin: auto;
            min-height: 35rem;
            img {
                width: 100%;
                max-width: 900px;
                @include tablet_vert_screen {
                    margin-top: 20px;
                    width: 100%;
                    max-width: 700px;
                }
            }
            @include tablet_vert_screen {
                min-height: auto !important;
                margin-top: 20px;
            }
        }
    }
    /*====================================
    ========= has style3
    =====================================*/
    &.has_style3 {
        @include before {
            background: #edf0f2;
            height: 100%;
            width: 80%;
            left: 0;
            top: 0;
            z-index: -2;
            @include tablet_vert_screen {
                width: 100%;
            }
        }
        @include after {
            bottom: -4rem;
            right: 10rem;
            background: url(../img/decorations/dots.svg);
            background-size: contain;
            background-repeat: no-repeat;
            height: 10rem;
            width: 10rem;
            z-index: -1;
        }
        .container,
        .row {
            height: auto;
        }

        .hero_form {
            background: white;
            box-shadow: $shadow_1;
            display: flex;
            align-items: center;
            padding: 4px;
            border-radius: 5px;
            max-width: 30rem;
            width: 100%;

            input {
                height: 30px;
                border: none;
                box-shadow: none;
            }
            .btn {
                padding: 18px;
                width: 60%;
            }
        }

        .img_wrap {
            position: relative;
            background-repeat: no-repeat;
            background-position: center;
            .deco_hero3 {
                position: absolute;
                bottom: 2rem;
                left: -4rem;
                @include tablet_vert_screen {
                    bottom: 0;
                    left: 0;
                }
            }
            img {
                margin-left: auto;
                margin-right: auto;
                height: auto;
                border-radius: 10px;
            }
        }

        .hero_title {
            color: $color_dark;
            margin-bottom: 20px;
            text-align: left;
            z-index: 1;
            @include fluid-type($font_size_h2_mobile, $font_size_h1);
            @include tablet_vert_screen {
                text-align: center;
                margin-bottom: 20px;
            }
        }

        .hero_text {
            font-size: 17px;
            line-height: 1.5;
            letter-spacing: -0.5px;
            font-weight: normal;
            margin-bottom: 20px;
            color: #6f7583;
            z-index: 1;
            @include tablet_vert_screen {
                text-align: center;
                margin-left: auto !important;
                mgright: auto !important;
            }
        }
        .swiper-button-prev {
            height: 3rem;
            width: 3rem;
            bottom: 0;
            top: auto;
            left: auto;
            left: 0 !important;
            background: $color_dark;
            border-radius: 0;
            transition: all 0.2s ease-in-out;
            outline: none;
            border-radius: 0 0 0 10px;

            &:hover {
                color: $color_brand;
            }
        }
        .swiper-button-next {
            height: 3rem;
            width: 3rem;
            bottom: 0;
            top: auto;
            left: 3.1rem !important;
            background: $color_dark;
            border-radius: 0;
            outline: none;
            border-radius: 0 10px 0 0;

            &:hover {
                color: $color_brand;
            }
        }
        .swiper-button-next::after,
        .swiper-button-prev::after {
            color: rgb(255, 255, 255);
            font-size: 15px;
            font-weight: 700;
            transition: all 0.2s ease-in-out 0s;
        }
    }
    /*====================================
    ========= has style4
    =====================================*/
    &.has_style4 {
        background-size: cover;
        height: auto;
        background-position: right bottom;
        background-color: $color_dark;
        z-index: 1;
        @include overlay {
            background: rgba(0, 0, 0, 0.479);

            @include lg_screen {
                background: rgba(0, 0, 0, 0.089);
            }
        }
        @include after {
            bottom: -4rem;
            left: 50%;
            transform: translateX(-50%);
            background-image: url(../img/decorations/circles.svg);
            background-size: contain;
            height: 6rem;
            width: 14rem;
            background-repeat: no-repeat;
            z-index: 2;
        }

        @include tablet_vert_screen {
            height: auto;
            padding-bottom: 60px;
        }

        .hero_wrap {
            height: 100%;
            padding: 10rem 0 10rem;
            overflow: hidden;
            @include tablet_vert_screen {
                padding: 60px 0 20px;
            }
        }
        .hero_title {
            font-size: 60px;
            color: $color_white;
            max-width: 60rem;
            margin-bottom: 20px;
            line-height: 1;
            @include tablet_vert_screen {
                font-size: 40px;
                text-align: center;
            }
        }
        .play_flex {
            img {
                transition: 0.2s ease-in-out;
                &:hover {
                    transform: scale(1.1);
                    opacity: 0.7;
                }
            }
            @include tablet_vert_screen {
                justify-content: center !important;
            }
        }
        .hero_text {
            color: $color_white;
            max-width: 30rem;
            margin-left: 0;
            margin-bottom: 27px;
            font-size: 15.5px;
            @include lg {
                text-align: center;
                margin: auto;
                margin-bottom: 27px;
            }
        }
        .price_wrap {
            background: $color_white;
            padding: 3rem;
            border-radius: 5px;
            box-shadow: -1px 20px 80px -14px #27073f21;
            @include before {
                height: 1rem;
                border-radius: 5px 5px 0 0;
                background: $color_grad;
                width: 100%;
                top: 0;
                left: 0;
            }
            .btn {
                padding: 20px 20px;
            }
        }
    }
    /*====================================
    ========= has style5
    =====================================*/
    &.has_style5 {
        background-size: cover;
        background-color: $color_dark;
        background-image: url(../img/decorations/wave.svg);
        background-attachment: fixed;
        background-size: contain;
        background-position-x: right;
        box-shadow: 0 36px 66px -42px #11164e;
        .container {
            position: relative;
        }
        .hero_wrap {
            padding: 4rem 0;
        }
        .swiper-button-prev {
            height: 3rem;
            width: 3rem;
            top: 3.6rem;
            left: 0;
            right: auto;
            background: $color_brand;
            border: solid 3px $color_dark;

            border-radius: 0%;
            transition: all 0.2s ease-in-out 0s;
            border-radius: 5px;
            &:hover {
                background: $color_brand_second;
            }
            @include tablet_vert_screen {
                display: none;
            }
        }
        .swiper-button-next {
            height: 3rem;
            width: 3rem;
            top: 3.6rem;
            border: solid 3px $color_dark;
            left: 4rem;
            right: auto;
            background: $color_brand;
            border-radius: 5px;
            &:hover {
                background: $color_brand_second;
            }
            @include tablet_vert_screen {
                display: none;
            }
        }
        .swiper-button-next::after,
        .swiper-button-prev::after {
            color: rgb(255, 255, 255);
            font-size: 15px;
            font-weight: 700;
            transition: all 0.2s ease-in-out 0s;
        }
        .hero_title {
            font-size: 64px;
            color: rgb(255, 255, 255);
            max-width: 60rem;
            margin-bottom: 20px;
            line-height: 1.2;

            @include lg {
                font-size: 60px;
                text-align: center;
            }

            @include md {
                font-size: 40px;
            }
        }

        .hero_text {
            color: white;
            max-width: 30rem;
            margin-bottom: 20px;
            font-size: 15.5px;
            margin-left: 0;

            @include lg {
                text-align: center;
                margin: auto;
                margin-bottom: 27px;
            }
        }
        .numbers {
            display: flex;
            background: $color_grad;
            position: absolute;
            right: 5px;
            padding: 20px 30px;
            box-shadow: $shadow_3;
            border-radius: 5px;
            color: white;
            z-index: 2;
            h2 {
                color: white;
            }
            @include sm_screen {
                left: 15px;
                right: 15px;
            }
        }
        .img_wrap {
            margin-top: 4rem;
            img {
                object-fit: cover;
                height: 30rem;
                width: 100%;
            }
        }
        .text-gray {
            margin-top: 20px;
            color: $color_neutral;
        }
        .card-form {
            padding: 32px;
            border-radius: 0.5rem;
            box-shadow: 0 15px 50px -10px rgba(21, 37, 72, 0.1);
            @include tablet_vert_screen {
                margin-top: 30px;
            }
        }
    }
    /*====================================
    ========= has style6
    =====================================*/
    &.has_style6 {
        overflow: hidden;
        @include before {
            background: #f7f5f7;
            height: 100%;
            width: 60%;
            left: 0;
            border-radius: 0 0 50px 0;
            top: 0;
            z-index: -2;
        }
        .img_wrap {
            position: relative;
            background-repeat: no-repeat;
            background-position: center;
            .deco_hero6 {
                position: absolute;
                bottom: -2rem;
                left: -4rem;
                width: 100%;
                z-index: -1;
            }
            img {
                max-height: 60rem;
            }
        }
        .hero_title {
            color: $color_dark;
            margin-bottom: 20px;
            text-align: left;
            z-index: 1;
            @include fluid-type($font_size_h1_mobile, $font_size_h1);
            @include tablet_vert_screen {
                text-align: center;
                font-size: 40px;
                margin-bottom: 20px;
            }
        }
        .hero_text {
            font-size: 17px;
            line-height: 1.5;
            font-weight: normal;
            margin-bottom: 20px;
            color: #54587f;
            z-index: 1;
            @include tablet_vert_screen {
                text-align: center;
            }
        }
    }
    /*====================================
    ========= is dark 
    =====================================*/
    &.is_dark {
        margin-bottom: 5rem;
        background: $color_dark;
        .navbar .menu .line-menu {
            background: $color_dark;
        }
        .hero_wrap {
            height: 100%;
            padding: 120px 0;
        }
        .hero_textription {
            margin-top: 20px;
            color: white;
            max-width: 40rem;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            font-size: 20px;
        }
        .hero_title {
            color: rgb(255, 255, 255);
            margin-bottom: 30px;

            @include lg {
                text-align: center;
            }

            @include md {
                font-size: 40px;
            }
        }

        .hero-img {
            text-align: center;
            margin: auto;
            img {
                width: 130%;
                @include lg {
                    padding-top: 5rem;
                    width: 80%;
                }
            }
        }
        .hero_wrap {
            overflow: hidden;
        }
    }
    /*====================================
    ========= has background
    =====================================*/
    &.has_bg {
        height: 100vh;
        background-repeat: no-repeat;
        background-size: cover;
        .hero_wrap {
            padding: 200px 0;
        }
        .header-title {
            color: white;
            @include fluid-type($font_size_h1_mobile, $font_size_h1);
            line-height: 1;
            margin-bottom: 30px;
        }
        .item_block {
            display: flex;
            align-items: center;
            border-radius: 50px;
            padding: 10px 20px 10px 10px;
            border: solid 1px #ffffff45;
            box-shadow: $shadow_3;
            color: $color_white;
            margin-bottom: 30px;
            span {
                color: $color_white;
                margin-left: 10px;
            }
            svg {
                height: 3rem;
                width: 3rem;
                fill: white;
                background: $color_brand;
                border-radius: 50%;
                padding: 10px;
            }
        }
    }
    /*====================================
    ========= has style deco
    =====================================*/
    &.has_style_deco {
        position: relative;
        @include before {
            background-color: lighten($color_brand, 30%);
            height: 80vh;
            width: 50%;
            right: 0;
            top: 0;
            border-radius: 0 0 0 30px;
        }
        .line-menu {
            background: #150d4c;
        }
        .hero_wrap {
            padding: 6rem 0 6rem;
            background-repeat: no-repeat;
            background-position: center;
            @include lg {
                padding: 7rem 0 7rem;
            }
            @include md {
                padding: 5rem 0 5rem;
            }
        }

        .hero_title {
            color: $color_brand_second;
            @include fluid-type($font_size_h1_mobile, $font_size_h1);
            margin-bottom: 40px;
            text-align: left;
            @include lg {
                font-size: 50px;
            }

            @include md {
                text-align: center;
                font-size: 40px;
                margin-bottom: 20px;
            }
        }

        .hero_text {
            font-size: 1.25rem;
            line-height: 1.5;
            letter-spacing: -0.5px;
            font-weight: normal;
            margin-bottom: 40px;
            color: $color_dark;
        }

        .img_wrap {
            transform: translate(10%, -6rem);
            img {
                width: 100%;

                max-width: 500px;
            }

            @include lg {
                img {
                    margin-top: 20px;
                    width: 100%;
                    max-width: 100%;
                }
            }
        }

        .header-shape {
            position: absolute;
            transform: scale(0.9);
            left: -4rem;
            top: -10rem;
            z-index: -1;
        }
    }
}

.hero_page {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat !important;
    .hero_wrap {
        padding: 120px 0;
    }

    &.has_style1 {
        background-size: contain;
        position: relative;
        z-index: 0;
        .hero_img {
            max-width: 40rem;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            margin-bottom: 30px;
        }
        .hero_wrap {
            padding: 80px 0;
            @include before {
                left: 0rem;
                top: 0;
                width: 10rem;
                height: 100%;
                background-image: url(../img/decorations/pattern.svg);
                background-size: contain;
                z-index: -1;
                background-repeat: no-repeat;
            }
            @include after {
                right: 0rem;
                bottom: 3rem;
                width: 10rem;
                height: 10rem;
                background-image: url(../img/decorations/pattern.svg);
                background-size: contain;
                z-index: -1;
                background-repeat: no-repeat;
            }
        }
        .hero_title {
            @include fluid-type($font_size_h1_mobile, $font_size_h1);
            line-height: 1;
            text-align: center;
            margin-bottom: 20px;
        }
        .hero_text {
            margin-bottom: 0;
            margin: 0;
            text-align: center;
            max-width: 40rem;
            color: #5a6875;
        }
    }

    &.has_style2 {
        background-color: $color_light;
        background-image: url(../images/others/Hero_Pattern.svg);
        background-position: center bottom;
        background-size: contain;
        .title {
            text-align: center;
            color: $color_dark;
        }
        .hero_textription {
            max-width: 40rem;
            color: $color_dark;
        }
        .hero_wrap {
            padding: 100px 0;
        }
    }
    &.has_bg {
        background-color: $color_light;
        background-size: cover;
        background-position: right top;
        @include overlay {
            background: rgba(8, 3, 43, 0.562) !important;
        }
        .title {
            text-align: center;
            color: $color_dark;
        }
        .hero_textription {
            max-width: 40rem;
            color: $color_dark;
        }
        .hero_wrap {
            padding: 100px 0;
        }
    }
    &.has_bg {
        background-size: cover;
        background-repeat: no-repeat;
        .hero_wrap {
            padding: 150px 0;
            @include tablet_vert_screen {
                padding: 70px 0;
            }
        }
        @include overlay {
        }
        .title {
            color: white;
            @include fluid-type($font_size_h2_mobile, $font_size_h1);
        }
        .hero_textription {
            max-width: 40rem;

            color: white;
        }
    }
    &.is_dark {
        background: $color_dark;

        .hero_wrap {
            padding: 100px 0;
        }
        .title {
            @include fluid-type($font_size_h1_mobile, $font_size_h1);
            line-height: 1;
            color: white;
            margin-bottom: 20px;
        }
        .description {
            color: white;
            margin-bottom: 0;
            margin: 0;
        }
    }
}
// ==================== elements hero
.elements_hero {
    background: $color_grad;
    z-index: 0;
    overflow: hidden;
    @include before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url(../img/hero.png);
        z-index: -1;
    }
    .wrap {
        width: 50%;
        @include tablet_vert_screen {
            width: 100%;
        }
    }
}

.pattern_hero {
    @include before {
        left: -9rem;
        top: 0;
        width: 10rem;
        height: 100%;
        background-image: url(../img/decorations/pattern.svg);
        background-size: contain;
        z-index: -1;
        background-repeat: no-repeat;
    }
    @include after {
        right: -9rem;
        bottom: -6rem;
        width: 10rem;
        height: 10rem;
        background-image: url(../img/decorations/pattern.svg);
        background-size: contain;
        z-index: -1;
        background-repeat: no-repeat;
    }
}

.banner_title_inner {
    @include before {
        right: -9rem;
        top: -4rem;
        width: 10rem;
        height: 10rem;
        background-image: url(../img/decorations/pattern.svg);
        background-size: contain;
        z-index: -1;
        background-repeat: no-repeat;
        transform: rotate(285deg);
    }
}
